import React, { useState } from 'react'

import { Box, Input } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import FormErrorText from '../../components/form/FormErrorText'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import FormSection from '../../components/form/Containers/FormSection'

import { createFormCategory, deleteFormCategory } from '../../../data/data'
import { ICategory } from '../../../domain/interfaces/IForm'
import {
  SubmissionsPageViewModelProps,
  useSubmissionsPageViewModel,
} from '../submissions/SubmissionsViewModel'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'

// react-hook-form state
type CategoryFormInputs = {
  name: string
  icon: string
  // categoryIcon: string | null
}
interface CategoryFormProps {
  id?: string | undefined
  name?: string | undefined
  icon?: string | undefined
  onCancel?: () => void
}
export const CategoryForm: React.FC<CategoryFormProps> = ({
  id,
  name,
  icon,
}) => {
  const vm: SubmissionsPageViewModelProps = useSubmissionsPageViewModel()
  const [loading, setLoading] = useState<boolean>(false)

  /**
   * Validates the input values for the person's profile form.
   */
  const resolver: Resolver<CategoryFormInputs> = async (values) => {
    const errors: FieldErrors<CategoryFormInputs> = {}
    if (!values.name) {
      errors.name = {
        type: 'required',
        message: 'Name is required',
      }
    }
    if (values.icon != 'fa-table' && values.icon != 'fa-line-chart') {
      errors.icon = {
        type: 'required',
        message:
          'Only "fa-table" or "fa-line-chart" are supported at this time',
      }
    }
    return {
      values,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CategoryFormInputs>({
    resolver,
    defaultValues: {
      name: name ?? '',
      icon: icon ?? '',
    },
    mode: 'onChange',
  })

  /**
   * Handles the form submission when the form is valid.
   * will run resolver validations before submitting
   * @param {Object} data - The form data submitted by the signUp.
   * @returns {void}
   */
  const onSubmit = handleSubmit(async (data) => {
    const formData = {
      name: data.name,
      icon: data.icon,
      // categoryIcon:categoryIcon
    }
    setLoading(true)
    const res = await createFormCategory(formData)
    setLoading(false)
    if (res.isRight()) {
      vm.setCategories([...vm.categories, res.right as ICategory])
      SuccessToast({
        title: 'Created Category',
      })
      reset()
      // add categories to vm
      // navigate(-1)
    } else {
      setLoading(false)
      ErrorToast({ title: 'Error Creating Category' })
    }
  })
  // const handleIconChange = (e: any) => {
  //   setCategoryIcon(e.target.value)
  // }

  // const navigate = useNavigate()

  return (
    <InnerPageContainer headerTitle='Manage Categories' collapsible collapsed={true}>
      <Box sx={{padding:'12px'}}>
        <form onSubmit={onSubmit}>
          <Box
            sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}
          >
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='Category Name' />
              <Input
                disableUnderline
                className={`form-control ${errors?.name ? 'error' : ''}`}
                {...register('name')}
              />
              {errors?.name && (
                <FormErrorText>{errors.name.message}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='Category Icon' />
              <Input
                disableUnderline
                className={`form-control ${errors?.icon ? 'error' : ''}`}
                {...register('icon')}
              />
              {errors?.icon && (
                <FormErrorText>{errors.icon.message}</FormErrorText>
              )}
            </EdifyFormField>
            {/* <IconPicker
            value=''
            onChange={handleIconChange}
            formControlProps={{
              sx: {},
            }}
          /> */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                // marginTop:'margin-top: 20px'
                marginTop: '60px',
              }}
            >
              <EdifyButton
                sx={{ marginTop: '20px' }}
                disabled={Object.keys(errors).length > 0}
                loading={loading}
                type='submit'
                data-testid={'SubmissionButton'}
                primary
                title={'Add'}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '48px',
            }}
          ></Box>
          <>
            {vm.categories.map((c) => (
              <Category key={c.id} category={c} vm={vm} />
            ))}
          </>
        </form>
      </Box>
    </InnerPageContainer>
  )
}

export default CategoryForm

interface CategoryProps {
  category: ICategory
  vm: SubmissionsPageViewModelProps
}
const Category: React.FC<CategoryProps> = ({ category, vm }) => {
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(true)
  const deleteCategory = async (id: string) => {
    setLoading(true)
    const res = await deleteFormCategory(id)

    setLoading(false)
    if (res.isRight()) {
      const newCategories = vm.categories.filter((c) => c.id !== id)
      vm.setCategories(newCategories)
      SuccessToast({
        title: 'Deleted Category',
      })
      // add categories to vm
      // navigate(-1)
    } else {
      setLoading(false)
      ErrorToast({ title: 'Error Creating Category' })
    }
  }
  // if(edit) return <CategoryForm id={category.id} name={category.name} icon={category.icon}/>
  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}>
      <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
        <EdifyFieldLabel label='Category Name' />
        <Input
          disabled
          className='form-control'
          value={category.name}
          disableUnderline
        />
      </EdifyFormField>
      <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
        <EdifyFieldLabel label='Category Icon' />
        <Input
          disabled
          className='form-control'
          value={category.icon}
          disableUnderline
        />
      </EdifyFormField>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          // marginTop:'margin-top: 20px'
          marginTop: '60px',
        }}
      >
        <EdifyButton
          sx={{ marginTop: '20px' }}
          onClick={() => deleteCategory(category.id)}
          loading={loading}
          primary
          title={'Delete'}
        />
      </Box>
    </Box>
  )
}
