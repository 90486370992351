import { Either, Failure } from '../../../core/core'
import { httpGet, httpPost } from '../../data'

export interface EGlobalSearchPayload {
  entities?: string[]
  properties?: string[]
  returns?: string[]
  searchkey?: string
}

export interface EGlobalSearchReturnPayload {
  code?: number
  data: any
  message: string
}

export interface EHomeFormLink {
  id:string
  title:string
  path:string
}

export interface EHomeFormLinks {
  forms: EHomeFormLink[]
}
export async function searchGlobal(searchPayload: EGlobalSearchPayload): Promise<Either<Failure, EGlobalSearchReturnPayload>> {
  const res: Either<Failure, EGlobalSearchReturnPayload> = await httpPost(
    '/globalSearch',
    searchPayload, 
    {},//no additional headers
    false, // direct data parsing is false
  )
  return res
}

export async function searchGlobalMinimal(entitiesString: string, searchKey:string): Promise<Either<Failure, EGlobalSearchReturnPayload>> {
  const res: Either<Failure, EGlobalSearchReturnPayload> = await httpGet(
    `/minimal/globalSearch?entities=${entitiesString}&searchKey=${searchKey}`,
    false, // direct data parsing is false
  )
  return res
}

export async function homePageDataGet(): Promise<Either<Failure, EHomeFormLinks>> {
  const res: Either<Failure, EHomeFormLinks> = await httpGet(
    '/homePageData',
    true, // direct data parsing is false
  )
  return res
}

