/* eslint-disable react/prop-types */
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, DialogActions } from '@mui/material'

import { EdifyButton } from '../../buttons'
import { AppColors, AppFonts } from '../../../Theme'
import { ETypography } from '../../fonts/ETypography'

let setController:
  | React.Dispatch<React.SetStateAction<IConfirmationDialogV3Props | undefined>>
  | undefined

let showDialog: React.Dispatch<React.SetStateAction<boolean>> | undefined
/**
 * Show [Confirmation Dialog].
 *
 * @param submission
 */
export function showConfirmationDialogV3(props: IConfirmationDialogV3Props) {
  setController?.(props)
  showDialog?.(true)
}

export function hideConfirmationDialogV3() {
  setController?.(() => undefined)
  showDialog?.(false)
}

export interface IConfirmationDialogV3Props {
  title: string
  message: string
  cancelText?: string
  confirmText?: string
  secondaryText?: string
  onCancel: () => void
  onSubmit: () => void
  oneButton?: boolean
  secondaryAction: () => void
}

export const ConfirmationDialogV3 = () => {
  const [open, setOpen] = React.useState(false)
  const [props, setProps] = React.useState<IConfirmationDialogV3Props>()

  setController = setProps
  showDialog = setOpen

  const closeDialog = () => {
    props?.onCancel()
    hideConfirmationDialogV3()
  }

  const onSubmitClick = () => {
    props?.onSubmit()
    hideConfirmationDialogV3()
  }

  return (
    <Dialog
      data-testid='ConfirmationDialogV2'
      open={open}
      maxWidth={'sm'}
      PaperProps={{
        sx: dialogStyling,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle
          sx={{
            ...AppFonts.textXLSemibold,
            color: AppColors.gray700,
            p: 0,
          }}
        >
          {props?.title}
        </DialogTitle>
        <ETypography
          font='BTSS'
          color='gray900'
          onClick={closeDialog}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          X
        </ETypography>
      </Box>
      <DialogContent sx={{ padding: '24px 0' }}>
        <DialogContentText
          sx={{ ...AppFonts.textSMedium, color: AppColors.gray50, padding: 0 }}
        >
          {props?.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          p: 0,
        }}
      >
        <EdifyButton
          data-testid='CloseDialogButton'
          title={props?.cancelText ?? 'Cancel'}
          primary
          noBackground
          onClick={closeDialog}
        />
        {props?.secondaryText && (
          <EdifyButton
            data-testid='ConfirmationDialogV2Button'
            title={props?.secondaryText ?? 'Secondary'}
            secondary
            onClick={props.secondaryAction}
          />
        )}

        <EdifyButton
          data-testid='ConfirmationDialogV2Button'
          title={props?.confirmText ?? 'Confirm'}
          primary
          onClick={onSubmitClick}
        />
      </DialogActions>
    </Dialog>
  )
}

export const showConfirmationV3Message = async (
  title: string,
  message: string,
  confirmText: string,
  cancelText: string,
  secondaryText: string,
  submit: () => void,
  cancel: () => void,
  secondaryAction: () => void,
) => {
  return showConfirmationDialogV3({
    title: title,
    message: message,
    cancelText,
    confirmText,
    secondaryText: secondaryText,
    onCancel: function (): void {
      cancel()
      hideConfirmationDialogV3()
    },

    onSubmit: async () => {
      await submit()
      hideConfirmationDialogV3()
    },
    secondaryAction: async () => {
      await secondaryAction()
      hideConfirmationDialogV3()
    },
  })
}

const dialogStyling = {
  width: '453px',
  height: 'auto',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 24,
}
