import { Box } from '@mui/material'
import React from 'react'
import logo from '../../../assets/icons/bullseye.svg'

import { ComponentProps } from '../ComponentProps'

interface Props extends ComponentProps {
  width?: number
  height?: number
  isDarkTheme?: boolean
}

export const BullseyeIcon: React.FC<Props> = ({ sx, width, height }) => {
  return (
    <Box
      data-testid='BullseyeIcon'
      component='img'
      sx={{
        width: 18, 
        height: 18,
        ...sx,
      }}
      src={logo}
    />
  )
}