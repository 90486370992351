import React from 'react'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { IRoleSettingsViewModelProps } from './RoleSettingsViewModel'
import {
  normalizeRolesForDataGrid,
  role_columns,
} from '../../components/datagrid/gridData/roles'
import { ROUTE_ROLES } from './Roles'
import pluralize from 'pluralize'
import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
import { ErrorToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import { EdifyFieldLabel, EdifySelect } from '../../components/form'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'
import {
  formatSnakeCase,
} from '../../../core/utils/text-formaters/TextFormatters'
import { AppColors, AppFonts } from '../../Theme'

// import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
interface IFormsDataGridProps {
  vm: IRoleSettingsViewModelProps
  title: string
  checkboxSelection?: boolean
}

const RolesDataGrid: React.FC<IFormsDataGridProps> = ({
  title,
  checkboxSelection = false,
  vm,
}) => {
  const { error, roles, loading, loadMore, sortClickedHandler, totalCount } = vm
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [newRoleOptions, setNewRoleOptions] = React.useState<
    { value: string; name: string }[]
  >([])
  const [selectReplaceId, setSelectedReplaceID] = React.useState<
    string | null
  >()

  const handleClose = () => {
    setOpen(false)
    setSelectedReplaceID(null)
  }

  const handleDeleteClicked = () => {
    setOpen(true)
  }

  const statusChanged = (e: any) => {
    setSelectedReplaceID(e.target.value)
  }
  const deleteRoles = () => {
    setSelectedReplaceID(null)
    setOpen(false)
  }

  const handleRowClicked = (params: GridRowParams) => {
    navigate(`${ROUTE_ROLES}/${params.id}`)
  }

  // If we want bulk delete button down the line
  const renderHeaderButtons = () => {
    if (!checkboxSelection) return
    const noSelections = selectionModel.length === 0
    return (
      <BulkDeleteButton
        noSelections={noSelections}
        onClick={handleDeleteClicked}
      />
    )
  }
  const selectionModelChanged = (ids: GridRowSelectionModel) => {
    const role = roles.find((role) => role.id === ids[ids.length - 1])
    if (role?.isSystemRole) {
      ErrorToast({ title: 'System roles cannot be deleted.' })
    }
    const systemRoleIds: string[] = roles
      .filter((role) => role.isSystemRole)
      .map((role) => role.id as string)
    const nonSystemRoleSelected = ids.filter(
      (id) => !systemRoleIds.includes(id + ''),
    )

    const selectableSystemRoleIds = roles
      .filter((role) => {
        return !nonSystemRoleSelected.includes(role.id)
      })
      .map((role) => {
        return { value: role.id, name: role.name }
      })
    setNewRoleOptions(selectableSystemRoleIds)
    setSelectionModel(nonSystemRoleSelected)
  }


  return (
    <>
      <Dialog
        PaperProps={{
          sx: dialogStyling,
        }}
        maxWidth={'xs'}
        key={newRoleOptions.length}
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          sx={{
            ...AppFonts.textXLSemibold,
            color: AppColors.gray700,
            // textAlign: 'center',
            p: 0,
          }}
          id='alert-dialog-title'
        >
          {'Delete Role'}
        </DialogTitle>
        <DialogContent sx={{ p: 0, pb: 16 }}>
          <DialogContentText
            sx={{
              ...AppFonts.textSMedium,
              color: AppColors.gray50,
              p: 5,
              mb: 12,
            }}
          >
            Are you sure you want to delete this Role? Before doing this you
            will need to select a role to replace the current users the deleted
            role with.
          </DialogContentText>
          <DialogContentText id='alert-dialog-description' sx={{ mb: 16 }}>
            <EdifyFieldLabel label='Select Replacement Role' />
            <EdifySelect
              width={'100%'}
              // sx={{ marginRight: '16px' }}
              dropDownItems={newRoleOptions}
              onChange={statusChanged}
              renderMenuItem={(item) => {
                return (
                  <EdifyMenuItem key={item.value} value={item.value}>
                    {formatSnakeCase(item.name)}
                  </EdifyMenuItem>
                )
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            p: 0,
          }}
        >
          <EdifyButton
            fullWidth
            title='Cancel'
            secondary
            onClick={handleClose}
          />
          <EdifyButton
            fullWidth
            disabled={selectReplaceId == null}
            alert={selectReplaceId == null ? false : true}
            title='Accept'
            onClick={deleteRoles}
          />
        </DialogActions>
      </Dialog>
      <EdifyDataGrid
        data-testid='RolesDataGrid'
        noRowsText={`No ${pluralize(title)} found.`}
        title={title}
        columns={role_columns}
        rows={normalizeRolesForDataGrid(roles)}
        error={error}
        // renderHeaderButtons={renderHeaderButtons()}
        rowSelectionModel={selectionModel}
        setSelectionModel={selectionModelChanged}
        handleRowClicked={handleRowClicked}
        loading={loading}
        loadMoreRows={loadMore}
        rowCount={totalCount || 0}
        // reload={vm.getForms}
        sortClickedHandler={sortClickedHandler}
        checkboxSelection={checkboxSelection}
        paginationMode='server'
        initSort={[
          {
            field: 'name',
            sort: 'desc',
          },
        ]}
      />
    </>
  )
}

export default RolesDataGrid

const dialogStyling = {
  width: '403px',
  height: 'auto',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 16,
}
