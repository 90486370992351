import React, { useState } from 'react'

import PageContainer from '../../components/page/PageContainer'

import {
  UserPageViewModel,
  UserPageViewModelProps,
  useUserPageViewModel,
} from './UserPageViewModel'

import { ROUTE_USERS } from '../users/UsersPage'

import { useParams } from 'react-router-dom'
import UsersForm from './UsersForm'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { ProfileHeaderV2 } from '../../components/profile/ProfileHeaderV2'
import { Box } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import UserBulkXLSXUpload from './UserBulkXLSXUpload'

const _UserFormPage: React.FC = () => {
  const { id } = useParams()
  const vm: UserPageViewModelProps = useUserPageViewModel()
  const canCreate = usePermission(ERole.UserCreate)
  const [uploadImage, setUploadImage] = useState<string | null>(null)
  const [singleCreate, setSingleCreate] = useState<boolean>(true)

  const getBreadCrumbs = () => {
    return [
      { title: 'Organization', to: '' },

      {
        title: 'Users',
        to: `${ROUTE_USERS}`,
      },
      {
        title: id !== 'new' ? 'Add New User' : (vm.user?.fullName as string),
        to: id ? `${ROUTE_USERS}/new` : `${ROUTE_USERS}/${vm.user?.id}`,
      },
    ]
  }

  const getTitle = () => {
    return (
      <>
        <ProfileHeaderV2
          fullName={'Add New User'}
          imageURL={uploadImage}
          canEdit={true}
          imageUploaded={(url) => setUploadImage(url)}
        />
      </>
    )

    return 'Loading...'
  }

  return (
    <PageContainer
      // title={getTitle()}
      canView={canCreate}
      mainPageLoading={vm.userLoading}
      mainPageError={vm.userError}
      breadCrumbSX={{ marginBottom: '0px' }}
      breadCrumbs={getBreadCrumbs()}
    >
      <Box sx={{ marginBottom: '24px', gap: 24 }}>
        <EdifyButton
          title='Add Individual User'
          secondary={!singleCreate}
          onClick={() => setSingleCreate(true)}
        />
        <EdifyButton
          buttonStyle={{ marginLeft: '12px' }}
          title='Add Multiple Users'
          secondary={singleCreate}
          onClick={() => setSingleCreate(false)}
        />
      </Box>
      {singleCreate && <UsersForm />}
      {!singleCreate && <UserBulkXLSXUpload />}
    </PageContainer>
  )
}

export const UserFormPage: React.FC = () => {
  return (
    <UserPageViewModel>
      <_UserFormPage />
    </UserPageViewModel>
  )
}
