import { useState, useCallback, useMemo } from 'react'

interface FormData {
  key: string
  isHidden: boolean
  label: string
  value: string
  isRequired: boolean
}

interface ChangedField {
  label: string
  oldValue: string
  newValue: string
}

// Add a Record type for the originalValues
type OriginalValuesType = Record<string, string>

interface UseFormTrackingReturn {
  formValues: FormData[]
  changedFields: ChangedField[]
  handleValueChange: (label: string, newValue: string) => void
  hasChanges: boolean
  resetChanges: () => void
  setFormValues: React.Dispatch<React.SetStateAction<FormData[]>>
}

export const useCustomFields = (initialData: FormData[]): UseFormTrackingReturn => {
  // Store current form values
  const [formValues, setFormValues] = useState<FormData[]>(initialData)
  
  // Store original values for comparison with proper typing
  const originalValues = useMemo<OriginalValuesType>(() => 
    initialData.reduce((acc, item) => ({
      ...acc,
      [item.label]: item.value
    }), {} as OriginalValuesType),
  [initialData]
  )

  // Track changed fields
  const [changedFields, setChangedFields] = useState<ChangedField[]>([])

  const handleValueChange = useCallback((label: string, newValue: string) => {
    setFormValues(prev => 
      prev.map(item => 
        item.label === label ? { ...item, value: newValue } : item
      )
    )

    const originalValue = originalValues[label]
    
    if (newValue !== originalValue) {
      setChangedFields(prev => {
        // Remove existing entry for this label if it exists
        const filtered = prev.filter(field => field.label !== label)
        
        // Add new change
        return [...filtered, {
          label,
          oldValue: originalValue,
          newValue
        }]
      })
    } else {
      // If value matches original, remove from changedFields
      setChangedFields(prev => 
        prev.filter(field => field.label !== label)
      )
    }
  }, [originalValues])

  const hasChanges = changedFields.length > 0

  const resetChanges = useCallback(() => {
    setFormValues(initialData)
    setChangedFields([])
  }, [initialData])

  return {
    formValues,
    changedFields,
    handleValueChange,
    hasChanges,
    resetChanges,
    setFormValues
  }
}