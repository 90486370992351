import { Box, Typography } from '@mui/material'
import React from 'react'
import { EdifyButton } from '../buttons'
import { AppColors, AppFonts } from '../../Theme'

interface NoRowsOverlayProps {
  error?: string
  text?: string
  reload?: () => void
  isLoading?: boolean
}

/**
 * Represents an overlay component to display when there are no rows in the datagrid.  This also handles error in the datagrid, should be placed in the slots props of the datagrid
 * @function NoRowsOverlay
 * @param {NoRowsOverlayProps} props - The props for the NoRowsOverlay component.
 * @returns {JSX.Element} - The rendered NoRowsOverlay component.
 * @example 
 *   <DataGrid
 *      ...
 *      slots={{
        noRowsOverlay: () => (
          <NoRowsOverlay error={vm.error} reload={vm.reload} />
        ),
    />
 */

export const NoRowsOverlay: React.FC<NoRowsOverlayProps> = ({
  error,
  reload,
  text
}) => {
  if (error) {

    return (
      <Box
        data-testid="NoRowsOverlayError"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '120px',
          borderTop:'1px solid #E0E0E0',
        }}
      >
        {/* TODO get Ui for error in data grid */}
        <Typography
          sx={{
            ...AppFonts.textLMedium,
            color: AppColors.danger600,
            marginBottom: '20px',
          }}
        >
          {error}
        </Typography>
        {reload && <EdifyButton secondary title='reload' onClick={reload} />}
      </Box>
    )
  }

  return (
    <Box
      data-testid="NoRowsOverlayEmpty"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '64px',
        borderTop:`1px solid ${AppColors.neutral600}`,
      }}
    >
      <Typography style={{...AppFonts.textSMedium, color:AppColors.gray50}}>{text ?? 'No rows found.'}</Typography>
    </Box>
  )
}
