import React, { ReactNode } from 'react'
import InnerPageContainer, { InnerPageContainerProps } from '../inner-page-container/InnerPageContainer'
import { SxProps } from '@mui/material'

interface ProfileContainerProps extends InnerPageContainerProps {
  children: ReactNode
  sx?: SxProps

}

const ProfileContainer: React.FC<ProfileContainerProps> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <InnerPageContainer
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '0',
        paddingBottom: '24px',
        marginBottom: '24px',
        flexGrow: 'grow',
        position: 'relative',
        ...sx,
     
      }}
      {...rest}
    >
      {children}
    </InnerPageContainer>
  )
}

export default ProfileContainer
