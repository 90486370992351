// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigStg = {
  apiKey: 'AIzaSyCKLFNyzU0uYMij1HzB5pTwHberfeot6J0',
  authDomain: 'rome-stg.firebaseapp.com',
  projectId: 'rome-stg',
  storageBucket: 'rome-stg.appspot.com',
  messagingSenderId: '533788388192',
  appId: '1:533788388192:web:574c28bfda4b2727664c05',
  measurementId: 'G-F31PDFY9X2',
}
export default firebaseConfigStg
