import React, { useState, useEffect, useRef } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import Popper from '@mui/material/Popper'
import Input from '@mui/material/Input'
import {
  Paper,
  ClickAwayListener,
  Box,
  SxProps,
  FormControl,
  Typography,
} from '@mui/material'

import { EdifyButton } from '../../buttons'
import { AppBorders, AppColors, AppFonts } from '../../../Theme'
import InnerPageContainer from '../../inner-page-container/InnerPageContainer'
import { Either, Failure } from '../../../../core/core'
import {
  getContractorById,
  getFromLocalStorage,
  saveToLocalStorage,
} from '../../../../data/data'
import { MagnifyGlassIcon } from '../../../../core/components/icons/MagnifyGlassIcon'
import EdifyMenuItem from '../EdifyMenuItem'
import ClearIcon from '@mui/icons-material/Clear'
import { EGlobalSearchPayload } from '../../../../data/repositories/utils/UtilsRepository'
import FormErrorText from '../FormErrorText'
import {
  getForm,
  getLevelById,
  getProjectById,
} from '../../../../domain/domain'
import { getContactById } from '../../../../data/repositories/contacts/ContactsRepository'

export interface ISearchResult {
  id: string
  name: string
  unEditable?: boolean
}

interface IEdifySearchDropdownProps {
  sx?: SxProps
  width?: number | string
  pillListWidth?: number | string
  multiSelect?: boolean
  onSelect: (searchItems: ISearchResult[]) => void
  searchFunction: (...args: any[]) => Promise<Either<Failure, any>>
  limit?: number
  nameKey?: string
  searchHistoryKey?: string
  initialSelectedItems?: ISearchResult[]
  darkMode?: boolean
  small?: boolean
  globalSearchPayload?: EGlobalSearchPayload
  validationError?: string | null
  disabled?: boolean
  placeholder?: string
  globalSearchKey?: string
  defaultItems?: ISearchResult[]
  defaultId?: string | null
}

/**
 * EdifySearchDropdown Component
 * A customizable search dropdown component that allows users to search for items and select them from the search results.
 *
 * @component
 *
 * @param {Object} props - Component props
 * @param {Object} [props.sx] - Custom styling using Material-UI's SxProps
 * @param {number} [props.width=400] - Width of the search dropdown
 * @param {boolean} [props.multiSelect=false] - If true, allows multiple items to be selected
 * @param {(searchItems: ISearchResult[]) => void} props.onSelect - Callback function triggered when items are selected
 * @param {(search: string) => Promise<Either<Failure, any>>} props.searchFunction - Async function that performs the search and returns a Promise
 *
 * @typedef {Object} ISearchResult - Represents a search result item
 * @property {string} id - The unique ID of the search result item
 * @property {string} name - The name or label of the search result item
 *
 * @returns {JSX.Element} - Rendered React component
 */

const EdifySearchDropdown: React.FC<IEdifySearchDropdownProps> = ({
  sx,
  onSelect,
  pillListWidth,
  width,
  searchFunction,
  multiSelect = false,
  limit,
  initialSelectedItems = [],
  nameKey = 'name',
  searchHistoryKey,
  darkMode,
  small = false,
  globalSearchPayload,
  validationError,
  disabled,
  globalSearchKey,
  defaultItems = [],
  placeholder = defaultItems.length ? 'Search' : 'Type to Search',
  defaultId = null,
}) => {
  const initialSearchTerm =
    !multiSelect && initialSelectedItems[0] ? initialSelectedItems[0].name : ''

  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm)
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([])
  const [selectedItems, setSelectedItems] = useState<ISearchResult[]>([])

  const [itemsHistory, setItemsHistory] = useState<ISearchResult[]>([])
  const [prefetchedItems, setPrefetchedItems] = useState<ISearchResult[]>(
    defaultItems.slice(0, limit ?? 20),
  )

  const [initialItems, setInitialItems] = useState<ISearchResult[]>([])

  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  // TODO: needs to be org specific to search
  const loadSearchHistory = (initialSelectedItems: ISearchResult[]) => {
    if (!searchHistoryKey) return
    const searchHistoryData = getFromLocalStorage(searchHistoryKey)
    const currentItems: ISearchResult[] = searchHistoryData
      ? JSON.parse(searchHistoryData)
      : []

    if (currentItems == null) return []
    // remove selected items (for edit)
    const selectedIds = initialSelectedItems.map((i) => i.id) || []
    const nonSelectedResults = currentItems.filter(
      (r) => !selectedIds.includes(r.id),
    )
    setItemsHistory(nonSelectedResults)
  }

  function updateSearchHistory(selectedItem: ISearchResult) {
    if (!searchHistoryKey) return

    // Get and display previous search items from localStorage
    const searchHistoryData = getFromLocalStorage(searchHistoryKey)
    const currentItems: ISearchResult[] = searchHistoryData
      ? JSON.parse(searchHistoryData)
      : []

    // empty case
    if (currentItems.length == 0) {
      saveToLocalStorage(searchHistoryKey!, JSON.stringify([selectedItem]))
      return
    }

    const isInSelectedItems = selectedItems.find(
      (item) => item.id === selectedItem.id,
    )
    const isInSearchHistory = currentItems.find(
      (item) => item.id === selectedItem.id,
    )

    if (isInSelectedItems) {
      let newItems = currentItems
      if (isInSearchHistory) {
        newItems = currentItems.filter((i) => i.id != selectedItem.id)
      }

      saveToLocalStorage(
        searchHistoryKey!,
        JSON.stringify([selectedItem, ...newItems]),
      )
      setItemsHistory([selectedItem, ...newItems])
    }
  }

  let debounceTimer: NodeJS.Timeout

  // Simulate the API call here you should replace this with your
  const performSearch = async (searchValue: string) => {
    setError(undefined)
    setLoading(true)
    let res

    if (globalSearchPayload) {
      //globalSearch
      res = await searchFunction({
        ...globalSearchPayload,
        searchkey: searchValue,
      })
    } else if (limit) {
      res = await searchFunction(0, limit, searchValue)
    } else {
      res = await searchFunction(searchValue)
    }
    setLoading(false)
    if (res.right) {
      let data = []
      // data can come back in two ways
      if (globalSearchKey) {
        data = res.right.data[globalSearchKey]
      } else {
        data = res.right.data ? res.right.data : res.right
      }
      const results: ISearchResult[] = data.map(
        (r: { id: string; [key: string]: any }) => ({
          id: r.id,
          name: r[nameKey] || '',
        }),
      )
      setSearchResults(results)
    } else {
      setError('Error loading results')
    }
  }

  // Clear the existing debounce timer and create a new one
  const debounce = (callback: () => void, delay: number) => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(callback, delay)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value
    setSearchTerm(newSearchTerm)

    if (newSearchTerm.length >= 1) {
      debounce(() => {
        performSearch(newSearchTerm)
        setOpen(true)
      }, 500)
    } else {
      setSearchResults(prefetchedItems)
    }
  }

  const boldMatchingText = (text: string, searchTerm: string) => {
    const startIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase())
    if (startIndex === -1)
      return (
        <Typography
          sx={{
            ...AppFonts.textSMedium,
            maxWidth: width,
            overflow: 'hidden',
            color: darkMode ? AppColors.gray25 : AppColors.gray50,
          }}
        >
          {text}
        </Typography>
      )
    const endIndex = startIndex + searchTerm.length
    return (
      <Typography style={{ overflow: 'hidden', maxWidth: width }}>
        <span
          style={{
            ...AppFonts.textMMedium,
            color: darkMode ? AppColors.gray25 : AppColors.gray50,
          }}
        >
          {text.substring(0, startIndex)}
        </span>
        <span
          style={{
            ...AppFonts.textMSemibold,
            color: darkMode ? AppColors.baseWhite : AppColors.gray700,
          }}
        >
          {text.substring(startIndex, endIndex)}
        </span>
        <span
          style={{
            ...AppFonts.textMMedium,
            color: darkMode ? AppColors.gray25 : AppColors.gray50,
          }}
        >
          {text.substring(endIndex)}
        </span>
      </Typography>
    )
  }

  const handleMenuItemClick = (item: ISearchResult) => () => {
    if (!multiSelect) {
      setSearchTerm(item.name)
      setOpen(false)
      onSelect([item])
      return
    }
    const selectedIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id,
    )
    const newSelected: ISearchResult[] = [...selectedItems]
    if (selectedIndex === -1) {
      newSelected.push(item)
      updateSearchHistory(item)
      // REMOVE SELECTED ITEM FROM ITEM HISTORY
      const newItemHistory = itemsHistory.filter((i) => i.id !== item.id)
      setItemsHistory(newItemHistory)
    } else {
      newSelected.splice(selectedIndex, 1)
    }
    setSelectedItems(newSelected)
    // setNewItemsHistory(newSelected)
    onSelect(newSelected)
  }
  const selectedClosed = () => {
    setSearchTerm('')
    setOpen(false)
  }

  const handlePopperClose = () => {
    setOpen(false)
    onSelect(selectedItems)
  }

  const inputClicked = () => {
    setOpen(true)
    if (searchTerm.length == 0) {
      setSearchResults(prefetchedItems)
      return
    }
    if (searchTerm.length >= 1) {
      performSearch(searchTerm)
    }
  }

  const renderMenu = () => {
    if (loading)
      return (
        <InnerPageContainer
          sx={{ border: 'none' }}
          loadingTiles={0}
          innerPageLoading={true}
          dark={small}
        ></InnerPageContainer>
      )
    if (error) {
      return (
        <InnerPageContainer
          sx={{ border: 'none' }}
          innerPageError={'Error loading results'}
          dark={small}
        ></InnerPageContainer>
      )
    }
    if (!searchResults.length)
      return (
        <InnerPageContainer
          innerPageEmpty={true}
          sx={{ border: 'none' }}
          dark={small}
        ></InnerPageContainer>
      )
    return searchResults.map((result) => (
      <EdifyMenuItem
        darkMode={darkMode}
        sx={{
          padding: '9px, 16px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        key={result.id}
        onClick={() => {
          // This breaks if I remove extra ()
          handleMenuItemClick(result)()
        }}
        selected={
          selectedItems.findIndex(
            (selectedItem) => selectedItem.id === result.id,
          ) !== -1
        }
      >
        {boldMatchingText(result.name, searchTerm)}
        {multiSelect && (
          <input
            type='checkbox'
            checked={
              selectedItems.findIndex(
                (selectedItem) => selectedItem.id === result.id,
              ) !== -1
            }
            readOnly
          />
        )}
      </EdifyMenuItem>
    ))
  }

  const handleClear = () => {
    setOpen(false)
    setSelectedItems(initialItems)
    // not sure if this needed
    // setNewItemsHistory(initialItems)
    setSearchTerm('')
    onSelect([])
  }
  const clear = () => {
    setSearchTerm('')
    onSelect([])
  }
  const getDefaultNameById = async (id: string) => {
    let res
    try {
      if (globalSearchKey == 'forms') {
        res = await getForm(id)
      }
      if (globalSearchKey == 'levels') {
        res = await getLevelById(id)
      }
      if (globalSearchKey == 'projects') {
        res = await getProjectById(id)
      }
      if (globalSearchKey == 'contractors') {
        res = await getContractorById(id)
      }
      if (res?.right) {
        setSearchTerm(res.right.name ?? '')
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    if (defaultId) {
      setInitialItems(initialSelectedItems)
      getDefaultNameById(defaultId)
      return
    }
    if (searchHistoryKey) {
      loadSearchHistory(initialSelectedItems)
    }
    setInitialItems(initialSelectedItems)
    setSelectedItems(initialSelectedItems)
  }, [])
  const renderSelectedItems = () => {
    if (!selectedItems || selectedItems.length == 0) {
      return null
    }
    return selectedItems.map((item) => (
      <EdifyButton
        disabled={disabled || item.unEditable}
        icon={
          !disabled && !item.unEditable ? (
            <ClearIcon
              sx={{ color: AppColors.baseWhite, width: '20px', height: '20px' }}
            />
          ) : null
        }
        textStyle={{
          ...AppFonts.textButtonSemiboldSmall,
          fontWeight: 600,
        }}
        buttonStyle={{
          marginTop: '8px',
          marginRight: '8px',
          padding: '16px 10px',
        }}
        title={item.name}
        key={item.id}
        onClick={() => handleMenuItemClick(item)()}
      />
    ))
  }

  const renderItemHistory = () => {
    if (!itemsHistory || itemsHistory.length == 0) {
      return null
    }
    return itemsHistory.map((item) => (
      <EdifyButton
        noBackground
        textStyle={{
          ...AppFonts.textButtonSemiboldSmall,
          fontWeight: 600,
        }}
        buttonStyle={{
          ...AppBorders.primary,
          marginTop: '8px',
          marginRight: '8px',
          padding: '16px 10px',
        }}
        title={item.name}
        key={item.id}
        onClick={() => handleMenuItemClick(item)()}
      />
    ))
  }

  useEffect(() => {
    if (searchTerm.length == 0) {
      setSearchResults(prefetchedItems)
    }
  }, [searchTerm])

  return (
    <div>
      <FormControl
        disabled={disabled}
        variant='standard'
        className='e-input'
        sx={{
          padding: small ? '5px 10px' : '8px 0px 8px 16px',
          border: `1px solid ${darkMode ? 'none' : AppColors.neutral600}`,
          borderRadius: '5px',
          backgroundColor: darkMode ? AppColors.gray900 : AppColors.baseWhite,
          width: width,
          gap: '12px',
          flex: 1,
          ...sx,
        }}
      >
        <Input
          disabled={disabled}
          sx={{
            ...AppFonts.textMRegular,
            color: darkMode ? AppColors.baseWhite : AppColors.gray700,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          autoComplete='off'
          disableUnderline
          id='search'
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleSearchChange}
          onClick={inputClicked}
          inputRef={(node) => {
            inputRef.current = node
          }}
          startAdornment={
            <InputAdornment position='start'>
              <MagnifyGlassIcon />
              {/* <Search sx={{ color: AppColors.neutral950 }} /> */}
            </InputAdornment>
          }
          endAdornment={
            multiSelect || !searchTerm.length ? null : (
              <InputAdornment
                position='start'
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <ClearIcon
                  onClick={clear}
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: AppColors.neutral950,
                    marginLeft: small ? '10px' : '16px',
                  }}
                />
              </InputAdornment>
            )
          }
        />
      </FormControl>
      {validationError && <FormErrorText>{validationError}</FormErrorText>}
      {/* Display the search results in a popover */}
      {(prefetchedItems.length || searchTerm.length >= 1) && (
        <Popper
          open={open}
          anchorEl={inputRef.current}
          placement='bottom-start'
          sx={{ zIndex: 10001 }}
        >
          <ClickAwayListener onClickAway={handlePopperClose}>
            <Paper
              sx={{
                ...AppBorders.primary,
                width: width,
                marginTop: '12px',
                position: 'relative',
                // need this because of the start adornment
                marginLeft: small ? '-37px' : '-44px',
                padding: '0px 0px',
                boxShadow: 'none',
                textOverflow: 'ellipsis',
                flex: 1,
                background: darkMode ? AppColors.gray700 : AppColors.baseWhite,
                border: `1px solid ${
                  darkMode ? AppColors.gray700 : AppColors.neutral600
                }`,
              }}
            >
              {multiSelect && (
                <Typography
                  sx={{
                    ...AppFonts.textMSemibold,
                    color: AppColors.gray700,
                    padding: '16px',
                  }}
                >
                  Suggested
                </Typography>
              )}
              {renderMenu()}
              {multiSelect && (
                <Box
                  sx={{
                    borderTop: darkMode
                      ? AppColors.gray700
                      : AppColors.baseWhite,
                    padding: '16px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <EdifyButton
                    noBackground
                    buttonStyle={{
                      padding: '10px 16px',
                      marginRight: '24px',
                    }}
                    title='Cancel'
                    onClick={handleClear}
                  />
                  <EdifyButton
                    disabled={selectedItems.length == 0}
                    title='Done'
                    onClick={selectedClosed}
                  />
                </Box>
              )}
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
      {multiSelect && (selectedItems.length > 0 || itemsHistory.length > 0) && (
        <ul
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: pillListWidth ? pillListWidth : width,
            // marginLeft: '-40px',
          }}
        >
          {renderSelectedItems()}
          {renderItemHistory()}
        </ul>
      )}
    </div>
  )
}

export default EdifySearchDropdown
