import { ILevel, IProject } from './IProjects'

export interface IContact {
  id: string
  createdAt: string
  updatedAt?: string
  email?: string
  phone?: string
  personId: string
  lastLogin?: string
  fullName?: string
  imageURL?: string
  projects?: IProject[]
  levels?: ILevel[]
  trade?: ETrade
  vendorIdNumber?: string
  title:string
}
export interface IContactCreate {
  email: string
  name: string
  trade?: string
  projectIds?: string[]
  levelIds?: string[]
  phone?: string
}



export interface IContactList {
  code?: number
  data: IContact[]
  message: string
  total_count?: number
}

export interface IContactDeleted {
  id: string
  createdAt: string
  updatedAt?: string
  name?: string
}


// title? role?

export enum ETrade {
  Plumbing = 'Plumbing',
  Laborer = 'Laborer',
  Electrical = 'Electrical',
  Roofing = 'Roofing',
  Ironworker = 'Ironworker',
  CarpenterFraming = 'Carpenter / Framing',
  Mason = 'Mason',
  HeavyEquipmentOperator = 'Heavy Equipment Operator',
  Exterior = 'Exterior',
  Housekeeping = 'Housekeeping',
  Architecture = 'Architecture',
}

// TODO: check when BE UPDATES
export const ETradeArray = [
  'Plumbing',
  'Laborer',
  'Electrical',
  'Roofing',
  'Ironworker',
  'Carpenter / Framing',
  'Mason',
  'Heavy Equipment Operator',
  'Exterior',
  'Housekeeping',
  'Architecture',
]