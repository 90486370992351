import React, { useState } from 'react'
import {
  downloadCorrectiveActionsPDF,
} from '../../../domain/domain'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { ErrorToast } from '../../../core/utils/toast-notifications/ToastNotifications'

import { GridRowSelectionModel } from '@mui/x-data-grid'
import { correctiveActionColumns } from '../../components/datagrid/gridData'
import BulkDownloadButton from '../../components/buttons/BulkButtons/BulkDownloadButton'
import { CorrectiveActionsPageViewModelProps } from './CorrectiveActionsViewModel'
import fileDownload from 'js-file-download'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import pluralize from 'pluralize'

interface ICorrectiveActionPageFilterProps {
  vm: CorrectiveActionsPageViewModelProps
  title: string
  rows: any[]
}

const CorrectiveActionDataGrid: React.FC<ICorrectiveActionPageFilterProps> = ({
  vm,
  title,
  rows,
}) => {

  const { getHierarchyName } = useOrganizationProvider()
  const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false)
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>(() => [])


  /**
   * Gets the PDF IDs for downloading.
   * @returns {string} A string representing the PDF IDs. ex 1;2;3
   *
   */
  const getPDFIds = (): any => {
    return selectionModel as string[]
  }

  /**
   * Downloads the PDF files. can handle 1 file
   */
  const downloadPDFs = async () => {
    if (!selectionModel.length) return
    setDownloadingPDF(true)
    const ids = getPDFIds()
    const res = await downloadCorrectiveActionsPDF(ids)
    if (res.isLeft() || !res.right) {
      ErrorToast({ title: 'Error downloading PDF.' })
      setDownloadingPDF(false)
      return
    }
    fileDownload(res.right, 'corrective-action.pdf')
    setDownloadingPDF(false)
  }

  return (
    <>
      <EdifyDataGrid
        data-testid='CorrectiveActionDataGrid'
        title={title}
        noRowsText={`No ${pluralize(title)} found.`}
        columns={correctiveActionColumns(getHierarchyName(0), getHierarchyName(1))}
        rows={rows}
        paginationMode='server'
        error={vm.correctiveActionError}
        rowSelectionModel={selectionModel}
        initSort={[
          {
            field: 'dateInspected',
            sort: 'asc',
          },
        ]}
        setSelectionModel={setSelectionModel}
        loading={vm.correctiveActionsLoading || vm.initialLoad}
        rowCount={vm.totalCount || 0}
        loadMoreRows={vm.loadMore}
        reload={vm.loadCorrectiveActions}
        sortClickedHandler={vm.sortClickedHandler}
        renderHeaderButtons={
          <BulkDownloadButton
            onClick={downloadPDFs}
            downloadingPDF={downloadingPDF}
            noSelections={selectionModel.length == 0}
          />
        }
      />
    </>
  )
}

export default CorrectiveActionDataGrid
