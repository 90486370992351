import React from 'react'
import { I18nProvider } from '../../../providers/InternationalizationProvider'
import OrganizationProvider from '../../../providers/OrganizationProvider'
import { getCurrentOrgId, getCurrentPerson } from '../../../domain/domain'
import { EdifyNav } from '../../components/banners/drawer/EdifiyNav'
import PublicOrganizationProvider from '../../../providers/PublicOrganizationProvider'
import withUrlChecker from './withUrlChecker'

const OrgPageWrapper = () => {
  const orgId = getCurrentOrgId()
  const person = getCurrentPerson()

  const getIsContractor = () => {
    if (person?.globalRole) {
      return false
    }
    return person?.users.length == 0
  }
  // this will change with new contractor/contact
  const isContractor = getIsContractor()

  if (isContractor) {
    return (
      <PublicOrganizationProvider orgId={orgId} isContractor={isContractor}>
        <OrganizationProvider>
          <EdifyNav />
        </OrganizationProvider>
      </PublicOrganizationProvider>
    )
  }

  return (
    <PublicOrganizationProvider orgId={orgId}>
      <I18nProvider orgId={orgId}>
        <OrganizationProvider>
          <EdifyNav />
        </OrganizationProvider>
      </I18nProvider>
    </PublicOrganizationProvider>
  )
}

export default withUrlChecker(OrgPageWrapper)

