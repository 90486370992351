import React from 'react'

import PageContainer from '../../components/page/PageContainer'
import { ROUTE_GOALS } from '../goals/GoalsPage'
import { useParams } from 'react-router'
import { GoalsForm } from './GoalForm'

export const GoalFormPage: React.FC = () => {
  const {id} = useParams()

  const title = id ? 'Edit Goal & Control' : 'New Goal & Control'

  return (
    <PageContainer
      title={title}
      breadCrumbs={[
        { title: 'Goals & Controls', to: ROUTE_GOALS },
        { title: title, to: `${ROUTE_GOALS}/new` },
      ]}
    >
      <GoalsForm />
    </PageContainer>
  )
}
