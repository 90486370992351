// TODO Talk to Andrew get a more standard way for UI/UX
// and expand this to other components
import React from 'react'
import { Box, CircularProgress, SxProps, Typography } from '@mui/material'
import { AppColors,AppFonts } from '../../Theme'
import { ToastErrorIcon } from '../../../core/components/icons/toast/ToastErrorIcon'

interface IEdifyErrorContainerProps {
  children?: React.ReactNode
  loading?: boolean
  borderColor?: string
  error?: string
  textStyle?: SxProps
  dark?: boolean
  sx?: SxProps
}

// coming from the /notifications page show a back link
const ErrorContainer: React.FC<IEdifyErrorContainerProps> = ({
  children,
  loading,
  borderColor,
  error='Error Occurred',
  textStyle,
  sx,
  dark = false,
 

}) => {
  return (
    <Box
      data-testid={'ErrorContainer'}
      sx={{
        // backgroundColor: AppColors.baseWhite,
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '24px',
        borderRadius: '6px',
        border: `1px solid ${borderColor ? borderColor : AppColors.danger500}`,
        ...sx
      }}
    >
      <Box
        data-testid={'PageError'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '12px',
        }}
      >
        <ToastErrorIcon />
        <Typography
          sx={{
            ...AppFonts.textMSemibold,
            color: dark? AppColors.gray25 : AppColors.gray700,
            marginLeft: '16px',
            ...textStyle
          }}
        >
          {error}
        </Typography>
      </Box>
      {loading && <CircularProgress />}
      {children}
    </Box>
  )
}

export default ErrorContainer
