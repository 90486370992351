// ED-1387 https://edifyai.atlassian.net/browse/ED-1387
// Just an outline will most likely be refactored
import React, { useContext, useEffect, useState } from 'react'

import { showConfirmationDeleteMessage } from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { useNavigate, useParams } from 'react-router-dom'

import {
  ILocation,
  ISubLocationHierarchyName,
} from '../../../domain/interfaces/ILocation'
import { getLocationById } from '../../../domain/domain'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

export interface SubLocationPageViewModelProps {
  getLocation: () => void
  location: ILocation | undefined
  locationLoading: boolean
  locationError: string | undefined
  showDeleteConfirmation: (ids: string[]) => void
  locationName: string
  setLocation: (location: ILocation) => void
  locationHierarchies: ISubLocationHierarchyName[]
  setLocationHierarchies: (
    locationHierarchies: ISubLocationHierarchyName[],
  ) => void
}

const SubLocationPageContext =
  React.createContext<SubLocationPageViewModelProps | null>(null)

export function useSubLocationPageViewModel(): SubLocationPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(SubLocationPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
  locationId?: string
}
type Key =
  | 'level1Name'
  | 'level2Name'
  | 'level3Name'
  | 'level4Name'
  | 'level5Name'
export const locationKeys: Key[] = [
  'level1Name',
  'level2Name',
  'level3Name',
  'level4Name',
  'level5Name',
]

export const SubLocationPageViewModel: React.FC<Props> = ({
  children,
  locationId,
}) => {

  const navigate = useNavigate()

  const [locationHierarchies, setLocationHierarchies] = useState<
    ISubLocationHierarchyName[]
  >([])

  //STATE
  const [location, setLocation] = useState<ILocation | undefined>(undefined)
  const [locationLoading, setLocationLoading] = useState<boolean>(true)
  const [locationError, setLocationError] = useState<string | undefined>()

  // Matches levels delete logic
  const handleDelete = async () => {
    // const res = await deleteLoation(location!.id)
    // if (res.isLeft()) {
    //   ErrorToast({ title: `Failed to delete ${location!.name}.` })
    //   return
    // }
    // SuccessToast({ title: `${location!.name} successfully deleted.` })
    // navigate(-1)
  }

  const showDeleteConfirmation = async () => {
    showConfirmationDeleteMessage(getLocationName(), handleDelete)
  }

  const getLocationName = () => {
    if (!location) return ''
    return location.name
  }

  const getLocation = async () => {
    setLocationError(undefined)
    setLocationLoading(true)
    const res = await getLocationById(locationId ?? 'none')
    setLocationLoading(false)

    if (res.isLeft()) {
      setLocationError('Error loading location')
    }
    setLocation(res.right as ILocation)
    const h = locationKeys.map((key, index) => {
      return {
        id: index + '',
        name: res.right?.[key] ?? '',
        level: index + 1,
      }
    })
    setLocationHierarchies(h)
  }

  useEffect(() => {
    if (!locationId) return
    getLocation()
  }, [])

  return (
    <SubLocationPageContext.Provider
      value={{
        location,
        locationLoading,
        locationError,
        locationName: getLocationName(),
        getLocation,
        showDeleteConfirmation,
        setLocation,
        locationHierarchies,
        setLocationHierarchies,
      }}
    >
      {children}
    </SubLocationPageContext.Provider>
  )
}
export default SubLocationPageContext
