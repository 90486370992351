import React, { useState, useEffect } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { documentsGetOne } from '../../../data/repositories/doucuments/DocumentsRepository'
import ErrorContainer from '../../components/error/ErrorContainer'
import ImageWithToken from '../../components/form/EdifyImage/ImageWithToken'

interface PdfViewerProps {
  pdfUrl: string
  pdfId: string
  skipLoad?: boolean
}

const PdfViewer: React.FC<PdfViewerProps> = ({
  pdfUrl,
  pdfId,
  skipLoad = false,
}) => {
  const [url, setUrl] = useState<any>(null)
  const [urlError, setUrlError] = useState<any>(null)
  const [pdfLoading, setPDFLoading] = useState<any>(true)

  const getOnePdf = async () => {
    setPDFLoading(true)
    setUrlError(null)
    const pdf = await documentsGetOne(pdfId)
    setPDFLoading(false)
    if (pdf.right) {
      setUrl(pdf.right.webPath)
    } else {
      setUrlError(pdf.left?.message)
    }
  }
  useEffect(() => {
    if (!skipLoad) {
      getOnePdf()
    } else{
      setPDFLoading(false)
    }
  }, [])

  const renderBody = () => {
    if (pdfLoading) {
      return (
        <Box display='flex' justifyContent='center' sx={{ marginTop: '24px' }}>
          <CircularProgress />
        </Box>
      )
    }

    if (url) {
      // return <iframe src={url} width='100%' height='100%' title='PDF Viewer' />
      return <ImageWithToken src={url} isPDF={true}/>
    }
    if (pdfUrl) {
      // return <iframe src={pdfUrl} width='100%' height='100%' title='PDF Viewer' />
      return <ImageWithToken src={pdfUrl} isPDF={true} />
    }

    return <ErrorContainer error='Error loading PDF' />
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        background: 'white',
      }}
    >
      {renderBody()}
    </div>
  )
}

export default PdfViewer
