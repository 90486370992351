import { Box, Toolbar, AppBar } from '@mui/material'
import { AppColors } from '../../../Theme'

import React, { useState } from 'react'
import { RomeLogo } from '../../../../core/core'
import { SettingsPopoverDialog } from '../../dialogs/settings-popover-dialog/SettingsPopoverDialog'
import { SettingsPopoverViewModel } from '../../dialogs/settings-popover-dialog/SettingsPopoverViewModel'
import { ProfileTile } from '../../tiles/ProfileTile'
import { getAuthToken, getCurrentPerson } from '../../../../domain/domain'
import { EdifyDrawer, drawerWidth } from '../drawer/EdifyDrawer'
import { ROUTE_PERSON_PROFILE } from '../../../pages/person_profile/PersonProfilePage'
import { useNavigate } from 'react-router-dom'
import { formatSnakeCase } from '../../../../core/utils/text-formaters/TextFormatters'

export enum AppBarTheme {
  LIGHT = 'light',
  DARK = 'dark',
}
interface Props {
  theme?: AppBarTheme
  barItems?: React.ReactElement | React.ReactElement[]
  children: React.ReactElement | React.ReactElement[]
  showDrawer?: boolean
}

// ORG SELECTION PAGE NAVBAR
export const EdifyAppBar: React.FC<Props> = ({
  children,
  barItems,
  theme = AppBarTheme.LIGHT,
  showDrawer = true,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  // const showNavigationHeaders = enabled('showNavigationHeaders')
  const handleSettingsClose = () => {
    setIsOpen(false)
  }
  const navigate = useNavigate()

  // DISABLING MENU FOR https://edifyai.atlassian.net/browse/ED-2009
  // const handleSettingsClick = () => {
  //   if (!isOpen) {
  //     setIsOpen(true)
  //   } else {
  //     setIsOpen(false)
  //   }
  // }

  const handleSettingsClick = () => {
    navigate(`${ROUTE_PERSON_PROFILE}`)
    // if (!barItems) {
    //   navigate(`${ROUTE_PERSON_PROFILE}/org`)
    // } else {
    //   navigate(`${ROUTE_PERSON_PROFILE}`)
    // }
  }

  //theme
  // let bgColor
  // if (theme === AppBarTheme.LIGHT) {
  //   bgColor = AppColors.g000
  // }
  // if (theme === AppBarTheme.DARK) {
  //   bgColor = AppColors.gray950
  // }

  const defaultBarItems = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    ></Box>
  )

  const { imageURL, fullName, globalRole } = getCurrentPerson() || {}
  const token = getAuthToken()
  const socImageURL = imageURL + `&token=${token}`

  const getRoleName = () => {
    if (globalRole?.name) {
      return formatSnakeCase(globalRole?.name)
    }
    return ''
  }

  const name = fullName ?? ''

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        elevation={0}
        position='fixed'
        sx={{
          backgroundColor: AppColors.g000,
          borderBottom: `1px solid ${AppColors.neutral600}`,
          paddingX: 18,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            height: '70px',
            display: 'flex',
            alignContent: 'center',
            gap: '16px',
          }}
        >
          <RomeLogo isDarkTheme={theme === AppBarTheme.DARK} />

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {barItems ?? defaultBarItems}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {/* ED-4145 Hide Profile on Org Selection Page */}
            {/* <ProfileTile
              imageUrl={socImageURL}
              name={name}
              role={getRoleName()}
              isDarkMode={theme === AppBarTheme.DARK}
              onClick={handleSettingsClick}
            /> */}
          </Box>
        </Toolbar>
      </AppBar>
      {showDrawer ? <EdifyDrawer /> : null}
      <SettingsPopoverViewModel>
        <SettingsPopoverDialog
          open={isOpen}
          handleClose={handleSettingsClose}
        />
      </SettingsPopoverViewModel>

      {showDrawer ? <EdifyDrawer /> : null}
      <Box
        component='main'
        sx={{ flexGrow: 1, p: 3, marginLeft: showDrawer ? drawerWidth : 0 }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}
