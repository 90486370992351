import { Either, Failure } from '../../../core/core'
import { IPerson, IPersonList, IUpdatePerson } from '../../../domain/interfaces/IPerson'
import { IUser } from '../../../domain/interfaces/IUser'
import { IVerifyOTPPayload } from '../../../domain/interfaces/IUserDetails'
import { httpGet, httpPut } from '../../data'

/**
 * @deprecated Use {@link personsClientSearch}.
 */
export async function searchPersons(
  skip: number,
  limit?: number,
  person?: string,
): Promise<Either<Failure, IPersonList>> {
  const res: Either<Failure, IPersonList> = await httpGet(
    `/persons/search?key=${person}&skip=${skip}&limit=${limit ?? 5}`,
    false,
  )

  return res
}
export async function getPerson(id: string): Promise<Either<Failure, IUser>> {
  const res: Either<Failure, IUser> = await httpGet(`/persons/${id}`)

  return res
}


export async function personUpdate(person: IUpdatePerson, id:string): Promise<Either<Failure, IPerson>> {
  const res: Either<Failure, IPerson> = await httpPut(`/persons/${id}`,person, true)
    
  return res
}

export async function refreshPerson(): Promise<Either<Failure, IVerifyOTPPayload>> {
  const res: Either<Failure, IVerifyOTPPayload> = await httpGet('/refreshPersonData')

  return res
}
