import { styled } from '@mui/material'
import { AppColors, AppHovers } from '../../Theme'
import { DataGrid } from '@mui/x-data-grid'

export const StyledDataGrid = styled(DataGrid)(() => ({
 
  border: `1px solid ${AppColors.neutral600}`,
  borderTop: 'none',
  borderBottomRightRadius: '10px',
  borderBottomLeftRadius: '10px',
  // border: 'none',

  '& .MuiDataGrid-main  ': {
    // borderLeft: `1px solid ${AppColors.neutral600}`,
    // borderRight: `1px solid ${AppColors.neutral600}`,
  },

  '& .MuiDataGrid-row': {
    backgroundColor: AppColors.baseWhite,
    borderBottom: 'none',
    // borderLeft: `1px solid ${AppColors.neutral600}`,
    // borderRight: `1px solid ${AppColors.neutral600}`,
  },
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
    borderTop: `1px solid ${AppColors.neutral600}`,
  
  },
  '& .MuiDataGrid-row:last-child': {
    // borderBottom: `1px solid ${AppColors.neutral600}`,
    borderBottom: '3px solid transparent',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  '& .MuiDataGrid-cell:last-child': {
    ////borderRight: `1px solid ${AppColors.neutral600}`,
  },

   
  '& .MuiDataGrid-row:hover': {
    ...AppHovers.primary
  },
  '& .MuiDataGrid-columnHeaders': {
    minHeight:'44px',
    maxHeight:'44px',
    backgroundColor: AppColors.baseWhite,
    border:'none'
    //// borderBottom: 'none',
    //// borderTop: 'none',
    //// borderLeft: `1px solid ${AppColors.neutral600}`,
    //// borderRight: `1px solid ${AppColors.neutral600}`,
  },
  '& .MuiDataGrid-row .Mui-selected': {
    backgroundColor: AppColors.baseWhite,
  },
  '& .MuiDataGrid-columnSeparator > svg >  path': {
    display: 'none',
  },
  '& .MuiDataGrid-checkboxInput .MuiSvgIcon-root': {
    color: AppColors.neutral600,
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: AppColors.baseWhite,
  },

  '& .Mui-checked .MuiSvgIcon-root': {
    color: AppColors.primary600,
  },
  '& .MuiDataGrid-columnHeaders,': {},
  '.MuiAvatar-root': {
    width: '32px',
    height: '32px',
  },
  '& .MuiDataGrid-overlayWrapperInner':{
    //// borderBottomLeftRadius: rowCount == 0 ? '5px' :0,
    //// borderBottomRightRadius: rowCount == 0 ? '5px' :0,
    //// borderBottom: rowCount == 0 ?  `1px solid ${AppColors.neutral600}`: 'none',
  }

}))
