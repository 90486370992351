import { Box } from '@mui/material'
import React from 'react'
import logo from '../../../assets/icons/check.svg'


import { ComponentProps } from '../ComponentProps'

interface Props extends ComponentProps {
  width?: number
  height?: number
  isDarkTheme?: boolean
}

export const Check: React.FC<Props> = ({
  sx,
  width,
  height,}) => {
  return (
    <Box
      data-testid='CheckIcon'
      component='img'
      sx={{
        width: width ?? 18,
        height: height ?? 18,
        ...sx,
      }}
      src={logo}
    />
  )
}