/**
 * Formats the title by capitalizing the first letter of each word and converting the rest to lowercase.
 * @param {string} buttonTitle - The title to be formatted.
 * @returns {string} - The formatted title.
 * @ example formatTitle('ALL_USERS') => 'All Users'
 */
export const formatSnakeCase = (buttonTitle: string) => {
  if(buttonTitle == 'MojoAI Admin') return 'MojoAI Admin'
  const words = buttonTitle.split('_')
  const capitalized = words.map(
    (w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase(),
  )
  return capitalized.join(' ')
}


export function camelCaseToNormal(str: string): string {
  // Insert a space before all caps and trim the resulting string
  const spacedStr = str.replace(/([a-z])([A-Z])/g, '$1 $2').trim()

  // Capitalize the first letter
  return spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1).toLowerCase()
}
