import React from 'react'

import { EdifyButton } from '../../components/buttons'
import { Box, Input } from '@mui/material'

import ContactsDataGrid from './ContactsDataGrid'
import { ContactsViewModelProps } from './ContactsViewModel'
import { ROUTE_CONTRACTORS } from '../contractors/ContractorsPage'
import { ROUTE_CONTACTS } from '../contact/ContactPage'
import { useNavigate, useParams } from 'react-router-dom'

import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'


interface IContactsFilterProps {
  vm: ContactsViewModelProps
  contractorName?: string
}

const ContactsList: React.FC<IContactsFilterProps> = ({
  vm,
  contractorName,
}) => {
  const { contractorId } = useParams()
  const navigate = useNavigate()
  const canCreate = usePermission(ERole.ContractorCreate)
  const canEdit = usePermission(ERole.ContractorEdit)
  const contactSelected = (e:any) => {
    vm.searchContacts(e.target.value)
  }


  const newContactClicked = () => {
    navigate(`${ROUTE_CONTRACTORS}/${contractorId}${ROUTE_CONTACTS}/new`, {
      state: { contractorName },
    })
  }

  return (
    <>
      <Box
        data-testid={'Contacts'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Input
          sx={{ width: '200px' }}
          data-testid='SearchContacts'
          disableUnderline
          onChange={contactSelected}
          placeholder={'Search Contacts'}
          className={'form-control'}
        />
        {canCreate && (
          <EdifyButton
            title='New Contact'
            primary
            onClick={newContactClicked}
          />
        )}
      </Box>

      <Box
        sx={{
          margin: '24px 0',
        }}
      >
        <ContactsDataGrid
          vm={vm}
          title='Contacts'
          checkboxSelection={canEdit}
          contractorName={contractorName}
        />
      </Box>
    </>
  )
}

export default ContactsList
