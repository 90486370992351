import React, { ElementType } from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  GoalPageViewModel,
  GoalPageViewModelProps,
  useGoalPageViewModel,
} from './GoalPageViewModel'
import { ROUTE_GOALS } from '../goals/GoalsPage'
import ParticipantsDataGrid from './ParticipantsDataGrid'
import { Avatar, Box, Grid } from '@mui/material'
import { ClipBoardIcon } from '../../../core/components/icons/ClipBoardIcon'
import { ETypography } from '../../components/fonts/ETypography'
import { BullseyeIcon } from '../../../core/components/icons/BullseyeIcon'
import { AlarmClockIcon } from '../../../core/components/icons/AlarmClockIcon'
import { Calendar3Icon } from '../../../core/components/icons/Calendar3Icon'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { InfoBlock } from '../levels/LevelsPage'
import { useNavigate } from 'react-router'
import {
  OrganizationProviderProps,
  useOrganizationProvider,
} from '../../../providers/OrganizationProvider'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import NotFoundPage from '../NotFoundPage'

export const GoalPage: React.FC = () => {
  return (
    <GoalPageViewModel>
      <_Page />
    </GoalPageViewModel>
  )
}

export const _Page: React.FC = () => {
  const vm: GoalPageViewModelProps = useGoalPageViewModel()
  const orgVm: OrganizationProviderProps = useOrganizationProvider()
  const navigate = useNavigate()
  // should move these fields to vm
  if (vm.goalNotFound) return <NotFoundPage />

  if (!vm.goal)
    return (
      <PageContainer
        errorObj={vm.errorObj}
        mainPageLoading={vm.goalLoading}
        mainPageError={vm.goalError}
        breadCrumbs={[{ title: 'Goals & Controls', to: ROUTE_GOALS }]}
      >
        <></>
      </PageContainer>
    )
  const {
    name,
    description,
    form,
    duration,
    incentive,
    goalCount,
    trackingPeriod,
    participantsCount,
    daysRemaining,
    id,
    formId,
    totalActivities,
    totalPossibleActivities,
    userId,
  } = vm.goal!
  const canEdit = usePermission(ERole.GoalEdit, false, userId)

  const editGoal = () => {
    navigate(`${ROUTE_GOALS}/${id}/edit`)
  }
  const getTemplateIconText = () => {
    if (formId) {
      const form = orgVm.forms.find((f) => f.id == formId)
      return <_IconText title={form?.title ?? ''} icon={ClipBoardIcon} />
    }
    return <_IconText title={'Daily Login'} icon={ClipBoardIcon} />
  }

  const getDaysRemaining = (): string => {
    if (!daysRemaining) return 'NA'
    if (daysRemaining !== 'Finished') return daysRemaining + ' Days Remaining'
    return daysRemaining + ''
  }

  return (
    <PageContainer
      // mainPageLoading={vm.goalLoading}
      title={name}
      mainPageError={vm.goalError}
      breadCrumbs={[
        { title: 'Goals & Controls', to: ROUTE_GOALS },
        { title: name ?? '', to: `${ROUTE_GOALS}/${name}` },
      ]}
      subtitle={description ?? ''}
      topActions={canEdit ? [{ title: 'Edit', callback: editGoal },{title:'Delete', callback: ()=> vm.showDeleteGoalConfirmation(id)}] : []}
    >
      <Grid container spacing={2} sx={{ marginTop: '8px' }}>
        {getTemplateIconText()}

        <_IconText
          title={`${goalCount} ${trackingPeriod}`}
          icon={BullseyeIcon}
        />
        <_IconText title={duration} icon={AlarmClockIcon} />
        <_IconText title={getDaysRemaining()} icon={Calendar3Icon} />
      </Grid>

      <InnerPageContainer
        sx={{ padding: '24px', marginTop: '32px', marginBottom: '0px' }}
        header={
          <ETypography
            font='XSHM'
            color='gray950'
            sx={{ marginBottom: '12px' }}
          >
            Metrics
          </ETypography>
        }
        // innerPageLoading={}
        // innerPageError={}
        // innerPageReload={}
      >
        <Grid container spacing={2} sx={{ marginTop: '8px' }}>
          <InfoBlock
            text={'Total submissions'}
            count={totalActivities + '/' + totalPossibleActivities}
          />
          <InfoBlock text={'Participants'} count={participantsCount ?? 'NA'} />
          <InfoBlock text={'Days Remaining'} count={daysRemaining ?? 'NA'} />
          <InfoBlock text='Bonus Incentive' count={incentive ?? 'NA'} />
        </Grid>
      </InnerPageContainer>

      <InnerPageContainer
        sx={{ padding: '24px', marginTop: '16px', marginBottom: '32px' }}
        header={
          <ETypography
            font='XSHM'
            color='gray950'
            sx={{ marginBottom: '12px' }}
          >
            Watchers
          </ETypography>
        }
        // innerPageLoading={}
        // innerPageError={}
        // innerPageReload={}
      >
        <Grid container spacing={2} sx={{ marginTop: '8px' }}>
          {vm.goal.watchers!.map((watcher) => {
            return (
              <Watcher
                key={watcher.id}
                fullName={watcher.fullName}
                role={watcher.email}
              />
            )
          })}
        </Grid>
      </InnerPageContainer>

      <ParticipantsDataGrid vm={vm} title={'Participant'} />
    </PageContainer>
  )
}

interface IconTextProps {
  icon: ElementType
  title?: string
}
const _IconText: React.FC<IconTextProps> = ({ icon: Icon, title = '' }) => {
  return (
    <Grid item sm={6} md={3} style={{ display: 'flex' }}>
      <Icon />
      <ETypography font='MM' color='gray50' sx={{ marginLeft: '8px' }}>
        {title}
      </ETypography>
    </Grid>
  )
}

interface WatcherProps {
  image?: string
  fullName?: string
  role?: string
}
const Watcher: React.FC<WatcherProps> = ({
  image,
  fullName = 'NA',
  role = 'NA',
}) => {
  return (
    <Grid item sm={6} md={3} style={{ display: 'flex', padding: '16px 8px' }}>
      <Avatar
        src={image}
        sx={{ width: '40px', height: '40px', marginRight: '12px' }}
      />
      <Box>
        <ETypography font='LSB' color='gray950'>
          {fullName}
        </ETypography>
        <ETypography font='SM' color='gray25' sx={{}}>
          {role}
        </ETypography>
      </Box>
    </Grid>
  )
}
