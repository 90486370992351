import { FormControl, Input, InputAdornment, InputProps } from '@mui/material'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { AppColors } from '../../../Theme'
import { Search } from '@mui/icons-material'
import { GridClearIcon } from '@mui/x-data-grid'
import { debounce } from 'lodash'

interface IEdifySearchProps extends InputProps {
  defaultValue?: string
  
  darkMode?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  width?: number | string
  small?: boolean
  clear?: () => void
  debounceTime?: number
}

export const EdifySearch: React.FC<IEdifySearchProps> = ({
  onChange,
  sx,
  defaultValue = '',
  darkMode = false,
  disabled = false,
  width = 'auto',
  small = false,
  placeholder = 'Search',
  debounceTime = 300, 
  clear
}) => {
  const [searchTerm, setSearchTerm] = useState<string>(defaultValue)
  const debouncedOnChange = useCallback(
    debounce((value: string) => {
      // Create a synthetic event
      const syntheticEvent = {
        target: { value },
      } as ChangeEvent<HTMLInputElement>
      onChange(syntheticEvent)
    }, debounceTime),
    [onChange, debounceTime]
  )

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedOnChange.cancel()
    }
  }, [debouncedOnChange])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setSearchTerm(newValue)
    debouncedOnChange(newValue)
  }

  const handleClear = () => {
    setSearchTerm('')
    debouncedOnChange.cancel() // Cancel any pending debounced calls
    clear && clear()
  }
  return (
    <FormControl
      disabled={disabled}
      variant='standard'
      className='e-input'
      sx={{
        padding: small ? '5px 10px' : '8px 0px 8px 16px',
        border: `1px solid ${darkMode ? 'none' : AppColors.neutral600}`,
        borderRadius: '5px',
        backgroundColor: darkMode ? AppColors.gray900 : AppColors.baseWhite,
        width: width,
        gap: '12px',
        ...sx,
      }}
    >
      <Input
        className='e-input'
        disableUnderline
        // id='search'
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={searchTerm}
        onChange={handleSearch}
        startAdornment={
          <InputAdornment position='start'>
            <Search sx={{ color: AppColors.neutral950 }} />
          </InputAdornment>
        }
        endAdornment={
          clear && !searchTerm.length ? null : (
            <InputAdornment
              position='start'
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <GridClearIcon  
                onClick={handleClear}
                sx={{
                  width: '20px',
                  height: '20px',
                  color: AppColors.neutral950,
                  marginLeft: small ? '10px' : '16px',
                }}
              />
            </InputAdornment>
          )
        }
      />
    </FormControl>
  )
}
