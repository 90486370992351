import React, { useContext, useEffect, useState } from 'react'
import { adminGetRoles, adminGetRolesWithQuery } from '../../../domain/domain'
import { IRole } from '../../../domain/interfaces/IRole'
import { set } from 'date-fns'
import useQuery from '../../../core/hooks/useQuery'
import { DEFAULT_LIMIT } from '../users/UsersViewModel'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { get } from 'http'

export interface IRoleSettingsViewModelProps {
  loading: boolean
  error: string | undefined
  roles: IRole[]
  defaultRoles: IRole[]
  sortClickedHandler: (sorter: GridSortModel) => void
  loadMore?: (paginationModel: GridPaginationModel) => void
  skip: number
  totalCount:number,
  errorObj: any | undefined
}

const RoleSettingsContext =
  React.createContext<IRoleSettingsViewModelProps | null>(null)

export function useRoleSettingsViewModel(): IRoleSettingsViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(RoleSettingsContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const RoleSettingsViewModel: React.FC<Props> = ({ children }) => {
  const [skip, setSkip] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [error, setError] = useState<string | undefined>(undefined)
  const [errorObj, setErrorObj] = useState<any | undefined>(undefined)
  const [roles, setRoles] = useState<IRole[]>([])
  const [defaultRoles, setDefaultRoles] = useState<IRole[]>([])

  const queryHelper = useQuery({ skip: 0, limit: DEFAULT_LIMIT })
  const { getQueryStringFromQuery, setNew, query } =
    queryHelper

  const fetchRoles = async (fetchSkip: number, query: Record<string, any>) => {
    setLoading(true)
    setError(undefined)
    const newQuery = { ...query, skip: fetchSkip }
    const res = await adminGetRolesWithQuery(getQueryStringFromQuery(newQuery))
    setLoading(false)
    if (res.isLeft()) {
      setError(res.left?.message ?? 'Error loading roles.')
      setErrorObj(res.left)
      return
    }
    if (res.right) {
      setRoles(res.right.data ?? [])
      setTotalCount(res.right?.total_count || 0)
      setDefaultRoles(
        res.right.data.filter((r) =>
          ['Super Admin', 'Admin', 'User'].includes(r.name),
        ) ?? [],
      )
      setNew(newQuery)
    }
    
  }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    fetchRoles(0, {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    })
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchRoles(fetchSkip, query)
  }

  const getRoles = async () => {
    setSkip(0)
    setRoles([])

    fetchRoles(0, query)
  }

  useEffect(() => {
    getRoles()
  }, [])

  return (
    <RoleSettingsContext.Provider
      value={{
        roles,
        error,
        loading,
        defaultRoles,
        sortClickedHandler,
        loadMore,
        skip,
        totalCount,
        errorObj
      }}
    >
      {children}
    </RoleSettingsContext.Provider>
  )
}
