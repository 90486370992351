import { useCallback, useEffect, useState } from 'react'
import { drawerWidthNum } from '../../presentation/components/banners/drawer/EdifyDrawer'
import debounce from '../utils/timers/debounce'

// This hook gets the width that the datagrid should be on resize,
export const useTableWidth = (showDrawer:boolean): number => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  const debouncedHandleResize = useCallback(
    debounce(() => {
      setWindowWidth(window.innerWidth)
    }, 100), 
    []
  )

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  //TODO fix this hardcoded 64 when table design are finalized
  //64 is from padding of the page which is 32 * 2
  if (showDrawer) return windowWidth -  drawerWidthNum - 64
  return windowWidth - 64
}