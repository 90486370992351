import * as React from 'react'
import { SxProps, styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { ETypography } from '../fonts/ETypography'

import HelpIcon from '@mui/icons-material/Help'
import { AppColors } from '../../Theme'
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow placement='right' {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
    background: AppColors.gray50,
    padding: '8px 12px',
    borderRadius: '10px',
  },
}))

interface Props {
  text?: string
  sx?:SxProps
}
const EdifyTooltip: React.FC<Props> = ({ text, sx }) => {
  return (
    <HtmlTooltip
      sx={{display:'flex', alignContent:'center'}}
      title={
        <ETypography font='MS' color='white'>
          {text}
        </ETypography>
      }
    >
      <HelpIcon sx={{color:AppColors.gray50, height:'18px', width:'18px', ...sx}} />
    </HtmlTooltip>
  )
}
export default EdifyTooltip
