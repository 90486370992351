import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridBadge, GridItem, GridStackItem } from '../gridItems'
import { Avatar, Box } from '@mui/material'
import { IParticipant } from '../../../../domain/interfaces/IGoal'
import { styled } from '@mui/material/styles'
import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import { AppColors } from '../../../Theme'
import { ETypography } from '../../fonts/ETypography'

export const participant_columns: GridColDef[] = [
  {
    field: 'fullName',
    renderHeader: () => <GridItem text='User' />,
    flex: 1,
    renderCell: ({ row }) => (
      <GridStackItem
        topText={row.fullName}
        bottomText={row.email ?? ''}
        avatar={() => (
          <Avatar
            alt={`${row.fullName}`}
            sx={{
              mr: 10,
              border:
                row.fullName == 'You'
                  ? `3px solid ${AppColors.primary600}`
                  : 'none',
            }}
          />
        )}
      />
    ),
  },
  {
    field: 'streak',
    renderHeader: () => <GridItem text='Streak #' />,
    width: 104,
    renderCell: ({ row }) => {
      // THIS IS BREAKING TEST
      if (!row.projects) return
      const projects = row.projects.map((proj: string, i: number) => (
        <GridBadge key={i} text={proj} />
      ))
      return projects
    },
  },
  {
    field: 'progress',
    renderHeader: () => <GridItem text='Progress' />,
    flex: 1,
    renderCell: ({ row }) => (
      <BorderLinearProgress variant='determinate' value={row.progress} />
    ),
    // renderCell: ({ row }) => <LinearProgressWithLabel value={50} />,
  },
]

export const normalizeParticipantsForGrid: any = (
  participants: IParticipant[],
  goalCount: number,
  userId?: string,
) => {
  if (!participants) return []

  return participants?.map((p: IParticipant) => {
    const isUser = p.id == userId
    let percentage = (p.totalActivities / goalCount) * 100
    percentage = isNaN(percentage) ? 0 : Math.min(percentage, 100)

    return {
      id: p.id,
      fullName: isUser ? 'You' : p.fullName,
      email: isUser ? '' : p.email || 'No Email',
      progress: percentage,
      paused: p.paused,
      streak: p.streak,
    }
  })
}

const BorderLinearProgress = styled(LinearProgressWithLabel)(() => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: AppColors.primary25,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: AppColors.primary600,
  },
}))

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: '8px' }}>
          <LinearProgress variant='determinate' {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <ETypography font='SM' color='gray700'>{`${Math.round(
            props.value,
          )}%`}</ETypography>
        </Box>
      </Box>
    </Box>
  )
}
