import React, { useState } from 'react'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { adminOrg_columns } from '../../components/datagrid/gridData'
import { AdminOrgsPageViewModelProps } from './AdminOrgsViewModel'
import { useNavigate } from 'react-router-dom'
import { setCurrentOrg } from '../../../domain/domain'
import { ROUTE_DASHBOARD } from '../dashboard/DashboardPage'
import { setCurrentOrgId } from '../../../data/repositories/orgs/OrgRepository'

interface IAdminOrgsDataGridProps {
  vm: AdminOrgsPageViewModelProps
  title: string
  checkboxSelection: boolean
}

const AdminOrgsDataGrid: React.FC<IAdminOrgsDataGridProps> = ({
  vm,
  title,
  checkboxSelection,
}) => {
  const [isNavigating, setIsNavigating] = useState<boolean>(false)
  const navigate = useNavigate()
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])

  // Edit adminOrg
  const handleRowClicked = async (params: GridRowParams) => {
    setIsNavigating(true)
    await setCurrentOrg(params.row.id)
    setIsNavigating(false)
    navigate(ROUTE_DASHBOARD)
  }

  const rows = vm.normalizeAdminOrgsForDataGrid()

  return (
    <>
      <EdifyDataGrid
        
        data-testid='AdminOrgsDataGrid'
        paginationMode='server'
        title={title}
        noRowsText={'No adminOrgs found.'}
        columns={adminOrg_columns()}
        rows={rows}
        error={vm.error}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={handleRowClicked}
        loading={vm.isLoading || vm.isLoadMore || isNavigating}
        rowCount={vm.totalCount || 0}
        loadMoreRows={vm.loadMore}
        reload={vm.reload}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={checkboxSelection}
      />
    </>
  )
}

export default AdminOrgsDataGrid
