import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_USERS } from '../users/UsersPage'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { EdifyButton } from '../../components/buttons'
import { Grid } from '@mui/material'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import { SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { getUsersTemplateCSV, uploadUsersCSVFile } from '../../../domain/domain'
import { EnhancedFileUpload } from './EnhancedFileUpload'
import { usersCSValidate } from '../../../data/data'

const UserBulkXLSXUpload: React.FC = () => {
  const navigate = useNavigate()
  const [loadingCSV, setLoadingCSV] = useState(false)
  const [error, setError] = useState<string | undefined>('')

  const handleUpload = async (file: File) => {
    if (file) {
      const res = await uploadUsersCSVFile(file)
      if (res.right) {
        SuccessToast({ title: 'Users Uploaded Successfully' })
        navigate(`${ROUTE_USERS}`)
        return
      }
      setError(res.left?.message ?? 'Failed to upload CSV file')
    }
  }

  const loadCSV = async () => {
    setLoadingCSV(true)
    const res = await getUsersTemplateCSV()
    setLoadingCSV(false)
    if (res.right) {
      downloadExcelFile(res.right, 'users.xlsx')
      return
    }
    setError(res.left?.message ?? 'Failed to load XLSX Template')
  }

  function downloadExcelFile(blob: Blob, filename: string) {
    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob)

    // Create a link element
    const link = document.createElement('a')

    // Set the download attribute with the desired filename
    link.download = filename

    // Set the href of the link to the blob URL
    link.href = url

    // Append the link to the body
    document.body.appendChild(link)

    // Programmatically click the link to trigger the download
    link.click()

    // Remove the link from the document
    document.body.removeChild(link)

    // Release the URL object
    window.URL.revokeObjectURL(url)
  }

  useEffect(() => {
    // loadCSV()
  }, [])

  return (
    <InnerPageContainer
      headerTitle='Upload Users'
      
      renderRightItems={() => (
        <EdifyButton
          noBackground
          title={loadingCSV ?'Downloading...' :'Download Template'}
          onClick={loadingCSV ? ()=>1 :loadCSV}
        />
      )}
    >
      <Grid container>
        <Grid item sm={12} md={12}>
          <EdifyFormField sx={{ marginTop: '12px' }}>
            <EdifyFieldLabel   label='Upload XLSX File' />
            <EnhancedFileUpload
              handleUpload={handleUpload}
              loading={loadingCSV}
              error={error}
              setError={setError}
              allowedFileTypes={['xlsx']}
              validateFileFunction={usersCSValidate}
              uploadButtonText='Upload Users'
            />
          </EdifyFormField>
        </Grid>
      </Grid>
    </InnerPageContainer>
  )
}

export default UserBulkXLSXUpload
