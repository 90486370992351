import React from 'react'
import { SxProps, Typography } from '@mui/material'
import { AppColors, AppFonts } from '../../../Theme'


export type TextType = 'primary' | 'grey' | 'red';

interface GridHeaderTextProps {
  text: string
  type?: TextType
  sx?: SxProps
}

const defaultStyles = {
  ...AppFonts.textButtonSemiboldSmall,
  color: AppColors.gray50,
}

const typeStyles = {
  primary: {
    color: AppColors.primary600,
  },
  grey: {
    color: AppColors.neutral800,
  },
  red: {
    color: AppColors.danger500,
  },

  // add more types here if needed...
}
/**
 * Represents a header component for a grid.
 * @function GridHeaderText
 * @param {GridHeaderTextProps} props - The props for the GridHeaderText component.
 * @returns {JSX.Element} - The rendered GridHeaderText component.
 * @example
 *      <GridHeaderText type={type} text={title} sx={{ mr: 10, ml: 10 }} />
 */
export const GridHeaderText: React.FC<GridHeaderTextProps> = ({
  text,
  type = 'primary',
  sx,
}) => {
  const styles = {
    ...defaultStyles,
    ...typeStyles[type],
    ...sx,
  }

  return <Typography sx={styles}>{text}</Typography>
}
