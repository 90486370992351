import React from 'react'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box, Grid } from '@mui/material'
import { ETypography } from '../../components/fonts/ETypography'
import {
  ProjectPageViewModel,
  useProjectPageViewModel,
} from './ProjectPageViewModel'

function _ProjectCustomFields() {
  const { customFields, project, level0Name } = useProjectPageViewModel()
  return (
    <InnerPageContainer
      headerTitle={`${level0Name} Information` }
      sx={{ padding: '24px', marginBottom: '24px' }}
    >
      <Grid container spacing={24} sx={{ marginTop: '12px' }}>
        {customFields.map((item, index) => (
          <Grid item xs={12} md={6} key={index} spacing={12}>
            <CustomSection
              label={item.label}
              value={item.value}
              inactive={false}
            />
          </Grid>
        ))}
      </Grid>
    </InnerPageContainer>
  )
}

const ProjectCustomFields = () => {
  return (
    <ProjectPageViewModel>
      <_ProjectCustomFields />
    </ProjectPageViewModel>
  )
}
export default ProjectCustomFields

interface _IDateSectionProps {
  label: string
  value: string
  inactive: boolean
}

export const CustomSection: React.FC<_IDateSectionProps> = ({
  label,
  value,
  inactive,
}) => {
  return (
    <Box style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <ETypography font='XSM' color='gray50'>
        {label}
      </ETypography>
      <ETypography font='MS' color={inactive ? 'gray25' : 'gray950'}>
        {value ? value :'NA'}
      </ETypography>
    </Box>
  )
}
