import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  refreshPerson,
  saveToLocalStorage,
  setAuthToken,
} from '../../../data/data'
import globalI18n from '../../../i18n'
import PageContainer from '../../components/page/PageContainer'
import { SafetyMojoLoginIcon } from '../../../core/components/icons/SafetyMojoLoginIcon'
import { Box } from '@mui/material'
import { ETypography } from '../../components/fonts/ETypography'
import { ROUTE_ORG_SELECTION } from '../org_selection/OrgSelectionPage'

// interface Props {
//   // token: string
// }

export const ROUTE_SSO_CHECK = '/sso-check'

// const SSOCheckPage: React.FC<Props> = () => {
const SSOCheckPage = () => {
  const location = useLocation()

  const navigate = useNavigate()
  const token = location.state?.token

  const loadSSOPerson = async (token: string) => {
    setAuthToken(token)
    const res = await refreshPerson()
    if (res.isLeft()) {
      // Error grabbing personData from server redirect to login
      navigate('/')
      return
    }
    if (res.right) {
      saveToLocalStorage('person', JSON.stringify(res.right))
      globalI18n.changeLanguage(res.right.language ?? 'en')
      navigate(ROUTE_ORG_SELECTION)
    }
  }
  useEffect(() => {

    if (token) {
      loadSSOPerson(token)
    } else {
      // no token or success was false so redirect back to login
      navigate('/')
    }
  }, [])

  return (
    <PageContainer>
      <Box
        sx={{
          // height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <SafetyMojoLoginIcon width={180} height={48} />

        <ETypography font='LM' color='gray700' sx={{ marginTop: '24px' }}>
          Verifying Credentials
        </ETypography>
      </Box>
    </PageContainer>
  )
}

export default SSOCheckPage

// FOR TESTING ONLY
// const handleRedirect = () => {
//   // Programmatically navigate to external URL
//   window.location.href =
//     'http://localhost:3000/org-selection?succes=true&token=toeknFromRedirect&personId=36dee29c-ecfa-4411-9a23-358a15c1768e&sso_id=_270db416-4ef6-4cbe-a2a0-1fa7db5d723b&errMessage='
// }
