import React from 'react'
import { SxProps, Typography, TypographyProps } from '@mui/material'
import { AppColors, AppFonts } from '../../../Theme'


export type IGridBadgeType ='primary' | 'grey' | 'success' | 'danger' | 'warn' 
interface GridBadgeProps extends TypographyProps {
  text: string
  type?: IGridBadgeType
  sx?: SxProps
}

/**
 * Shortens the provided string.
 * @function shortenString
 * @param {string} str - The string to be shortened.
 * @returns {string} - The shortened string.
 */
const shortenString = (input: string): string => {
  if (input && input.length > 21) {
    return input.substring(0, 18) + '...'
  }
  return input
}

/* Text XS/Medium */
const defaultStyles = {
  ...AppFonts.textXSMedium,
  paddingX: 8,
  paddingY: 2,
  mr: 24,
  borderRadius: 8,
}

const typeStyles = {
  primary: {
    color: AppColors.primary600,
    background: AppColors.primary25,
  },
  grey: {
    color: AppColors.gray700,
    background: AppColors.neutral300,
  },
  success: {
    color: AppColors.success700,
    background: AppColors.success50,
  },
  danger: {
    color: AppColors.danger700,
    background: AppColors.danger100,
  },
  warn: {
    color: AppColors.warning600,
    background: AppColors.warning50,
  },

  // add more types here if needed...
}
/**
 * Represents a badge component for a grid.
 * @function GridBadge
 * @param {GridBadgeProps} props - The props for the GridBadge component.
 * @returns {JSX.Element} - The rendered GridBadge component.
 * @example
 * // Usage example:
 * // Renders a GridBadge component with 'grey' type and 'bob' text content.
 * renderCell: ({ row }) => <GridBadge type="grey" text="bob" />,
 */
export const GridBadge: React.FC<GridBadgeProps> = ({
  text,
  type = 'primary',
  sx,
  ...rest
}) => {
  const styles = {
    ...defaultStyles,
    ...typeStyles[type],
    ...sx,
  }

  return (
    <Typography
      sx={{ ...styles, cursor: 'pointer', textTransform: 'capitalize' }}
      {...rest}
    >
      {shortenString(text)}
    </Typography>
  )
}
