import { Either, Failure } from '../../../core/core'
import {
  getAllCorrectiveActions,
  correctiveActionResolve,
  getAllOwnedCorrectiveActions,
  correctiveActionById,
  correctiveActionAddComment,
  correctiveActionSendBack,
  correctiveActionClose,
  correctiveActionPDF,
  getOnePersonsCorrectiveActions,
  getAllCorrectiveActionComments,
  correctiveActionPersonAddComment,
  correctiveActionsPDF,
} from '../../../data/repositories/correctiveActions/CorrectiveActionsRepository'

import {
  ICorrectiveAction,
  ICorrectiveActionCommentList,
  ICorrectiveActionList,
  ICorrectiveActionSubmitData,
} from '../../interfaces/ICorrectiveAction'

export async function resolveCorrectiveAction(
  id: string,
  data: { description: string },
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await correctiveActionResolve(
    id,
    data,
  )
  return res
}

export async function 
getCorrectiveActions(
  query: string,
): Promise<Either<Failure, ICorrectiveActionList>> {
  return await getAllCorrectiveActions(query)
}

export async function getCorrectiveActionsComment(
  id: string,
): Promise<Either<Failure, ICorrectiveActionCommentList>> {
  return await getAllCorrectiveActionComments(id)
}

export async function getPersonsCorrectiveActions(
  query: string,
): Promise<Either<Failure, ICorrectiveActionList>> {
  return await getOnePersonsCorrectiveActions(query)
}

export async function getOwnedCorrectiveActions(
  query: string,
): Promise<Either<Failure, ICorrectiveActionList>> {
  return await getAllOwnedCorrectiveActions(query)
}

export async function getCorrectiveActionById(
  id: string,
): Promise<Either<Failure, ICorrectiveAction>> {
  return await correctiveActionById(id)
}

export async function addCommentOnCorrectiveAction(
  id: string,
  data: ICorrectiveActionSubmitData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> =
    await correctiveActionAddComment(id, data)
  return res
}

export async function personAddCommentOnCorrectiveAction(
  id: string,
  data: ICorrectiveActionSubmitData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> =
    await correctiveActionPersonAddComment(id, data)
  return res
}

export async function sendBackCorrectiveAction(
  id: string,
  data: ICorrectiveActionSubmitData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> =
    await correctiveActionSendBack(id, data)
  return res
}

export async function closeCorrectiveAction(
  id: string,
  data: ICorrectiveActionSubmitData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await correctiveActionClose(
    id,
    data,
  )
  return res
}

export async function downloadCorrectiveActionPDF(
  id: string,
): Promise<Either<Failure, Blob>> {
  const res: Either<Failure, Blob> = await correctiveActionPDF(id)
  return res
}

export async function downloadCorrectiveActionsPDF(
  ids: string[],
): Promise<Either<Failure, Blob>> {
  const res: Either<Failure, Blob> = await correctiveActionsPDF(ids)
  return res
}
