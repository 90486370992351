// ED-1383 https://edifyai.atlassian.net/browse/ED-1383
// Just an outline will most likely be refactored
import React, { useContext, useEffect, useState } from 'react'
import { deleteLevel, favoriteLevel, getLevelById, unfavoriteLevel } from '../../../domain/domain'
import { ILevel } from '../../../domain/interfaces/IProjects'
import { showConfirmationDeleteMessage } from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { useNavigate, useParams } from 'react-router-dom'
import { IUser } from '../../../domain/interfaces/IUser'
import { ErrorToast, SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'

export interface LevelPageViewModelProps {
  getLevel: () => void
  level: ILevel | undefined
  levelLoading: boolean
  levelError: string | undefined
  showDeleteConfirmation: (ids: string[]) => void
  levelName: string
  setFavorite: () => void
  unSetFavorite: () => void
  contact: IUser | undefined
}

const LevelPageContext = React.createContext<LevelPageViewModelProps | null>(
  null,
)

export function useLevelPageViewModel(): LevelPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(LevelPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const LevelPageViewModel: React.FC<Props> = ({ children }) => {
  const { id } = useParams()
  const navigate = useNavigate()

  //STATE
  const [level, setLevel] = useState<ILevel>()
  const [contact, setContact] = useState<IUser>()
  const [levelLoading, setLevelLoading] = useState<boolean>(false)
  const [levelError, setLevelError] = useState<string | undefined>()

  const handleDelete = async()=>{
    const res = await deleteLevel(level!.id)
    if(res.isLeft()){
      ErrorToast({title:  `Failed to delete ${level!.name}.`})
      return
    }
    SuccessToast({title: `${level!.name} successfully deleted.`})
    navigate(-1)
  }

  const showDeleteConfirmation = async () => {
    showConfirmationDeleteMessage(getLevelName(), handleDelete)
  }

  const getLevelName = () => {
    if (!level) return ''
    return level.name
  }

  const getLevel = async () => {
    setLevelError(undefined)
    setLevelLoading(true)
    const res = await getLevelById(id!)
    setLevelLoading(false)
    if (res.isLeft()) {
      setLevelError(res.left?.message ?? 'A unknown error occurred on our end.')
    }
    setLevel(res.right as ILevel)
    setContact(res.right?.pointOfContact)
  }

  const setFavorite = async() => {
    const name = getLevelName()
    const res = await favoriteLevel(level!.id)
    if(res.left){
      ErrorToast({title: `Error adding ${name} to favorites.
      `})
      return
    }
    SuccessToast({title: `${name} has been added to favorites successfully.`})
    setLevel({...level as ILevel, favorited:true})
  }

  const unSetFavorite = async() => {
    const name = getLevelName()
    const res = await unfavoriteLevel(level!.id)
    if(res.left){
      ErrorToast({title: `Error removing ${name} as favorite.`})
      return
    }
    SuccessToast({title: `${name} has been removed from favorites.`})
    setLevel({...level as ILevel, favorited:false})
  }

  useEffect(() => {
    getLevel()
  }, [id])

  return (
    <LevelPageContext.Provider
      value={{
        level,
        levelLoading,
        levelError,
        levelName: getLevelName(),
        getLevel,
        showDeleteConfirmation,
        unSetFavorite,
        setFavorite,
        contact,
      }}
    >
      {children}
    </LevelPageContext.Provider>
  )
}
export default LevelPageContext
