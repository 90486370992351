import React, { useEffect } from 'react'

import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
import { useNavigate, useParams } from 'react-router-dom'
import {
  certificate_columns,
  normalizeCertificatesForDataGrid,
} from '../../components/datagrid/gridData/certificates'
import { CertificatesPageViewModelProps } from './CertificatesViewModel'
import { EdifyButton } from '../../components/buttons'
import { Box } from '@mui/material'
import { ROUTE_USERS } from '../users/UsersPage'
import CertificateModal from './CertificateModal'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { ICertificate } from '../../../domain/interfaces/ICertificate'
import FilterButtonGroup from '../../components/buttons/filter-button-group/FilterButtonGroup'

interface IUsersDataGridProps {
  vm: CertificatesPageViewModelProps
  title: string
  checkboxSelection: boolean
  userName?: string
  isAnon?: boolean
  anonOrgId?: string
}

const CertificatesDataGrid: React.FC<IUsersDataGridProps> = ({
  vm,
  title,
  checkboxSelection,
  isAnon = false,
  anonOrgId,
}) => {
  const or = useOrganizationProvider()
  const { query, set } = vm.queryHelper
  const navigate = useNavigate()
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const { userId } = useParams()
  const [open, setOpen] = React.useState(false)
  const [certificateId, setCertificateId] = React.useState<string | undefined>(
    undefined,
  )
  const [activeCertificate, setActiveCertificate] = React.useState<
    ICertificate | undefined
  >(undefined)

  const canEdit = !isAnon && usePermission(ERole.TrainingCertificateEdit, false, userId)
  const canCreate = !isAnon && usePermission(ERole.TrainingCertificateCreate, false, userId)
  const canView = isAnon || usePermission(ERole.TrainingCertificateRead)

  const modalClosed = () => {
    setCertificateId(undefined)
    setOpen(false)
  }

  const handleRowClicked = (params: GridRowParams) => {
    if (!canView) return
    const certificate = vm.certificates!.find((c) => c.id === params.id)
    setActiveCertificate(certificate)
    setCertificateId(params.id as string)
    // will trigger useEffect
  }

  const removeClicked = async () => {
    vm.deleteCertificates(selectionModel as string[])
    setSelectionModel([])
  }

  const renderHeaderButtons = () => {
    if (!checkboxSelection) return
    const noSelections = selectionModel.length === 0
    return (
      <Box>
        {query.type != 'training' && (
          <EdifyButton
            hide={!canCreate}
            noBackground
            title='+ Add Certificate'
            onClick={() =>
              navigate(`${ROUTE_USERS}/${userId}/certificates/new`)
            }
          />
        )}
        {canEdit && (
          <BulkDeleteButton
            noSelections={noSelections}
            onClick={removeClicked}
          />
        )}
      </Box>
    )
  }

  // will we get certificates from user or seperate API call?
  const rows = normalizeCertificatesForDataGrid(
    vm.certificates || [],
    userId,
    canEdit,
  )

  const getActiveButton = () => {
    if (query.type == 'certificate') return 'Certificates'
    if (query.type == 'training') return 'Trainings'
    return 'All'
  }
  const getTitle = () => {
    if (query.type == 'certificate') return 'Certificates'
    if (query.type == 'training') return 'Trainings'
    return title
  }

  useEffect(() => {
    if (certificateId !== undefined) {
      setOpen(true)
    }
  }, [certificateId])

  if (or && or.orgProviderLoading()) return null

  return (
    <>
      <FilterButtonGroup
        onButtonClicked={vm.handleFilterButtonClicked}
        activeButton={getActiveButton()}
        setActiveButton={(value) => set('activeButton', value)}
        buttons={['All', 'Certificates', 'Trainings']}
      />
      <Box sx={{ marginBottom: '24px' }} />
      <EdifyDataGrid
        data-testid='CertificatesDataGrid'
        paginationMode='server'
        title={getTitle()}
        noRowsText={`No ${getTitle().toLocaleLowerCase()} found.`}
        renderHeaderButtons={renderHeaderButtons()}
        columns={certificate_columns()}
        rows={rows}
        error={vm.certificatesError}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={handleRowClicked}
        loading={vm.certificatesLoading}
        rowCount={vm.totalCount || 0}
        loadMoreRows={vm.loadMore}
        reload={vm.reload}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={canEdit && checkboxSelection}
        initSort={[
          {
            field: 'expirationDate',
            sort: 'desc',
          },
        ]}
      />
      <CertificateModal
        orgId={anonOrgId ?? or?.org?.id ?? ''}
        certificateId={certificateId}
        activeCertificate={activeCertificate}
        open={open}
        handleClose={modalClosed}
        isAnon={isAnon}
        canEdit={canEdit}
      />
    </>
  )
}

export default CertificatesDataGrid
