import { Either, Failure } from '../../../core/core'
import { notificationMarkAsRead, notificationsGet } from '../../../data/repositories/notifications/NotificationsRepository'
import { INotification, INotificationList } from '../../interfaces/INotification'

export async function getNotifications(skip:number| string, limit=20): Promise<
  Either<Failure, INotificationList>
  // eslint-disable-next-line indent
> {
  return await notificationsGet(skip, limit)
}

export async function markNotificationRead(id: string): Promise<
  Either<Failure, INotification>
  // eslint-disable-next-line indent
> {
  return await notificationMarkAsRead(id)
}