export interface Node1 {
  id: string
  name: string
  level: number
  parentId: string
  children: Node1[]
  locationId: string
  isPlaceholder?: boolean // Additional property to mark placeholders
}

interface RootNode extends Node1 {
  parentId: string
}

export function createTreeNew1(data: any[], locationId: string): RootNode {
  const nodes: Record<string, Node1> = {}
  const root: RootNode = {
    id: 'root',
    name: 'location-name',
    level: 0,
    locationId: locationId,
    parentId: locationId,
    children: [],
  }

  // Sort data by level to ensure parent nodes are processed first
  data.sort((a, b) => a.level - b.level)

  // Second pass: link parents and children
  data.forEach((item) => {
    // Ensure each node has a children array initialized
    if (!nodes[item.id]) {
      nodes[item.id] = { ...item, children: [] }
    }

    if (item.level === 1) {
      // Attach level 1 nodes directly to the predefined root
      root.children.push(nodes[item.id])
    } else {
      const parent = nodes[item.parentId]
      if (!parent) {
        console.error('Parent node missing for', item)
      } else {
        // Ensure the parent node has a children array initialized
        if (!parent.children) {
          parent.children = []
        }
        parent.children.push(nodes[item.id])
      }
    }
  })

  // bottom add option
  // Object.values(nodes).forEach((node) => {
  //   const parent = nodes[node.parentId]
  //   if (parent && parent.children[parent.children.length - 1] === node) {
  //     const siblingPlaceholder: Node1 = {
  //       id: `add-sibling-${node.id}`,
  //       name: `add-sibling-${node.level}`,
  //       level: node.level,
  //       parentId: node.parentId,
  //       locationId: node.locationId,
  //       children: [],
  //       isPlaceholder: true,
  //     }
  //     parent.children.push(siblingPlaceholder)
  //   }
  // })
  root.children.push({
    id: 'add-child',
    name: 'add-child 1',
    level: 1,
    parentId: locationId,
    locationId: locationId,
    children: [],
    isPlaceholder: true,
  })
  return root
}
