import React from 'react'
import { EdifyButton } from '../edifyButton/EdifyButton'
import { ButtonProps, CircularProgress } from '@mui/material'
import { AppColors } from '../../../Theme'
import { DownloadDisabledIcon } from '../../../../core/components/icons/DownloadDisabledIcon'
import { DownloadIcon } from '../../../../core/components/icons/DownloadIcon'

interface Props extends ButtonProps {
  noSelections: boolean
  onClick: () => void
  downloadingPDF: boolean
}

const BulkDownloadButton: React.FC<Props> = ({ noSelections = true, onClick, downloadingPDF }) => {
  return (
    <>
      <EdifyButton
        disabled={downloadingPDF || noSelections}
        secondary
        noBackground
        textStyle={{
          color: noSelections || downloadingPDF
            ? AppColors.neutral700
            : AppColors.primary600,
        }}
        buttonStyle={{ backgroundColor: AppColors.baseWhite }}
        title='Download PDF'
        onClick={onClick}
        icon={
          downloadingPDF ? (
            <CircularProgress size={18} sx={{ color: AppColors.neutral700 }} />
          ) : noSelections ? (
            <DownloadDisabledIcon/>
          ) : (
            <DownloadIcon />
          )
        }
      />
    </>
  )
}
export default BulkDownloadButton
