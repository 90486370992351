import { camelCaseToNormal } from '../../../core/utils/text-formaters/TextFormatters'

const SheaLocations = [
  {
    value: 'basement',
    Name: 'Basement'
  },
  {
    value: '1CarGarage',
    Name: '1 Car Garage'
  },
  {
    value: '1WholeHouse',
    Name: '1 Whole House'
  },
  {
    value: '2CarGarage',
    Name: '2 Car Garage'
  },
  {
    value: '3CarGarage',
    Name: '3 Car Garage'
  },
  {
    value: 'attic',
    Name: 'Attic'
  },
  {
    value: 'backPorch',
    Name: 'Back Porch'
  },
  {
    value: 'bathroom2',
    Name: 'Bathroom 2'
  },
  {
    value: 'bathroom3',
    Name: 'Bathroom 3'
  },
  {
    value: 'bathroom4',
    Name: 'Bathroom 4'
  },
  {
    value: 'bathroomMaster',
    Name: 'Bathroom Master'
  },
  {
    value: 'bedroom2',
    Name: 'Bedroom 2'
  },
  {
    value: 'bedroom3',
    Name: 'Bedroom 3'
  },
  {
    value: 'bedroom4',
    Name: 'Bedroom 4'
  },
  {
    value: 'bedroom5',
    Name: 'Bedroom 5'
  },
  {
    value: 'bedroomMaster',
    Name: 'Bedroom Master'
  },
  {
    value: 'casita',
    Name: 'Casita'
  },
  {
    value: 'courtyard',
    Name: 'Courtyard'
  },
  {
    value: 'den',
    Name: 'Den'
  },
  {
    value: 'diningRoom',
    Name: 'Dining Room'
  },
  {
    value: 'entryFoyer',
    Name: 'Entry/Foyer'
  },
  {
    value: 'exteriorBack',
    Name: 'Exterior - Back'
  },
  {
    value: 'exteriorFront',
    Name: 'Exterior - Front'
  },
  {
    value: 'exteriorLeft',
    Name: 'Exterior - Left'
  },
  {
    value: 'exteriorRight',
    Name: 'Exterior - Right'
  },
  {
    value: 'exteriorThroughout',
    Name: 'Exterior - Throughout'
  },
  {
    value: 'familyRoom',
    Name: 'Family Room'
  },
  {
    value: 'flexRoom',
    Name: 'Flex Room'
  },
  {
    value: 'frontPorch',
    Name: 'Front Porch'
  },
  {
    value: 'gameRoom',
    Name: 'Game Room'
  },
  {
    value: 'garage',
    Name: 'Garage'
  },
  {
    value: 'gatheringRoom',
    Name: 'Gathering Room'
  },
  {
    value: 'greatRoom',
    Name: 'Great Room'
  },
  {
    value: 'guestSuite',
    Name: 'Guest Suite'
  },
  {
    value: 'hallway',
    Name: 'Hallway'
  },
  {
    value: 'hallway2ndFloor',
    Name: 'Hallway - 2nd Floor'
  },
  {
    value: 'interiorThroughout',
    Name: 'Interior Throughout'
  },
  {
    value: 'kitchen',
    Name: 'Kitchen'
  },
  {
    value: 'landing',
    Name: 'Landing'
  },
  {
    value: 'laundryRoom',
    Name: 'Laundry Room'
  },
  {
    value: 'livingRoom',
    Name: 'Living Room'
  },
  {
    value: 'loft',
    Name: 'Loft'
  },
  {
    value: 'masterCloset',
    Name: 'Master Closet'
  },
  {
    value: 'nA',
    Name: 'N/A'
  },
  {
    value: 'nook',
    Name: 'Nook'
  },
  {
    value: 'powderRoom',
    Name: 'Powder Room'
  },
  {
    value: 'qpr5',
    Name: 'QPR 5'
  },
  {
    value: 'qpr7',
    Name: 'QPR 7'
  },
  {
    value: 'roof',
    Name: 'Roof'
  },
  {
    value: 'seeNotes',
    Name: 'See Notes'
  },
  {
    value: 'stairs',
    Name: 'Stairs'
  },
  {
    value: 'study',
    Name: 'Study'
  },
  {
    value: 'valet',
    Name: 'Valet'
  },
  {
    value: 'wetBar',
    Name: 'Wet Bar'
  }
]

export const getSheaLocation = (value: string) => {
  const location = SheaLocations.find(location => location.value === value)
  if (!location) {
    return camelCaseToNormal(value)
  }
  return location.Name
}