import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getFromLocalStorage } from './data/data'

// TODO: When language feature extends beyond forms setup global and load languages


let tempLanguage = false
export const setTempLanguage = async (
  tempLanguageVal: boolean,
) => {
  tempLanguage = tempLanguageVal
}
export const getTempLanguage = () => {
  return tempLanguage
}

const resources = {
  en: {
    translation: {
      'Cancel': 'Cancel'
    }
  },
}

const person = getFromLocalStorage('person')
const lang = getFromLocalStorage('tempLang')
if (lang != null) {
  const language = JSON.parse(lang)
  if (language != null && language) {
    setTempLanguage(JSON.parse(lang))
    localStorage.setItem('tempLang', JSON.stringify(false))
  }
}
const language = person != null ? JSON.parse(person).language : 'en'

i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    lng: language,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
  })

export default i18n