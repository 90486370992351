import React, { ReactNode, useEffect, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import './filepond-override.css'
import { EBox } from '../../box/Ebox'
import { getFromLocalStorage, getInitialToken } from '../../../../data/data'
import { REACT_APP_SERVER_API } from '../../../../config'
import FormErrorText from '../FormErrorText'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import ImageWithToken from './ImageWithToken'

interface EdifyImageUploaderProps {
  noUI?: boolean
  edifyUI?: boolean
  label?: string
  handleLoad: (url: string | any) => void
  // these two are needed for the noUI version
  handleOnData?: (data?: FormData) => void
  handleOnError?: (err: any) => void
  handleOnAddStart?: (file?: any) => void
  singleFile?: boolean
  uploadUrl?: string | null
  dataKey?: string
  decodeResponse?: (object: any) => string
  resetFilesOnSuccess?: boolean
  onFilesProcessed?: (files: any) => void
  returnArray?: boolean
  acceptedFileTypes?: string[] | 'All'
}

const supportedFileTypes = [
  'application/pdf', // .pdf
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/rtf',
  // text
  'text/plain', // .txt
  'text/csv', // .csv
  'text/markdown', // .md
  'text/yaml', // .yaml, .yml
  'text/json', // .json
  'application/json', // .json alternative type
  // images
  'image/*',
  // media
  'video/mp4',
  'video/webm',
  'audio/mpeg',
  'audio/wav',
]

// const addImageSVG = `<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M21.0002 14.3334V27.6667M27.6668 21L14.3335 21" stroke="#323539" stroke-width="1.5" stroke-linecap="round"/>
// <rect x="1" y="1" width="40" height="40" rx="20" stroke="#D6D7DD" stroke-linecap="round" stroke-dasharray="4 4"/>
// </svg>`

// Register the plugins

const EdifyImageUploader: React.FC<EdifyImageUploaderProps> = ({
  noUI = false,
  label = '<p class="filepond--drop-file-text">Drop your files here <p class="filepond--label-action">Or browse your files</p>',
  handleLoad,
  handleOnData,
  handleOnError,
  handleOnAddStart,
  uploadUrl = '/common/fileUpload',
  dataKey = 'images',
  decodeResponse = (object: any) => object.data[0],
  singleFile = false,
  edifyUI = false,
  resetFilesOnSuccess = false,
  onFilesProcessed,
  returnArray = false,
  acceptedFileTypes = ['image/*'],
}) => {
  // if (!noUI) {
  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
  )
  // }
  const authToken: string = getInitialToken()
  const storedActiveOrg = getFromLocalStorage('currentOrgId')
  const org = storedActiveOrg != null ? storedActiveOrg : ''

  const [files, setFiles] = useState<any[]>([])
  const [actualFile, setActualFile] = useState<any[]>([])
  const [title, setTitle] = useState<string>(label)
  const [error, setError] = useState<any | undefined>(undefined)

  const handleRemoveFile = (file: any) => {
    // TODO STILL CALLS WRONG API HERE
  }

  // TODO TEMPORARY FIX
  useEffect(() => {
    setActualFile(files)
  }, [files])

  const renderFilePond = () => {
    return (
      <FilePond
        files={files}
        onupdatefiles={setFiles}
        // TODO: CHECK WITH CERTIFICATES
        acceptedFileTypes={
          acceptedFileTypes == 'All' ? supportedFileTypes : acceptedFileTypes
        }
        labelIdle={title}
        allowMultiple={singleFile ? false : true}
        maxFiles={singleFile ? 1 : 5}
        name='images'
        onremovefile={handleRemoveFile}
        allowRevert={false}
        // server={{ process: handleUpload }}
        server={{
          process: {
            url: `${REACT_APP_SERVER_API}${uploadUrl}`,
            method: 'POST',
            headers: {
              Authorization: authToken,
              orgId: org,
            },
            withCredentials: false,
            // called when frontend gets the file
            ondata: () => {
              noUI && setError(undefined)
              handleOnData && handleOnData()
              const formData: FormData = new FormData()
              // prevents filepond from getting stuck loading
              // need to send an error if this happens though
              // if (files[0] && files[0].file) {
              //   const file = files[0].file
              //   formData.append(dataKey, file)

              // }
              files.forEach((fileItem) => {
                if (fileItem && fileItem.file) {
                  formData.append(dataKey, fileItem.file)
                }
              })

              return formData
            },
            // called when unsuccessfully uploaded
            onerror: (error: any) => {
              try {
                const res = JSON.parse(error)
                noUI && setTitle(label)
                noUI && setError(res.message ?? 'Error uploading image')
                handleOnError && handleOnError(res)
              } catch (e) {
                noUI && setError('Error uploading image')
              }
            },

            // called when successfully uploaded
            onload: (props: any) => {
              noUI && setTitle(label)
              const res = JSON.parse(props)
              if (returnArray) {
                handleLoad(res.data)
                return res.data
              }
              const url = decodeResponse(res)

              handleLoad(url)
              resetFilesOnSuccess && setFiles([])
              return url
            },
          },
        }}
        onaddfilestart={() => {
          noUI && setTitle('Uploading...')
          handleOnAddStart && handleOnAddStart()
        }}
        onprocessfiles={() => {
          resetFilesOnSuccess && setFiles([])
          onFilesProcessed && onFilesProcessed(files)
        }}
      />
    )
  }

  if (edifyUI) {
    return <CustomUIWrapper>{renderFilePond()}</CustomUIWrapper>
  }
  const getClassName = () => {
    if (noUI) return 'no-upload-ui'
    if (edifyUI) return 'edify-upload-ui'
    return ''
  }

  const width = label == 'Add Photo' ? 75 : 95
  return (
    <EBox
      p='0'
      border={noUI ? 'none' : 'dashed'}
      sx={{ position: 'relative', width: noUI ? width : 'auto' }}
      className={getClassName()}
    >
      <>
        {renderFilePond()}
        {error && (
          <FormErrorText
            sx={{
              position: 'absolute',
              width: '200px',
              // width - width of EBox
              left: `-${200 - width}px`,
              textAlign: 'right',
              marginTop: '8px',
            }}
          >
            {error}
          </FormErrorText>
        )}
      </>
    </EBox>
  )
}

export default EdifyImageUploader

{
  /*TODO: This work but makes it unClickable, which makes it pointless (because the z-index of this to make it visible but cover the clickable element)  */
}
{
  /* <ETypography
        font='SM'
        color='primary600'
        sx={{ textAlign: 'center', position: 'relative', zIndex: '99',top:66 }}
      >
        Or browse your computer
      </ETypography> */
}

interface ICustomUIWrapperProps {
  children: ReactNode
  imgURL?: string
}
const CustomUIWrapper: React.FC<ICustomUIWrapperProps> = ({
  children,
  imgURL,
}) => {
  return (
    <EBox
      p='0'
      border='primary'
      // onClick={handleOpenInNewTab}
      sx={{
        display: 'flex',
        jc: 'flex-start',
        fd: 'row',
        width: '495px',
        borderRadius: '10px',
        // minWidth: 'calc(50% - 12px)',
        // ...sx,
      }}
    >
      {imgURL && (
        <ImageWithToken
          src={imgURL}
          style={{
            width: '48px',
            height: '48px',
            marginBottom: '16px',
            marginRight: '16px',
          }}
        />
      )}
      <EBox style={{ flex: 1 }}>{children}</EBox>
    </EBox>
  )
}
