import { useEffect, useState } from 'react'
import { IForm } from '../../domain/interfaces/IForm'
import { anonGetForm, getForm } from '../../domain/domain'
import { addOrgIdAndTokenToFormComponents } from '../../presentation/pages/submission/submissionHelpers'
import { updateFormComponentsWithOrgFields } from '../../presentation/components/panels/form-renderer/CustomFormioComponents'
import { usePublicOrganizationProvider } from '../../providers/PublicOrganizationProvider'

interface IOptions {
  orgId: string
  isAnon: boolean
  orgI18nLoaded?: boolean
  l1Name?: string
  l0Name?: string
}
export interface IFormLoader {
  form: IForm | undefined
  formReadOnly: IForm | undefined
  loadFormError: string | undefined
  reloadForm: () => void
  formLoading: boolean
  isAnon: boolean
}
//Loads form, gets it ready for View/Create/Edit (no Submission functionality)
const useFormLoader = (formId: string, options: IOptions): IFormLoader => {
  const { level0Name, level1Name } = usePublicOrganizationProvider()
  const { orgId, isAnon } = options
  const [formLoading, setFormLoading] = useState<boolean>(false)
  const [loadFormError, setLoadFormError] = useState<string | undefined>(
    undefined,
  )
  const [form, setForm] = useState<IForm | undefined>(undefined)
  const [formReadOnly, setFormReadOnly] = useState<IForm | undefined>(undefined)

  const getOrgSpecificElements = async (form: IForm) => {
    updateFormComponentsWithOrgFields(form, level1Name, level0Name)
  }

  const loadForm = async () => {
    setFormLoading(true)
    setLoadFormError(undefined)
    let res
    if (isAnon) {
      res = await anonGetForm(formId, orgId)
    } else {
      res = await getForm(formId)
    }
    setFormLoading(false)
    if (res.right) {
      await getOrgSpecificElements(res.right)
      const updateFormJson = addOrgIdAndTokenToFormComponents(
        res.right,
        level1Name,
        level0Name,
        orgId,
      )
      setForm(updateFormJson)

      const readOnlyFormJson = addOrgIdAndTokenToFormComponents(
        res.right,
        level1Name,
        level0Name,
        orgId,
        true,
      )
      setFormReadOnly(readOnlyFormJson)


      return
    }
    setLoadFormError(res.left?.message ?? 'Error Loading Form')
  }

  useEffect(() => {
    if (!form) {
      loadForm()
    }
    return () => {
      setForm(undefined)
    }
  }, [])

  return { formLoading, loadFormError, form, formReadOnly, reloadForm: loadForm, isAnon }
}

export default useFormLoader
