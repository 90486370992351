import { Box } from '@mui/material'
import React from 'react'
import { AppColors } from '../../Theme'
import { TABLE_ROW_HEIGHT } from './EdifyDataGrid'
import { ETypography } from '../fonts/ETypography'

interface DataGridHeaderProps {
  title: string
  children?: JSX.Element | JSX.Element[]
}

/**
 * Represents a header component for a data grid. This assumes the title will be on left and then 'click' actions will always be on right
 * @function DataGridHeader
 * @param {DataGridHeaderProps} props - The props for the DataGridHeader component.
 * @returns {JSX.Element} - The rendered DataGridHeader component.
 * @example
 *  <DataGridHeader title={title}>
 *    // children  will all be pushed to right of the header
 *    <Box></Box>
 *  </DataGridHeader>
 */

const DataGridHeader: React.FC<DataGridHeaderProps> = ({ title, children }) => {
  return (
    <Box
      className='ellipsis-text'
      sx={{
        height: `${TABLE_ROW_HEIGHT}px`,
        padding: '0 20px',
        border: `1px solid ${AppColors.neutral600}`,
        borderTopRadius: '10px',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: AppColors.baseWhite,
      }}
    >
      <ETypography font='HXSS' color='gray700'>{title}</ETypography>
      {/* Allows/Assumes to add item to left of header */}
      <>{children}</>
    </Box>
  )
}

export default DataGridHeader
