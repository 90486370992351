import React, { useEffect } from 'react'
import {
  SubmissionPageViewModelProps,
  useSubmissionPageViewModel,
} from './SubmissionViewModel'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useI18nProvider } from '../../../providers/InternationalizationProvider'

import useSubmissionLoader from '../../../core/hooks/useSubmission'
import useFormio, { IUseFormioReturn } from '../../../core/hooks/useFormio'
import useFormLoader from '../../../core/hooks/useFormLoader'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import MojoNewFormRender from './MojoNewFormRender'


const NewSubmission: React.FC = () => {
  // const submissionsVM: SubmissionsPageViewModelProps = useSubmissionsPageViewModel()
  const [lastActiveTab, setLastActiveTab] = React.useState<number>(0)
  let imgUploads: NodeListOf<HTMLAnchorElement>
  const vm: SubmissionPageViewModelProps = useSubmissionPageViewModel()
  const { orgI18nLoaded, options } = useI18nProvider()
  const { formId } = useParams()
  const [activeFormId, setActiveFormId] = React.useState<string>(formId ?? '')

  const { org, hierarchies } = useOrganizationProvider()
  const orgId = org ? org.id : 'ERROR NO ORGID'
  // const submissionId = vm.submission?.id ?? undefined

  const formLoader = useFormLoader(formId!, {
    orgId,
    isAnon: false,
    orgI18nLoaded,
  })
  const submissionLoader = useSubmissionLoader(undefined)
  const fio: IUseFormioReturn = useFormio(
    formId!,
    submissionLoader.submission,
    {
      form: formLoader.form,
      orgId: orgId,
      isAnon: false,
    },
  )

  const addClickListener = () => {
    imgUploads = document.querySelectorAll(
      '.formio-component-file .fileSelector',
    )

    imgUploads.forEach((imgUpload) => {
      imgUpload.addEventListener('click', handleImgUpload)
    })
  }
  const handleImgUpload = (event: any) => {
    if (event.target.tagName == 'A') return
    const fileBrowseLink = document.querySelector(
      '.formio-component-file .fileSelector',
    )

    if (fileBrowseLink && fileBrowseLink.contains(event.target)) {
      // Trigger a click event on the found <a> tag
      const link = fileBrowseLink.querySelector('a')
      if (link) {
        link.click()
      }
    }
  }

  useEffect(() => {
    return () => {
      if (imgUploads) {
        imgUploads.forEach((imgUpload) => {
          imgUpload.removeEventListener('click', handleImgUpload)
        })
      }
    }
  }, [])


  const disableButton = vm.isSubmitting || vm.disableSubmit
  if (!formLoader.form) return <p>Loading</p>

  return (
    <>
      <Box
        sx={{ padding: '24px' }}
        // innerPageLoading={!orgI18nLoaded || formLoader.formLoading}
      >
        <MojoNewFormRender
          mode='CREATE'
          submissionId={undefined}
          orgId={orgId}
          formId={formId!}
          fio={fio}
          formLoader={formLoader}
          submissionLoader={submissionLoader}
          options={options}
          orgI18nLoaded={orgI18nLoaded}
          isAnon={false}
          readOnly={false}
          lastActiveTab={lastActiveTab}
          setLastActiveTab={setLastActiveTab}
          setActiveFormId={setActiveFormId}
          activeFormId={activeFormId}
        />
      </Box>
    </>
  )
}
export default NewSubmission
