import { Either, Failure } from '../../../core/core'

import { httpGet } from '../../data'

const listsURL = '/list'

// could maybe combine this into one function
// these right are return a list of strings
// but might return a list of objects {id: string, name: string}
// type List = string | {id: string, name: string}
export interface IListItem {
  id: string
  name: string
}

export async function getTitles(): Promise<
  Either<Failure, string[] | IListItem[] >
  // eslint-disable-next-line
> {
  const res: Either<Failure, string[] | IListItem[] > = await httpGet(
    `${listsURL}/titles`,
    true,
  )

  return res
}

export async function getTrades(): Promise<
  Either<Failure, string[] | IListItem[]>
  // eslint-disable-next-line
> {
  const res: Either<Failure, string[] | IListItem[]> = await httpGet(
    `${listsURL}/trades`,
    true,
  )

  return res
}
