import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Box, Input, MenuItem, Select } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'

import { FieldErrors, Resolver, useFieldArray, useForm } from 'react-hook-form'

import FormErrorText from '../../components/form/FormErrorText'

import { httpPost } from '../../../data/data'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import FormSection from '../../components/form/Containers/FormSection'
import { ETypography } from '../../components/fonts/ETypography'
import { EdifyButtonGroup } from '../../components/form/EdifyButtonGroup/EdifyButtonGroup'

// react-hook-form state
type ProfileInputs = {
  fullName: string
  email: string
  companyName: string
  projectName?: string
  projectCity?: string
  projectState?: string
  industry: string
  companyEMod?: string
  companySize?: string
  users: { fullName: string; email: string }[]
}
interface SignUpFormProps {
  id?: string | undefined
  onCancel?: () => void
}
export const SignUpForm: React.FC<SignUpFormProps> = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [companySize, setCompanySize] = useState<string>('0-99')
  const industries = [
    'Construction',
    'Oil & Gas',
    'Manufacturing',
    'Quality Assurance',
    'Operations',
    'Other',
  ]

  /**
   * Validates the input values for the person's profile form.
   */
  const resolver: Resolver<ProfileInputs> = async (values) => {
    const errors: FieldErrors<ProfileInputs> = {}
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    if (!values.fullName) {
      errors.fullName = {
        type: 'required',
        message: 'Name is required',
      }
    }
    if (!emailRegex.test(values.email)) {
      errors.email = {
        type: 'invalid',
        message: 'Email is not valid',
      }
    }
    if (!values.companyName) {
      errors.companyName = {
        type: 'required',
        message: 'Company name is required',
      }
    }
    if (!values.industry) {
      errors.industry = {
        type: 'industry',
        message: 'Industry is required',
      }
    }
    if (Array.isArray(values.users) && values.users.length > 0) {
      values.users.forEach((user, index) => {
        const nameKey = `users.${index}.fullName` as keyof ProfileInputs
        const emailKey = `users.${index}.email` as keyof ProfileInputs
        if (!user.fullName) {
          errors[nameKey] = {
            type: 'required',
            message: 'Full Name is required',
          }
        }
        if (!emailRegex.test(user.email)) {
          errors[emailKey] = {
            type: 'invalid',
            message: 'Invalid email address',
          }
        }
      })
    }

    return {
      values,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<ProfileInputs>({
    resolver,
    defaultValues: {
      industry: 'Construction', // Set default value for industry
    },
    mode: 'all',
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  })

  /**
   * Handles the form submission when the form is valid.
   * will run resolver validations before submitting
   * @param {Object} data - The form data submitted by the signUp.
   * @returns {void}
   */
  const onSubmit = handleSubmit(async (data) => {
    // react-hook-form will check for errors here and return
    // if there are any
    // if we get here validations have passed
    const formData = {
      fullName: data.fullName,
      email: data.email,
      companyName: data.companyName ?? '',
      industry: data.industry ?? '',
      companyEMod: data.companyEMod ?? 'NA',
      companySize: companySize,
      users: data.users,
    }
    setLoading(true)

    const res = await httpPost('/orgs/signUp', formData)
    if (res.isRight()) {
      SuccessToast({
        title: 'Created Org Successfully! Redirecting you to sign in.',
      })
      setTimeout(() => {
        setLoading(false)
        navigate('/')
      }, 300)
    } else {
      setLoading(false)
      ErrorToast({ title: 'Failed:' })
    }
  })

  const navigate = useNavigate()
  return (
    <form onSubmit={onSubmit}>
      <FormSection title='Account Information'>
        <ETypography font='SM' color='gray50' sx={{ marginTop: '12px' }}>
          The name and email entered below will be the administrator for the
          account in our system. We recommend the person responsible for
          managing the system be added here.
        </ETypography>
        <Box
          sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
        >
          <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
            <EdifyFieldLabel label='YOUR NAME' />
            <Input
              disableUnderline
              placeholder='Enter your name'
              className={`form-control ${errors?.fullName ? 'error' : ''}`}
              {...register('fullName')}
            />
            {errors?.fullName && (
              <FormErrorText>{errors.fullName.message}</FormErrorText>
            )}
          </EdifyFormField>
          <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
            <EdifyFieldLabel label='YOUR EMAIL' />
            <Input
              disableUnderline
              placeholder='sample@email.com'
              className={'form-control'}
              {...register('email')}
            />
            {errors?.email && (
              <FormErrorText>{errors.email.message}</FormErrorText>
            )}
          </EdifyFormField>
        </Box>
      </FormSection>

      <FormSection title='Organization Details'>
        <ETypography font='SM' color='gray50' sx={{ marginTop: '12px' }}>
          Organization Details will help us make suggestions within your account
          to improve your safety culture. Aside from the company name all other
          details will be hidden from other account holders.
        </ETypography>
        <Box
          sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}
        >
          <EdifyFormField sx={{ flex: 2, marginRight: '24px' }}>
            <EdifyFieldLabel label='COMPANY NAME' />
            <Input
              disableUnderline
              placeholder='Enter company name'
              className={'form-control'}
              {...register('companyName')}
            />
            {errors?.companyName && (
              <FormErrorText>{errors.companyName.message}</FormErrorText>
            )}
          </EdifyFormField>

          <EdifyFormField sx={{ flex: 2, marginRight: '24px' }}>
            <EdifyFieldLabel label='COMPANY INDUSTRY' />
            <Select
              variant='standard'
              disableUnderline
              displayEmpty
              {...register('industry')}
              defaultValue='Construction'
              className={'form-control e-input'}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {industries.map((i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </EdifyFormField>
          <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
            <EdifyFieldLabel label='Company EMOD' />
            <Input
              type='number'
              disableUnderline
              placeholder='Enter company EMOD'
              className={'form-control'}
              {...register('companyEMod')}
            />
          </EdifyFormField>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
        >
          <EdifyFormField>
            <EdifyFieldLabel label='COMPANY SIZE' />
            <EdifyButtonGroup
              selectedButton={companySize}
              setSelectedButton={(size) => setCompanySize(size)}
              buttonGroup={['0-99', '100-499', '500-999', '1000+']}
            />
          </EdifyFormField>
        </Box>
      </FormSection>

      <FormSection title='Project Information'>
        <ETypography font='SM' color='gray50' sx={{ marginTop: '12px' }}>
          Tell us about your first project. The free trial starts with one but
          any paid account can have as many as you would like.
        </ETypography>
        <Box
          sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}
        >
          <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
            <EdifyFieldLabel label='Project Name' />
            <Input
              disableUnderline
              placeholder='Enter project Name'
              className={'form-control'}
              {...register('projectName')}
            />
          </EdifyFormField>
          <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
            <EdifyFieldLabel label='Project State' />
            <Input
              disableUnderline
              placeholder='Enter project state'
              className={'form-control'}
              {...register('projectState')}
            />
          </EdifyFormField>
          <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
            <EdifyFieldLabel label='Project City' />
            <Input
              disableUnderline
              placeholder='Enter project city'
              className={'form-control'}
              {...register('projectCity')}
            />
          </EdifyFormField>
        </Box>
      </FormSection>

      <FormSection title='Add Users'>
        <ETypography font='SM' color='gray50' sx={{ marginTop: '12px' }}>
          All Users (up to 15) listed below will be able to interact with all
          features available in your trial account. More can be added in the
          paid version.
        </ETypography>
        {/* Users array */}

        {fields.map((user, index: number) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' },
              }}
              key={'users-' + index}
            >
              <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
                <EdifyFieldLabel label='Name' />
                <Input
                  defaultValue={user.fullName}
                  disableUnderline
                  {...register(
                    `users.${index}.fullName` as `users.${number}.fullName`,
                    { required: true },
                  )}
                  placeholder='Enter name'
                  className={'form-control'}
                />
                {errors.users && errors.users[index]?.fullName && (
                  <FormErrorText>
                    {errors!.users![index]!.fullName!.message}
                  </FormErrorText>
                )}
              </EdifyFormField>
              <EdifyFormField sx={{ flex: 1 }}>
                <EdifyFieldLabel label='Email' />

                <Box sx={{ marginRight: '24px', display: 'flex' }}>
                  <Box sx={{ flex: 1, marginRight: '24px' }}>
                    <Input
                      disableUnderline
                      required
                      defaultValue={user.email}
                      {...register(`users.${index}.email`, {
                        required: 'Email is required',
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: 'Invalid email address',
                        },
                      })}
                      placeholder='Enter email'
                      className={'form-control'}
                    />
                    {errors.users && errors.users[index]?.email && (
                      <FormErrorText>
                        {errors.users[index]!.email!.message}
                      </FormErrorText>
                    )}
                  </Box>
                  <Box sx={{ height: '20px' }}>
                    <EdifyButton
                      noBackground
                      title='Remove'
                      onClick={() => remove(index)}
                    ></EdifyButton>
                  </Box>
                </Box>
              </EdifyFormField>
            </Box>
          )
        })}
        {/* </Box> */}
        <Box sx={{ display: 'flex', marginTop: '24px' }}>
          <EdifyButton
    
            disabled={fields.length >= 15 }
            type='button'
            title='Add User'
            onClick={() => append({ fullName: '', email: '' })}
          ></EdifyButton>
        </Box>
      </FormSection>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          marginTop: '48px',
        }}
      >
        <Box>
          <EdifyButton
            // disabled={Object.keys(errors).length > 0}
            loading={loading}
            type='submit'
            data-testid={'SubmissionButton'}
            primary
            title={'Create Account'}
          />
        </Box>
      </Box>
    </form>

  )
}

export default SignUpForm
