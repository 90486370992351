import React, { useState } from 'react'
import { LoadingTile } from '../tiles/LoadingTile'
import { EdifyButton } from '../buttons'
import ErrorContainer from '../error/ErrorContainer'
import { Box, SxProps, Typography } from '@mui/material'
import { AppColors, AppFonts } from '../../Theme'
import { UpArrow } from '../../../core/components/icons/UpArrow'
import { DownArrow } from '../../../core/components/icons/DownArrow'
// import { ToastErrorIcon } from '../../../core/components/icons/toast/ToastErrorIcon'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import InnerPageHeader from './InnerPageHeader'

interface InnerContainerProps {
  collapsed?: boolean
  collapsible?: boolean
  children?: React.ReactNode
  header?: React.ReactNode
  headerTitle?: string
  error?: boolean
  sx?: SxProps
  renderRightItems?: () => React.ReactNode | null
  close?: () => void
  dark?: boolean
  // load?: () => void  // Could pass a load function which it could load and handle its own loading logic
}

// Wraps all pages
const _InnerContainer: React.FC<InnerContainerProps> = ({
  collapsible = false,
  collapsed = false,
  children,
  header,
  headerTitle,
  error = false,
  sx,
  renderRightItems,
  dark = false,
}) => {
  const [collapse, setCollapse] = useState(collapsed)
  const border = error
    ? AppColors.d300
    : dark
      ? AppColors.gray700
      : AppColors.neutral600
  const renderArrow = () => {
    return (
      <Box
        data-testid='CollapseIcon'
        sx={{ pointer: 'curser' }}
        onClick={() => setCollapse(!collapse)}
      >
        {collapse ? <DownArrow /> : <UpArrow />}
      </Box>
    )
  }

  const innerPageHeader = header ? (
    header
  ) : headerTitle ? (
    <InnerPageHeader title={headerTitle} />
  ) : null

  return (
    <Box
      data-testid='InnerPageContainer'
      sx={{
        padding: '12px 20px',
        border: `1px solid ${border}`,
        background: dark ? AppColors.gray700 : AppColors.baseWhite,
        height: 'auto',
        borderRadius: '10px',
        marginBottom: '8px',
        ...sx,
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: innerPageHeader ? 'space-between' : 'flex-end',
        }}
      >
        {innerPageHeader && innerPageHeader}
        {renderRightItems && renderRightItems()}
        {collapsible && renderArrow()}
      </Box>
      {!collapse && children && children}
    </Box>
  )
}

export interface InnerPageContainerProps extends InnerContainerProps {
  innerPageLoading?: boolean
  innerPageError?: string | undefined
  innerPageReload?: () => void
  loadingTiles?: number
  innerPageEmpty?: boolean
  sx?: SxProps
  header?: React.ReactNode
  headerTitle?: string
  dark?: boolean
}

/**
 * MAIN Component.
 * Wraps all sub components that need loading/error/reloading in the application with a consistent layout and style.
 * Provides an outer container with padding, border, and background color.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.innerPageLoading=false] - Whether the inner page is in a loading state.
 * @param {string} [props.innerPageError] - The error message to be displayed when the inner page has an error.
 * @param {Function} [props.innerPageReload] - The function to be called when the inner page needs to be reloaded.
 * @param {React.ReactNode} [props.children] - The child components to be wrapped.
 * @param {React.ReactNode} [props.header] - Optional header component to be displayed at the top of the container.
 * @param {boolean} [props.collapsed=false] - Whether the content should be initially collapsed.
 * @param {boolean} [props.collapsible=false] - Whether the content can be collapsed or expanded.
 * @param {boolean} [props.error=false] - Whether the component is in an error state.
 */
const InnerPageContainer: React.FC<InnerPageContainerProps> = ({
  innerPageLoading,
  innerPageError,
  innerPageReload,
  innerPageEmpty,
  children,
  header,
  headerTitle,
  sx,
  close,
  loadingTiles = 1,
  dark = false,
  ...rest
}) => {
  // Like PageContainer Check if it is loading first
  if (innerPageLoading) {
    return (
      <_InnerContainer
        sx={{ ...sx }}
        header={header}
        headerTitle={headerTitle}
        data-testId='InnerPageLoading'
        dark={dark}
      >
        <span data-testid='InnerPageLoading' />
        <LoadingTile dark={dark} loadingTiles={loadingTiles} />
      </_InnerContainer>
    )
  }

  // once loading is done check for errors and give option to reload
  if (innerPageError) {
    return (
      <_InnerContainer
        sx={{ ...sx }}
        header={header}
        headerTitle={headerTitle}
        dark={dark}
        error
      >
        <ErrorContainer
          borderColor={dark ? 'none' : AppColors.baseWhite}
          error={innerPageError}
          dark
        >
          {/* MIGHT WANT A RENDER BUTTONS PROP IN ERROR CONTAINER AS WELL */}
          <Box
            data-testid='InnerPageError'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {close && (
              <EdifyButton
                data-testid='PageContainerClose'
                buttonStyle={{ padding: '0px 24px' }}
                noBackground
                title='Close'
                onClick={close}
              />
            )}
            {innerPageReload && (
              <EdifyButton
                data-testid='PageContainerReload'
                buttonStyle={{ padding: '0px 24px' }}
                noBackground
                title='Reload'
                onClick={innerPageReload}
              />
            )}
          </Box>
        </ErrorContainer>
      </_InnerContainer>
    )
  }

  if (innerPageEmpty) {
    return (
      <_InnerContainer
        sx={{ ...sx }}
        header={header}
        headerTitle={headerTitle}
        dark={dark}
      >
        <Box
          data-testid={'InnerPageEmpty'}
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '12px',
            justifyContent: 'center',
            marginTop: '12px',
            alignItems: 'center',
          }}
        >
          <>
            <PriorityHighIcon
              sx={{
                fontSize: '18px',
                color: dark ? AppColors.gray25 : AppColors.gray25,
              }}
            />
            <Typography
              sx={{
                ...AppFonts.textMSemibold,
                color: dark ? AppColors.gray25 : AppColors.gray25,
              }}
            >
              No results found
            </Typography>
          </>
        </Box>
      </_InnerContainer>
    )
  }
  // Once we get here loading and error are false
  return (
    <_InnerContainer
      header={header}
      headerTitle={headerTitle}
      sx={{ ...sx }}
      {...rest}
    >
      {children}
    </_InnerContainer>
  )
}

export default InnerPageContainer
