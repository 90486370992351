import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  ContractorsPageViewModel,
  ContractorsPageViewModelProps,
  useContractorsPageViewModel,
} from './ContractorsViewModel'
import { EdifyButton } from '../../components/buttons'
import ContractorsDataGrid from './ContractorsDataGrid'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'

// import ContractorsFilter from './ContractorsFilter'
import { useNavigate } from 'react-router-dom'
import ContractorsFilter from './ContractorsFilter'
import withPermission from '../../../core/hooks/withPermission'

export const ROUTE_CONTRACTORS = '/contractors'

export const _Page: React.FC = () => {
  const vm: ContractorsPageViewModelProps = useContractorsPageViewModel()
  const canCreate = usePermission(ERole.ContractorCreate)
  const canEdit = usePermission(ERole.ContractorEdit, false, 'NoBulkEdit')

  const navigate = useNavigate()

  const renderRightItems = () => {
    if (canCreate) {
      return (
        <EdifyButton title={'Add New Contractor'} onClick={handleNewClicked} />
      )
    }
  }

  const handleNewClicked = () => {
    navigate(`${ROUTE_CONTRACTORS}/new`)
  }

  return (
    <PageContainer
      errorObj={vm.errorObj}
      title={'Contractors'}
      breadCrumbs={[{ title: 'Contractors', to: ROUTE_CONTRACTORS }]}
      renderRightItems={renderRightItems}
    >
      <ContractorsFilter vm={vm} />
      <ContractorsDataGrid
        vm={vm}
        title={'Contractor'}
        checkboxSelection={canEdit}
      />
    </PageContainer>
  )
}

const ContractorsPageWithPermission = withPermission(
  _Page,
  ERole.ContractorFeatureAccess,
)

export const ContractorsPage: React.FC = () => {
  return (
    <ContractorsPageViewModel>
      <ContractorsPageWithPermission />
    </ContractorsPageViewModel>
  )
}
