import { RemoteConfig, getRemoteConfig, getValue } from 'firebase/remote-config'
import rcDefaults from './remote_config_defaults.json'
import { REACT_APP_BUILD_TYPE } from '../../config'
import { FirebaseOptions, initializeApp } from 'firebase/app'
import firebaseConfigDev from './firebase-options-dev'
import firebaseConfigStg from './firebase-options-stg'
import firebaseConfigProd from './firebase-options-prod'

let _remoteConfig: RemoteConfig
let _isSetupDone = false

export async function setup() {
  const buildType = REACT_APP_BUILD_TYPE
  let _firebaseConfig: FirebaseOptions

  if (buildType == 'prod') {
    _firebaseConfig = firebaseConfigProd
  } else if (buildType == 'stg') {
    _firebaseConfig = firebaseConfigStg
  } else {
    _firebaseConfig = firebaseConfigDev
  }

  const _app = initializeApp(_firebaseConfig)
  _remoteConfig = getRemoteConfig(_app)
  _remoteConfig.defaultConfig = rcDefaults
  _isSetupDone = true
}

export function enabled(feature: string): boolean {
  if (!_isSetupDone) {
    throw new Error('Setup needs to be done first.')
  }
  return getValue(_remoteConfig, feature).asBoolean()
}
