import { Failure } from '../../../core/failures/Failure'
import { Either } from '../../../core/utils/Either'
import {
  getPerson,
  personUpdate,
  searchPersons,
} from '../../../data/repositories/persons/PersonsRepository'
import { IPerson, IPersonList, IUpdatePerson } from '../../interfaces/IPerson'
import { IUser } from '../../interfaces/IUser'

/**
 * @deprecated Use {@link clientSearch}.
 */
export async function personsSearch(
  skip: number,
  limit?: number,
  person?: string,
): Promise<Either<Failure, IPersonList>> {
  return await searchPersons(skip, limit, person)
}

export async function getPersonById(
  id: string,
): Promise<Either<Failure, IUser>> {
  return getPerson(id)
  
}

export async function updatePerson(
  person:IUpdatePerson,
  id: string,
): Promise<Either<Failure, IPerson>> {
  return personUpdate(person, id)
  
}
