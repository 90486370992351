import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridItem } from '../gridItems'
import { Avatar } from '@mui/material'
import { IUser } from '../../../../domain/interfaces/IUser'

import { formatSnakeCase } from '../../../../core/utils/text-formaters/TextFormatters'
import { GridAvatarItem } from '../gridItems/GridAvatarText'
import { dateToMMMDYYYY } from '../../../../core/core'

export const project_user_columns: GridColDef[] = [
  {
    field: 'name',
    renderHeader: () => <GridItem text='Point of Contact' />,
    flex: 1.5,
    renderCell: ({ row }) => (
      <GridAvatarItem
        text={row.fullName}
        // inactive={row.inactive}
        // TODO SETUP WHEN images adde
        // avatar={() => <Avatar alt={`${row.fullName}`} sx={{ mr: 10 }} />}
      />
    ),
  },

  {
    field: 'role',
    renderHeader: () => <GridItem text='Role' />,
    flex: 1,
    renderCell: ({ row }) => <GridItem text={row.role} />,
  },
  // last login not used for now
  {
    field: 'dateAdded',
    flex: 1,
    renderHeader: () => <GridItem text='Date Added' />,
    renderCell: ({ row }) => (
      <GridItem text={dateToMMMDYYYY(row.dateAdded)} />
    ),
  },
  {
    field: 'status',
    flex: 1,
    renderHeader: () => <GridItem text='Status' />,
    renderCell: ({ row }) => <GridItem text={row.status} />,
  },
]

export const normalizeProjectUsers: any = (users?: IUser[]) => {
  if (!users) return []

  // projects: user.projects?.map((project) => project?.name),
  return users?.map((user: IUser, i) => {
    // for dummy data
    const date = new Date()
    date.setDate(date.getDate() - i)
    return {
      id: user.id,
      fullName: user.fullName,
      status: 'Active', // no status on a projects users
      inActive:false,
      dateAdded: user.createdAt,
      role:
        user.roles[0] && user.roles[0]?.name
          ? formatSnakeCase(user.roles[0]?.name)
          : 'None',
    }
  })
}
