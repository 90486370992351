/* eslint-disable react/prop-types */
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, DialogActions, IconButton } from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import { EdifyButton } from '../../buttons'
import { AppColors, AppFonts } from '../../../Theme'
import { EdifyTextField } from '../../textfield/EdifyTextField'
import { ETypography } from '../../fonts/ETypography'

let setController:
  | React.Dispatch<React.SetStateAction<IConfirmationDialogV2Props | undefined>>
  | undefined

let showDialog: React.Dispatch<React.SetStateAction<boolean>> | undefined
/**
 * Show [Confirmation Dialog].
 *
 * @param submission
 */
export function showConfirmationDialogV2(props: IConfirmationDialogV2Props) {
  setController?.(props)
  showDialog?.(true)
}

export function hideConfirmationDialogV2() {
  setController?.(() => undefined)
  showDialog?.(false)
}

export interface IConfirmationDialogV2Props {
  title: string
  message: string
  language?: boolean
  cancelText?: string
  confirmText?: string
  onCancel: () => void
  onSaveAsDraft: () => void
  onSubmit: () => void
  oneButton?: boolean
}

export const ConfirmationDialogV2 = () => {
  const [open, setOpen] = React.useState(false)
  const [props, setProps] = React.useState<IConfirmationDialogV2Props>()

  setController = setProps
  showDialog = setOpen

  const closeDialog = () => {
    props?.onCancel()
    hideConfirmationDialogV2()
  }

  const onSubmitClick = () => {
    props?.onSubmit()
    hideConfirmationDialogV2()
  }

  const onSaveAsDraftClick = () => {
    props?.onSaveAsDraft()
    hideConfirmationDialogV2()
  }

  return (
    <Dialog
      data-testid='ConfirmationDialogV2'
      open={open}
      maxWidth={'sm'}
      PaperProps={{
        sx: dialogStyling,
      }}
    >
      {props?.language && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <InfoIcon sx={{ color: AppColors.danger500, marginBottom: '16px', fontSize: '30px' }} />
          <CloseIcon sx={{
            marginRight: '6px', fontSize: 'px', '&:hover': {
              cursor: 'pointer',
            },
          }} onClick={closeDialog} />


        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

        <DialogTitle
          sx={{
            ...AppFonts.textXLSemibold,
            color: AppColors.gray700,
            flexWrap: 'wrap',
            flex: 6,
            p: 0,
          }}
        >
          {props?.title}
        </DialogTitle>
        {!props?.language && (
          <CloseIcon sx={{
            flex: 1,
            marginRight: '6px', fontSize: '25px', '&:hover': {
              cursor: 'pointer',
            },
          }} onClick={closeDialog} />
        )}
      </Box>
      <DialogContent sx={{ padding: '12px 0' }}>
        <DialogContentText
          sx={{ ...AppFonts.textSMedium, color: AppColors.gray50, padding: 0 }}
        >
          {props?.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          marginTop: '10px',
          width: '100%',
          display: 'flex',
          justifyContent: props?.language ? 'space-between' : 'flex-end',
          p: 0,
        }}
      >
        <EdifyButton
          buttonStyle={{ flex: 1 }}
          data-testid='CloseDialogButton'
          title={props?.cancelText ?? 'Save as Draft'}
          secondary
          onClick={onSaveAsDraftClick}
        />

        <EdifyButton
          buttonStyle={{ flex: 1 }}
          data-testid='ConfirmationDialogV2Button'
          title={props?.confirmText ?? 'Create'}
          primary
          onClick={onSubmitClick}
        />
      </DialogActions>
    </Dialog>
  )
}

export const showConfirmationV2Message = async (
  message: string,
  submit: () => void,
  saveAsDraft: () => void,
) => {
  return showConfirmationDialogV2({
    title: 'Create New Team Meeting',
    message: message,
    cancelText: 'Save as Draft',
    confirmText: 'Create',
    onCancel: function (): void {
      hideConfirmationDialogV2()
    },
    onSaveAsDraft: async () => {
      await saveAsDraft()
      hideConfirmationDialogV2()
    },
    onSubmit: async () => {
      await submit()
      hideConfirmationDialogV2()
    },
  })
}

export const showLangConfirmationV2Message = async (
  message: string,
  title: string,
  confirmText: string,
  cancelText: string,
  submit: () => void,
  saveAsDraft: () => void,
) => {
  return showConfirmationDialogV2({
    title: title,
    message: message,
    language: true,
    cancelText: cancelText,
    confirmText: confirmText,
    onCancel: function (): void {
      hideConfirmationDialogV2()
    },
    onSaveAsDraft: async () => {
      await saveAsDraft()
      hideConfirmationDialogV2()
    },
    onSubmit: async () => {
      await submit()
      hideConfirmationDialogV2()
    },
  })
}

const dialogStyling = {
  width: '453px',
  height: 'auto',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 24,
}
