import React, { useContext, useEffect, useState } from 'react'
import { Either, Failure } from '../../../core/core'

import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import {
  IContractor,
  IContractorList,
} from '../../../domain/interfaces/IContractor'
import {
  adminDeleteContractors,
  getAllContractors,
} from '../../../domain/domain'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'

import { normalizeContractorsForGrid } from '../../components/datagrid/gridData'
import useQuery from '../../../core/hooks/useQuery'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'

//This will need to be dynamic hardcoding for now
interface ContractorRowProps {
  id: string
  name: string
  projects: string[] | undefined
  lastLogin: string | undefined
  role: string | undefined
}

export interface ContractorsPageViewModelProps {
  contractors: IContractor[] | undefined
  normalizeContractorsForDataGrid: () => ContractorRowProps[] | []
  error?: string
  errorObj?: any | undefined
  clearError?: () => void
  loadMore?: (paginationModel: GridPaginationModel) => void
  sortSubmissions?: () => void
  sort?: string
  skip: number
  totalCount?: number
  reload: () => void
  isLoading: boolean
  isLoadMore: boolean
  isSearching: boolean
  editContractor?: IContractor
  setEditContractor: (contractor: IContractor | undefined) => void
  open: boolean
  closeDialog: () => void
  openDialog: () => void
  onSubmit: () => void
  deleteContractors: (ids: string[]) => void
  formError: string | undefined
  setFormError: (error: string | undefined) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  clearFilters: (reload: boolean) => void
  queryHelper: Record<string, any>
  fetchContractors: (fetchSkip: number, query: Record<string, any>) => void
}

const ContractorsPageContext =
  React.createContext<ContractorsPageViewModelProps | null>(null)

export function useContractorsPageViewModel(): ContractorsPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(ContractorsPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}
export const DEFAULT_LIMIT = 20

export const ContractorsPageViewModel: React.FC<Props> = ({ children }) => {
  const queryHelper = useQuery({ skip: 0, limit: DEFAULT_LIMIT })
  const { defaultQuery, reset, getQueryStringFromQuery, setNew, query } =
    queryHelper

  const [isLoading, setIsLoading] = useState({
    initialLoad: false,
    loadMore: false,
    search: false,
  })
  const [skip, setSkip] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [error, setError] = useState<string | undefined>(undefined)
  const [errorObj, setErrorObj] = useState<any | undefined>(undefined)
  const [contractors, setContractors] = useState<IContractor[] | undefined>(
    undefined,
  )
  const [open, setOpen] = useState<boolean>(false)
  const [editContractor, setEditContractor] = useState<IContractor | undefined>(
    undefined,
  )
  const [formError, setFormError] = useState<string | undefined>(undefined)



  const clearFilters = async (reload = false) => {
    reset()
    if (reload) {
      fetchContractors(0, defaultQuery)
    }
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const openDialog = () => {
    setOpen(true)
  }
  const onSubmit = () => {
    // Not sure if this is being used
  }

  const fetchContractors = async (
    fetchSkip: number,
    query: Record<string, any>,
  ) => {
    setIsLoading((oldState) => {
      return { ...oldState, loadMore: true }
    })
    setError(undefined)

    // load submissions or corrective actions
    const newQuery = { ...query, skip: fetchSkip }
    const res: Either<Failure, IContractorList> = await getAllContractors(
      getQueryStringFromQuery(newQuery),
    )
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: false, loadMore: false }
    })
    if (res.isRight()) {
      setTotalCount(res.right?.total_count || 0)
      setContractors(res.right?.data as IContractor[])
      setSkip(fetchSkip)
    } else {
      setError(res.left?.message)
      setErrorObj(res.left)
    }
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchContractors(fetchSkip, query)
  }

  const getContractors = async () => {
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: true }
    })
    setSkip(0)
    setContractors([])

    fetchContractors(0, query)
  }

  const normalizeContractorsForDataGrid: any = () => {
    return normalizeContractorsForGrid(contractors)
  }

  //DELETE CONTRACTOR
  const deleteContractors = async (ids: string[]) => {
    const confirmMessage = `${ids.length} ${
      ids.length == 1 ? 'contractor' : 'contractors'
    }`
    showConfirmationDialog({
      title: 'Confirm Delete',
      message: `Are you sure you want to delete ${confirmMessage} from the database? This action cannot be undone.`,
      cancelText: 'Cancel',
      confirmText: 'Delete',
      onCancel: function (): void {
        hideConfirmationDialog()
      },
      onConfirm: async () => {
        const results = await adminDeleteContractors(ids)
        // something went wrong with api call
        if (results.isLeft()) {
          // backend error
          ErrorToast({
            title: `Failed to delete ${confirmMessage} from the database`,
          })
        }
        if (results.right) {
          SuccessToast({
            title: `Successfully deleted ${confirmMessage} from the database`,
          })
          getContractors()
        }
        setEditContractor(undefined)
        hideConfirmationDialog()
        closeDialog()
      },
    })
  }

  //DEMO FOR sorting to if query idea works
  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    fetchContractors(0, {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    })
  }

  useEffect(() => {
    setError(undefined)
    getContractors()
  }, [])

  const onClearError = () => {
    //not sure what do here
    setError(undefined)
  }

  return (
    <ContractorsPageContext.Provider
      value={{
        contractors,
        normalizeContractorsForDataGrid,
        isLoading: isLoading.initialLoad,
        isLoadMore: isLoading.loadMore,
        isSearching: isLoading.search,
        loadMore,
        skip,
        error,
        clearError: onClearError,
        reload: getContractors,
        totalCount,
        editContractor,
        setEditContractor,
        open,
        closeDialog,
        openDialog,
        onSubmit,
        deleteContractors,
        formError,
        setFormError,
        sortClickedHandler,
        queryHelper,
        clearFilters,
        fetchContractors,
        errorObj
      }}
    >
      {children}
    </ContractorsPageContext.Provider>
  )
}
export default ContractorsPageContext
