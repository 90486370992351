// NO Ticket with this.  This here to demo ways to document components
import React from 'react'
import { Box,  } from '@mui/material'
import PageContainer from '../../components/page/PageContainer'

import { Link, Outlet } from 'react-router-dom'
import {
  OrganizationProviderProps,
  useOrganizationProvider,
} from '../../../providers/OrganizationProvider'


const _ComponentDocs: React.FC = () => {
  const {
    hierarchiesLoading,
    formsLoading,
    formsError,
    hierarchiesError,
  }: OrganizationProviderProps = useOrganizationProvider()

  return (
    <PageContainer
      title='Component Docs'
      mainPageError={formsError || hierarchiesError}
      mainPageLoading={hierarchiesLoading || formsLoading}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '24px',
        }}
      >
        <Link style={{ marginRight: '24px' }} to='/cd/innerpage'>
          InnerPage Component
        </Link>
        <Link to='/cd/edifySearchDropdown'>EdifySearchDropdown</Link>
      </Box>
      <Outlet />
    </PageContainer>
  )
}
export const ComponentDocs = () => {
  return <_ComponentDocs />
}
