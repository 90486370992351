import React, { useEffect } from 'react'

// Type for the props of the wrapped component
type WrappedComponentProps = Record<string, any>

// Function to check if a URL is "good"
const isGoodUrl = (url: string): boolean => {
  if (url.includes('rome-uploads.s3')) return false
  if (url.includes('amazonaws')) return false
  return true
}

// Interface for elements that can have a src or href attribute
interface URLElement extends Element {
  src?: string
  href?: string
}

// Function to check a single element
const checkElement = (element: URLElement): void => {
  const url = element.tagName.toLowerCase() === 'iframe' ? element.src : (element.href || element.src)
  
  if (url && !isGoodUrl(url)) {
    console.warn(`Bad URL found in ${element.tagName}: ${url}`)
    // You can add more actions here, like highlighting the element
  }
}

// Higher-order component
function withUrlChecker<P extends WrappedComponentProps>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> {
  const WithUrlChecker: React.FC<P> = (props: P) => {
    useEffect(() => {
      // Create a MutationObserver to watch for DOM changes
      const observer = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach((mutation: MutationRecord) => {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach((node: Node) => {
              if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node as Element
                if (['A', 'IMG', 'IFRAME'].includes(element.tagName)) {
                  checkElement(element as URLElement)
                }
                element.querySelectorAll('a, img, iframe').forEach(
                  (el) => checkElement(el as URLElement)
                )
              }
            })
          }
        })
      })

      // Start observing the document body for changes
      observer.observe(document.body, { childList: true, subtree: true })

      // Initial check of existing elements
      document.querySelectorAll('a, img, iframe').forEach(
        (el) => checkElement(el as URLElement)
      )

      // Cleanup function to disconnect the observer when the component unmounts
      return () => observer.disconnect()
    }, [])

    return <WrappedComponent {...props} />
  }

  // Set the display name for the HOC
  WithUrlChecker.displayName = `WithUrlChecker(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

  return WithUrlChecker
}

export default withUrlChecker