import React from 'react'

import PageContainer from '../../components/page/PageContainer'

import {
  ContactPageViewModel,
  ContactPageViewModelProps,
  useContactPageViewModel,
} from './ContactPageViewModel'

import { ROUTE_CONTRACTORS } from '../contractors/ContractorsPage'

import { useLocation, useParams } from 'react-router-dom'
import ContactsForm from './ContactForm'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { ROUTE_CONTACTS } from './ContactPage'

const _ContactFormPage: React.FC = () => {
  const { id, contractorId } = useParams()
  const {state} = useLocation()
  const contractorName = state?.contractorName 
  const vm: ContactPageViewModelProps = useContactPageViewModel()
  const canCreate = usePermission(ERole.ContractorCreate)

  const getBreadCrumbs = () => {
    return [
      {
        title: 'Contractors',
        to: `${ROUTE_CONTRACTORS}`,
      },
      {
        title: contractorName? contractorName :'Contractor',
        to: `${ROUTE_CONTRACTORS}/${contractorId}`,
      },
      {
        title: 'New Contact',
        to: `${ROUTE_CONTRACTORS}/${contractorId}${ROUTE_CONTACTS}/${id}/new`,
      },
    ]
  }

  return (
    <PageContainer
      title={'Add New Contact'}
      canView={canCreate}
      mainPageLoading={vm.contactLoading}
      mainPageError={vm.contactError}
      breadCrumbSX={{ marginBottom: '0px' }}
      breadCrumbs={getBreadCrumbs()}
    >
      <ContactsForm  />
    </PageContainer>
  )
}

export const ContactFormPage: React.FC = () => {
  return (
    <ContactPageViewModel>
      <_ContactFormPage />
    </ContactPageViewModel>
  )
}
