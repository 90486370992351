import React, { useContext, useEffect, useState } from 'react'

import { IContractor } from '../../../domain/interfaces/IContractor'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_CONTRACTORS } from '../contractors/ContractorsPage'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import {
  adminCreateContractor,
  adminDeleteContractor,
  adminEditContractor,
  getSpecificContractor,

} from '../../../domain/domain'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'


export interface contractorParams {
  id?: string
  name: string
  pointOfContactName: string
  pointOfContactPhone?: string
  pointOfContactEmail: string
  trade?: string
  identifierField?: string
  levelIds: string[]
  projectIds: string[]

}

export interface ContractorPageViewModelProps {
  getContractor: () => void
  deleteContractor: () => void
  updateContractor: (contractor: contractorParams, id:string) => void
  createContractor: (contractor: contractorParams, redirect?: boolean) => void
  contractor: IContractor | undefined
  contractorLoading: boolean
  contractorError: string | undefined
  formError: string | undefined
}

const ContractorPageContext = React.createContext<ContractorPageViewModelProps | null>(null)

export function useContractorPageViewModel(): ContractorPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(ContractorPageContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
  onSuccess?: () => void
}

export const ContractorPageViewModel: React.FC<Props> = ({ children, onSuccess, }) => {
  const { contractorId } = useParams()
  const navigate = useNavigate()
  const [contractor, setContractor] = useState<IContractor | undefined>(undefined)
  const [contractorLoading, setContractorLoading] = useState<boolean>(false)
  const [contractorError, setContractorError] = useState<string | undefined>(undefined)
  const [formError, setFormError] = useState<string | undefined>(undefined)

  const getContractor = async () => {
    setContractorLoading(true)
    setContractorError(undefined)
    const res = await getSpecificContractor(contractorId as string)
    setContractorLoading(false)
    if (res.isLeft()) {
      setContractorError(res.left?.message ?? 'Error loading contractor.')
      return
    }
    setContractor(res.right as IContractor)
  }

  //CREATE CONTRACTOR
  const createContractor = async (contractor: contractorParams) => {
    setFormError(undefined)

    const result = await adminCreateContractor(contractor)

    if (result.isLeft()) {
      setFormError(result.left?.message)
    } else {
      SuccessToast({
        title: `Contractor: ${contractor.name} Created and Invitation Sent`,
      })
      if (onSuccess) {
        onSuccess()
      } else {
        navigate(ROUTE_CONTRACTORS)
      }
    }
  }

  //Update CONTRACTOR, maybe combine with create contractor
  const updateContractor = async (contractor: contractorParams, id:string) => {
    setFormError(undefined)
    const result = await adminEditContractor(contractor, contractorId as string)

    if (result.isLeft()) {
      setFormError(result.left?.message)
    } else {
      SuccessToast({ title: `Contractor: ${name} Updated` })
      navigate(`${ROUTE_CONTRACTORS}`)
    }
  }

  const deleteContractor = async () => {
    showConfirmationDialog({
      title: 'Confirm Delete',
      message:
        'Are you sure you want to delete this contractor from the database? This action cannot be undone.',
      cancelText: 'Cancel',
      confirmText: 'Delete',
      onCancel: function (): void {
        hideConfirmationDialog()
      },
      onConfirm: async () => {
        const results = await adminDeleteContractor(contractorId!)
        if (results.isLeft()) {
          ErrorToast({
            title: 'Error removing contractor.',
          })
          return
        }

        SuccessToast({
          title: 'Contractor has been deleted successfully.',
        })
        navigate(`${ROUTE_CONTRACTORS}`)
        hideConfirmationDialog()
      },
    })
  }


  useEffect(() => {
    if (contractorId) {
      getContractor()
    }
  }, [contractorId])

  return (
    <ContractorPageContext.Provider
      value={{
        getContractor,
        contractor,
        contractorLoading,
        contractorError,
        deleteContractor,
        formError,
        createContractor,
        updateContractor,

      }}
    >
      {children}
    </ContractorPageContext.Provider>
  )
}
