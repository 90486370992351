import { Container } from '@mui/material'
import React from 'react'
import { SafetyMojoLoginIcon } from '../../../core/components/icons/SafetyMojoLoginIcon'
import { EdifyButton } from '../../components/buttons'
import mobile from '../../../assets/images/Mobile.png'
import { ETypography } from '../../components/fonts/ETypography'
interface Props {
  mobileLink: string
}
const MobileDownLoadScreen: React.FC<Props> = ({ mobileLink }) => {
  const isIOS = mobileLink.split('.').includes('apple')
  return (
    <Container
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        background: 'white',
        alignContent: 'center',
        marginTop:'-40px'
      }}
    >
      <img src={mobile} width={'275px'} height='367px' />
      <SafetyMojoLoginIcon />
      <ETypography
        font='MR'
        color='gray950'
        sx={{ marginTop: '24px', marginBottom: '32px', padding: '0 16px ' }}
      >
        SafetyMojo is best viewed on our mobile app.
      </ETypography>
      <EdifyButton
        onClick={()=> window.location.href = mobileLink}
        title={
          isIOS
            ? 'Download App in the App Store'
            : 'Download App in the Google Play Store'
        }
      />
    </Container>
  )
}

export default MobileDownLoadScreen
