import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridItem } from '../gridItems'
import { ILevel } from '../../../../domain/interfaces/IProjects'
import { ILocation } from '../../../../domain/interfaces/ILocation'

export const location_columns: GridColDef[] = [
  {
    field: 'name',
    renderHeader: () => <GridItem text='Name' />,
    flex: 2,
    renderCell: ({ row }) => <GridItem text={row.name} />,
  },
  {
    field: 'address',
    renderHeader: () => <GridItem text='Address' />,
    flex: 3,
    renderCell: ({ row }) => <GridItem text={row.address} />,
  },
  // {
  //   field: 'state',
  //   flex: 1,
  //   renderHeader: () => <GridItem text='State' />,
  //   renderCell: ({ row }) => <GridItem text={row.state} />,
  // },
  // {
  //   field: 'city',
  //   flex: 1,
  //   renderHeader: () => <GridItem text='City' />,
  //   renderCell: ({ row }) => <GridItem text={row.city} />,
  // },
  // {
  //   field: 'country',
  //   width: 120,
  //   renderHeader: () => <GridItem text='Country' />,
  //   renderCell: ({ row }) => <GridItem text={row.country} />,
  // },
]

export const normalizeLocationsForDataGrid: any = (locations: ILocation[]) => {
  return locations.map((location: any) => {
    return {
      id: location.id,
      name: location.name,
      address: location.address,
      state: location.long +'', //  'Todo get from lat/long'
      city: location.lat +'', //'Todo get from lat/long'
      country: 'Todo get from lat/long',
    }
  })
}
