import { AppColors } from '../../Theme'

export const levelStyles = [
  { color: AppColors.primary600, background: AppColors.primary25 },
  { color: AppColors.p600, background: AppColors.p25 },
  { color: AppColors.s600, background: AppColors.s100 },
  { color: AppColors.danger600, background: AppColors.danger50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
  { color: AppColors.warning600, background: AppColors.warning50 },
]
