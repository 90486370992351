import React, { useState } from 'react'
import PageContainer from '../../components/page/PageContainer'
import { Box } from '@mui/material'

import {
  ProjectsPageViewModel,
  ProjectsPageViewModelProps,
  useProjectsPageViewModel,
} from '../projects/ProjectsViewModel'
import ProjectsDataGrid from '../projects/ProjectsDataGrid'
import {
  ContactPageViewModel,
  ContactPageViewModelProps,
  useContactPageViewModel,
} from './ContactPageViewModel'
import ProfileContainer from '../../components/profile/ProfileContainer'
import { EdifyReadFormField } from '../../components/form/shared/EdifyReadFormField'
import { phoneInputFormatter } from '../../../core/utils/input-formatters/InputFormatters'
import {
  LevelsPageViewModel,
  LevelsPageViewModelProps,
  useLevelsPageViewModel,
} from '../levels/LevelsViewModel'
import { normalizeLevelsForDataGrid } from '../../components/datagrid/gridData'
import LevelsDataGrid from '../levels/LevelsDataGrid'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

import { ROUTE_CONTRACTORS } from '../contractors/ContractorsPage'
import ContactsForm from './ContactForm'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { EdifyButton } from '../../components/buttons'
import { AppColors } from '../../Theme'
import { TrashIcon } from '../../../core/components/icons/TrashIcon'
import PageHeader from '../../components/page/PageHeader'

// need to change
export const ROUTE_CONTACTS = '/contacts'

const _ContactPage: React.FC<IContactProps> = ({ isEdit }) => {
  const vm: ContactPageViewModelProps = useContactPageViewModel()
  const levelVM: LevelsPageViewModelProps = useLevelsPageViewModel()
  const { getHierarchyName } = useOrganizationProvider()
  const projectVM: ProjectsPageViewModelProps = useProjectsPageViewModel()
  const [levelCount, setLevelCount] = useState(-1)
  const [projectCount, setProjectCount] = useState(-1)
  const { contractorId } = useParams()
  const location = useLocation()
  const contractorName = location.state?.contractorName
  const navigate = useNavigate()

  const canEdit = usePermission(ERole.ContractorEdit)
  const canViewProjects = usePermission(ERole.ProjectRead)
  const canViewLevels = usePermission(ERole.LevelRead)

  const getRows = () => {
    if (!vm.contact) return []
    if (projectCount == -1) {
      setProjectCount(vm.contact.projects ? vm.contact.projects.length : 0)
    }
    return vm.contact.projects
      ? normalizeLevelsForDataGrid(vm.contact.projects)
      : []
    // if using pagination/vm, will need this for sorting
    // return projectVM.projects
    //   ? normalizeProjectsForDataGrid(projectVM.projects)
    //   : []
  }

  // TODO should get levels from levels view model in case
  // of pagination, BE needs to be updated
  const getLevelRows = () => {
    if (!vm.contact) return []
    if (levelCount == -1) {
      setLevelCount(vm.contact.levels ? vm.contact.levels.length : 0)
    }
    return vm.contact.levels
      ? normalizeLevelsForDataGrid(vm.contact.levels)
      : []
  }
  const getContractForm = () => {
    return (
      <ContactsForm
        id={vm.contact?.id}
        initContact={vm.contact}
        onCancel={() => navigate(-1)}
      />
    )
  }
  const getTitle = () => {
    if (vm.contact) {
      return (
        <PageHeader title={vm.contact.fullName ?? ''} />
        // <ProfileHeaderV2
        //   fullName={vm.user.fullName}
        //   imageURL={vm.user.imageURL}
        // />
      )
    }

    return 'Loading...'
  }
  return (
    <PageContainer
      breadCrumbSX={{ marginBottom: '0px' }}
      title={getTitle()}
      renderRightItems={() => {
        if (isEdit) {
          return (
            <Box>
              <EdifyButton
                sx={{ '&:hover': { color: AppColors.danger600 } }}
                data-testid='DeleteSubButton'
                noBackground
                alertInverse
                onClick={vm.deleteContact}
                icon={<TrashIcon />}
                title='Remove'
              />
            </Box>
          )
        }

        return (
          <Box sx={{ display: 'flex' }}>
            <EdifyButton
              hide={!canEdit}
              noBackground
              title={'Edit'}
              onClick={() =>
                navigate(
                  `${ROUTE_CONTRACTORS}/${contractorId}${ROUTE_CONTACTS}/${
                    vm.contact!.id
                  }/edit`,
                )
              }
            />
          </Box>
        )
      }}
      breadCrumbs={[
        {
          title: 'Contractors!',
          to: `${ROUTE_CONTRACTORS}`,
        },
        {
          title: contractorName ? contractorName : 'Contractor',
          to: `${ROUTE_CONTRACTORS}/${contractorId}`,
        },
        {
          title: vm.contact?.fullName as string,
          to: `${ROUTE_CONTRACTORS}/${contractorId}${ROUTE_CONTACTS}/${vm.contact?.id}`,
        },
        ...(isEdit
          ? [
            {
              title: 'Edit',
              to: `${ROUTE_CONTRACTORS}/${contractorId}/edit`,
            },
          ]
          : []),
      ]}
    >
      {isEdit ? (
        <>{getContractForm()}</>
      ) : (
        <>
          <ProfileContainer
            // innerPageError={vm.contactError}
            innerPageLoading={vm.contactLoading}
          >
            <Box sx={{ width: '100%', padding: '0px 24px' }}>
              <Box style={{ display: 'flex', flex: 1 }}>
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'TRADE'}
                  value={vm.contact?.trade ?? ''}
                />
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'WORK EMAIL'}
                  value={vm.contact?.email ?? 'NA'}
                />
              </Box>
            </Box>
            <Box sx={{ width: '100%', padding: '0px 24px' }}>
              <Box style={{ display: 'flex', flex: 1 }}>
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'PHONE'}
                  value={
                    vm.contact?.phone
                      ? phoneInputFormatter(vm.contact?.phone)
                      : 'NA'
                  }
                />
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'TITLE'}
                  value={vm.contact?.title ? vm.contact?.title : 'NA'}
                />
              </Box>
            </Box>
            <Box sx={{ width: '100%', padding: '0px 24px' }}>
              <Box style={{ display: 'flex', flex: 1 }}>
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'CONTACT ID'}
                  value={
                    vm.contact?.vendorIdNumber
                      ? phoneInputFormatter(vm.contact?.vendorIdNumber)
                      : 'NA'
                  }
                />
              </Box>
            </Box>
          </ProfileContainer>
          {canViewProjects && (
            <>
              <ProjectsDataGrid
                vm={projectVM}
                error={projectVM.error}
                title={getHierarchyName(0)}
                rows={getRows()}
                checkboxSelection={false}
                totalCount={projectCount}
              />
              <Box sx={{ marginBottom: '24px' }} />
            </>
          )}
          {canViewProjects && (
            <>
              <LevelsDataGrid
                vm={levelVM}
                error={levelVM.levelsError}
                title={getHierarchyName(1)}
                rows={getLevelRows()}
                checkboxSelection={false}
                totalCount={levelCount}
              />
            </>
          )}
        </>
      )}
    </PageContainer>
  )
}
interface IContactProps {
  isEdit?: boolean
}

export const ContactPage: React.FC<IContactProps> = (props) => {
  return (
    <ContactPageViewModel>
      <LevelsPageViewModel>
        <ProjectsPageViewModel>
          <_ContactPage {...props} />
        </ProjectsPageViewModel>
      </LevelsPageViewModel>
    </ContactPageViewModel>
  )
}
