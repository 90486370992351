import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import InnerPageContainer from '../../../../components/inner-page-container/InnerPageContainer'

import { AppColors } from '../../../../Theme'
import { EdifyButton } from '../../../../components/buttons'

// eslint ignore
const md = `
# InnerPageContainer 

The InnerPageContainer component accepts the following props:

# Props

- 'innerPageLoading' (boolean, default: 'false'): Whether the inner page is in a loading state. When set to 'true', a loading tile will be displayed until the content is ready.
- 'innerPageError' (string | undefined): The error message to be displayed when the inner page has an error. If provided, the component will render an error container with the specified error message.
- 'innerPageReload' (function): The function to be called when the inner page needs to be reloaded. When an error occurs, a reload button will be displayed, and clicking it will trigger this function.
- 'header' (ReactNode): Optional header component to be displayed at the top of the container. It allows you to add a custom header for the inner page.
- 'collapsed' (boolean, default: 'false'): Whether the content should be initially collapsed. If set to 'true', the content will be hidden by default, and users can expand it by clicking the arrow icon.
- 'collapsible' (boolean, default: 'false'): Whether the content can be collapsed or expanded. If set to 'true', an arrow icon will be displayed, allowing users to toggle the visibility of the content.
- 'error' (boolean, default: 'false'): Whether the component is in an error state. When set to 'true', the component will render with an error border color.

## InnerContainerProps

This component uses the 'InnerContainerProps' interface to define its props. The 'InnerContainerProps' interface includes the following properties:

- 'collapsed' (boolean, default: 'false'): Whether the content should be initially collapsed.
- 'collapsible' (boolean, default: 'false'): Whether the content can be collapsed or expanded.
- 'children' (ReactNode): The child components to be wrapped by the container.
- 'header' (ReactNode): Optional header component to be displayed at the top of the container.
- 'error' (boolean, default: 'false'): Whether the component is in an error state.
- 'sx' (SxProps): Additional custom style props to be applied to the container.

## Components

The 'InnerPageContainer' component internally uses the '_InnerContainer' component. '_InnerContainer' is a private component that handles the actual rendering of the container with the specified styles and logic for collapsing and expanding the content. You do not need to use '_InnerContainer' directly, as 'InnerPageContainer' provides a cleaner interface for using the container in your application.

## Additional Notes

- The 'InnerPageContainer' component is designed to be used in conjunction with other components such as loading tiles and error containers. It gracefully handles different states and provides a consistent user experience across the application.
- To ensure proper functionality, make sure to pass the required props when using 'InnerPageContainer' and provide appropriate handlers for loading, error, and reloading logic.
- The component is customizable with various style properties ('sx' prop) to allow for seamless integration with your application's visual design.
- Use the 'InnerPageContainer' component to improve the layout and styling of your sub-components, providing a cleaner and more user-friendly interface for your application.
`

export const InnerPageContainerDoc = () => {
  const [loading, setLoading] = useState(true)
  const [loadingError, setLoadingError] = useState(false)
  const [error1, setError1] = useState<string | undefined>('Another Error from InnerPageContainerDoc')
  const handleReload = () => {
    // Simulate reloading data
    setLoadingError(true)
    setTimeout(() => {
      setLoadingError(false)
      setError1(undefined)
    }, 2000)
  }

  return (
    <>
      <InnerPageContainer header={<h3>markdown</h3>} collapsible>
        <ReactMarkdown>{md}</ReactMarkdown>
      </InnerPageContainer>


      <InnerPageContainer header={<h3>Examples</h3>} collapsible>
        <div>
          {/* Example with loading state */}
          <InnerPageContainer
            header={<h4 style={{ color: AppColors.primary600 }}>Loading</h4>}
            innerPageLoading={loading}
          >
            <p>This is the content of the inner page.</p>
          </InnerPageContainer>

          {/* Example with error state */}
          <InnerPageContainer
            header={
              <h4 style={{ color: AppColors.danger500 }}>Error Reload</h4>
            }
            innerPageLoading={loadingError}
            innerPageError={error1}
            innerPageReload={handleReload}
          >
            <p>Error Fixed!</p>
            <p>content</p>
            <p>content</p>
            <p>content</p>
            <p>content</p>
            <EdifyButton
              title='redo'
              onClick={() => setError1('ANOTHER ERROR')}
            />
          </InnerPageContainer>

          {/* Example with custom header */}
          <InnerPageContainer
            header={
              <h4 style={{ color: AppColors.gray700 }}>Not Collapsible</h4>
            }
          >
            <p>This is the content of the inner page.</p>
          </InnerPageContainer>

          <InnerPageContainer
            collapsible
            header={
              <h4 style={{ color: AppColors.primary600 }}>collapsible</h4>
            }
          >
            <p>Collapsible content</p>
          </InnerPageContainer>

          {/* Example with error state and custom styles */}
          <InnerPageContainer
            sx={{
              border: '2px dashed red',
              backgroundColor: 'lightgray',
            }}
          >
            <p>Custom styling</p>
          </InnerPageContainer>
        </div>
      </InnerPageContainer>
    </>
  )
}
