import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  GoalsPageViewModel,
  GoalsPageViewModelProps,
  useGoalsPageViewModel,
} from './GoalsPageViewModel'
import GoalsFilter from './GoalsFilter'
import GoalsDataGrid from './GoalsDataGrid'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import withPermission from '../../../core/hooks/withPermission'

export const ROUTE_GOALS = '/goals'

////// PAGE

const _GoalsPage: React.FC = () => {
  const vm: GoalsPageViewModelProps = useGoalsPageViewModel()
  const canCreate = usePermission(ERole.GoalCreate)
  const canEdit = usePermission(ERole.GoalEdit, false, 'NoBulkEdit')
  return (
    <PageContainer
      errorObj={vm.errorObj}
      // mainPageLoading={vm.isLoading}
      mainPageError={vm.error}
      reload={vm.getGoals}
      title='Goals & Controls'
      breadCrumbs={[{ title: 'Goals & Controls', to: ROUTE_GOALS }]}
      subtitle='View and manage your goals & controls here.'
    >
      <GoalsFilter vm={vm} buttonTitle='Goal & Control' canCreate={canCreate} />
      <GoalsDataGrid
        title='Goals & Control'
        vm={vm}
        rows={vm.goals}
        checkboxSelection={canEdit}
      />
    </PageContainer>
  )
}

const GoalsPageWithPermission = withPermission(_GoalsPage , ERole.GoalFeatureAccess)

export const GoalsPage = () => (
  <GoalsPageViewModel>
    <GoalsPageWithPermission />
  </GoalsPageViewModel>
)
