import { Either, Failure } from '../../../core/core'
import {
  ILocation,
  ILocationList,
  ISubLocation,
  ISubLocationHierarchyName,
  ISubLocationHierarchyNameList,
  ISubLocationList,
} from '../../../domain/interfaces/ILocation'
import { httpDelete, httpGet, httpPost, httpPut } from '../../data'

const locationsUrl = '/locations'
const subLocationHierarchyNamesUrl = '/subLocationHierarchyNames'
const subLocationsUrl = '/subLocations'

export async function locationCreate(
  locationData: any,
): Promise<Either<Failure, ILocation>> {
  const res: Either<Failure, ILocation> = await httpPost(
    locationsUrl,
    locationData,
  )
  return res
}

export async function locationUpdate(
  locationData: any,
  id: string,
): Promise<Either<Failure, ILocation>> {
  const res: Either<Failure, ILocation> = await httpPut(
    `${locationsUrl}/${id}`,
    locationData,
  )
  return res
}

export async function locationDelete(
  id: string,
): Promise<Either<Failure, ILocation>> {
  const res: Either<Failure, ILocation> = await httpDelete(
    `${locationsUrl}/${id}`,
  )
  return res
}

export async function locationsGet(
  queryString: string,
): Promise<Either<Failure, ILocationList>> {
  const res: Either<Failure, ILocationList> = await httpGet(
    `${locationsUrl}?${queryString}`,
    false,
  )
  // // return dummy data for now
  // res.right = { total_count: 3, data: dummyLocations }
  return res
}
export async function locationGet(
  id: string,
): Promise<Either<Failure, ILocation>> {
  const res: Either<Failure, ILocation> = await httpGet(
    `${locationsUrl}/${id}`,
    true,
  )
  return res
}

export async function subLocationsHierarchyNameGet(): Promise<
  Either<Failure, ISubLocationHierarchyNameList>
  > {
  const res: Either<Failure, ISubLocationHierarchyNameList> = await httpGet(
    subLocationHierarchyNamesUrl,
    false,
  )
  return res
}

export async function subLocationHierarchyNameCreate(
  locationData: ISubLocationHierarchyName,
): Promise<Either<Failure, ISubLocationHierarchyName>> {
  const res: Either<Failure, ISubLocationHierarchyName> = await httpPost(
    subLocationHierarchyNamesUrl,
    locationData,
  )
  return res
}

export async function subLocationHierarchyNameUpdate(
  locationData: ISubLocationHierarchyName,
  id: string,
): Promise<Either<Failure, ISubLocationHierarchyName>> {
  const res: Either<Failure, ISubLocationHierarchyName> = await httpPut(
    `${subLocationHierarchyNamesUrl}/${id}`,
    locationData,
  )
  return res
}

export async function subLocationHierarchyNameDelete(
  id: string,
): Promise<Either<Failure, ISubLocationHierarchyName>> {
  const res: Either<Failure, ISubLocationHierarchyName> = await httpDelete(
    `${subLocationHierarchyNamesUrl}/${id}`,
  )
  return res
}

export async function subLocationsGet(
  locationId: string,
): Promise<Either<Failure, ISubLocationList>> {
  const res: Either<Failure, ISubLocationList> = await httpGet(
    `${subLocationsUrl}/?locationId=${locationId}`,
    false,
  )
  return res
}

export async function subLocationCreate(
  locationData: ISubLocation,
): Promise<Either<Failure, ISubLocation>> {
  const res: Either<Failure, ISubLocation> = await httpPost(
    subLocationsUrl,
    locationData,
  )
  return res
}

export async function subLocationUpdate(
  locationData: ISubLocation,
  id: string,
): Promise<Either<Failure, ISubLocation>> {
  const res: Either<Failure, ISubLocation> = await httpPut(
    `${subLocationsUrl}/${id}`,
    locationData,
  )
  return res
}

export async function subLocationDelete(
  id: string,
): Promise<Either<Failure, ISubLocation>> {
  const res: Either<Failure, ISubLocation> = await httpDelete(
    `${subLocationsUrl}/${id}`,
  )
  return res
}

export async function locationTemplateCSVGet(): Promise<
  Either<Failure, string>
  > {
  const res: Either<Failure, string> = await httpGet(
    'projects/downloadLocationTemplateCSV',
    false,
  )
  return res
}

export async function locationCSVCreate(
  csvFile: File,
  id: string,
): Promise<Either<Failure, string>> {
  const formData = new FormData()
  formData.append('csvFile', csvFile)

  const res: Either<Failure, string> = await httpPost(
    `projects/uploadLocations/${id}`,
    formData,
  )
  return res
}

export async function subLocationTemplateCSVGet(): Promise<
  Either<Failure, string>
  > {
  const res: Either<Failure, string> = await httpGet(
    'locations/downloadSubLocationTemplateCSV',
    false,
  )
  return res
}

export async function subLocationCSVCreate(
  csvFile: File,
  id: string,
): Promise<Either<Failure, string>> {
  const formData = new FormData()
  formData.append('csvFile', csvFile)

  const res: Either<Failure, string> = await httpPost(
    `locations/uploadSubLocations/${id}`,
    formData,
  )
  return res
}
