import React, { useContext, useEffect, useState } from 'react'

import { IContact } from '../../../domain/interfaces/IContact'
import { useNavigate, useParams } from 'react-router-dom'

import {
  hideConfirmationDialog,
  showConfirmationDeleteMessage,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import {
  adminCreateContact,
  adminDeleteContact,
  adminEditContact,
  getSpecificContact,
} from '../../../domain/domain'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { ETrade } from '../../../domain/interfaces/IContractor'

import { showConfirmationV3Message } from '../../components/dialogs/confirmation-dialog/ConfirmationV3'
import { ROUTE_CONTRACTORS } from '../contractors/ContractorsPage'
import { ROUTE_CONTACTS } from './ContactPage'


// { 
//   "contractorId": "a29d8989-06c8-4759-ba4f-c69e9a9a57ec", // required contractor id
//   "fullName": "Dan Ten",// required full name
//   "email": "dan.contractor10@gmail.com", // required email
//   "phone": "+16467023890",
//   "trade": "Mason",
//   "title": "Test Title",
//   "vendorIdNumber": "3988457485748",
//   "levelIds": ["f94429c1-5873-4011-a4ae-5f0d9172056c"],
//   "projectIds": ["543ae9f8-d7cd-4bef-9f07-151bcb2332f1"]
// }

export interface contactParams {
  id?: string
  fullName: string
  email: string
  trade?: string | ETrade
  title?: string
  projectIds?: string[]
  levelIds?: string[]
  phone?: string | undefined
  vendorIdNumber?: string | undefined
}

export interface ContactPageViewModelProps {
  getContact: () => void
  deleteContact: () => void
  updateContact: (contact: contactParams, id: string) => void
  createContact: (contact: contactParams, contractorId:string, reset: any) => void
  contact: IContact | undefined
  contactLoading: boolean
  contactError: string | undefined
  formError: string | undefined
}

const ContactPageContext =
  React.createContext<ContactPageViewModelProps | null>(null)

export function useContactPageViewModel(): ContactPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(ContactPageContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const ContactPageViewModel: React.FC<Props> = ({ children }) => {
  const { contactId, contractorId } = useParams()
  const navigate = useNavigate()
  const [contact, setContact] = useState<IContact | undefined>(undefined)
  const [contactLoading, setContactLoading] = useState<boolean>(false)
  const [contactError, setContactError] = useState<string | undefined>(
    undefined,
  )
  const [formError, setFormError] = useState<string | undefined>(undefined)

  const getContact = async () => {
    setContactLoading(true)
    setContactError(undefined)
    const res = await getSpecificContact(contactId as string)
    setContactLoading(false)
    // TODO: uncomment when DB done
    // if (res.isLeft()) {
    //   setContactError(res.left?.message ?? 'Error loading contact.')
    //   return
    // }
    setContact(res.right as IContact)
  }

  //CREATE CONTRACTOR
  const createContact = async (contact: contactParams, contractorId:string, reset: any) => {
    setFormError(undefined)

    const result = await adminCreateContact(contact, contractorId)

    if (result.isLeft()) {
      setFormError(result.left?.message)
    } else {
      // SuccessToast({
      //   title: `Contact: ${contact.fullName} Created and Invitation Sent`,
      // })
      // show pop up to add another contact
      showConfirmationV3Message(
        'Contact saved. Add another contact?',
        'Click the `Add Another` button below if you want to add another contact. Otherwise, click `No` to return to the Contacts page.',
        'Add Another',
        'Back To list',
        'View Contact',
        () => {
          reset()
        },
        () => {
          navigate(`${ROUTE_CONTRACTORS}/${contractorId}`)
        },
        () => {
          navigate(`${ROUTE_CONTRACTORS}/${contractorId}${ROUTE_CONTACTS}/${result.right?.id}`)
        },
      )
    }
  }

  //Update CONTRACTOR, maybe combine with create contact
  const updateContact = async (contact: contactParams, id: string) => {
    setFormError(undefined)
    const result = await adminEditContact(contact, id)

    if (result.isLeft()) {
      setFormError(result.left?.message)
    } else {
      SuccessToast({ title: `Contact: ${contact.fullName} Updated` })
      navigate(`${ROUTE_CONTRACTORS}/${contractorId}`)
    }
  }

  const deleteContact = async () => {
    showConfirmationDialog({
      title: 'Confirm Delete',
      message:
        'Are you sure you want to delete this contact from the database? This action cannot be undone.',
      cancelText: 'Cancel',
      confirmText: 'Delete',
      onCancel: function (): void {
        hideConfirmationDialog()
      },
      onConfirm: async () => {
        const results = await adminDeleteContact(contactId!)
        if (results.isLeft()) {
          ErrorToast({
            title: 'Error removing contact.',
          })
          return
        }

        SuccessToast({
          title: 'Contact has been deleted successfully.',
        })
        navigate(`${ROUTE_CONTRACTORS}`)
        hideConfirmationDialog()
      },
    })
  }

  useEffect(() => {
    if (contactId) {
      getContact()
    }
  }, [contactId])

  return (
    <ContactPageContext.Provider
      value={{
        getContact,
        contact,
        contactLoading,
        contactError,
        deleteContact,
        formError,
        createContact,
        updateContact,
      }}
    >
      {children}
    </ContactPageContext.Provider>
  )
}
