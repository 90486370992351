import { Either, Failure } from '../../../core/core'
import {
  ICertificate,
  ICertificateCreate,
  ICertificateList,
} from '../../../domain/interfaces/ICertificate'

import { httpDelete, httpGet, httpGetAnon, httpPost, httpPut } from '../../data'

export async function getCertificates(
  queryString: string,
): Promise<Either<Failure, ICertificateList>> {
  const res: Either<Failure, ICertificateList> = await httpGet(
    `/trainingCertificates?${queryString}&platform=web`,
    false,
  )

  return res
}

export async function getCertificateById(
  id: string,
): Promise<Either<Failure, ICertificate>> {
  const res: Either<Failure, ICertificate> = await httpGet(
    `/trainingCertificates/${id}?platform=web`,
    true,
  )

  return res
}

export async function createCertificate(
  certificate: ICertificateCreate,
  userId: string,
): Promise<Either<Failure, ICertificate>> {
  const res: Either<Failure, ICertificate> = await httpPost(
    '/trainingCertificates',
    { ...certificate, userId },
    undefined, //no additional headers
    true,
  )

  return res
}

export async function editCertificate(
  certificate: ICertificateCreate,
  certificateId: string,
): Promise<Either<Failure, ICertificate>> {
  const res: Either<Failure, ICertificate> = await httpPut(
    `/trainingCertificates/${certificateId}`,
    certificate,
    false, // direct data parsing is false
  )

  return res
}

export async function deleteCertificate(
  certificateId: string,
): Promise<Either<Failure, ICertificate>> {
  const res: Either<Failure, ICertificate> = await httpDelete(
    `/trainingCertificates/${certificateId}`,
    undefined, //no additional headers
  )

  return res
}

export async function deleteCertificates(
  certificateIds: string[],
): Promise<Either<Failure, ICertificate[]>> {
  const res: Either<Failure, ICertificate[]> = await httpDelete(
    `/trainingCertificates/${certificateIds}`,
    undefined, //no additional headers
  )

  return res
}

export async function anonGetAllCertificates(
  orgId: string,
  userId: string,
  queryString: string,
): Promise<Either<Failure, ICertificateList>> {
  const res: Either<Failure, ICertificateList> = await httpGetAnon(
    `/anonymous/trainingCertificates/${userId}?${queryString}&orgId=${orgId}`,
    { orgId },
    false,
  )
  return res
}

//Not functional
// export async function anonGetOneCertificate(
//   orgId: string,
//   certificateId: string,

// ): Promise<Either<Failure, ICertificate>> {
//   const res: Either<Failure, ICertificate> = await httpGetAnon(
//     `/anonymous/trainingCertificates/${certificateId}?&orgId=${orgId}`,
//     { orgId },
//     false,
//   )
//   return res
// }
