// if server is not configured externally, use REACT env variables. ie. for local development
export const REACT_APP_SERVER_API =
  window.env?.REACT_APP_SERVER_API ?? process.env.REACT_APP_SERVER_API

export const REACT_APP_FORMIO_PROJECT_URL =
  window.env?.REACT_APP_FORMIO_PROJECT_URL ??
  process.env.REACT_APP_FORMIO_PROJECT_URL

export const REACT_APP_DEPLOYED_FRONTEND =
  window.env?.REACT_APP_DEPLOYED_FRONTEND ??
  process.env.REACT_APP_DEPLOYED_FRONTEND

export const REACT_APP_BUILD_TYPE =
  window.env?.REACT_APP_BUILD_TYPE ?? process.env.REACT_APP_BUILD_TYPE

export const GOOGLE_MAPS_API_KEY =
  window.env?.GOOGLE_MAPS_API_KEY ?? process.env.GOOGLE_MAPS_API_KEY
