import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridItem } from '../gridItems'
import { IDocument } from '../../../../domain/interfaces/IDocument'


export const document_columns: GridColDef[] = [
  {
    field: 'name',
    renderHeader: () => <GridItem text='Document Name' />,
    flex: 1.5,
    renderCell: ({ row }) => (
      <GridItem text={row.name}  />
    ),
  },

]

export const normalizeDocumentsForDataGrid: any = (documents?: IDocument[]) => {
  if (!documents) return []

  return documents?.map((document: IDocument, i) => {
    return {
      id: document.id,
      name: document.filename,
      path: document.webPath

    }
  })
}
