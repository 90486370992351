import React, { useEffect, useState } from 'react'
import { Grid, Input } from '@mui/material'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { EdifyButton } from '../../components/buttons'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_PROJECTS } from '../projects/ProjectsPage'
import { SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import { AppColors } from '../../Theme'
import {
  getSubLocationTemplateCSV,
  uploadSubLocationsCSVFile,
} from '../../../domain/domain'
import { EnhancedFileUpload } from '../user/EnhancedFileUpload'

interface SubLocationCardProps {
  locationId:string
}

const SubLocationCSVUpload:React.FC<SubLocationCardProps> = ({locationId})=> {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [loadingCSV, setLoadingCSV] = useState(false)
  const [csvString, setCSVString] = useState('')
  const [error, setError] = useState<string | undefined>('')

  const handleUpload = async (file: File) => {
    if (file) {
      const res = await uploadSubLocationsCSVFile(file, locationId)
      if (res.right) {
        SuccessToast({ title: 'Locations Uploaded Successfully' })
        navigate(`${ROUTE_PROJECTS}/${projectId}/edit`)
        return
      }
      setError(res.left?.message ?? 'Failed to upload CSV file')
    }
  }

  const loadCSV = async () => {
    setLoadingCSV(true)
    //  TODO REPLACE WITH SubLocationCSVUpload
    const res = await getSubLocationTemplateCSV()
    setLoadingCSV(false)
    if (res.right) {
      setCSVString(res.right)
      return
    }
    setError(res.left?.message ?? 'Failed to load CSV Template')
  }

  const handleDownload = () => {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sub-locations.csv') // Choose the file name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link) // Clean up after downloading
  }
  useEffect(() => {
    loadCSV()
  }, [])

  return (
    <InnerPageContainer
      headerTitle='Upload Sub Location CSV'
      renderRightItems={() => (
        <EdifyButton
          noBackground
          title='Download Location CSV Template'
          onClick={handleDownload}
        />
      )}
    >
      <Grid container spacing={'24px'}>
        <Grid item sm={12} md={6}>
          <EdifyFormField>
            <EdifyFieldLabel label='Upload CSV File' />
            <EnhancedFileUpload
              handleUpload={handleUpload}
              loading={loadingCSV}
              error={error}
              setError={setError}
              allowedFileTypes={['csv']}
            />
          </EdifyFormField>
        </Grid>
        <Grid item sm={12} md={6}>
          <EdifyFormField>
            <EdifyFieldLabel label='CSV Format' />
            <Input
              sx={{
                border: `1px solid ${AppColors.neutral600}`,
                borderRadius: '4px',
                width: '100%',
                padding: '2px 8px',
                height:'150px'
              }}
              disableUnderline
              disabled
              multiline
              rows={4}
              value={csvString}
            />
          </EdifyFormField>
        </Grid>
      </Grid>
    </InnerPageContainer>
  )
}

export default SubLocationCSVUpload

