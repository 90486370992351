import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box, Input } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import {
  EdifyFieldLabel,
  EdifyFormField,
  EdifySelect,
} from '../../components/form'

import { Controller, FieldErrors, Resolver, useForm } from 'react-hook-form'
import { phoneInputFormatter } from '../../../core/utils/input-formatters/InputFormatters'
import FormErrorText from '../../components/form/FormErrorText'
import { IUser } from '../../../domain/interfaces/IUser'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import { ROUTE_USERS } from '../users/UsersPage'
import { useUserPageViewModel, userParams } from './UserPageViewModel'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'
import { ERole, IRole } from '../../../domain/interfaces/IRole'
import {
  OrganizationProviderProps,
  useOrganizationProvider,
} from '../../../providers/OrganizationProvider'
import { formatSnakeCase } from '../../../core/utils/text-formaters/TextFormatters'
import usePermission from '../../../core/hooks/usePermission'
import { ProfileHeader } from '../../components/profile/ProfileHeader'
import { globalSearch } from '../../../domain/usecases/utils/UtilsUsecasses'

const default_user: any = {
  id: '',
  email: '',
  phone: '',
  projects: [],
  role: undefined,
  //TODO add division
}

// react-hook-form state
type ProfileInputs = {
  fullName: string
  email: string
  phone?: string
  projects?: string[]
  levels?: string[]
  role: string
  pointOfContact?: string | undefined
}
interface UserProfileFormProps {
  id?: string | undefined
  onCancel?: () => void
  canEditUser?: boolean
}
export const UsersForm: React.FC<UserProfileFormProps> = ({ id, onCancel }) => {
  const [user, setUser] = useState<IUser | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [projects, setProjects] = useState<ISearchResult[]>([])
  const [levels, setLevels] = useState<ISearchResult[]>([])
  const [loadedProfile, setLoadedProfile] = useState<boolean>(false)
  const [role, setRole] = useState<string>()
  const [pointOfContact, setPointOfContact] = useState<
    ISearchResult | undefined
  >(undefined)
  const vm = useUserPageViewModel()
  const {
    getHierarchyName,
    allLevels,
    allProjects,
  }: OrganizationProviderProps = useOrganizationProvider()

  const projectName = getHierarchyName(0, true)
  const levelName = getHierarchyName(1, true)
  const canEdit = usePermission(ERole.UserEdit)

  /**
   * Validates the input values for the person's profile form.
   */
  const resolver: Resolver<ProfileInputs> = async (values) => {
    const errors: FieldErrors<ProfileInputs> = {}

    if (!values.fullName) {
      errors.fullName = {
        type: 'required',
        message: 'Name is required',
      }
    }
    if (!values.email) {
      errors.email = {
        type: 'required',
        message: 'Email is required',
      }
    }
    if (!values.role) {
      errors.role = {
        type: 'required',
        message: 'Role is required',
      }
    }

    if (values.phone) {
      const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/
      if (!phoneNumberPattern.test(values.phone)) {
        errors.phone = {
          type: 'pattern',
          message: 'Invalid phone number',
        }
      }
    }
    return {
      values,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<ProfileInputs>({ resolver, mode: 'onSubmit' })

  /**
   * Handles the form submission when the form is valid.
   * will run resolver validations before submitting
   * @param {Object} data - The form data submitted by the user.
   * @returns {void}
   */
  const onSubmit = handleSubmit(async (data) => {
    // react-hook-form will check for errors here and return
    // if there are any
    // if we get here validations have passed
    const pointOfContactId = pointOfContact?.id ?? null
    const formData: userParams = {
      fullName: data.fullName,
      email: data.email,
      phone: data.phone ?? '',
      role: data.role ?? '',
      selectedProjects: projects.map((p) => p.id),
      selectedLevels: levels.map((l) => l.id),
      pointOfContactId: pointOfContactId != '' ? pointOfContactId : undefined,
    }
    setLoading(true)
    if (id) {
      vm.updateUser({ id, ...formData })
    } else {
      vm.createUser(formData)
    }
    setLoading(false)
  })

  const handlePhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    const formattedValue = phoneInputFormatter(value)
    setValue('phone', formattedValue, {
      shouldValidate: value.length > 11 || value.length == 0,
    })
  }

  const contactSelected = (contacts: ISearchResult[]) => {
    setPointOfContact(contacts[0])
  }

  // Sets Initial values of the form
  const getUser = () => {
    setLoadedProfile(false)
    let UserData
    // new User
    if (!id) {
      UserData = default_user
    } else {
      UserData = vm.user
      setProjects(vm.user?.projects ?? [])
      setLevels(vm.user?.levels ?? [])
      setRole(vm.user?.roles[0].id)
      // TODO CHECK WHEN BE DONE SHOULD POPULATE POINT OF CONTACT
      // ON EDIT
      setPointOfContact({
        name: vm.user?.pointOfContactName ?? '',
        id: vm.user?.pointOfContactId ?? '',
      })
    }

    setUser(UserData)
    const defaultValues: ProfileInputs = {
      fullName: UserData?.fullName ?? '',
      email: UserData?.email ?? '',
      phone:
        UserData && UserData.phone ? phoneInputFormatter(UserData.phone) : '',
      role: UserData.roles ? UserData.roles[0].id : undefined,
      projects: UserData.projects,
      levels: UserData.levels,
    }
    reset({ ...defaultValues })
    setLoadedProfile(true)
  }

  const projectSelected = (projects: ISearchResult[]) => {
    setProjects(projects)
  }
  const levelSelected = (levels: ISearchResult[]) => {
    setLevels(levels)
  }

  useEffect(() => {
    getUser()
  }, [])

  const navigate = useNavigate()

  // // TODO: ACTUALLY DELETE THE IMAGE FROM THE SERVER
  // const imageUploaded = async (imageURL: string | null) => {
  //   // setLoadedUser(false)
  //   setLoading(true)
  //   // setServerError(undefined)
  //   const res = await adminEditUser(
  //     {
  //       imageURL: imageURL,
  //     },
  //     User!.id,
  //   )
  //   setImageURL(imageURL ?? null)
  //   setLoading(false)
  //   handleUploadResponse(res, false)
  // }
  const imageUploaded = (url:string | null)=>{
    if(url){
      setImageUrl(url)
    }
  }

  if (!loadedProfile)
    return <InnerPageContainer innerPageLoading></InnerPageContainer>
  return (
    <form onSubmit={onSubmit}>
      {vm.formError && (
        <InnerPageContainer innerPageError={vm.formError}>
          {/* TODO: Add close logic, this is done in another story */}
        </InnerPageContainer>
      )}

      <InnerPageContainer sx={{ padding: '0px' }}>
        <ProfileHeader
          isEdit={true}
          canEdit={false}
          // Do not allow admin to add photo when creating a new user
          // this could change.
          // imageUploaded={imageUploaded}
          imageURL={''}
          fullName={''}
          loadedProfile={true}
        />
        <Box sx={{ padding: '24px' }}>
          <Box style={{ display: 'flex', flex: 1 }}>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel required label='NAME' />
              <Input
                data-testid='fullName'
                disableUnderline
                placeholder='Enter name'
                className={`form-control ${errors?.fullName ? 'error' : ''}`}
                {...register('fullName')}
              />
              {errors?.fullName && (
                <FormErrorText>{errors.fullName.message}</FormErrorText>
              )}
            </EdifyFormField>
          </Box>
          <Box>
            <Box style={{ display: 'flex', flex: 1 }}>
              <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
                <EdifyFieldLabel label='PHONE NUMBER' />
                <Controller
                  name='phone'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <Input
                      {...field}
                      data-testid='PhoneNumber'
                      disableUnderline
                      placeholder='000-000-0000'
                      className={`form-control ${errors?.phone ? 'error' : ''}`}
                      value={field.value}
                      onChange={handlePhoneInputChange}
                    />
                  )}
                />
                {errors?.phone && (
                  <FormErrorText>{errors.phone.message}</FormErrorText>
                )}
              </EdifyFormField>
              <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
                <EdifyFieldLabel
                  required
                  label='EMAIL'
                  disabled={id != undefined}
                />
                <Input
                  disabled={id != undefined}
                  data-testid='Email'
                  disableUnderline
                  placeholder='sample@email.com'
                  className={'form-control'}
                  {...register('email')}
                />
                {errors?.email && (
                  <FormErrorText>{errors.email.message}</FormErrorText>
                )}
              </EdifyFormField>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <EdifyFormField>
                <EdifyFieldLabel required label='SELECT ROLE' />

                <EdifySelect
                  width={483}
                  // onChange={handleRoleChanged}
                  // value={role}
                  defaultValue={vm.user?.roles[0].id}
                  sx={{ marginRight: '24px', flex: 1 }}
                  dropDownItems={vm.roles ?? []}
                  {...register('role')}
                  renderMenuItem={(item: IRole) => (
                    <EdifyMenuItem key={item.id} value={item.id}>
                      {formatSnakeCase(item.name)}
                    </EdifyMenuItem>
                  )}
                />
                {errors?.role && (
                  <FormErrorText>{errors.role.message}</FormErrorText>
                )}
              </EdifyFormField>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <EdifyFormField sx={{ flex: 1 }}>
                <EdifyFieldLabel label='Point Of Contact' />
                <EdifySearchDropdown
                  pillListWidth={'100%'}
                  sx={{ flex: 1 }}
                  initialSelectedItems={pointOfContact ? [pointOfContact] : []}
                  searchFunction={globalSearch}
                  width={483}
                  onSelect={contactSelected}
                  nameKey='fullName'
                  limit={16}
                  globalSearchKey='users'
                  globalSearchPayload={{
                    entities: ['users'],
                    properties: ['id'],
                    returns: ['id', 'fullName'],
                  }}
                />
              </EdifyFormField>
            </Box>
            <EdifyFormField>
              <EdifyFieldLabel
                label={`SELECT EXISTING ${levelName.toUpperCase()}`}
              />
              <EdifySearchDropdown
                data-testid='LevelsSearch'
                defaultItems={allLevels.map((p) => {
                  return { id: p.id, name: p.name }
                })}
                multiSelect
                pillListWidth={'100%'}
                width={484}
                onSelect={levelSelected}
                searchFunction={globalSearch}
                initialSelectedItems={vm.user?.levels}
                globalSearchKey='levels'
                globalSearchPayload={{
                  entities: ['levels'],
                  properties: ['id'],
                  returns: ['id', 'name'],
                }}
              />
            </EdifyFormField>
            <EdifyFormField>
              <EdifyFieldLabel
                label={`SELECT EXISTING ${projectName.toUpperCase()}`}
              />
              <EdifySearchDropdown
                defaultItems={allProjects.map((p) => {
                  return { id: p.id, name: p.name }
                })}
                data-testid='ProjectSearch'
                multiSelect
                pillListWidth={'100%'}
                width={484}
                onSelect={projectSelected}
                searchFunction={globalSearch}
                initialSelectedItems={vm.user?.projects}
                globalSearchKey='projects'
                globalSearchPayload={{
                  entities: ['projects'],
                  properties: ['id'],
                  returns: ['id', 'name'],
                }}
              />
            </EdifyFormField>
          </Box>
        </Box>
      </InnerPageContainer>

      {/* ADMIN SETTINGS UI  */}
      {/* <UserPermissionsSection /> */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <EdifyButton
          noBackground
          title='Cancel'
          onClick={onCancel ? onCancel : () => navigate(ROUTE_USERS)}
        />
        <Box>
          <EdifyButton
            disabled={Object.keys(errors).length > 0 || loading}
            type='submit'
            data-testid={'SubmissionButton'}
            primary
            title={'Save Changes'}
          />
        </Box>
      </Box>
    </form>
  )
}

export default UsersForm
