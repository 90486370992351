import React from 'react'

import PageContainer from '../../components/page/PageContainer'

import {
  ContractorPageViewModel,
  ContractorPageViewModelProps,
  useContractorPageViewModel,
} from './ContractorPageViewModel'

import { ROUTE_CONTRACTORS } from '../contractors/ContractorsPage'

import { useParams } from 'react-router-dom'
import ContractorsForm from './ContractorsForm'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'

const _ContractorFormPage: React.FC = () => {
  const { id } = useParams()
  const vm: ContractorPageViewModelProps = useContractorPageViewModel()
  const canCreate = usePermission(ERole.ContractorCreate)

  const getBreadCrumbs = () => {
    return [
      {
        title: 'Contractors',
        to: `${ROUTE_CONTRACTORS}`,
      },
      {
        title: id !=='new' ? 'Add New Contractor' : vm.contractor?.name as string,
        to: id ? `${ROUTE_CONTRACTORS}/new` : `${ROUTE_CONTRACTORS}/${vm.contractor?.id}`,
      },
    ]
  }

  return (
    <PageContainer
      title={'Add New Contractor'}
      canView={canCreate}
      mainPageLoading={vm.contractorLoading}
      mainPageError={vm.contractorError}
      breadCrumbSX={{ marginBottom: '0px' }}
      breadCrumbs={getBreadCrumbs()}
    >
      <ContractorsForm  />
    </PageContainer>
  )
}

export const ContractorFormPage: React.FC = () => {
  return (
    <ContractorPageViewModel>
      <_ContractorFormPage />
    </ContractorPageViewModel>
  )
}
