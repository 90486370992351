import { Avatar, Box, SxProps, Typography } from '@mui/material'
import React from 'react'
import { AppColors, AppFonts } from '../../Theme'
import { ETypography } from '../fonts/ETypography'

interface IEdifyContactInfoProps {
  name: string
  role: string
  phone: string
  email: string
  imgUrl?: string
  pointOfContactText?: string
}

const EdifyContactInfo: React.FC<IEdifyContactInfoProps> = ({
  name,
  role,
  phone,
  email,
  pointOfContactText = 'Point of Contact',
}) => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'row' }}>
      <InfoBlock text={pointOfContactText} sx={{ paddingLeft: '24px' }}>
        <_AvatarInfoBlock
          text={name}
          subtext={role}
          sx={{ paddingLeft: '24px' }}
        />
      </InfoBlock>
      <InfoBlock text={'Phone Number'}>
        <Typography sx={textStyle}>{phone}</Typography>
      </InfoBlock>
      <InfoBlock text={'Email Address'}>
        <Typography sx={textStyle}>{email}</Typography>
      </InfoBlock>
    </Box>
  )
}

const textStyle = {
  paddingTop: '24px',
  ...AppFonts.textSMedium,
  color: AppColors.gray950,
}

interface InfoBlockProps {
  text: string
  children: React.ReactElement
  sx?: SxProps
}

export const InfoBlock: React.FC<InfoBlockProps> = ({ text, children, sx }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginTop: '24px',
    }}
  >
    <>
      <ETypography
        font='XSM'
        color='gray50'
        sx={{
          paddingBottom: '16px',
          ...sx,
        }}
      >
        {text}
      </ETypography>
      <Box
        sx={{
          borderTop: `1px solid ${AppColors.neutral600}`,
        }}
      ></Box>
    </>
    {children}
  </Box>
)

interface _IAvatarInfoBlockProps {
  text: string
  subtext: string
  sx?: SxProps
}

// This could maybe be export if needed else where
export const _AvatarInfoBlock: React.FC<_IAvatarInfoBlockProps> = ({
  text,
  subtext,
  sx,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '12px',
        ...sx,
      }}
    >
      <Avatar
        sx={{
          width: 32,
          height: 32,
          alignItems: 'center',
          marginRight: '16px',
        }}
      />
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <ETypography font='BTSS' color='gray700'>
          {text}
        </ETypography>
        <ETypography font='SM' color='gray50'>
          {subtext}
        </ETypography>
      </Box>
    </Box>
  )
}

export default EdifyContactInfo
