/// Check if [email] is valid or not.
export function isValidEmail(email: string): boolean {
  if (email.length === 0) {
    return false
  }

  const res: RegExpMatchArray | null = email.toLowerCase().match(
    String.raw`^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$`,
  )

  return res !== null && res.length > 0
}

/// Check if [phone] is valid or not.
export function isValidPhoneNumber(phone: string): boolean {
  return phone.length == 10 && RegExp('[0-9]').test(phone)
}

export function validateEmails(emails: string): boolean {
  // Regular expression for email validation
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/

  if(!emails) return false
  // Split the string into an array of emails
  const emailArray = emails.split(',')

  // Check each email
  for (let email of emailArray) {
    // Trim whitespace
    email = email.trim()

    // If the email doesn't match the regex, return false
    if (!emailRegex.test(email)) {
      return false
    }
  }

  // If all emails are valid, return true
  return true
}

