import React, { useContext, useState } from 'react'
import { IForm } from '../../../../domain/interfaces/IForm'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'

export interface EdifyNavViewModelProps {
  selectedDropdown: string
  forms: IForm[] | undefined
  getAllForms: () => void
  isLoading: boolean
  setSelectedDropdown: (value: string) => void
  searchForms: (formName?: string) => void
  getOptions: (value: string) => (string | undefined)[]
}

const EdifyNavContext = React.createContext<EdifyNavViewModelProps | null>(null)

export function useEdifyNavViewModel(): EdifyNavViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(EdifyNavContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const EdifyNavViewModel: React.FC<Props> = ({ children }) => {
  const [selectedDropdown, setSelectedDropdown ] = useState<string>('All')
  const {forms, getAllForms, searchForms, formsLoading} = useOrganizationProvider() 

  const getOptions = (value: string) => {
    if (forms === undefined) {
      return [] //['No results found']
    }

    return forms.map((form) => form.name)
  }



  return (
    <EdifyNavContext.Provider
      value={{
        selectedDropdown,
        setSelectedDropdown,
        forms: forms,
        getAllForms,
        searchForms,
        isLoading: formsLoading,
        getOptions,
      }}
    >
      {children}
    </EdifyNavContext.Provider>
  )
}
