import { Either, Failure } from '../../../core/core'
import { languagesGet, putLanguages, translationsGet } from '../../../data/repositories/translations/TranslationRepositories'
import { ILanguage, ITranslationList, IUpdateTranslation } from '../../interfaces/ITranslation'

export async function getTranslations(orgId: string, language: string): Promise<Either<Failure, ITranslationList>> {
  return await translationsGet(orgId, language)
}

export async function getLanguages(orgId?: string): Promise<Either<Failure, ILanguage>> {
  return await languagesGet(orgId)
}



export async function updateLanguages(data: IUpdateTranslation): Promise<Either<Failure, any>> {
  return await putLanguages(data)
}