import { ICorrectiveAction } from './ICorrectiveAction'

export enum EState {
  Submitted = 'submitted',
  Draft = 'draft',
}
export interface ISubmissionList {
  code?: number
  data?: ISubmission[]
  message: string
  total_count: number
}

export interface ISubmission {
  id: string
  createdAt: string
  updatedAt: string
  formId?: string
  formioSubmissionId?: string
  loginAccountId?: string
  targetAccountId?: string
  state?: EState
  data?: Record<string, any>
  originalData?: Record<string, any>
  project?: string
  externalIds?: []
  orgId?: string
  token?: string | undefined // Added
  orgName: string
  ownerName: string
  ownerEmail?: string
  ownerPhone?: string
  submittedBy: string
  userId?: string
  level1Name?: string
  projectName?: string
  isValid?: boolean
  correctiveActions?: ICorrectiveAction[]
  //TODO: update when BE changes
  postSubmissionData?: any[]
  parentSubmissionId?: string
  submitterId?: string
  submitterName?: string
  additionalFormSubmissions?: any[]
  linkFormSubmissions?: any[]
  originSubmissionId?: string
  originalLanguage?: string
  originalLanguageCode?: string
  status?: string
}


export interface ICreateSubmission {
  formId: string
  state: EState
  submissionLanguage?: string
  data: Record<string, any>
  orgId?: string
  correctiveActions?: any
  //
  parentSubmissionId?: string
  submitterId?: string
  submitterName?: string
  linkForms?: any
  additionalForms?: any
}

export interface ISubmissionPDFLink {
  link: string
}
