import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import { Box, Typography } from '@mui/material'

import { AppColors } from '../../Theme'
import { EdifyButton } from '../../components/buttons'
import { showContactSalesDialog } from './ContactSalesDialogue'
import { getCurrentUser } from '../../../domain/domain'

export const ROUTE_HOME = '/home'

interface Props {
  page: 'home' | 'dashboard'
}

const pageText = {
  home: {
    title: 'Homepage',
    description:
      'Here you can find your favorited forms, links to corrective actions and other important information for the safety of your team.',
  },
  dashboard: {
    title: 'Dashboard',
    description:
      'Dashboards help your teams dig into the quantitative side of safety and visualize what are the most common observations your team is noticing or how often do your foreman hold safety meetings?',
  },
}
const DemoPage: React.FC<Props> = ({ page }) => {
  const user = getCurrentUser()
  const showContactSales = async () => {
    showContactSalesDialog({ user })
  }
  return (
    <PageContainer>
      <Typography
        sx={{
          fontWeight: 900,
          fontSize: {  xs: '50px', md: '100px',  lg: '170px'},
          lineHeight: {  xs: '61px', md: '121px', lg:'212px'},
          color: AppColors.neutral600,
          marginBottom: '16px',
        }}
      >
        {pageText[page].title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '28px',
          lineHeight: '38px',
          color: AppColors.gray50,
          marginBottom: '64px',
        }}
      >
        {pageText[page].description}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <EdifyButton title='Talk to Sales' onClick={showContactSales} />
      </Box>
    </PageContainer>
  )
}

// TODO MOVE IN

export default DemoPage
