import React, { useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridItem } from '../gridItems'
import { IForm } from '../../../../domain/interfaces/IForm'
import EdifyIconButton from '../../buttons/EdifyIconButton/EdifyIconButton'
import { toggleFavoriteForm } from '../../../../data/data'
import { PinActiveIcon } from '../../../../core/components/icons/PinActiveIcon'
import { PinIcon } from '../../../../core/components/icons/PinIcon'
import { Box } from '@mui/material'
import { ROUTE_SUBMISSIONS } from '../../../pages/submissions/SubmissionsPage'
import { EdifyButton } from '../../buttons'
import { dateToMMMDYYYY } from '../../../../core/core'
import aiPNG from '../../../../assets/images/ai-v4.png'
import { ErrorToast } from '../../../../core/utils/toast-notifications/ToastNotifications'
import { Add } from '@mui/icons-material'
import { AppColors } from '../../../Theme'
import { canCreateSubmission } from '../../../pages/submission/submissionPermissionHelpers'
import { IUser } from '../../../../domain/interfaces/IUser'

interface Props {
  favorite: boolean
  id: string
  cb: (id: string) => void
  navigate: (id: string) => void
  getFavoriteCount: () => number
  canCreate: boolean
  canView: boolean
  isGlobalAdmin: boolean
}

const FavoriteFormIcon: React.FC<Props> = ({
  favorite,
  id,
  cb,
  navigate,
  getFavoriteCount,
  canCreate,
  canView,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const toggle = async (e: any, getFavoriteCount: () => number) => {
    e.stopPropagation()

    if (getFavoriteCount() >= 5 && !favorite) {
      ErrorToast({ title: 'You can only favorite 5 forms.' })
      return
    }
    setLoading(true)
    const res = await toggleFavoriteForm(id, favorite)
    setLoading(false)
    if (res.isRight()) {
      cb(id)
    } else {
      ErrorToast({ title: 'Error saving as favorite.' })
    }
  }
  const nav = (e: any, url: string) => {
    e.stopPropagation()
    navigate(url)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        marginRight: '12px',
      }}
    >
      <EdifyIconButton
        onClick={(e) => toggle(e, getFavoriteCount)}
        disabled={loading}
        loading={loading}
        sx={{ border: 'none' }}
      >
        {favorite && !loading ? <PinActiveIcon /> : <PinIcon />}
      </EdifyIconButton>
      {canView ? (
        <EdifyButton
          title='View Submissions'
          noBackground
          onClick={(e) => nav(e, `/forms${ROUTE_SUBMISSIONS}/${id}`)}
          sx={{ border: 'none' }}
        >
          <PinActiveIcon />
        </EdifyButton>
      ) : (
        ''
      )}
      {canCreate && (
        <EdifyIconButton
          background={AppColors.primary700}
          onClick={(e) =>
            nav(e, `/forms${ROUTE_SUBMISSIONS}/${id}/submission/new`)
          }
          sx={{
            border: 'none',
            padding: '12px 8px',
            '&:hover': {
              background: AppColors.primary600,
            },
          }}
        >
          <Add sx={{ color: 'white' }} />
        </EdifyIconButton>
      )}
      {/* {isGlobalAdmin && (
        <EdifyIconButton
          background={AppColors.primary700}
          onClick={(e) => nav(e, `/forms${ROUTE_SUBMISSIONS}/${id}/settings`)}
          sx={{
            border: 'none',
            padding: '12px 8px',
            marginLeft: '12px',
            '&:hover': {
              background: AppColors.primary600,
            },
          }}
        >
          <Settings sx={{ color: 'white' }} />
        </EdifyIconButton>
      )} */}
    </Box>
  )
}

export const form_columns: GridColDef[] = [
  {
    field: 'title',
    renderHeader: () => <GridItem text='Title' />,
    flex: 1.5,
    renderCell: ({ row }) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            <GridItem
              text={row.title}
              sx={{
                fontWeight: 500,
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            />
          </Box>
          {(row.aiModelEndpoint || row.aiVisionModelEndPoint) && (
            <Box sx={{ flexShrink: 0, marginLeft: '8px', marginRight: '16px' }}>
              <img
                style={{ marginLeft: '8px' }}
                src={aiPNG}
                alt='AI'
                width={'18px'}
              />
            </Box>
          )}
        </Box>
      )
    },
  },
  {
    field: 'lastSubmission',
    renderHeader: () => <GridItem text='Last Submission' />,
    flex: 1.5,
    renderCell: ({ row }) => (
      <GridItem text={dateToMMMDYYYY(row.lastSubmission)} />
    ),
  },
  {
    field: 'favorite',
    width: 320,
    renderHeader: () => <GridItem text='Favorite' />,
    renderCell: ({ row }) => (
      <FavoriteFormIcon
        favorite={row.favorite}
        id={row.id}
        cb={row.callback}
        navigate={row.navigate}
        getFavoriteCount={row.getFavoriteCount}
        canCreate={row.canCreate}
        canView={row.canView}
        isGlobalAdmin={row.isGlobalAdmin}
      />
    ),
  },
]

export const normalizeFormsForDataGrid: any = (
  forms: IForm[],
  updateFormUI: () => void,
  navigate: (url: string) => void,
  getFavoriteCount: () => void,
  canCreate: boolean,
  isGlobalAdmin: boolean,
  user: IUser,
) => {
  if (!forms) return []
  return forms.map((form: IForm) => {
    return {
      id: form.id,
      title: form.title,
      name: form.name,
      lastSubmission: form.lastSubmission ?? 'NA',
      favorite: form.isFavorited,
      callback: updateFormUI,
      navigate: navigate,
      isCopilot: form.copilotStatus,
      aiModelEndpoint: form.aiModelEndpoint,
      aiVisionModelEndpoint: form.aiVisionModelEndpoint,
      getFavoriteCount: getFavoriteCount,
      canCreate: canCreateSubmission ? canCreateSubmission(form, user) : false,
      canView: form.currentUserPermission?.read ?? 0,
      isGlobalAdmin: isGlobalAdmin,
    }
  })
}
