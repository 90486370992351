import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import { EdifyButton } from '../../components/buttons'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { RolePageViewModel, useRolePageViewModel } from './RolePageViewModel'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ROLES } from '../roles/Roles'
import { RolePageHeader } from './RolePageHeader'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { ETypography } from '../../components/fonts/ETypography'
import RolesRenderer from './RolesRenderer'
import { formatSnakeCase } from '../../../core/utils/text-formaters/TextFormatters'
import { AppColors, AppFonts } from '../../Theme'
import { EdifyFieldLabel, EdifySelect } from '../../components/form'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'
import { adminDeleteRole } from '../../../domain/domain'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { TrashIcon } from '../../../core/components/icons/TrashIcon'
import AiFormFeature from './AiFormFeature'

export const RolePage: React.FC = () => {
  return (
    <RolePageViewModel>
      <_Page />
    </RolePageViewModel>
  )
}

export const _Page: React.FC = () => {
  const vm = useRolePageViewModel()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)

  const canCreateRole = usePermission(ERole.RoleCreate)
  const canEditRole = usePermission(ERole.RoleEdit)

  const [selectReplaceId, setSelectedReplaceID] = React.useState<
    string | null
  >()

  const handleClose = () => {
    setOpen(false)
    setSelectedReplaceID(null)
  }

  const handleDeleteClicked = () => {
    setOpen(true)
  }

  const statusChanged = (e: any) => {
    setSelectedReplaceID(e.target.value)
  }
  const deleteRole = async () => {
    if (selectReplaceId == null || !vm.id) return
    const res = await adminDeleteRole(vm.id, selectReplaceId)
    if (res.right) {
      SuccessToast({ title: 'Role Deleted Successfully' })
      navigate(ROUTE_ROLES)
      setSelectedReplaceID(null)
    } else {
      ErrorToast({ title: res.left?.message ?? 'Error Deleting Role' })
    }

    setOpen(false)
  }
  const getIsSuperAdmin = () => {
    return vm.role
      ? vm.role.name == 'SUPER_ADMIN' || vm.role.name == 'MOJO_AI_ADMIN'
      : false
  }
  const canDeleteAndEdit = () => {
    if (!canEditRole) return false
    return !vm.role?.isSystemRole
  }
  return (
    <PageContainer
      mainPageLoading={vm.loading}
      // mainPageError={vm.error}
      // topActions={
      //   canDelete()
      //     ? [
      //       {
      //         title: 'Delete Custom Role',
      //         callback: () => handleDeleteClicked(),
      //       },
      //     ]
      //     : []
      // }
      title={vm.role?.name ? formatSnakeCase(vm.role.name) : ''}
      breadCrumbs={[
        { title: 'Settings', to: '' },
        { title: 'Manage Roles', to: ROUTE_ROLES },
        { title: 'View', to: ROUTE_ROLES },
      ]}
      renderRightItems={() => (
        <Box>
          <EdifyButton
            hide={!canCreateRole || getIsSuperAdmin()}
            buttonStyle={{
              marginRight: vm.role?.isSystemRole ? '0px' : '24px',
            }}
            secondary
            title='Create Duplicate'
            onClick={() => {
              navigate(`${ROUTE_ROLES}/${vm.id}/duplicate`)
            }}
          />

          <EdifyButton
            title='Edit Custom Role'
            hide={!canDeleteAndEdit()}
            onClick={() => {
              navigate(`${ROUTE_ROLES}/${vm.id}/edit`)
            }}
          />
          <EdifyButton
            hide={!canDeleteAndEdit()}
            alert
            noBackground
            buttonStyle={{
              marginLeft: vm.role?.isSystemRole ? '0px' : '24px',
              '&:hover': { color: AppColors.danger700 },
            }}
            textStyle={{
              color: AppColors.danger600,
              '&:hover': { color: AppColors.danger700 },
            }}
            title='Delete'
            onClick={handleDeleteClicked}
          >
            <TrashIcon />
          </EdifyButton>
        </Box>
      )}
    >
      <RolePageHeader
        mode={'VIEW'}
        name={vm.role?.name ?? '[TODO GET NAME FROM BE]'}
      />
      <Box />
      <InnerPageContainer sx={{ padding: '0px', marginBottom: '24px' }}>
        <Box sx={{ padding: '24px 24px 0 24px', marginBottom: '12px' }}>
          <ETypography font='MS' color='gray900'>
            FEATURES
          </ETypography>
          <ETypography font='MM' color='neutral800'>
            List of all features within Safety Mojo and the ability to configure
            visibility.
          </ETypography>
        </Box>
        <RolesRenderer
          key={2}
          roleData={vm.roleData}
          setRoleData={vm.setRoleData}
          disabled={true}
        />
      </InnerPageContainer>
      <InnerPageContainer sx={{ padding: '0px', marginBottom: '12px' }}>
        <Box sx={{ padding: '24px 24px 0 24px' }}>
          <ETypography font='MS' color='gray900'>
            FORMS
          </ETypography>
          <ETypography font='MM' color='neutral800'>
            List of all forms your organization currently has available and the
            ability to configure visibility for each one.
          </ETypography>
        </Box>
        {!vm.hideForms && (
          <RolesRenderer
            key={1}
            roleData={vm.formData}
            setRoleData={vm.setFormData}
            disabled={true}
          />
        )}
      </InnerPageContainer>
      <AiFormFeature />
      <Dialog
        PaperProps={{
          sx: dialogStyling,
        }}
        maxWidth={'xs'}
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          sx={{
            ...AppFonts.textXLSemibold,
            color: AppColors.gray700,
            // textAlign: 'center',
            p: 0,
          }}
          id='alert-dialog-title'
        >
          {'Delete Role'}
        </DialogTitle>
        <DialogContent sx={{ p: 0, pb: 16 }}>
          <DialogContentText
            sx={{
              ...AppFonts.textSMedium,
              color: AppColors.gray50,
              p: 5,
              mb: 12,
            }}
          >
            Are you sure you want to delete this Role?
          </DialogContentText>

          <DialogContentText id='alert-dialog-description' sx={{ mb: 16 }}>
            <EdifyFieldLabel label='Select Replacement Role' />
            <EdifySelect
              width={'100%'}
              // sx={{ marginRight: '16px' }}
              dropDownItems={vm.newRoleOptions}
              onChange={statusChanged}
              renderMenuItem={(item) => {
                return (
                  <EdifyMenuItem key={item.value} value={item.value}>
                    {formatSnakeCase(item.name)}
                  </EdifyMenuItem>
                )
              }}
            />
            <DialogContentText
              sx={{
                ...AppFonts.textSMedium,
                color: AppColors.gray25,
                p: 5,
                mb: 12,
              }}
            >
              Before deleting this role you will need to assign current users
              with to role to a new role.
            </DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            p: 0,
          }}
        >
          <EdifyButton
            fullWidth
            title='Cancel'
            secondary
            onClick={handleClose}
          />
          <EdifyButton
            fullWidth
            disabled={selectReplaceId == null}
            alert={selectReplaceId == null ? false : true}
            title='Accept'
            onClick={deleteRole}
          />
        </DialogActions>
      </Dialog>
    </PageContainer>
  )
}

const dialogStyling = {
  width: '403px',
  height: 'auto',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 16,
}
