import React from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  LinearProgress,
  Typography,
} from '@mui/material'
import { AppColors, AppFonts } from '../../../Theme'
import { formatSnakeCase } from '../../../../core/utils/text-formaters/TextFormatters'
import { EdifyButton } from '../edifyButton/EdifyButton'
import { ETypography } from '../../fonts/ETypography'

interface FilterButtonGroupProps {
  isLoading?: boolean
  error?: string
  reload?: () => void
  buttons: Array<any>
  activeButton: string
  setActiveButton: (value: string) => void
  onButtonClicked?: (button: string) => void
}

const FilterButtonGroup: React.FC<FilterButtonGroupProps> = ({
  isLoading,
  error,
  buttons,
  activeButton,
  setActiveButton,
  onButtonClicked,
  reload,
  ...props
}) => {
  const handleClick = (button: string) => {
    setActiveButton(button)
    if (onButtonClicked) {
      // already on setting
      if (activeButton == button) {
        return
      }
      onButtonClicked(button)
    }
  }
  if (isLoading) {
    return (
      <Box
        data-testid='FilterButtonGroupLoading'
        sx={{
          width: '400px',
          padding: 8,
          borderRadius: '10px',
          border: `1px solid ${AppColors.neutral700}`,
        }}
      >
        <LinearProgress />
      </Box>
    )
  }
  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: 'auto',
          paddingLeft: 12,
          borderRadius: '5px',
          border: `1px solid ${AppColors.neutral700}`,
        }}
      >
        <Typography
          data-testid='FilterButtonGroupError'
          sx={{
            ...AppFonts.textLMedium,
            color: AppColors.danger600,
            marginRight: '8px',
          }}
        >
          {error || 'Error ocurred loading filter options'}
        </Typography>
        {reload && <EdifyButton secondary title='reload' onClick={reload} />}
      </Box>
    )
  }
  return (
    <ButtonGroup sx={{height:'48px'}} data-testid='FilterButtonGroup' {...props}>
      {buttons.map((button) => {
        return (
          <Button
            data-testid='FilterButtonGroupButton'
            onClick={() => handleClick(button)}
            key={button}
            name={button}
            sx={{
              ...AppFonts.textButtonSemiboldSmall,
              padding: '16px 18px',
              background:
                activeButton === button
                  ? AppColors.neutral600
                  : AppColors.baseWhite,
            }}
          >
            <ETypography color='gray700' font='BTSS'>{formatSnakeCase(button)}</ETypography>
          </Button>
        )
        // TODO MAKE EDIFY BUTTON WORK HERE
        // EDIFY BUTTON NEEDS SELECTED STATE
        // ALSO PADDING IS DIFFERENT
        // return (
        // //   <EdifyButton
        // //     onClick={()=> handleClick(button)}
        // //     key={button}
        // //     title={button}
        // //     secondary
        // //   />
        // // )
      })}
    </ButtonGroup>
  )
}

export default FilterButtonGroup
