import { Either, Failure } from '../../../core/core'
import {
  createCertificate,
  editCertificate,
  getCertificates,
  getCertificateById,
  deleteCertificates,
  deleteCertificate,
  anonGetAllCertificates,
} from '../../../data/repositories/certificates/CertificatesRepository'
import {
  ICertificate,
  ICertificateCreate,
  ICertificateList,
} from '../../interfaces/ICertificate'

export async function getAllCertificates(
  queryString: string,
): Promise<Either<Failure, ICertificateList>> {
  return await getCertificates(queryString)
}

export async function adminGetCertificateById(
  id: string,
): Promise<Either<Failure, ICertificate>> {
  return await getCertificateById(id)
}

export async function adminCreateCertificate(
  certificate: ICertificateCreate,
  userId: string,
): Promise<Either<Failure, ICertificate>> {
  return await createCertificate(certificate, userId)
}

export async function adminEditCertificate(
  certificate: ICertificateCreate,
  certificateId: string,
): Promise<Either<Failure, ICertificate>> {
  return await editCertificate(certificate, certificateId)
}

export async function adminDeleteCertificate(
  certificateId: string,
): Promise<Either<Failure, ICertificate>> {
  return await deleteCertificate(certificateId)
}

export async function adminDeleteCertificates(
  certificateIds: string[],
): Promise<Either<Failure, ICertificate[]>> {
  return await deleteCertificates(certificateIds)
}

export async function anonGetCertificates(
  orgId: string,
  userID: string,
  queryString: string,
): Promise<Either<Failure, ICertificateList>> {
  return await anonGetAllCertificates(orgId, userID, queryString)
}

// Not functional
// export async function anonGetCertificate(
//   orgId: string,
//   userID: string,

// ): Promise<Either<Failure, ICertificate>> {
//   return await anonGetOneCertificate(orgId, userID)
// }
