import _ from 'lodash'
import { Either, Failure } from '../../../core/core'
import { ICreateLevel } from '../../../domain/domain'
import { IHierarchyNameList } from '../../../domain/interfaces/IHierarchyName'
import { IMinimalSubLocationName } from '../../../domain/interfaces/ILocation'
import { IMinimalIdName, IOrg, IOrgList, IUpdateOrg } from '../../../domain/interfaces/IOrg'
import { IDivisionStats, IDeletedObject, ILevel, ILevelList, IProject, IProjectList, LevelOptions, IProjectFormData, IProjectCustomField, IProjectCustomFieldFormData } from '../../../domain/interfaces/IProjects'
import { IRole, IRoleResponse, IRoles } from '../../../domain/interfaces/IRole'
import { IMinimalUser, IUserList } from '../../../domain/interfaces/IUser'
import {
  getFromLocalStorage,
  httpDelete,
  httpDeleteWithPayload,
  httpGet,
  httpGetAnon,
  httpPost,
  httpPut,
  removeFromLocalStorage,
  saveToLocalStorage,
} from '../../data'

const levelsUrl = '/levels'
const projectsUrl = '/projects'
const projectCustomizeFieldsUrl = '/projectCustomizeFields'



export async function divisionStatsGet(): Promise<Either<Failure, IDivisionStats>> {
  const res: Either<Failure, IDivisionStats> = await httpGet('/stats/division', true)

  return res
}

export async function createNewOrg(
  orgName: string,
  orgAddress: string,
  orgPolicyNumber: string,
): Promise<Either<Failure, IOrg>> {
  const res: Either<Failure, IOrg> = await httpPost('/orgs', {
    name: orgName,
    address: orgAddress,
    policyNumber: orgPolicyNumber,
  })

  return res
}

export async function updateOrg(id: string, org: IUpdateOrg): Promise<Either<Failure, IOrg>> {
  const res: Either<Failure, IOrg> = await httpPut(`/orgs/${id}`, org)
  return res
}

export async function resetOrg(id: string): Promise<Either<Failure, IOrg>> {
  const res: Either<Failure, IOrg> = await httpPut(`/orgs/reset/${id}`, {})
  return res
}


export async function getOrgs(): Promise<Either<Failure, IOrgList>> {
  const res: Either<Failure, IOrgList> = await httpGet(
    '/orgs',
    false
  )

  return res
}

export async function getOrgById(
  orgId: string,
): Promise<Either<Failure, IOrg>> {
  const res: Either<Failure, IOrg> = await httpGet(
    `/orgs/${orgId}?orgId=${orgId}`,
  )

  return res
}

export function currentOrg(): IOrg | undefined {
  const storedActiveOrg = getFromLocalStorage('org')
  return storedActiveOrg != null ? JSON.parse(storedActiveOrg) : undefined
}

export function currentOrgId(): string | undefined {
  const currentOrgId = getFromLocalStorage('currentOrgId')
  if (currentOrgId != null) {
    return currentOrgId
  }

  const storedActiveOrg = getFromLocalStorage('org')
  return storedActiveOrg != null ? JSON.parse(storedActiveOrg).id : undefined
}

export function setCurrentOrgId(orgId: string) {
  saveToLocalStorage('currentOrgId', orgId)
}

export function clearOrg() {
  removeFromLocalStorage('org')
  removeFromLocalStorage('currentOrgId')
}

export async function getHierarchyNames(): Promise<
  Either<Failure, IHierarchyNameList>

  // eslint-disable-next-line indent
> {
  const res: Either<Failure, IHierarchyNameList> = await httpGet(
    '/hierarchyNames',
    false,
  )

  return res
}

export async function getRoles(): Promise<Either<Failure, IRole[]>> {
  const res: Either<Failure, IRole[]> = await httpGet('/roles', true)

  return res
}

export async function getRolesWithQuery(queryString: string): Promise<Either<Failure, IRoles>> {
  const res: Either<Failure, IRoles> = await httpGet(`/roles?${queryString}`, false)

  return res
}

export async function getRolesV2(): Promise<Either<Failure, IRole[]>> {
  const res: Either<Failure, IRole[]> = await httpGet('/roles', true)

  return res
}

export async function getMinimalRoles(orgId: string): Promise<
  Either<Failure, IRoles>

// eslint-disable-next-line indent
> {
  const res: Either<Failure, IRoles> = await httpGetAnon(
    '/minimal/roles?limit=100',
    { orgId },
    false,
  )

  return res
}


export async function getRole(id: string): Promise<Either<Failure, IRoleResponse>> {
  const res: Either<Failure, IRoleResponse> = await httpGet(`/roles/${id}`, true)

  return res
}
export async function deleteRole(id: string, replacementRoleId: string): Promise<Either<Failure, IRole>> {
  const res: Either<Failure, IRole> = await httpDelete(`/roles/${id}`, true, { 'replacementRoleId': replacementRoleId })

  return res
}

export async function postRole(data: object): Promise<Either<Failure, IRoleResponse>> {
  const res: Either<Failure, IRoleResponse> = await httpPost('/roles', data, {}, true)

  return res
}

export async function updateRole(data: object, id: string): Promise<Either<Failure, IRoleResponse>> {
  const res: Either<Failure, IRoleResponse> = await httpPut(`/roles/${id}`, data)

  return res
}

export async function getProjects(): Promise<Either<Failure, IProject[]>> {
  const res: Either<Failure, IProject[]> = await httpGet(projectsUrl, true)

  return res
}

// ED -1386
export async function getProjectsFromQuery(queryString = 'skip=0&limit=20'): Promise<Either<Failure, IProjectList>> {
  const res: Either<Failure, IProjectList> = await httpGet(`${projectsUrl}?${queryString}`, false)

  return res
}


export async function singleLevelsGet(
  queryString: string,
  level: LevelOptions
): Promise<Either<Failure, ILevelList>> {
  const res: Either<Failure, ILevelList> = await httpGet<ILevelList>(
    `${levelsUrl}?level=${level}${queryString}`,
    false,
  )
  return res
}

// Only one for now
export async function levelDelete(
  id: string
): Promise<Either<Failure, ILevel>> {
  const res: Either<Failure, ILevel> = await httpDelete(
    `${levelsUrl}/${id}`,
    true,
  )
  return res
}

export async function projectDelete(
  id: string
): Promise<Either<Failure, IProject>> {
  const res: Either<Failure, IProject> = await httpDelete(
    `${projectsUrl}/${id}`,
    true,
  )
  return res
}

//ED-1386
export async function projectsDelete(
  ids: string[]
): Promise<Either<Failure, IDeletedObject[]>> {
  const res: Either<Failure, IDeletedObject[]> = await httpDeleteWithPayload(
    `${projectsUrl}/bulk`, { projectIds: ids },
    true,
  )
  return res
}

export async function levelsDelete(
  ids: string[]
): Promise<Either<Failure, IDeletedObject[]>> {
  const res: Either<Failure, IDeletedObject[]> = await httpDeleteWithPayload(
    `${levelsUrl}/bulk`, { levelIds: ids },
    true,
  )
  return res
}

export async function levelGetById(
  id: string
): Promise<Either<Failure, ILevel>> {
  const res: Either<Failure, ILevel> = await httpGet(
    `${levelsUrl}/${id}`,
    true,
  )
  return res
}

export async function projectGetById(
  id: string
): Promise<Either<Failure, IProject>> {
  const res: Either<Failure, IProject> = await httpGet(
    `${projectsUrl}/${id}`,
    true,
  )
  return res
}

export async function projectsSearch(
  searchTerm: string
): Promise<Either<Failure, IProject[]>> {
  const res: Either<Failure, IProject[]> = await httpGet(
    `${projectsUrl}/suggestions?key=${searchTerm}`,
    false,
  )

  return res
}

export async function levelCreate(levelData: ICreateLevel): Promise<Either<Failure, ILevel>> {
  const res: Either<Failure, ILevel> = await httpPost(levelsUrl, levelData, {}, false)

  return res
}

export async function levelUpdate(id: string, levelData: ICreateLevel): Promise<Either<Failure, ILevel>> {
  const res: Either<Failure, ILevel> = await httpPut(`${levelsUrl}/${id}`, levelData, false)

  return res
}

export async function levelsSearch(
  searchTerm: string,
  level: LevelOptions
): Promise<Either<Failure, ILevel[]>> {
  const res: Either<Failure, ILevel[]> = await httpGet(
    `${levelsUrl}/suggestions?level=${level}&key=${searchTerm}`,
    false,
  )
  return res
}

export async function levelFavorite(
  id: string
): Promise<Either<Failure, ILevel>> {
  const res: Either<Failure, ILevel> = await httpPost(
    `${levelsUrl}/addToFavorites/${id}`, {}, {}, false
  )
  return res
}
export async function levelUnfavorite(
  id: string
): Promise<Either<Failure, ILevel>> {
  const res: Either<Failure, ILevel> = await httpDelete(
    `${levelsUrl}/removeFromFavorites/${id}`,
    false,
  )
  return res
}

export async function projectFavorite(
  id: string
): Promise<Either<Failure, IProject>> {
  const res: Either<Failure, IProject> = await httpPost(
    `${projectsUrl}/addToFavorites/${id}`, {}, {}, false
  )
  return res
}
export async function projectUnfavorite(
  id: string
): Promise<Either<Failure, IProject>> {
  const res: Either<Failure, IProject> = await httpDelete(
    `${projectsUrl}/removeFromFavorites/${id}`,
    false,
  )
  return res
}

export async function projectCreate(
  data: IProjectFormData
): Promise<Either<Failure, IProject>> {
  const res: Either<Failure, IProject> = await httpPost(
    `${projectsUrl}`,
    data,
    {},
    true,
  )
  return res
}

export async function projectUpdate(id: string, project: IProjectFormData): Promise<Either<Failure, IProject>> {
  const res: Either<Failure, IProject> = await httpPut(`${projectsUrl}/${id}`, project, true)
  return res
}

export async function projectCustomizeFieldsGet(): Promise<Either<Failure, IProjectCustomField[]>> {
  const res: Either<Failure, IProjectCustomField[]> = await httpGet(projectCustomizeFieldsUrl, true)
  return res
}

export async function projectCustomizeFieldCreate(
  data: IProjectCustomFieldFormData
): Promise<Either<Failure, IProjectCustomField>> {
  const res: Either<Failure, IProjectCustomField> = await httpPost(
    `${projectCustomizeFieldsUrl}`,
    data,
    {},
    true,
  )
  return res
}

export async function projectCustomizeFieldUpdate(
  id:string,
  data: IProjectCustomFieldFormData
): Promise<Either<Failure, IProjectCustomField>> {
  const res: Either<Failure, IProjectCustomField> = await httpPut(
    `${projectCustomizeFieldsUrl}/${id}`,
    data,
    true
  )
  return res
}


//Minimal endpoints
export async function getMinimalHierarchyNames(orgId: string, formId?: string): Promise<
  Either<Failure, IHierarchyNameList>

// eslint-disable-next-line indent
> {
  const res: Either<Failure, IHierarchyNameList> = await httpGetAnon(
    '/minimal/hierarchyNames',
    { orgId, ...(formId ? { formId } : {}) },
    false,
  )

  return res
}

export async function getMinimalUsers(orgId: string, formId?: string): Promise<
  Either<Failure, IMinimalUser[]>

// eslint-disable-next-line indent
> {
  const res: Either<Failure, IMinimalUser[]> = await httpGetAnon(
    '/minimal/users',
    { orgId, ...(formId ? { formId } : {}) },
    true,
  )

  return res
}

export async function getMinimalContractor(orgId: string, formId?: string): Promise<
  Either<Failure, IMinimalUser[]>

// eslint-disable-next-line indent
> {
  const res: Either<Failure, IMinimalUser[]> = await httpGetAnon(
    '/minimal/contractors',
    { orgId, ...(formId ? { formId } : {}) },
    true,
  )

  return res
}

export async function getMinimalLevels(orgId: string, formId?: string): Promise<
  Either<Failure, IMinimalIdName[]>

// eslint-disable-next-line indent
> {
  const res: Either<Failure, IMinimalIdName[]> = await httpGetAnon(
    // Hardcode to level one for now
    '/minimal/levels?level=1',
    { orgId, ...(formId ? { formId } : {}) },
    true,
  )

  return res
}

export async function getMinimalProjects(orgId: string, formId?: string): Promise<
  Either<Failure, IMinimalIdName[]>

// eslint-disable-next-line indent
> {
  const res: Either<Failure, IMinimalIdName[]> = await httpGetAnon(
    '/minimal/projects',
    { orgId, ...(formId ? { formId } : {}) },
    true,
  )

  return res
}

export async function getMinimalSubLocationHierarchyNames(orgId: string, formId?: string): Promise<
  Either<Failure, IMinimalSubLocationName[]>

// eslint-disable-next-line indent
> {
  const res: Either<Failure, IMinimalSubLocationName[]> = await httpGetAnon(
    '/minimal/subLocationHierarchyNames',
    { orgId, ...(formId ? { formId } : {}) },
    true,
  )

  return res
}
