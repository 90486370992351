import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  DocumentPageViewModel,
  useDocumentPageViewModel,
} from './DocumentLibraryPageViewModel'
import DocumentLibraryDataGrid from './DocumentLibraryDataGrid'
import PDFUploader from '../../components/form/EdifyPDFUploader.tsx/EdifyPDFUploader'
import { Box } from '@mui/material'
import { EBox } from '../../components/box/Ebox'
import { SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import PageHeader from '../../components/page/PageHeader'
import withPermission from '../../../core/hooks/withPermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { EdifySearch } from '../../components/form/EdifySearch/EdifySearch'

export const ROUTE_DOCUMENT_LIBRARY = 'document-library'
const _DocumentLibraryPage = () => {
  const vm = useDocumentPageViewModel()

  const handleLoad = (file: any) => {
    // SuccessToast({title: 'Successfully uploaded'})
    // setTimeout(() => {
    //   vm.getOrgDocuments()
    // }, 1000)
  }
  const onFilesProcessed = () => {
    SuccessToast({ title: 'Successfully uploaded' })
    vm.loadDocs()
  }

  const renderBreadCrumbs = () => {
    const breadCrumbs = [
      { title: 'Document Library', to: `/${ROUTE_DOCUMENT_LIBRARY}` },
    ]
    if (vm.title && vm.id) {
      return [
        { title: 'Document Library', to: '' },
        { title: vm.title, to: `/${ROUTE_DOCUMENT_LIBRARY}/${vm.id}` },
      ]
    }

    return breadCrumbs
  }
  const additionalData = vm.folderId ? { folderId: vm.folderId } : {}
  return (
    <PageContainer errorObj={vm.errorObj} mainPageError={vm.error} breadCrumbs={renderBreadCrumbs()}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
          marginTop: '-12px',
        }}
      >
        <PageHeader title={vm.title ?? 'Document Library'} />
        <EdifySearch
          placeholder='Search'
          width={'260px'}
          onChange={(e: any) => vm.setSearch(e.target.value)}
          clear={() => vm.setSearch('')}
        />
      </Box>
      <DocumentLibraryDataGrid title={vm.title} vm={vm} rows={vm.documents} />
      {vm.canCreateDocuments && (
        <EBox p='0' sx={{ marginTop: '24px' }}>
          <PDFUploader
            singleFile={true}
            resetFilesOnSuccess
            handleLoad={handleLoad}
            uploadUrl='/fileUploads/DL'
            dataKey='documents'
            // decodeResponse={(object: any) => object.data.originalUrl}
            edifyUI
            acceptedFileTypes={['application/pdf']}
            additionalData={additionalData}
            onFilesProcessed={onFilesProcessed}
            fullWidth
          />
        </EBox>
      )}
    </PageContainer>
  )
}

const DocumentsPageWithPermission = withPermission(
  _DocumentLibraryPage,
  ERole.DocumentRead,
)

const DocumentLibraryPage = () => (
  <DocumentPageViewModel>
    <DocumentsPageWithPermission />
  </DocumentPageViewModel>
)

export default DocumentLibraryPage
