import { PDFDocument } from 'pdf-lib'
import fileDownload from 'js-file-download'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

/**
 * Extracts files from a ZIP archive, merges them into a single PDF document, and saves it.
 * @param {Blob} zipBlob - The ZIP archive file as a Blob.
 * @returns {Promise<void>} A Promise that resolves when the PDF has been extracted and saved.
 */

export const extractAndMergeFilesToPDF = async (zipBlob: Blob, filename?:string) => {
  const zip = await JSZip.loadAsync(zipBlob)
  const pdfDoc = await PDFDocument.create()

  await Promise.all(
    Object.keys(zip.files).map(async (fileName) => {
      const fileData = await zip.file(fileName)?.async('arraybuffer')
      if (fileData) {
        const pdfBytes = new Uint8Array(fileData)
        const externalPdfDoc = await PDFDocument.load(pdfBytes)
        const pages = await pdfDoc.copyPages(
          externalPdfDoc,
          externalPdfDoc.getPageIndices(),
        )
        pages.forEach((page) => pdfDoc.addPage(page))
      }
    }),
  )

  const mergedPdfBytes = await pdfDoc.save()

  saveAs(new Blob([mergedPdfBytes], { type: 'application/pdf' }), filename || 'download.pdf')
}

/**
 * Merges multiple PDFs from the given URLs into a single PDF file and initiates a file download.
 *
 * @async
 * @param {string[]} pdfUrls - An array of URLs pointing to the PDF files to be merged.
 */
export const mergePDFs = async (pdfUrls: string[]) => {
  try {
    // replace with pdfUrls for selected urls
    const mergedPdf = await mergePDFsFromUrls(pdfUrls)
    fileDownload(mergedPdf, 'merged.pdf')
  } catch (error) {
    console.error('Error merging PDFs:', error)
  }
}

const mergePDFsFromUrls = async (urls: string[]) => {
  const pdfDoc = await PDFDocument.create()

  for (const url of urls) {
    const response = await fetch(url)
    const pdfBytes = await response.arrayBuffer()
    const sourcePdf = await PDFDocument.load(pdfBytes)
    const pageCount = sourcePdf.getPageCount()

    for (let pageNumber = 0; pageNumber < pageCount; pageNumber++) {
      const copiedPage = await pdfDoc.copyPages(sourcePdf, [pageNumber])
      pdfDoc.addPage(copiedPage[0])

    }
  }

  const mergedPdfBytes = await pdfDoc.save()
  return new Blob([mergedPdfBytes], { type: 'application/pdf' })
}

export default mergePDFs



