import { useEffect, useState } from 'react'
import { removeFromLocalStorage, saveToLocalStorage } from '../../data/data'
import { useLocation } from 'react-router-dom'
import { clearAllQueries } from '../../providers/OrganizationProvider'
// TODO Might want to user generics here to make the return type more specific

/** @typedef IQuery The type for a generic object that can have any string key, and values of any type. */

/**
 * A custom hook for managing state as a query object and creating a query string.
 *
 * @param {IQuery} initQuery - An object that will be used as the initial state for the query.
 * @returns {object} An object containing methods to interact with the query state.
 */
const useQuery = (initQuery: Record<string, any>) => {
  const location = useLocation()

  const currentPathKey = `query_${location.pathname}`
  const lastPathKey = localStorage.getItem('lastPathKey')

  function shouldClearStorage(oldPath: string, newPath: string): boolean {
    const oldParts = oldPath.split('/')
    const newParts = newPath.split('/')

    // Different base paths
    if (oldParts[1] !== newParts[1]) return true

    // Check submissions case
    if (oldParts.includes('submissions') && newParts.includes('submissions')) {
      // Get ID index (should be after 'submissions')
      const oldIdIndex = oldParts.indexOf('submissions') + 1
      const newIdIndex = newParts.indexOf('submissions') + 1
      
      // Clear if IDs are different
      return oldParts[oldIdIndex] !== newParts[newIdIndex]
    }

    return false
  }


  const [query, setQuery] = useState<Record<string, any>>(() => {
    const savedQuery = localStorage.getItem(currentPathKey)
    // Clear when changing base paths
    // Removing this check will cause the query to persist across different base paths
    // and wont be reset until the page is refreshed
    if (lastPathKey && shouldClearStorage(lastPathKey, currentPathKey)) {
      clearAllQueries()
      lastPathKey && localStorage.removeItem(lastPathKey)
      localStorage.setItem('lastPathKey', currentPathKey)
      return initQuery
    }
    if (savedQuery) {
      try {
        const savedQueryData = JSON.parse(savedQuery)
        return savedQueryData
      } catch (err) {
        return initQuery
      }
    }
    // localStorage.setItem('lastPathKey', location.pathname)
    return initQuery
  })
  const defaultQuery = initQuery

  /**
   * Returns the value of the query state associated with a given key.
   *
   * @param {string} key - The key to get the value of from the query state.
   * @returns {any} The value from the query state associated with the key.
   */
  const get = (key: string) => {
    return query[key]
  }

  /**
   * Sets the value of a given key in the query state.
   *
   * @param {string} key - The key to set the value of in the query state.
   * @param {any} value - The value to set for the key in the query state.
   */
  const set = (key: string, value: any) => {
    setQuery((prevQuery) => ({ ...prevQuery, [key]: value }))
  }

  const setNew = (query: Record<string, any>) => {
    setQuery(query)
  }

  const remove = (key: string) => {
    setQuery((prevQuery) => {
      const { [key]: value, ...remainingQuery } = prevQuery
      return remainingQuery
    })
  }

  const reset = () => {
    setQuery(defaultQuery)
    removeFromLocalStorage(currentPathKey)
  }

  /**
   * Transforms the query state into a query string.
   *
   * @returns {string} The query state transformed into a query string.
   */
  const getQueryString = () => {
    let queryString = ''
    Object.keys(query).forEach((key) => {
      if (query[key] !== '' && query[key] !== 'false' ) {
        queryString += `&${key}=${query[key]}`
      }
    })
    return queryString
  }

  const getQueryStringFromQuery = (obj: Record<string, any>) => {
    let queryString = ''
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== '' && obj[key] !== 'false' ) {
        queryString += `&${key}=${obj[key]}`
      }
    })
    return queryString
  }
  useEffect(() => {
    return () => {
      try {
        saveToLocalStorage(currentPathKey, JSON.stringify(query))
        saveToLocalStorage('lastPathKey', currentPathKey )
      } catch (err) {
        console.error('Error saving query:', err)
      }
    }
  }, [query])

  return {
    get,
    set,
    getQueryString,
    reset,
    setNew,
    query,
    remove,
    defaultQuery,
    getQueryStringFromQuery,
  }
}

export default useQuery