import React from 'react'
import PageContainer from '../../components/page/PageContainer'

export default function GlobalFormSettingsPage() {
  return (
    <PageContainer title='Global Form Settings Page'>
      GlobalFormSettingsPage
      <p>What goes here?</p>
      <p>add form</p>
      <p>default create/edit/view for roles?</p>
    </PageContainer>
  )
}
