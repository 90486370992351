import React from 'react'

import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { contractor_columns } from '../../components/datagrid/gridData'
import { ContractorsPageViewModelProps } from './ContractorsViewModel'
import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CONTRACTORS } from './ContractorsPage'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

interface IContractorsDataGridProps {
  vm: ContractorsPageViewModelProps
  title: string
  checkboxSelection: boolean
}

const ContractorsDataGrid: React.FC<IContractorsDataGridProps> = ({
  vm,
  title,
  checkboxSelection,
}) => {
  const navigate = useNavigate()
  const { getHierarchyName } = useOrganizationProvider()
  const l0Name = getHierarchyName(0, true)
  const l1Name = getHierarchyName(1, true)
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])

  // Edit contractor
  const handleRowClicked = (params: GridRowParams) => {
    // TODO: Setup contractor Show
    navigate(`${ROUTE_CONTRACTORS}/${params.row.id}`)
  }

  // delete contractor
  const removeClicked = async () => {
    vm.deleteContractors(selectionModel as string[])
    setSelectionModel([])
  }

  const renderHeaderButtons = () => {
    if (!checkboxSelection) return
    const noSelections = selectionModel.length === 0
    return (
      <BulkDeleteButton noSelections={noSelections} onClick={removeClicked} />
    )
  }

  const rows = vm.normalizeContractorsForDataGrid()

  return (
    <>
      <EdifyDataGrid
        data-testid='ContractorsDataGrid'
        paginationMode='server'
        title={title}
        noRowsText={'No contractors found.'}
        renderHeaderButtons={renderHeaderButtons()}
        columns={contractor_columns(l0Name, l1Name)}
        rows={rows}
        error={vm.error}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={handleRowClicked}
        loading={vm.isLoading || vm.isLoadMore}
        rowCount={vm.totalCount || 0}
        loadMoreRows={vm.loadMore}
        reload={vm.reload}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={checkboxSelection}
        // TODO: figure out why infinite loop/sort call being triggered
        initSort={[
          {
            field: 'name',
            sort: 'asc',
          },
        ]}
      />
    </>
  )
}

export default ContractorsDataGrid
