import styled from '@emotion/styled'
import React from 'react'
import { AppColors, AppFonts } from '../../../Theme'
import { Box, SvgIconProps, Typography } from '@mui/material'
import { IForm } from '../../../../domain/interfaces/IForm'

const NavbarDropdown = styled(Box)(() => ({
  display: 'flex',
  direction: 'ltr',
  alignItems: 'center',
  gap: '12px',
  padding: '12px 28px',
  textDecoration: 'none',
  '&:hover': {
    color: AppColors.g000,
    opacity: 1,
    background: AppColors.gray400,
    textDecoration: 'none',
  },
}))

interface Props {
  iconComponent: React.ComponentType<SvgIconProps>
  iconComponentStyle?: React.CSSProperties
  linkTitle: string
  paths:string[]
  onClick?: ()=>void
  suffixIconComponent?: React.ComponentType<SvgIconProps>
  style?: React.CSSProperties
  state?: {form: IForm}
  hide?: boolean
}

export const NavbarDropdownTile: React.FC<Props> = ({
  iconComponent: IconComponent,
  iconComponentStyle,
  linkTitle,
  onClick,
  suffixIconComponent: SuffixIcon,
  style,
  paths,
  hide = false
}) => {
  if(hide) return null

  const path = location.pathname.split('/')[1]
  
  return (
    <NavbarDropdown onClick={onClick} style={{ ...style }}>
      <IconComponent
        sx={{
          // color: AppColors.g000,
          color: paths.includes(path) ? AppColors.neutral200 : AppColors.gray25,
          height: '24px',
          ...iconComponentStyle,
        }}
      />
      <Typography
        sx={{
          ...AppFonts.interMSemibold,
          // color: AppColors.g000,
          color: paths.includes(path) ? AppColors.neutral200 : AppColors.gray25,
          // opacity: 0.6,
          width: '154px',
        }}
      >
        {linkTitle}
      </Typography>

      {SuffixIcon ? (
        <SuffixIcon
          sx={{
            height: '24px',
            color: paths.includes(path) ? AppColors.neutral200 : AppColors.gray25,
            alignContent: 'flex-end',
          }}
        />
      ) : null}
    </NavbarDropdown>
  )
}
