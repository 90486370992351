import { Box, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { AppColors, AppFonts } from '../../Theme'
import {  HourglassBottomOutlined } from '@mui/icons-material'
import { App } from '../../App'

interface ILoadingTile {
  isDialog?: boolean
  tileHeight?: string | number
  message?: string
  loadingTiles?: number
  dark?: boolean
}

export const LoadingTile: React.FC<ILoadingTile> = ({
  isDialog = false,
  tileHeight,
  message,
  loadingTiles = 3,
  dark = false

}: ILoadingTile) => {
  return (
    <Box
      data-testid={'LoadingTile'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {isDialog ? (
        <Typography
          sx={{
            ...AppFonts.latoLarge,
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'left',
          }}
        >
          {message ?? 'Loading...'}
        </Typography>
      ) : null}
      {Array.from(Array(loadingTiles).keys()).map((i) => (
        <Skeleton
         
          key={i}
          variant='rounded'
          sx={{
            width: 'auto',
            height: tileHeight ?? '90px',
            left: 0,
            marginTop: 16,
            background: AppColors.g000,
            borderRadius: '16px',
          }}
          animation='wave'
        />
      ))}

      {isDialog ? null : (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '12px',
            marginTop: '12px',
            alignSelf:'center'
          }}
        >
          <HourglassBottomOutlined sx={{color: dark ?  AppColors.gray25 : AppColors.primary600}} />
          <Typography
            data-testid={'PageLoading'}
            style={{
              ...AppFonts.textMSemibold,
              color: dark ?  AppColors.gray25 : AppColors.gray700,
              marginLeft: '12px'
            }}
          >
            {message || 'Loading...'}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
