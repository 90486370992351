import { Either, Failure } from '../../../core/core'
import { IGoal, IGoalFormData, IGoalList, IParticipant, IParticipantsList } from '../../../domain/interfaces/IGoal'
import { httpDelete, httpDeleteWithPayload, httpGet, httpPost, httpPut } from '../../data'

// Check this
const goalsUrl = '/goals'
const participantsUrl = '/participants'

export async function createGoal(goalData: IGoalFormData): Promise<Either<Failure, IGoal>> {
  const res: Either<Failure, IGoal> = await httpPost(
    goalsUrl,
    goalData,
    undefined, //no additional headers
    false
  )

  return res
}

export async function updateGoal(goalData: IGoalFormData, id:string): Promise<Either<Failure, IGoal>> {
  const res: Either<Failure, IGoal> = await httpPut(
    `${goalsUrl}/${id}`,
    goalData,
    false
  )

  return res
}

export async function goalsGet(queryString: string): Promise<Either<Failure, IGoalList>> {

  const res: Either<Failure, IGoalList> = await httpGet(
    `${goalsUrl}?${queryString}`, false
  )
  return res
}

export async function goalGetById(
  id: string,
): Promise<Either<Failure, IGoal>> {

  const res: Either<Failure, IGoal> = await httpGet(
    `${goalsUrl}/${id}`,
  )
  return res
}

export async function deleteGoals(
  goalIds: string[],
): Promise<Either<Failure, IGoalList>> {
  const res: Either<Failure, IGoalList> = await httpDeleteWithPayload(
    `${goalsUrl}/bulk`, { goalIds }
  )
  return res
}

export async function deleteGoalById(
  id: string,
): Promise<Either<Failure, IGoal>> {
  const res: Either<Failure, IGoal> = await httpDelete(
    `${goalsUrl}/${id}`
  )
  return res
}


export async function goalsSearch(
  searchTerm: string,
): Promise<Either<Failure, IGoal[]>> {
  const res: Either<Failure, IGoal[]> = await httpGet(
    `${goalsUrl}?searchKey=${searchTerm}`,
    false,
  )
  return res
}

export async function participantsGet(queryString: string): Promise<Either<Failure, IParticipantsList>> {
  const res: Either<Failure, IParticipantsList> = await httpGet(
    `/goals/participants?${queryString}`,
    false
  )
  return res
}

export async function participantGetById(
  id: string,
): Promise<Either<Failure, IParticipant>> {
  const res: Either<Failure, IParticipant> = await httpGet(
    `${participantsUrl}/${id}`,
  )
  return res
}

export async function participantsDelete(
  participantsIds: string[],
): Promise<Either<Failure, IParticipantsList>> {
  const res: Either<Failure, IParticipantsList> = await httpDeleteWithPayload(
    participantsUrl, { participantsIds }
  )
  return res
}
