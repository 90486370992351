import React from 'react'
import {
  ProjectsPageViewModel,
  ProjectsPageViewModelProps,
  useProjectsPageViewModel,
} from './ProjectsViewModel'

import PageContainer from '../../components/page/PageContainer'

import ProjectFilter from './ProjectsFilter'
import ProjectDataGrid from './ProjectsDataGrid'
import {
  OrganizationProviderProps,
  useOrganizationProvider,
} from '../../../providers/OrganizationProvider'

import { normalizeProjectsForDataGrid } from '../../components/datagrid/gridData/projects'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import withPermission from '../../../core/hooks/withPermission'

export const ROUTE_PROJECTS = '/projects'


export const _Page: React.FC = () => {
  const vm: ProjectsPageViewModelProps = useProjectsPageViewModel()
  const {
    hierarchiesError,
    hierarchiesLoading,
    getHierarchyName,
  }: OrganizationProviderProps = useOrganizationProvider()

  const canCreate = usePermission(ERole.ProjectCreate)
  const canEdit = usePermission(ERole.ProjectEdit, false, 'NoBulkEdit')
  
  const projectName = getHierarchyName(0, true)
  const getRows = () => {
    return vm.projects ? normalizeProjectsForDataGrid(vm.projects) : []
  }
  const getTitle = () => {
    return `All ${projectName}`
  }
  // this will make sure loading is done and there are no errors before render content within the page container
  // mainPageError={hierarchiesError || vm.projectsError}
  // mainPageLoading={hierarchiesLoading || vm.projectsLoading}
  return (
    <PageContainer
      errorObj={vm.errorObj}
      mainPageError={hierarchiesError || vm.projectsError}
      mainPageLoading={hierarchiesLoading}
      mainPageReload={vm.getProjects}
      breadCrumbs={[
        { title: 'Organization', to: '' },
        { title: `${projectName}`, to: '/projects' },
      ]}
      title={getTitle()}
    >
      <ProjectFilter canCreate={canCreate} vm={vm} buttonTitle={getHierarchyName(0)} />
      <ProjectDataGrid checkboxSelection={canEdit} vm={vm} title={`${projectName}`} rows={getRows()} />
    </PageContainer>
  )
}

const ProjectsPageWithPermission = withPermission(_Page, ERole.ProjectRead)

export const ProjectsPage: React.FC = () => {
  return (
    <ProjectsPageViewModel>
      <ProjectsPageWithPermission />
    </ProjectsPageViewModel>
  )
}
