import React from 'react'

import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { user_columns } from '../../components/datagrid/gridData'
import { UsersPageViewModelProps } from './UsersViewModel'
import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_USERS } from './UsersPage'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'


interface IUsersDataGridProps {
  vm: UsersPageViewModelProps
  title: string
  checkboxSelection?: boolean
}

const UsersDataGrid: React.FC<IUsersDataGridProps> = ({ vm, title,checkboxSelection=false }) => {
  const navigate = useNavigate()
  const { getHierarchyName } = useOrganizationProvider()
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])

  // Edit user
  const handleRowClicked = (params: GridRowParams) => {
    navigate(`${ROUTE_USERS}/${params.row.id}`)
  }

  // Will be Used in future
  // const pauseClicked = async () => {
  //   TODO: Implement 
  // }

  // delete user
  const removeClicked = async () => {
    vm.deleteUsers(selectionModel as string[])
    setSelectionModel([])
  }

  const renderHeaderButtons = () => {
    if(!checkboxSelection) return
    const noSelections = selectionModel.length === 0
    return (
      <BulkDeleteButton noSelections={noSelections} onClick={removeClicked} />
    )
  }

  const rows = vm.normalizeUsersForDataGrid()

  return (
    <>
      <EdifyDataGrid
        data-testid='UsersDataGrid'
        paginationMode='server'
        title={title}
        noRowsText={'No users found.'}
        renderHeaderButtons={renderHeaderButtons()}
        columns={user_columns(getHierarchyName(0, true))}
        rows={rows}
        error={vm.error}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={handleRowClicked}
        loading={vm.isLoading || vm.isLoadMore}
        rowCount={vm.totalCount || 0}
        loadMoreRows={vm.loadMore}
        reload={vm.reload}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={checkboxSelection}
      />
    </>
  )
}

export default UsersDataGrid
