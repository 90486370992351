import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridBadge, GridItem, GridStackItem } from '../gridItems'
import { Avatar } from '@mui/material'
import { IUser } from '../../../../domain/interfaces/IUser'
import { dateToMMMDYYYY } from '../../../../core/core'
import { formatSnakeCase } from '../../../../core/utils/text-formaters/TextFormatters'
import { getAuthToken } from '../../../../domain/domain'

export const user_columns: (l0Name: string) => GridColDef[] = (l0Name) => {
  const token = getAuthToken()
  return [
    {
      field: 'name',
      renderHeader: () => <GridItem text='Name' />,
      width: 260,
      renderCell: ({ row }) => (
        <GridStackItem
          topText={row.fullName}
          bottomText={row.email}
          avatar={() => {
            return row.imageUrl ? (
              <Avatar
                src={`${row.imageUrl}&token=${token}`}
                alt={`${row.fullName}`}
                sx={{ mr: 10 }}
              />
            ) : (
              <Avatar
                alt={`${row.fullName}`}
                sx={{ mr: 10 }}
              />
            )
          }}
        />
      ),
    },
    {
      field: 'projects',
      renderHeader: () => <GridItem text={l0Name} />,
      flex: 2,
      renderCell: ({ row }) => {
        // THIS IS BREAKING TEST
        if (!row.projects) return
        const projects = row.projects
        let displayProjects =
          projects.length > 2
            ? [...projects.slice(0, 2), `+ ${projects.length - 2} more`]
            : projects

        displayProjects = displayProjects.map((proj: string, i: number) => (
          <GridBadge key={i} text={proj.toString()} />
        ))

        return displayProjects
      },
    },
    {
      field: 'lastLogin',
      width: 190,
      renderHeader: () => <GridItem text='Last Login' />,
      renderCell: ({ row }) => (
        <GridBadge
          type='grey'
          text={row.lastLogin == 'Invalid date' ? 'NA' : row.lastLogin}
        />
      ),
    },

    {
      field: 'role',
      renderHeader: () => <GridItem text='Role' />,
      width: 160,
      renderCell: ({ row }) => <GridItem text={row.role} />,
    },
  ]
}

export const normalizeUsersForGrid: any = (users?: IUser[]) => {
  if (!users) return []

  //TODO EDITING A PERSON TRIGGERED ERROR HERE WHY?
  // projects: user.projects?.map((project) => project?.name),
  return users?.map((user: IUser, i) => {
    // for dummy data
    const date = new Date()
    date.setDate(date.getDate() - i)
    return {
      id: user.id,
      fullName: user.fullName,
      email: user.email || 'No Email',
      projects: user.projects?.map((project) => project?.name ?? ''),
      imageUrl: user.imageURL ?? '',
      lastLogin: dateToMMMDYYYY(user.lastLogin ?? ''),
      role:
        user.roles[0] && user.roles[0]?.name
          ? formatSnakeCase(user.roles[0]?.name)
          : 'None',
    }
  })
}
