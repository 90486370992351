import { Box, Popover, Typography } from '@mui/material'
import React, { useState } from 'react'
import logo from '../../../assets/icons/pin.svg'

import { ComponentProps } from '../ComponentProps'
import { AppFonts, AppColors } from '../../../presentation/Theme'

interface Props extends ComponentProps {
  width?: number
  height?: number
  isDarkTheme?: boolean
}

export const PinIcon: React.FC<Props> = ({ sx, width, height }) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    // Clear any previous timers
    if (timerId !== null) {
      clearTimeout(timerId)
    }

    // Set a new timer to show the popover after a delay (e.g., 500 milliseconds)
    const newTimerId = setTimeout(() => {
      setOpen(true)
    }, 500)

    setTimerId(newTimerId)
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setOpen(false)
    setAnchorEl(null)
    if (timerId !== null) {
      clearTimeout(timerId)
    }
  }

  // const open = Boolean(anchorEl)

  return (
    <>
      <Box
        data-testid='PinIcon'
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        component='img'
        sx={{
          position: 'relative',
          width: width ?? 20,
          height: height ?? 20,
        }}
        src={logo}
      />
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
          border: 'none',
          top: '-20px',
          '& .MuiPaper-root': {
            backgroundColor: AppColors.gray800,
            boxShadow: 'none', // Remove the box-shadow
            border: 'none', // Remove the border
          },
          '& .MuiTypography-root': {
            color: AppColors.baseWhite,
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            ...AppFonts.textMSemibold,
            color: AppColors.neutral200,
            padding: '8px 12px',
          }}
        >
          Pin as favorite
        </Typography>
      </Popover>
    </>
  )
}
