import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box, Input, SxProps } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import {
  EdifyFieldLabel,
  EdifyFormField,
  EdifySelect,
} from '../../components/form'

import { Controller, FieldErrors, Resolver, useForm } from 'react-hook-form'
import { phoneInputFormatter } from '../../../core/utils/input-formatters/InputFormatters'
import FormErrorText from '../../components/form/FormErrorText'
import { IContractor } from '../../../domain/interfaces/IContractor'

import { ROUTE_CONTRACTORS } from '../contractors/ContractorsPage'
import {
  useContractorPageViewModel,
  contractorParams,
} from './ContractorPageViewModel'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'

import {
  OrganizationProviderProps,
  useOrganizationProvider,
} from '../../../providers/OrganizationProvider'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { getContractorById } from '../../../data/data'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import { globalSearch } from '../../../domain/usecases/utils/UtilsUsecasses'

const default_contractor: any = {
  name: '',
  pointOfContactEmail: '',
  pointOfContactPhone: '',
  pointOfContactName: '',
  trade: '',
}

// react-hook-form state
type ProfileInputs = {
  name: string
  pointOfContactEmail: string
  pointOfContactPhone?: string
  pointOfContactName: string
  projects?: string[]
  levels?: string[]
  trade?: string
  identifierField?: string
}
interface ContractorProfileFormProps {
  id?: string | undefined
  onCancel?: () => void
  contractor?: IContractor
  sx?: SxProps
}

export const ContractorsForm: React.FC<ContractorProfileFormProps> = ({
  id,
  onCancel,
  contractor,
  sx,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [imageURL, setImageURL] = useState<string | null>(null)

  const [loadedProfile, setLoadedProfile] = useState<boolean>(false)
  const vm = useContractorPageViewModel()
  const {
    getHierarchyName,
    trades,
    allLevels,
    allProjects,
  }: OrganizationProviderProps = useOrganizationProvider()
  const projectName = getHierarchyName(0, true)
  const levelName = getHierarchyName(1, true)
  const canEdit = usePermission(ERole.ContractorEdit)
  const [projects, setProjects] = useState<ISearchResult[]>([])
  const [levels, setLevels] = useState<ISearchResult[]>([])

  /**
   * Validates the input values for the person's profile form.
   */
  const resolver: Resolver<ProfileInputs> = async (values) => {
    const errors: FieldErrors<ProfileInputs> = {}

    if (!values.name) {
      errors.name = {
        type: 'required',
        message: 'Name is required',
      }
    }
    if (!values.pointOfContactName) {
      errors.pointOfContactName = {
        type: 'required',
        message: 'Name is required',
      }
    }
    if (!values.pointOfContactEmail) {
      errors.pointOfContactEmail = {
        type: 'required',
        message: 'Email is required',
      }
    }

    if (values.pointOfContactPhone) {
      const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/
      if (!phoneNumberPattern.test(values.pointOfContactPhone)) {
        errors.pointOfContactPhone = {
          type: 'pattern',
          message: 'Invalid phone number',
        }
      }
    }
    return {
      values,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<ProfileInputs>({ resolver, mode: 'onSubmit' })

  const projectSelected = (projects: ISearchResult[]) => {
    setProjects(projects)
  }
  const levelSelected = (levels: ISearchResult[]) => {
    setLevels(levels)
  }

  /**
   * Handles the form submission when the form is valid.
   * will run resolver validations before submitting
   * @param {Object} data - The form data submitted by the contractor.
   * @returns {void}
   */
  const onSubmit = handleSubmit(async (data) => {
    // react-hook-form will check for errors here and return
    // if there are any
    // if we get here validations have passed
    const filterUndefinedValues = (data: any) => {
      return Object.keys(data).reduce((acc: any, key) => {
        if (data[key] !== undefined && data[key] !== '') {
          acc[key] = data[key]
        }
        return acc
      }, {})
    }
    const formData: contractorParams = {
      name: data.name,
      pointOfContactName: data.pointOfContactName,
      pointOfContactEmail: data.pointOfContactEmail,
      pointOfContactPhone: data.pointOfContactPhone,
      trade: data.trade,
      identifierField: data.identifierField,
      levelIds: levels.map((p) => p.id),
      projectIds: projects.map((l) => l.id),
    }

    const filteredData = filterUndefinedValues(formData)

    setLoading(true)
    if (id) {
      // vm.updateContractor(formData,id)
      vm.updateContractor(filteredData, id)
    } else {
      // vm.createContractor(formData)
      vm.createContractor(filteredData)
    }
    setLoading(false)
  })

  const handlePhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    const formattedValue = phoneInputFormatter(value)
    setValue('pointOfContactPhone', formattedValue, {
      shouldValidate: value.length > 11 || value.length == 0,
    })
  }

  // Sets Initial values of the form
  const loadContractor = async () => {
    setLoadedProfile(false)
    let ContractorData
    // new Contractor

    if (!id) {
      ContractorData = default_contractor
    } else {
      const res = await getContractorById(id)
      if (res.right) {
        ContractorData = res.right

        setProjects(vm.contractor?.projects ?? [])
        setLevels(vm.contractor?.levels ?? [])
      }
    }

    const defaultValues: ProfileInputs = {
      name: ContractorData?.name ?? '',
      pointOfContactName: ContractorData?.pointOfContactName ?? '',
      pointOfContactEmail: ContractorData?.pointOfContactEmail,
      pointOfContactPhone:
        ContractorData && ContractorData.pointOfContactPhone
          ? phoneInputFormatter(ContractorData.pointOfContactPhone)
          : '',
      trade: ContractorData?.trade ?? '',
      identifierField: ContractorData?.identifierField ?? '',
      projects: ContractorData.projects,
      levels: ContractorData.levels,
    }
    reset({ ...defaultValues })
    setLoadedProfile(true)
  }

  const getContractorProjects = () => {
    if (!vm.contractor?.projects) return []
    const projects = vm.contractor?.projects.map((p) => {
      return { id: p.id, name: p.name }
    })
    console.log({ projects })
    return projects
  }
  const getContractorLevels = () => {
    if (!vm.contractor?.levels) {
      return undefined
    }
    const levels = vm.contractor?.levels.map((l) => {
      return { id: l.id, name: l.name }
    })
    console.log({ allLevels })
    console.log({ levels })
    return levels
  }
  useEffect(() => {
    loadContractor()
  }, [])

  const navigate = useNavigate()

  // TODO: ACTUALLY DELETE THE IMAGE FROM THE SERVER
  // const imageUploaded = async (imageURL: string | null) => {
  //   setLoadedContractor(false)
  //   setLoading(true)
  //   setServerError(undefined)
  //   const res = await adminEditContractor(
  //     {
  //       imageURL: imageURL,
  //     },
  //     Contractor!.id,
  //   )
  //   setImageURL(imageURL ?? null)
  //   setLoading(false)
  //   handleUploadResponse(res, false)
  // }

  return (
    <form onSubmit={onSubmit}>
      <InnerPageContainer sx={{ paddingBottom: '24px', ...sx }}>
        {vm.formError && (
          <InnerPageContainer innerPageError={vm.formError}>
            {/* TODO: Add close logic, this is done in another story */}
          </InnerPageContainer>
        )}

        <Box sx={{ width: '100%', padding: '0px 24px' }}>
          <Box style={{ display: 'flex', flex: 1 }}>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel required label='CONTRACTOR NAME' />
              <Input
                data-testid='fullName'
                disableUnderline
                placeholder='Enter name'
                className={`form-control ${errors?.name ? 'error' : ''}`}
                {...register('name')}
              />
              {errors?.name && (
                <FormErrorText>{errors.name.message}</FormErrorText>
              )}
            </EdifyFormField>

            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel required label='PRIMARY POINT OF CONTACT' />
              <Input
                data-testid='poc'
                disableUnderline
                placeholder='Enter name'
                className={`form-control ${errors?.name ? 'error' : ''}`}
                {...register('pointOfContactName')}
              />
              {errors?.pointOfContactName && (
                <FormErrorText>
                  {errors.pointOfContactName?.message}
                </FormErrorText>
              )}
            </EdifyFormField>
          </Box>
        </Box>
        <Box sx={{ width: '100%', padding: '0px 24px' }}>
          <Box style={{ display: 'flex', flex: 1 }}>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='PHONE NUMBER' />
              <Controller
                name='pointOfContactPhone'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    {...field}
                    data-testid='PhoneNumber'
                    disableUnderline
                    placeholder='000-000-0000'
                    className={`form-control ${
                      errors?.pointOfContactPhone ? 'error' : ''
                    }`}
                    value={field.value}
                    onChange={handlePhoneInputChange}
                  />
                )}
              />
              {errors?.pointOfContactPhone && (
                <FormErrorText>
                  {errors.pointOfContactPhone.message}
                </FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel
                required
                label='EMAIL'
                disabled={id != undefined}
              />
              <Input
                disabled={id != undefined}
                data-testid='Email'
                disableUnderline
                placeholder='sample@email.com'
                className={'form-control'}
                {...register('pointOfContactEmail')}
              />
              {errors?.pointOfContactEmail && (
                <FormErrorText>
                  {errors.pointOfContactEmail.message}
                </FormErrorText>
              )}
            </EdifyFormField>
          </Box>
          {/* <EdifyFormField>
            <EdifyFieldLabel
              label={`SELECT EXISTING ${levelName.toUpperCase()}`}
            />
            <EdifySearchDropdown
              data-testid='LevelsSearch'
              defaultItems={allLevels.map((p) => {
                return { id: p.id, name: p.name }
              })}
              multiSelect
              pillListWidth={'100%'}
              width={484}
              onSelect={levelSelected}
              searchFunction={searchLevels}
              initialSelectedItems={vm.contractor?.levels}
            />
          </EdifyFormField> */}
          {/* <EdifyFormField>
            <EdifyFieldLabel
              label={`SELECT EXISTING ${projectName.toUpperCase()}`}
            />
            <EdifySearchDropdown
              data-testid='ProjectSearch'
              defaultItems={allProjects.map((p) => {
                return { id: p.id, name: p.name }
              })}
              multiSelect
              pillListWidth={'100%'}
              width={484}
              onSelect={projectSelected}
              searchFunction={searchProjects}
              initialSelectedItems={vm.contractor?.projects}
            />
          </EdifyFormField> */}
          <Box sx={{ display: 'flex' }}>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='SELECT TRADE' />
              <Controller
                name='trade'
                control={control}
                defaultValue={contractor?.trade ?? ''}
                render={({ field }) => (
                  <EdifySelect
                    {...field}
                    width='100%'
                    placeholder='Select'
                    sx={{ marginRight: '24px', flex: 1 }}
                    dropDownItems={trades ?? []}
                    renderMenuItem={(item: string) => (
                      <EdifyMenuItem key={item} value={item}>
                        {item}
                      </EdifyMenuItem>
                    )}
                  />
                )}
              />
              {errors?.trade && (
                <FormErrorText>{errors.trade.message}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='Contractor ID' />
              <Input
                data-testid='identifierField'
                disableUnderline
                placeholder='Contractor ID'
                className={'form-control'}
                {...register('identifierField')}
              />
              {errors?.identifierField && (
                <FormErrorText>{errors.identifierField.message}</FormErrorText>
              )}
            </EdifyFormField>
          </Box>
          <EdifyFormField>
            <EdifyFieldLabel
              label={`SELECT EXISTING ${levelName.toUpperCase()}`}
            />
            <EdifySearchDropdown
              data-testid='LevelsSearch'
              defaultItems={allLevels.map((p) => {
                return { id: p.id, name: p.name }
              })}
              multiSelect
              pillListWidth={'100%'}
              width={464}
              onSelect={levelSelected}
              searchFunction={globalSearch}
              initialSelectedItems={getContractorLevels()}
              globalSearchKey='levels'
              globalSearchPayload={{
                entities: ['levels'],
                properties: ['id'],
                returns: ['id', 'name'],
              }}
            />
          </EdifyFormField>
          <EdifyFormField>
            <EdifyFieldLabel
              label={`SELECT EXISTING ${projectName.toUpperCase()}`}
            />
            <EdifySearchDropdown
              defaultItems={allProjects.map((p) => {
                return { id: p.id, name: p.name }
              })}
              data-testid='ProjectSearch'
              multiSelect
              pillListWidth={'100%'}
              width={464}
              onSelect={projectSelected}
              searchFunction={globalSearch}
              initialSelectedItems={getContractorProjects()}
              globalSearchKey='projects'
              globalSearchPayload={{
                entities: ['projects'],
                properties: ['id'],
                returns: ['id', 'name'],
              }}
            />
          </EdifyFormField>
        </Box>
      </InnerPageContainer>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '24px',
          marginBottom: '0px',
        }}
      >
        <EdifyButton
          noBackground
          title='Cancel'
          onClick={onCancel ? onCancel : () => navigate(ROUTE_CONTRACTORS)}
        />
        <Box>
          <EdifyButton
            disabled={Object.keys(errors).length > 0 || loading}
            type='submit'
            data-testid={'SubmissionButton'}
            primary
            title={'Save Changes'}
          />
        </Box>
      </Box>
    </form>
  )
}

export default ContractorsForm
