import React from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { ErrorToastComponent, SuccessToastComponent } from './Toast'
import { AppColors } from '../../../presentation/Theme'

interface ToastProps {
  title: string
  subtitle?: string
  comment?: string
  isThreeLine?: boolean
}

export const SuccessToast = ({ title }: ToastProps) => {
  return toast((t) => (
    <SuccessToastComponent
      key={t.id}
      message={title}
      onClose={() => toast.dismiss(t.id)}
    />
  ))
}

export const ErrorToast = ({ title }: ToastProps) => {
  return toast((t) => (
    <ErrorToastComponent
      key={t.id}
      message={title}
      onClose={() => toast.dismiss(t.id)}
    />
  ))
}

// this is need to get toasters coming in from right
// loaded in App.tsx
export const AppToaster = () => {
  return (
    <Toaster
      position='top-right'
      reverseOrder={false}
      gutter={60}
      toastOptions={{
        // Define default options
        className: '',
        duration: 2000,
        // was showing a missed placed box this is to hide it
        style: {
          background: AppColors.gray600,
          padding: 1,
          width: 0,
          height: 0,
        },
      }}
    />
  )
}
