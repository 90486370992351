import { Either, Failure } from '../../../core/core'
import { ILanguage, ILanguageList, ITranslationList, IUpdateTranslation } from '../../../domain/interfaces/ITranslation'
import { getFromLocalStorage, httpGet, httpPut, saveToLocalStorage } from '../../data'

const translations_url = '/translations'

export async function translationsGet(
  orgId: string,
  language: string,
): Promise<Either<Failure, ITranslationList>> {
  const res: Either<Failure, ITranslationList> = await httpGet(
    `${translations_url}?orgId=${orgId}&language=${language}`,
    false,
  )
  return res
}

export async function languagesGet(
  orgId?: string,
): Promise<Either<Failure, ILanguage>> {
  const res: Either<Failure, ILanguage> = await httpGet(
    `${translations_url}/languages?orgId=${orgId}`,
    true,
  )
  return res
}

export async function putLanguages(
  data: IUpdateTranslation
): Promise<Either<Failure, any>> {
  const res: Either<Failure, any> = await httpPut(
    `${translations_url}`,
    data,
    false,
  )
  return res
}

