import React, { useState, useEffect } from 'react'

// Define a type for the hook's return value
interface UseNextColumnNumber {
  addColumn: () => number
  deleteColumn: (columnNumber: number) => void
}

// Function to initialize available numbers
const initializeAvailableNumbers = (initialColumns: number[]): Set<number> => {
  const maxNumber = Math.max(0, ...initialColumns)
  const availableNumbers = new Set<number>()
  for (let i = 1; i <= maxNumber; i++) {
    if (!initialColumns.includes(i)) {
      availableNumbers.add(i)
    }
  }
  return availableNumbers
}

// Define the hook with TypeScript
const useNextColumnNumber = (initialColumns: number[]): UseNextColumnNumber => {
  const [columns, setColumns] = useState<Set<number>>(new Set(initialColumns))
  const [availableNumbers, setAvailableNumbers] = useState<Set<number>>(() =>
    initializeAvailableNumbers(initialColumns)
  )
  const [nextNumber, setNextNumber] = useState<number>(Math.max(0, ...initialColumns) + 1)

  const deleteColumn = (columnNumber: number) => {
    setColumns((prevColumns) => {
      const newColumns = new Set(prevColumns)
      if (newColumns.delete(columnNumber)) {
        setAvailableNumbers((prevAvailable) => {
          const newAvailable = new Set(prevAvailable)
          newAvailable.add(columnNumber)
          return newAvailable
        })
      }
      return newColumns
    })
  }

  const getNextColumnNumberSync = (): number => {
    let newColumnNumber: number
    if (availableNumbers.size > 0) {
      newColumnNumber = Math.min(...Array.from(availableNumbers))
    } else {
      newColumnNumber = nextNumber
    }
    return newColumnNumber
  }

  const addColumn = (): number => {
    const newColumnNumber = getNextColumnNumberSync()
    setColumns((prevColumns) => {
      const newColumns = new Set(prevColumns)
      newColumns.add(newColumnNumber)
      return newColumns
    })
    setAvailableNumbers((prevAvailable) => {
      const newAvailable = new Set(prevAvailable)
      newAvailable.delete(newColumnNumber)
      return newAvailable
    })
    if (newColumnNumber === nextNumber) {
      setNextNumber((prevNext) => prevNext + 1)
    }
    return newColumnNumber
  }

  return { addColumn, deleteColumn }
}

export default useNextColumnNumber
