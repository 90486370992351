import { IForm } from '../../../domain/interfaces/IForm'
import { IUser } from '../../../domain/interfaces/IUser'

export const canCreateSubmission = (form: IForm | undefined, user?: IUser) => {

  if (form?.currentUserPermission?.roleName == 'GLOBAL') {
    if (!user?.roles[0]) return false
    const role = form.rolePermissions!.find(
      (rp) => rp.roleName == user?.roles[0].name,
    )
    return role!.create == 2
  }
  return (
    form &&
    form.currentUserPermission &&
    form.currentUserPermission.create == 2
  )
}
export const canEditSubmission = (
  form: IForm,
  submissionUserId: string,
  user: IUser | undefined,
) => {
  // UNCOMMENT WHEN EPIC MERGED
  // // user not part of org
  const cup = form.currentUserPermission
  if (!cup) return false
  if (!user) return false

  // if global check edit
  if (cup.roleName == 'GLOBAL') {
    return cup.edit == 2
  }
  //case 1 edit is full
  if (cup.edit == 2) return true
  // case 2 no
  if (cup.edit == 0) return false
  // case 3 edit is limited match form
  return submissionUserId == user.id
}

export const canViewSubmission = (
  form: IForm,
  submissionUserId: string,
  userId: string | undefined,
) => {
  const cup = form.currentUserPermission
  if (!cup) return false
  if (!userId) return false

  if (cup.roleName == 'GLOBAL') {
    return cup.read == 2
  }
  if (cup.read == 2) return true
  if (cup.read == 0) return false
  return submissionUserId == userId
}
