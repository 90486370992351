import React, { ReactElement, useState } from 'react'
import { SubmissionsPageViewModelProps } from './SubmissionsViewModel'
import { generatePDFZipFile } from '../../../domain/domain'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { ErrorToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { ROUTE_SUBMISSIONS } from './SubmissionsPage'
import { extractAndMergeFilesToPDF } from '../../../core/utils/pdf-converter/PdfConverter'
import {
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid'
import {
  getDynamicHeader,
  mock_columns,
  normalizeDynamicHeadersRows,
  normalizeSubmissionsMockDataGrid,
} from '../../components/datagrid/gridData'
import { useNavigate, useParams } from 'react-router-dom'
import BulkDownloadButton from '../../components/buttons/BulkButtons/BulkDownloadButton'
import pluralize from 'pluralize'

import { DialogActions } from '@mui/material'
import { EdifyButton } from '../../components/buttons'

interface ISubmissionPageFilterProps {
  vm: SubmissionsPageViewModelProps
  title: string
  showPDF?: boolean
  modalButtonGroup?: boolean
  handleCloseModal?: () => void
  handleAddSelected?: (selections: GridRowSelectionModel) => void
  selectedSubmissionIds?: string[]
}

const SubmissionDataGrid: React.FC<ISubmissionPageFilterProps> = ({
  vm,
  title,
  showPDF = true,
  modalButtonGroup,
  handleAddSelected,
  handleCloseModal,
  selectedSubmissionIds,
}) => {
  const { formCategory } = useParams()
  const navigate = useNavigate()
  const { query, set } = vm.queryHelper

  const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false)
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>(() => selectedSubmissionIds ?? [])

  /**
   * Gets the column headers for the data grid.
   *
   * we have three cases isCorrectiveActionView(static), formHeaders(dynamic), and mock_columns(static)(to be removed)
   * @returns {GridColDef[]} An array of GridColDef representing the column headers.
   */
  const getColumns = (): GridColDef[] => {
    if (!vm.form.webHeaders || vm.form.webHeaders.length === 0) {
      return mock_columns
    }
    return getDynamicHeader(vm.normalizedDynamicHeaders ?? [], vm.hasCreatedAtHeader)
  }

  /**
   * Gets the column rows for the data grid.
   *
   * We have three cases isCorrectiveActionView(static), formHeaders(dynamic), and mock_columns(static)(to be removed)
   * check MUI DataGrid render rows for more info
   * @returns {any[]} An array of objects representing the column rows.
   */
  const getRows = (): any[] => {
    if (vm.submissionError || !vm.submissions) return []
    if (!vm.form.webHeaders || vm.form.webHeaders.length === 0)
      return normalizeSubmissionsMockDataGrid(vm.submissions)
    return normalizeDynamicHeadersRows(
      vm.submissions,
      vm.form,
      vm.normalizedDynamicHeaders,
    )
  }

  /**
   * Handles the row click event in the data grid.
   *
   * note that we have two cases here isCorrectiveActionView and not isCorrectiveActionView so the id comes in different ways
   * @param {GridRowParams} params - The parameters object containing row data.
   */
  const handleRowClicked = (params: GridRowParams) => {
    const subId = params.row.id

    const formId = vm.form!.id

    navigate(
      `/${formCategory}${ROUTE_SUBMISSIONS}/${formId}/submission/${subId}`,
      {
        state: {},
      },
    )
  }

  /**
   * Gets the PDF IDs for downloading.
   * @returns {string} A string representing the PDF IDs. ex 1;2;3
   *
   */
  const getPDFIds = (): string => {
    
    const selectedSubmissions = vm.submissions?.filter(submission =>
      selectionModel.includes(submission.id)
    )

    const results = selectedSubmissions?.map(submission => {
      let afIds = ''
      let lfIds = ''

      if (submission.additionalFormSubmissions) {
        afIds = submission.additionalFormSubmissions.map(af => af.id).join(';')
      }

      if (submission.linkFormSubmissions) {
        lfIds = submission.linkFormSubmissions.reduce(
          (acc, lf) => [...acc, ...lf.submissionIds],
          []
        ).join(';')
      }

      return [submission.id, afIds, lfIds].filter(Boolean).join(';')
    })
   
    return results?.join(';') ??''
  }

  /**
   * Downloads the PDF files. can handle 1 - n files
   */
  const downloadPDF = async () => {
    if (!selectionModel.length) return
    setDownloadingPDF(true)
    const ids = getPDFIds()
    const res = await generatePDFZipFile(ids, 'list')
    if (res.isLeft() || !res.right) {
      ErrorToast({ title: 'Error downloading PDF.' })
      setDownloadingPDF(false)
      return
    }
    extractAndMergeFilesToPDF(res.right, vm.form?.name)
    setDownloadingPDF(false)
  }
  const addSelected = () => {
    handleAddSelected && handleAddSelected(selectionModel)
    handleCloseModal && handleCloseModal()
  }
  // if dynamic header have the the first key be what i s sorted.

  const firstFormHeader = query.sortBy ?? 'createdAt'

  return (
    <>
      <EdifyDataGrid
        title={title}
        noRowsText={`No ${pluralize(title)} found.`}
        renderHeaderButtons={
          showPDF ? (
            <BulkDownloadButton
              onClick={downloadPDF}
              downloadingPDF={downloadingPDF}
              noSelections={selectionModel.length == 0}
            />
          ) : (
            <></>
          )
        }
        columns={getColumns()}
        rows={getRows()}
        paginationMode='server'
        error={vm.submissionError}
        initSort={[
          {
            field: 'createdAt',
            sort: query.sortOrder,
          },
        ]}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={modalButtonGroup ? undefined : handleRowClicked}
        loading={vm.isLoading || vm.isLoadMore}
        rowCount={vm.totalCount || 0}
        loadMoreRows={vm.loadMore}
        reload={vm.reloadSubmissions}
        sortClickedHandler={vm.sortClickedHandler}
      />
      {modalButtonGroup && (
        <DialogActions
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            p: 0,
            marginTop: '24px',
          }}
        >
          <EdifyButton
            data-testid='CloseDialogButton'
            noBackground
            title={'Cancel'}
            onClick={handleCloseModal}
          />
          <EdifyButton
            onClick={addSelected}
            title={'Add Selected Submissions'}
          />
        </DialogActions>
      )}
    </>
  )
}

export default SubmissionDataGrid
