import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { ETypography } from '../../components/fonts/ETypography'
import { EdifyButton } from '../../components/buttons'
import SubLocationCard from './SublocationCard'
import SubLocationForm from './SubLocationForm'
import SubLocationCSVUpload from './SubLocationCSVUpload'
import { getSubLocations } from '../../../domain/domain'
import { createTreeNew1 } from './treeHelpers'
import { SubLocationPageViewModel } from './SubLocationViewModel'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'

interface Node {
  id: string
  name: string
  level: number
  parentId: string | null
  children: Node[]
}

interface RootNode extends Node {
  parentId: null
}
interface Props {
  locationId: string
  locationName?: string
}

const _SubLocation: React.FC<Props> = ({ locationId, locationName }) => {
  const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const [subLocationTree, setSubLocationTree] = React.useState<any[]>([])
  const [initSubLocationTreeLength, setInitSubLocationTreeLength] =
    React.useState<number>(0)
  const [subLocationIds, setSubLocationIds] = React.useState<string[]>([])
  const [subLocationsLoading, setSubLocationsLoading] =
    React.useState<boolean>(true)
  const [subLocationsError, setSubLocationsError] = React.useState<
    string | undefined
  >(undefined)

  const loadSubLocations = async () => {
    setSubLocationsLoading(true)
    setSubLocationsError(undefined)
    const res = await getSubLocations(locationId!)
    if (res.right) {
      const treeRoot = createTreeNew1(res.right.data, locationId ?? '')
      // to expand the tree
      const subLocationIds = res.right.data.map((sl: any) => sl.id)
      setSubLocationIds(subLocationIds)
      setSubLocationTree(treeRoot.children)
      setInitSubLocationTreeLength(treeRoot.children.length)
      setSubLocationsLoading(false)
      return
    }
    setSubLocationsError(res?.left?.message ?? 'An error occurred')
    setSubLocationsLoading(false)
  }

  useEffect(() => {
    loadSubLocations()
  }, [])

  // TODO
  const canCreate = usePermission(ERole.LocationCreate)
  const canEdit = usePermission(ERole.LocationEdit, false, 'TODO IS MEMBER ID')

  if (initSubLocationTreeLength <= 1 && !canCreate) return null
  if (subLocationsLoading) return <div>Loading...</div>
  if (initSubLocationTreeLength <= 1 ) {
    return (
      <NoLocationsUI
        locationId={locationId}
        subLocationTree={subLocationTree}
        setSubLocationTree={setSubLocationTree}
        subLocationIds={subLocationIds}
      />
    )
  }
  return (
    <InnerPageContainer
      headerTitle='Sub Locations'
      // TODO CHECK WHN CSV UPLOAD IS DONE
      sx={{ marginTop: '12px' }}
      renderRightItems={() => (
        <Box
          sx={{
            // marginTop: '24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ gap: 24 }}>
            <EdifyButton
              hide={!canEdit}
              noBackground
              title={isEdit ? 'Done Editing' : 'Edit'}
              onClick={() => setIsEdit(!isEdit)}
            />
            {/* {isEdit && (
              <EdifyButton
                noBackground
                title={'Delete'}
                onClick={() => setIsEdit(!isEdit)}
              />
            )} */}
          </Box>
        </Box>
      )}
    >
      <>
        {!isEdit && (
          <SubLocationCard
            locationId={locationId}
            subLocationTree={subLocationTree}
            subLocationIds={subLocationIds}
          />
        )}
        {isEdit && (
          <SubLocationForm
            locationId={locationId}
            subLocationTree={subLocationTree}
            setSubLocationTree={setSubLocationTree}
            subLocationIds={subLocationIds}
          />
        )}
      </>
    </InnerPageContainer>
  )
}

interface NoLocationsUIProps {
  locationId?: string
  subLocationTree?: any
  setSubLocationTree: any
  subLocationIds: string[]
}

const NoLocationsUI: React.FC<NoLocationsUIProps> = ({
  locationId,
  subLocationTree,
  setSubLocationTree,
  subLocationIds,
}) => {
  const [csvView, setCsvView] = React.useState<boolean>(false)
  const [hideAll, setHideAll] = React.useState<boolean>(false)
  const [showDone, setShowDone] = React.useState<boolean>(false)
  const renderRightItems = () => {
    if (hideAll) {
      return (
        <EdifyButton
          noBackground
          title={'Add'}
          onClick={() => setHideAll(false)}
        />
      )
    }
    if (!csvView && !showDone && subLocationTree.length > 1) {
      return (
        <EdifyButton
          noBackground
          title={'Done Editing'}
          onClick={() => setShowDone(true)}
        />
      )
    }
  }

  return (
    <InnerPageContainer
      headerTitle='Add Sub Locations'
      renderRightItems={renderRightItems}
    >
      {!hideAll && (
        <>
          {subLocationTree.length <= 1 && (
            <Box>
              <ETypography font='MR' color='gray700'>
                How would you like to add sub loactions
              </ETypography>
              <Box sx={{ margin: '12px' }}>
                <EdifyButton
                  title='Add One'
                  secondary={csvView}
                  onClick={() => setCsvView(false)}
                />
                <EdifyButton
                  buttonStyle={{ marginLeft: '12px' }}
                  title='CSV Upload'
                  secondary={!csvView}
                  onClick={() => setCsvView(true)}
                />

                <EdifyButton
                  buttonStyle={{ marginLeft: '12px' }}
                  title='Skip Add Sub Location'
                  noBackground
                  // secondary={true}
                  onClick={() => setHideAll(true)}
                />
              </Box>
            </Box>
          )}
          <>
            {!showDone && csvView && locationId &&(
              <SubLocationCSVUpload locationId={locationId} />
            )}
            {!showDone && !csvView && (
              <SubLocationForm
                locationId={locationId}
                subLocationTree={subLocationTree}
                setSubLocationTree={setSubLocationTree}
                subLocationIds={subLocationIds}
              />
            )}
            {showDone && (
              <SubLocationCard
                locationId={locationId}
                subLocationTree={subLocationTree}
                subLocationIds={subLocationIds}
              />
            )}
          </>
        </>
      )}
    </InnerPageContainer>
  )
}

const SubLocation: React.FC<Props> = (props) => {
  return (
    <SubLocationPageViewModel locationId={props.locationId}>
      <_SubLocation {...props} />
    </SubLocationPageViewModel>
  )
}
export default SubLocation
