import { Typography } from '@mui/material'
import React from 'react'
import { AppColors, AppFonts } from '../../Theme'

interface InnerPageHeaderProps {
  title: string
}

const InnerPageHeader: React.FC<InnerPageHeaderProps> = ({ title }) => {
  return (
    <Typography
      sx={{
        ...AppFonts.headlineXXSDesktopSemibold,
        color: AppColors.gray700,
      }}
    >
      {title}
    </Typography>
  )
}

export default InnerPageHeader
