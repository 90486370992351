// ED-1386 https://edifyai.atlassian.net/browse/ED-1386
import React, { useContext, useEffect, useState } from 'react'
import useQuery from '../../../core/hooks/useQuery'

import {
  IForm,
} from '../../../domain/interfaces/IForm'
import { showConfirmationDeleteMessage } from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { Either, Failure } from '../../../core/core'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import {
  getForm,
  getFormsFromQuery,
} from '../../../domain/domain'
import { useParams } from 'react-router-dom'
import { ISearchResult } from '../../components/form/EdifySearch/EdifySearchDropdown'
import { DEFAULT_LIMIT } from '../../components/datagrid/EdifyDataGrid'
import { IFormList } from '../../../domain/interfaces/IFormList'

export interface FormsPageViewModelProps {
  queryHelper: Record<string, any>
  forms: IForm[]
  isLoading: boolean
  totalCount: number
  formsError: string | undefined
  error: string | undefined
  getForms: () => void
  // showDeleteConfirmation: (ids: string[]) => void
  loadMore: (paginationModel: GridPaginationModel) => void
  fetchForms: (fetchSkip: number, query: Record<string, any>) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  onFormSearch: (result: ISearchResult) => void
  setPageForms:(forms:IForm[])=> void
  getFavoriteCount:()=> void
  errorObj: any | undefined
}

const FormPageContext = React.createContext<FormsPageViewModelProps | null>(
  null,
)

export function useFormsPageViewModel(): FormsPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(FormPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const FormsPageViewModel: React.FC<Props> = ({ children }) => {
  const { id } = useParams()

  const queryHelper = useQuery({
    ...(id ? { formId: id } : {}),
    skip: 0,
    limit: DEFAULT_LIMIT,
  })
  const { query, getQueryStringFromQuery, setNew } = queryHelper
  const [forms, setForms] = useState<IForm[]>([])
  const [formsError, setFormsError] = useState<string | undefined>(undefined)
  const [errorObj, setErrorObj] = useState<any | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)

  const loadForms = async () => {
    setFormsError(undefined)
    setIsLoading(true)
    setForms([])
    fetchForms(0, query)
  }

  const fetchForms = async (fetchSkip: number, query: Record<string, any>) => {
    setFormsError(undefined)
    setIsLoading(true)

    const newQuery = { ...query, skip: fetchSkip }

    const res: Either<Failure, IFormList> = await getFormsFromQuery(
      getQueryStringFromQuery(newQuery),
    )
    setIsLoading(false)
    if (res.isLeft()) {
      setFormsError(res.left?.message)
      setErrorObj(res.left)
      return
    }
    setTotalCount(res.right?.total_count || 0)
    setForms(res.right?.data as IForm[])
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchForms(fetchSkip, query)
  }

  const getFavoriteCount = ()=>{
    return forms.filter(f=> f.isFavorited).length
  }

  // const deleteSelectedForms = async (ids: string[]) => {

  //   const res = await adminDeleteForms(ids)
  //   const formString = ids.length == 1 ? 'Form' : 'Forms'
  //   const formStringLower = ids.length == 1 ? 'form' : 'forms'
  //   if (res.isRight()) {
  //     SuccessToast({ title: `${formString} removed successfully.` })
  //     fetchForms(query.skip, query)
  //   } else {
  //     ErrorToast({ title: `Error removing ${formStringLower}.` })
  //   }
  // }

  // const showDeleteConfirmation = async (ids: string[]) => {
  //   const confirmMessage = ids.length > 1 ? `${ids.length} forms` : '1 form'

  //   showConfirmationDeleteMessage(confirmMessage, () =>
  //     deleteSelectedForms(ids),
  //   )
  // }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    const newQuery = {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    }
    fetchForms(0, newQuery)
  }

  // This might need to be changed (UX)..
  const onFormSearch = async (formSearchResult: ISearchResult) => {
    // check to see if it is already in the page
    const matchedForm = forms.find((p) => p.id == formSearchResult.id)
    if (matchedForm) {
      setForms([matchedForm])
      setTotalCount(1)
      return
    }
    // if not grab from API
    if (!matchedForm) {
      setIsLoading(true)
      setFormsError(undefined)
      setIsLoading(false)
      const res = await getForm(formSearchResult.id)
      if (res.right) {
        setForms([res.right as IForm])
        setTotalCount(1)
      } else {
        ErrorToast({ title: 'Could not find form' })
      }
    }
  }

  useEffect(() => {
    loadForms()
  }, [])

  return (
    <FormPageContext.Provider
      value={{
        queryHelper,
        forms,
        isLoading,
        formsError,
        fetchForms,
        getForms: loadForms,
        // showDeleteConfirmation,
        loadMore,
        totalCount,
        sortClickedHandler,
        onFormSearch,
        error: formsError,
        setPageForms: setForms,
        getFavoriteCount,
        errorObj
      }}
    >
      {children}
    </FormPageContext.Provider>
  )
}
export default FormPageContext
