import React from 'react'
import {
  Box,
  ButtonProps,
  CircularProgress,
  IconButton,
  SxProps,
} from '@mui/material'
import { AppColors } from '../../../Theme'
interface EdifyIconButton extends ButtonProps {
  children: React.ReactNode
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void
  loading?: boolean
  hoverColor?: string
  hover?: string
  hoverBorder?: string
  background?: string

}

const EdifyIconButton: React.FC<EdifyIconButton> = ({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  loading,
  sx,
  hoverColor = AppColors.p800,
  background = AppColors.baseWhite,

}) => {
  return (
    <IconButton
      data-testid='EdifyIconButton'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      sx={{
        width: '40px',
        height: '40px',
        padding: '10px',
        border: `1px solid ${AppColors.neutral700}`,
        borderRadius: '5px',
        background,
        '&:hover': {
          background,
          border: `1px solid ${hoverColor}`,
          color: hoverColor,
        },
        ...sx,
      }}
    >
      <>
        {loading && (
          <Box>
            <CircularProgress size='1rem' />
          </Box>
        )}
        {!loading && children}
      </>
    </IconButton>
  )
}

export default EdifyIconButton
