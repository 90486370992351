import { AccountCircle, Group, Logout } from '@mui/icons-material'
import { Popover, Box, Typography, Stack, Button } from '@mui/material'
import React, { useState } from 'react'
import { getCurrentOrg } from '../../../../domain/domain'
import { AppFonts, AppColors } from '../../../Theme'
import { OrgAvatar } from '../../icons/icon'
import { ROUTE_PERSON_PROFILE } from '../../../pages/person_profile/PersonProfilePage'


interface SettingsProps {
  open: boolean
  handleClose: VoidFunction
}

export const SettingsPopoverDialog: React.FC<SettingsProps> = ({
  open,
  handleClose,
}) => {
  const currentUserOrg = getCurrentOrg()?.name ?? ''

  const [onHover, setOnHover] = useState({
    profile: false,
    manageUsers: false,
    logout: false,
  })

  return (
    <Popover
      data-testid={'SettingsPopover'}
      anchorReference='anchorEl'
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          marginTop: '55px',
          borderRadius: '0px 0px 16px 16px',
        },
      }}
    >
      <Box
        sx={{
          width: '400px',
          height: '303px',
          p: '16px',
        }}
      >
        <Typography sx={{ p: '8px', ...AppFonts.montserratHuge }}>
          Settings
        </Typography>
        <Box
          sx={{
            marginTop: '8px',
            p: '16px',
            borderRadius: '16px',
            bgcolor: AppColors.grey,
            display: 'flex',
            gap: '13px',
          }}
        >
          <OrgAvatar
            sx={{
              bgcolor: AppColors.g000,
              ...AppFonts.montserratLarge,
              color: AppColors.g400,
            }}
            intial={currentUserOrg?.charAt(0)}
          />
          <Stack>
            <Typography sx={{ ...AppFonts.montserratSmall, fontWeight: 400 }}>
              Member of
            </Typography>
            <Typography sx={{ ...AppFonts.latoMedium, fontWeight: 700 }}>
              {currentUserOrg}
            </Typography>
          </Stack>
        </Box>
        <Button
          href={ROUTE_PERSON_PROFILE}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            p: '8px',
            marginTop: '8px',
            '&:hover': {
              color: AppColors.p500,
            },
            ...AppFonts.montserratMedium,
            cursor: 'pointer',
          }}
          onMouseEnter={() => {
            setOnHover((oldState) => {
              return { ...oldState, profile: true }
            })
          }}
          onMouseLeave={() => {
            setOnHover((oldState) => {
              return { ...oldState, profile: false }
            })
          }}
        >
          <AccountCircle
            sx={{
              color: onHover.profile ? AppColors.p500 : AppColors.g400,
              width: '24px',
              height: '24px',
            }}
          />
          Profile
        </Button>
        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            p: '8px',
            marginTop: '8px',
            '&:hover': {
              color: AppColors.p500,
            },
            ...AppFonts.montserratMedium,
            cursor: 'pointer',
          }}
          onMouseEnter={() => {
            setOnHover((oldState) => {
              return { ...oldState, manageUsers: true }
            })
          }}
          onMouseLeave={() => {
            setOnHover((oldState) => {
              return { ...oldState, manageUsers: false }
            })
          }}
        >
          <Group
            sx={{
              color: onHover.manageUsers ? AppColors.p500 : AppColors.g400,
              width: '24px',
              height: '24px',
            }}
          />
          Manage Users
        </Button>
        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            p: '8px',
            marginTop: '8px',
            '&:hover': {
              color: AppColors.p500,
            },
            ...AppFonts.montserratMedium,
            cursor: 'pointer',
          }}
          onMouseEnter={() => {
            setOnHover((oldState) => {
              return { ...oldState, logout: true }
            })
          }}
          onMouseLeave={() => {
            setOnHover((oldState) => {
              return { ...oldState, logout: false }
            })
          }}
        >
          <Logout
            sx={{
              color: onHover.logout ? AppColors.p500 : AppColors.g400,
              width: '24px',
              height: '24px',
            }}
          />
          Logout
        </Button>
      </Box>
    </Popover>
  )
}
