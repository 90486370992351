import { Box } from '@mui/material'
import React from 'react'
import logo from '../../../assets/icons/safetyMojoLogin.svg'


import { ComponentProps } from '../ComponentProps'

interface Props extends ComponentProps {
  width?: number
  height?: number
  isDarkTheme?: boolean
}

export const SafetyMojoLoginIcon: React.FC<Props> = ({
  sx,
  width,
  height,
  isDarkTheme,
}) => {
  return (
    <Box
      data-testid='SafetyMojoIcon'
      component='img'
      sx={{
        width: width ?? 180,
        height: height ?? 48,
        ...sx,
      }}
      src={logo}
    />
  )
}
