import React, { useState } from 'react'
import { Box } from '@mui/material'
import { ContractorsPageViewModelProps } from './ContractorsViewModel'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import { EdifySelect } from '../../components/form'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'

import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { globalSearch } from '../../../domain/usecases/utils/UtilsUsecasses'
import { EdifySearch } from '../../components/form/EdifySearch/EdifySearch'

interface IContractorsFilterProps {
  vm: ContractorsPageViewModelProps
}

const ContractorsFilter: React.FC<IContractorsFilterProps> = ({ vm }) => {
  const { query } = vm.queryHelper
  const [value, setValue] = useState(query.trade ?? 'All Trades')
  const [showTradeClear, setShowTradeClear] = useState(query.trade != 'All Trades')
  const { trades } = useOrganizationProvider() ?? []

  const tradeChanged = (event: any) => {
    const trade = event.target.value
    const newUserQueryField = { ...query, trade }
    setValue(trade)
    setShowTradeClear(trade != 'All Trades')
    if (trade === 'All Trades') {
      delete newUserQueryField.trade
    }
    vm.fetchContractors(0, newUserQueryField)
  }

  const clear = () => {
    setValue('All Trades')
    setShowTradeClear(false)
    const newUserQueryField = { ...query }
    delete newUserQueryField.trade
    vm.fetchContractors(0, newUserQueryField)
  }

  const contactSelected = (contacts: ISearchResult[]) => {
    // search cleared
    if (contacts.length == 0) {
      const newQuery = { ...query }
      // might be fullName
      delete newQuery.searchKey
      vm.fetchContractors(0, newQuery)
      return
    }
    vm.fetchContractors(0, { ...query, searchKey: contacts[0].name })
  }
  const onContractorsTextSearch = (searchKey: string) => {
    const newQuery = { ...query, searchKey }
    vm.fetchContractors(query.skip, newQuery)
  }


  // const filterButtons =
  //   (!vm.rolesLoading && vm.roles?.map((role: any) => role.name)) || []

  return (
    <>
      <Box
        data-testid={'ContractorsPage'}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {/* <FilterButtonGroup
          onButtonClicked={handleButtonClicked}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          buttons={['ALL', ...filterButtons]}
        /> */}
        <EdifySelect
          width={230}
          defaultValue={query.trade ?? 'All Trades'}
          sx={{ marginRight: '24px', marginBottom: '24px' }}
          onChange={tradeChanged}
          onClear={clear}
          value={value}
          showClear={showTradeClear}
          dropDownItems={['All Trades', ...(trades as string[])]}
          renderMenuItem={(item: string) => (
            <EdifyMenuItem key={item} value={item}>
              {item}
            </EdifyMenuItem>
          )}
        />
        {/* <EdifySearchDropdown
          data-testid='PointOfContact'
          placeholder='Search Name'
          nameKey='name'
          width={218}
          limit={8}
          onSelect={contactSelected}
          sx={{ marginBottom: '24px' }}
          globalSearchKey='contractors'
          globalSearchPayload={{
            entities: ['contractors'],
            properties: ['id'],
            returns: ['id', 'name'],
          }}
          searchFunction={globalSearch}
          
        /> */}
        <EdifySearch
          placeholder='Search Name'
          width={'260px'}
          defaultValue={vm.queryHelper.query.searchKey ?? ''}
          onChange={(e: any) => onContractorsTextSearch(e.target.value)}
          clear={() => {
            onContractorsTextSearch('')
          }}
          sx={{ marginBottom: '24px' }}
        />
      </Box>

      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: '24px',
        }}
      >
        <EdifyButton
          onClick={() => vm.clearFilters(true)}
          title='Clear Filters'
          primary
          noBackground
        />
      </Box> */}
    </>
  )
}

export default ContractorsFilter
