import * as React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AppColors, AppFonts } from '../../Theme'
import dayjs from 'dayjs'
import { SxProps } from '@mui/material'

interface IEdifyDatePickerProps {
  date: string | null
  // setDate: (date: string | null) => void
  setDate: (date: dayjs.Dayjs | null) => void
  disabled?: boolean
  error?: boolean
  sx?: SxProps
}
const EdifyDatePicker: React.FC<IEdifyDatePickerProps> = ({
  date,
  setDate,
  disabled = false,
  error = false,
  sx,
}) => {
  // Update still not working but prevents crash
  // look into this https://moment.github.io/luxon/#/
  let formateDate = ''
  if (date && date.split) {
    const dates = date.split('T')
    formateDate = dates[0]
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={disabled}
        className={`form-control e-input ${error ? 'error' : ''}`}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& input': {
            ...AppFonts.textMRegular,
            padding: '11.5px',
            paddingLeft: '0px',
            color: date ? AppColors.gray950 : AppColors.gray25,
          },
          ...sx,
        }}
        value={dayjs(formateDate)}
        defaultValue={dayjs(formateDate)}
        onChange={(newDate) => setDate(newDate)}
        slotProps={{
          //TODO REPLACE WITH CORRECT ICON
          // ADD DROPDOWN ICON
          inputAdornment: {
            position: 'start',
          },
        }}
      />
    </LocalizationProvider>
  )
}
export default EdifyDatePicker
