import React from 'react'
import { GridBadge, GridItem, GridStackItem } from '../gridItems'
import { GridColDef } from '@mui/x-data-grid'
import { dateToMMMDYYYY, getTimeAgo } from '../../../../core/core'
import { ICorrectiveAction } from '../../../../domain/interfaces/ICorrectiveAction'

// Hardcoded columns for Corrective Actions
export const correctiveActionColumnsOld: (
  l0Name: string,
  locationEnabled: boolean,
  sublocationEnabled: boolean,
) => GridColDef[] = (l0Name, locationEnabled, sublocationEnabled) => {
  const orgId = localStorage.getItem('currentOrgId')
  const isShea = orgId == 'f8782756-9dd8-45cf-9495-70131108fd7f'
  return [
    {
      field: 'form',
      renderHeader: () => <GridItem text='Title' />,
      flex: 1,
      renderCell: ({ row }) => (
        <GridStackItem
          data-testid='CorrectiveActionGridRow'
          topText={row.title?.text ?? ''}
          bottomText={dateToMMMDYYYY(row.title.date)}
        />
      ),
    },
    {
      field: 'author',
      flex: 1,
      renderHeader: () => <GridItem text='Author' />,
      renderCell: ({ row }) => <GridItem text={row.authorName} />,
    },
    {
      field: 'responsibleParty',
      flex: 1,
      renderHeader: () => <GridItem text='Responsible Party' />,
      renderCell: ({ row }) => <GridItem text={row.responsiblePartyName} />,
    },
    {
      field: 'project',
      flex: 1,
      renderHeader: () => <GridItem text={l0Name == '' ? 'Project' : l0Name} />,
      renderCell: ({ row }) => <GridItem text={row.projectName} />,
    },

    {
      field: 'location',
      renderHeader: () => <GridItem text={isShea ? '' : 'Location'} />,
      width: isShea ? 0 : 150,
      renderCell: (x: any) => <div>{isShea ? '' : x.row.location}</div>, // Example cell rendering
    },

    {
      field: 'status',
      renderHeader: () => <GridItem text='Status' />,
      width: 130,
      renderCell: ({ row }) => <GridItem text={row.closed} />,
    },
    {
      field: 'createdAt',
      width: 150,
      renderHeader: () => <GridItem text='Created Date' />,
      renderCell: ({ row }) => (
        <GridBadge type='grey' text={getTimeAgo(row.created)} />
      ),
    },
    {
      field: 'closedAt',
      width: 130,
      renderHeader: () => <GridItem text='Closed Date' />,
      renderCell: ({ row }) => (
        <GridBadge
          type='grey'
          text={row.closedAt ? getTimeAgo(row.closedAt) : 'Open'}
        />
      ),
    },
  ]
}

// Maps corrective actions static rows
export const normalizeCorrectiveActionRowsOld = (
  correctiveActions?: ICorrectiveAction[],
  showLocations?: boolean,
  showSubLocations?: boolean,
): any[] => {
  if (!correctiveActions) return []
  return correctiveActions.map((sub: ICorrectiveAction) => {
    return {
      id: sub.id,
      title: { text: sub.formTitle, date: sub.createdAt },
      authorName: sub.authorName,
      projectName: sub.projectName,
      responsiblePartyName: sub.responsiblePartyName,
      location: sub.location,
      created: sub.createdAt,
      closed: sub.status,
      closedAt: sub.closedAt,
    }
  })
}
