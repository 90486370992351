import React from 'react'

import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import {
  contact_columns,
  normalizeContactsForGrid,
} from '../../components/datagrid/gridData/contacts'
import { ContactsViewModelProps } from './ContactsViewModel'
import { ROUTE_CONTACTS } from '../contact/ContactPage'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { ROUTE_CONTRACTORS } from '../contractors/ContractorsPage'
import { IContact } from '../../../domain/interfaces/IContact'

interface IContractorsDataGridProps {
  vm: ContactsViewModelProps
  title: string
  checkboxSelection: boolean
  contractorName?: string
}

const ContactsDataGrid: React.FC<IContractorsDataGridProps> = ({
  vm,
  title,
  checkboxSelection,
  contractorName,
}) => {
  const navigate = useNavigate()
  const { getHierarchyName } = useOrganizationProvider()
  const l0Name = getHierarchyName(0, true)
  const l1Name = getHierarchyName(1, true)
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const { contractorId } = useParams()

  const handleRowClicked = (params: GridRowParams) => {
    navigate(
      `${ROUTE_CONTRACTORS}/${contractorId}${ROUTE_CONTACTS}/${params.row.id}`,
      {
        state: { contractorName: contractorName },
      },
    )
  }

  const removeClicked = async () => {
    vm.deleteContacts(selectionModel as string[])
    setSelectionModel([])
  }

  const renderHeaderButtons = () => {
    if (!checkboxSelection) return
    const noSelections = selectionModel.length === 0
    return (
      <BulkDeleteButton noSelections={noSelections} onClick={removeClicked} />
    )
  }

  // will we get contacts from contractor or seperate API call?
  const rows = vm.normalizeContactsForDataGrid()

  return (
    <>
      <EdifyDataGrid
        data-testid='ContactsDataGrid'
        paginationMode='server'
        title={title}
        noRowsText={'No contacts found.'}
        renderHeaderButtons={renderHeaderButtons()}
        columns={contact_columns(l0Name, l1Name)}
        rows={rows}
        error={vm.error}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={handleRowClicked}
        loading={vm.isLoading || vm.isLoadMore}
        rowCount={vm.totalCount || 0}
        loadMoreRows={vm.loadMore}
        reload={vm.reload}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={checkboxSelection}
        initSort={[
          {
            field: 'fullName',
            sort: 'asc',
          },
        ]}
      />
    </>
  )
}

export default ContactsDataGrid
