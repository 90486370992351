export class Either<T, U> {
  left: T | undefined
  right: U | undefined

  constructor(left: T | undefined, right: U | undefined) {
    this.left = left
    this.right = right
  }

  isLeft(): boolean {
    return this.left != undefined
  }

  isRight(): boolean {
    return this.right != undefined
  }
}

export class Left<T, U> extends Either<T, U> {
  constructor(left: T) {
    super(left, undefined)
  }
}

export class Right<T, U> extends Either<T, U> {
  constructor(right: U) {
    super(undefined, right)
  }
}
