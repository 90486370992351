/* eslint-disable react/prop-types */
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, DialogActions, Input, TextareaAutosize } from '@mui/material'
import { AppColors, AppFonts } from '../../Theme'
import { EdifyButton } from '../../components/buttons'
import { Controller, FieldErrors, Resolver, useForm } from 'react-hook-form'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import FormErrorText from '../../components/form/FormErrorText'
import { phoneInputFormatter } from '../../../core/utils/input-formatters/InputFormatters'
import { SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { Either } from '../../../core/core'
import { contactSales } from '../../../data/repositories/home/HomeRepository'
import { IUser } from '../../../domain/interfaces/IUser'

export type IContactSalesInputs = {
  fullName: string
  email: string
  phone?: string
  additionalInformation?: string
}

let setController:
  | React.Dispatch<React.SetStateAction<IContactSalesDialogProps | undefined>>
  | undefined

let showDialog: React.Dispatch<React.SetStateAction<boolean>> | undefined
/**
 * Show [ContactSales Dialog].
 *
 * @param submission
 */
export function showContactSalesDialog(props: IContactSalesDialogProps) {

  setController?.(props)
  showDialog?.(true)
}

export function hideContactSalesDialog() {
  setController?.(() => undefined)
  showDialog?.(false)
}

export interface IContactSalesDialogProps {
  user: IUser | undefined
  onCancel?: () => void
  onConfirm?: () => void
  oneButton?: boolean
}

export const ContactSalesDialog = () => {
  const [open, setOpen] = React.useState(false)
  const [props, setProps] = React.useState<IContactSalesDialogProps>()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>(undefined)

  setController = (props:any) => {
    reset({
      fullName: props?.user?.fullName ?? '',
      email: props?.user?.email ?? '',
      phone: props?.user?.phone ?? '',
    })
    return setProps
  }
  showDialog = setOpen

  const closeDialog = () => {
    reset()
    setError(undefined)
    hideContactSalesDialog()
  }

  // FORM LOGIC
  /**
   * Validates the input values for the person's profile form.
   */
  const resolver: Resolver<IContactSalesInputs> = async (values) => {
    const errors: FieldErrors<IContactSalesInputs> = {}

    if (!values.fullName) {
      errors.fullName = {
        type: 'required',
        message: 'Name is required',
      }
    }
    if (!values.email) {
      errors.email = {
        type: 'required',
        message: 'Email is required',
      }
    }

    if (values.phone) {
      const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/
      if (!phoneNumberPattern.test(values.phone)) {
        errors.phone = {
          type: 'pattern',
          message: 'Invalid phone number',
        }
      }
    }
    return {
      values,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<IContactSalesInputs>({ resolver, mode: 'onSubmit' })

  const onSubmit = handleSubmit(async (data) => {
    // react-hook-form will check for errors here and return
    // if there are any
    // if we get here validations have passed
    const formData: IContactSalesInputs = {
      fullName: data.fullName,
      email: data.email,
      phone: data.phone ?? '',
      additionalInformation: data.additionalInformation ?? '',
    }
    setLoading(true)
    setError(undefined)
    const res = await contactSales(formData)
    if (res.isRight()) {
      SuccessToast({
        title: 'We’ve received your message! Sales will contact you shortly.',
      })
      hideContactSalesDialog()
      reset()
    } else {
      setError(res.left?.message ?? 'Error contacting sales, try again later')
    }
    setLoading(false)
  })

  const handlePhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    const formattedValue = phoneInputFormatter(value)
    setValue('phone', formattedValue, {
      shouldValidate: value.length > 11 || value.length == 0,
    })
  }

  return (
    <Dialog
      data-testid='ContactSalesDialog'
      open={open}
      PaperProps={{
        sx: dialogStyling,
      }}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle
          sx={{
            ...AppFonts.headlineSDesktopSemibold,
            color: AppColors.gray700,
            p: 0,
          }}
        >
          Contact Sales
        </DialogTitle>
        <DialogContent sx={{ p: 0, pb: 24 }}>
          <Box sx={{ width: '100%' }}>
            {error && <FormErrorText>{error}</FormErrorText>}
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='Name' />
              <Input
                data-testid='fullName'
                disableUnderline
                placeholder='Name'
                className={`form-control ${errors?.fullName ? 'error' : ''}`}
                {...register('fullName')}
              />
              {errors?.fullName && (
                <FormErrorText>{errors.fullName.message}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='EMAIL' />
              <Input
                data-testid='Email'
                disableUnderline
                placeholder='sample@email.com'
                className={'form-control'}
                {...register('email')}
              />
              {errors?.email && (
                <FormErrorText>{errors.email.message}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='PHONE NUMBER' />
              <Controller
                name='phone'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    {...field}
                    data-testid='PhoneNumber'
                    disableUnderline
                    placeholder='000-000-0000'
                    className={`form-control ${errors?.phone ? 'error' : ''}`}
                    value={field.value}
                    onChange={handlePhoneInputChange}
                  />
                )}
              />
              {errors?.phone && (
                <FormErrorText>{errors.phone.message}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='ADDITIONAL INFO' />
              <TextareaAutosize
                minRows={3}
                data-testid='AdditionalInfo'
                placeholder='Is there anything else you would like to discuss during the call?'
                className={'form-control'}
                {...register('additionalInformation')}
              />
            </EdifyFormField>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            p: 0,
          }}
        >
          <EdifyButton
            data-testid='CloseDialogButton'
            fullWidth
            title={'Cancel'}
            secondary
            onClick={closeDialog}
          />

          {!props?.oneButton && (
            <EdifyButton
              type='submit'
              disabled={loading}
              loading={loading}
              data-testid='ContactSalesDialogButton'
              fullWidth
              title={'Send'}
            />
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}

const dialogStyling = {
  width: '425px',
  height: 'auto',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 24,
}
