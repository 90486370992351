import React  from 'react'
import { Grid } from '@mui/material'
import { EdifyFieldLabel } from '../../components/form'
import LocationHierarchyForm from './LocationHierarchyForm'

import TreeMUI from './TreeMUI'


interface Props {
  locationId?: string
  subLocationTree: any
  setSubLocationTree: any
  subLocationIds: string[]
}

const _SubLocationForm: React.FC<Props> = ({
  locationId,
  subLocationTree,
  subLocationIds,
  setSubLocationTree,
}) => {
  return (
    <>
      <Grid container spacing={'24px'} sx={{ marginTop: '12px' }}>
        <Grid item md={12} lg={8}>
          <EdifyFieldLabel label='Structure' />
          <TreeMUI
            setSubLocationTree={setSubLocationTree}
            defaultExpanded={subLocationIds}
            locationId={locationId}
            subLocationsTree={subLocationTree}
            isEdit={true}
          />
        </Grid>
        <Grid item md={12} lg={4}>
          <EdifyFieldLabel label='Sub Level Names' />
          <LocationHierarchyForm locationId={locationId} />
        </Grid>
      </Grid>
    </>
  )
}
const SubLocationForm = (props: Props) => {
  return <_SubLocationForm {...props} />
}
export default SubLocationForm
