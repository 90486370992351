import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  addOnTokenExpiredListener,
  getFromLocalStorage,
  getInitialToken,
  refreshPerson,
  removeOnTokenExpiredListener,
  saveToLocalStorage,
} from '../../../data/data'
import globalI18n from '../../../i18n'
import { isUserLoggedIn, logoutUser } from '../../../domain/domain'
import { logout } from '@formio/react'
import { ROUTE_LOGIN } from '../login/LoginPage'
import { ROUTE_SSO_CHECK } from '../login/SSOCheckPage'
import { Box, CircularProgress } from '@mui/material'
import { AppColors } from '../../Theme'
import { SafetyMojoLoginIcon } from '../../../core/components/icons/SafetyMojoLoginIcon'
import { getTempLanguage } from '../../../i18n'

const RefreshPersonLoader: React.FC = () => {
  return (
    <Box
      data-testid='LoginPage'
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: AppColors.baseWhite,
      }}
    >
      <SafetyMojoLoginIcon width={180} height={48} />
      <Box
        sx={{
          marginTop: '96px',
          height: '324px',
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  )
}

const PrivatePage: React.FC = () => {
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false)
  const [refreshedPerson, setRefreshedPerson] = useState<boolean>(false)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const SSOtoken = queryParams.get('token')
  const success = queryParams.get('success')
  const error = queryParams.get('errMessage')
  const navigate = useNavigate()

  const checkPerson = async () => {
    const person = await refreshPerson()
    setRefreshedPerson(true)

    // save person to local storage if person
    if (person.isRight() && person.right) {
      //if temp language selected
      if (getTempLanguage()) {
        const rawUser = getFromLocalStorage('person')
        const language = rawUser != null ? JSON.parse(rawUser).language : 'en'
        person.right.language = language
      }
      saveToLocalStorage('person', JSON.stringify(person.right))
      globalI18n.changeLanguage(person.right.language ?? 'en')
      return
    }
    navigate(ROUTE_LOGIN)
  }

  useEffect(() => {

    // NOT SURE WHAT TODO WITH SSO TOKEN
    if (SSOtoken) {
      setRefreshedPerson(true)
      return
    }
    if (!refreshedPerson) {
      checkPerson()
      return
    }

    addOnTokenExpiredListener(() => {
      setIsTokenExpired(true)
      logoutUser()
    })
    if (!getInitialToken()) {
      setIsTokenExpired(true)
      logout()
    }

    return () => {
      removeOnTokenExpiredListener()
    }
  }, [refreshedPerson])
  if (!refreshedPerson) return <RefreshPersonLoader />
  if (SSOtoken && success) {
    return <Navigate to={ROUTE_SSO_CHECK} state={{ token: SSOtoken }} />
  }
  if (!isUserLoggedIn() || isTokenExpired) {
    logout()
    return <Navigate to={ROUTE_LOGIN} state={{ error: error }} />
  }

  return <Outlet />
}

export default PrivatePage