import React from 'react'
import { ETypography } from '../../fonts/ETypography'
import { EBox } from '../../box/Ebox'
import { TrashBlueIcon } from '../../../../core/components/icons/TrashBlueIcon'
import { Box, SxProps } from '@mui/material'
import ImageDialog from '../../dialogs/ImageDialog'

import PDFViewerModal from '../../../pages/document-library/PDFViewerModal'
import ImageWithToken from './ImageWithToken'
import { getAuthToken } from '../../../../domain/domain'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'
import fileIcon from '../../../../assets/images/file-image.png'
import pdfFile from '../../../../assets/images/pdf-file.png'

const  DISPLAY_IN_IFRAME = [
  'application/pdf',         // PDF files will display in most modern browsers
  'text/plain',             // Text files usually display
  'text/csv',               // CSV may display as text
  'text/markdown',          // Markdown may display as text
  'text/html',              // HTML will render
  'image/*',  
  'video/*',
  'audio/*'               // Images will display
]


const removeUuidFromString = (str: string): string => {
  // UUID pattern is 32 hex digits and 4 hyphens, followed by a hyphen
  const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-/i
  return str.replace(uuidPattern, '')
}
const extractFormFileName = (url: string): string | null => {
  try {
    const urlObject = new URL(url)

    // Get the 'form' parameter from the URL
    const formParam = urlObject.searchParams.get('form')

    if (!formParam) return null

    return removeUuidFromString(formParam)
  } catch {
    return null
  }


}

interface EdifyAttachmentViewerProps {
  attachment: string
  name?: string
  description?: string
  type?: string
  onDelete?: () => void
  sx?: SxProps
}

const EdifyAttachmentViewer: React.FC<EdifyAttachmentViewerProps> = ({
  attachment: image,
  name = 'Image',
  description = '',
  type = 'image',
  onDelete,
  sx,
}) => {
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const fileName = extractFormFileName(image) || image
  const token = getAuthToken()
  const org = useOrganizationProvider()
  let orgId: undefined | string = undefined
  let swappedSrc = image

  if (org) {
    orgId = org.org?.id
  } else {
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('orgId')
    if (id) {
      orgId = id
    }
  }

  const isImageUrl = (url: string): boolean => {
    if (!url?.trim()) return false

    const imageExtensions = [
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.bmp',
      '.webp',
      '.svg',
      '.tiff',
      '.ico',
      '.avif',
    ]

    const lowerUrl = url.toLowerCase()
    return imageExtensions.some((ext) => lowerUrl.endsWith(ext))
  }

  const isPdfUrl = (url: string): boolean => {
    if (!url?.trim()) return false
    return url.toLowerCase().endsWith('.pdf')
  }

  const isIframeCompatible = (url: string): boolean => {
    if (!url?.trim()) return false
    
    const lowerUrl = url.toLowerCase()
    
    // File extensions that can typically be displayed in iframes
    const iframeCompatible = [
      '.pdf',  // PDF documents
      '.txt',  // Text files
      '.html', // HTML documents
      '.htm',  // HTML documents
      '.jpg',  // Images
      '.jpeg', 
      '.png', 
      '.gif', 
      '.svg',  // Vector graphics
      '.mp4',  // Videos
      '.webm'  // WebM videos
    ]
  
    return iframeCompatible.some(ext => lowerUrl.endsWith(ext))
  }
  const isIframe = isIframeCompatible(fileName) || isPdfUrl(fileName)

  if (!isImageUrl(fileName)) {
    if (isPdfUrl(fileName)) {
      type = 'pdf'
      name = 'PDF'
    } else {
      type = 'file'
      name = 'File'
    }
  }

  const imgComponent = (
    <ImageWithToken
      src={image}
      alt={name}
      style={{
        width: '48px',
        height: '48px',
        marginBottom: '16px',
        marginRight: '16px',
        cursor: 'pointer',
      }}
    />
  )
  const pdfComponent = (
    <img
      src={pdfFile}
      alt={name}
      style={{
        width: '48px',
        height: '48px',
        marginBottom: '16px',
        marginRight: '16px',
        cursor: 'pointer',
      }}
    />
  )
  const fileComponent = (
    <img
      src={fileIcon}
      alt={name}
      style={{
        width: '48px',
        height: '48px',
        marginBottom: '16px',
        marginRight: '16px',
        cursor: 'pointer',
      }}
    />
  )

  const renderBody = () => {
    if (!isIframe) {
      return <ImageWithToken src={image} fileName={fileName} isLink={true} />
    }
    return (
      <>
        {' '}
        <ETypography font='SM' color='gray700'>
          {fileName ?? name}
        </ETypography>
        {/* <ETypography font='SR' color='gray50'>
          {description}
        </ETypography> */}
      </>
    )
  }

  const handleClick = () => {
    if (isIframe) {
      setOpen(true)
      return
    }

    if (token) {
      swappedSrc += `&token=${token}`
    }
    if (orgId) {
      swappedSrc += `&orgId=${orgId}`
    }
    // downloadFile()
    openInNewTab()
  }

  // Method 1: Automatic download
  const downloadFile = () => {
    const link = document.createElement('a')
    link.href = swappedSrc
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }  

  // Method 2: Open in new tab
  const openInNewTab = () => {
    window.open(swappedSrc, '_blank')
  }

  return (
    <>
      <EBox
        p='16'
        border='primary'
        onClick={handleClick}
        sx={{
          display: 'flex',
          jc: 'flex-start',
          fd: 'row',
          minWidth: '280px',
          borderRadius: '10px',
          width: 'calc(50% - 12px)',
          ...sx,
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'primary100',
          },
        }}
      >
        {type === 'image'
          ? imgComponent
          : type === 'pdf'
            ? pdfComponent
            : fileComponent}

        <EBox style={{ flex: 1 }} sx={{ overflow: 'hidden' }}>
          {renderBody()}
        </EBox>
        {onDelete && (
          <Box
            sx={{
              marginLeft: '12px',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={(e) => {
              e.stopPropagation() // Stop click event propagation
              onDelete() // Trigger the onDelete action
            }}
          >
            <TrashBlueIcon />{' '}
          </Box>
        )}
      </EBox>
      {type === 'image' && (
        <ImageDialog
          url={image}
          open={open}
          onClose={handleClose}
          type={type}
          
        />
      )}
      {isIframe && type !== 'image' &&(
        <PDFViewerModal
          open={open}
          onClose={handleClose}
          pdfId='-1'
          pdfUrl={image}
          skipLoad={true}
        />
      )}
    </>
  )
}

export default EdifyAttachmentViewer
