import React, { useEffect, useState } from 'react'
import {
  APIProvider,
  Map,
  MapMouseEvent,
  Marker,
  useMapsLibrary,
} from '@vis.gl/react-google-maps'
import AutoComplete from './AutoComplete'
import { GOOGLE_MAPS_API_KEY } from '../../../config'
import { useMap } from 'usehooks-ts'
import usePlacesService from '../../../core/hooks/usePlacesService'
import { EdifyFieldLabel } from '../../components/form'
import { EdifyButton } from '../../components/buttons'
import { Box } from '@mui/material'
import { map } from '@firebase/util'

export type AutocompleteMode = { id: string; label: string }
export type LatLong = { lat: string; lng: string } | null
interface Props {
  address: string
  setAddress?: (str: string) => void
  setName?: (str: string) => void
  setLatLng?: (ltnLng: any) => void
  latLng: any
  disabled?: boolean
}
const defaultLatLng = { lat: 40.792661, lng: -112.294172 }
const MapDemo: React.FC<Props> = ({
  address,
  setAddress,
  setName,
  setLatLng,
  latLng,
  disabled = false,
}) => {
  // const [selectedPlace, setSelectedPlace] =
  //   useState<google.maps.places.PlaceResult | null>(null)
  const [showAdd, setShowAdd] = useState(true)

  const onPlaceSelect = (e: any) => {
    if (!e.geometry) return
    if (!e.geometry.location) return
    const lat = e.geometry.location.lat()
    const lng = e.geometry.location.lng()

    if (!setAddress || !setName || !setLatLng) return
    setAddress(e.formatted_address)
    setName(e.name)
    setLatLng({ lat, lng })
   
  }

  const onMapClicked = (e: MapMouseEvent) => {
    if (!setLatLng) return
    if (!e.detail) return
    setLatLng(e.detail.latLng)
  

    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ location: e.detail.latLng }, (results, status) => {
      if (status === 'OK' && results) {
        onPlaceSelect(results[0])
        const mapLocation = {
          center: {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lat(),
          },
          markerName: results[0].formatted_address,
          name: results[0].address_components[0].short_name,
        }
        setAddress && setAddress(mapLocation.markerName)
        setName && setName(mapLocation.name)
        setShowAdd(true)
      } else {
        alert('Geocode was not successful for the following reason: ' + status)
      }
    })
  }
  return (
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
      <EdifyFieldLabel label='Address' />
      {/* {!disabled && (
        <Box sx={{ margTop: '-24px' }}>
          <EdifyButton
            noBackground
            onClick={() => setShowAdd(true)}
            primary={showAdd}
            secondary={!showAdd}
            title='Select location on map'
            // sx={{ marginTop: '0px' }}
          />
          <EdifyButton
            noBackground
            onClick={() => setShowAdd(false)}
            primary={!showAdd}
            secondary={showAdd}
            title='Search for  location'
          />
        </Box>
      )} */}

      <AutoComplete
        disabled={disabled}
        onPlaceSelect={onPlaceSelect}
        address={address}
        showAdd={showAdd}
        setShowAdd={setShowAdd}
      />
      <div style={{ width: 'auto', height: '300px', marginTop: '24px' }}>
        <Map
          defaultZoom={latLng ? 13 : 2}
          defaultCenter={latLng ? latLng : defaultLatLng}
          gestureHandling={'greedy'}
          onClick={onMapClicked}
        >
          {latLng && <Marker position={latLng} />}
        </Map>
      </div>
      {/* <MapHandler place={selectedPlace} /> */}
      {/* <MyComponent /> */}
    </APIProvider>
  )
}
export default MapDemo

