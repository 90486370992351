import { Failure } from './Failure'

export class StatusFailure extends Failure {
  readonly status: number

  constructor(status: number, message: string) {
    super(message, status)
    this.status = status
  }
}

export class Failure500 extends StatusFailure {
  constructor() {
    super(500, 'Server error! Please try again later.')
  }
}

export class FailureAuthentication extends StatusFailure {
  constructor(statusCode: number) {
    super(statusCode, 'Authentication error! Please try again later')
  }
}
