import { Box, Typography } from '@mui/material'
import React from 'react'
import { ROUTE_NOTIFICATIONS } from './notifications/NotificationsPage'
import { useLocation, useNavigate } from 'react-router-dom'
import PageContainer from '../components/page/PageContainer'
import { EdifyButton } from '../components/buttons'

import ErrorContainer from '../components/error/ErrorContainer'
import { AppColors } from '../Theme'
import { ETypography } from '../components/fonts/ETypography'
import { ROUTE_DASHBOARD } from './dashboard/DashboardPage'

//TODO: NEED ACTUAL DESIGN FOR THIS PAGE
const NotFoundPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { from } = location.state || { from: { pathname: '/' } }

  // coming from the /notifications page show a back link
  const isFromNotification = () => {
    if (!from || from.pathname !== ROUTE_NOTIFICATIONS) return
    return (
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: 32,
        }}
      >
        <ErrorContainer>
          <Typography>
            It seems this link is either broken or the page no longer exists
          </Typography>
          <EdifyButton
            noBackground
            title='Go back to notifications'
            onClick={() => navigate(-1)}
          />
        </ErrorContainer>
      </Box>
    )
  }

  return (
    <PageContainer title='Page Not Found.'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: { xs: '50px', md: '100px', lg: '170px' },
            lineHeight: { xs: '61px', md: '121px', lg: '212px' },
            color: AppColors.neutral600,
            marginBottom: '16px',
          }}
        >
          404
        </Typography>
        <Typography sx={{ fontSize: '40px', color: '#272d37' }}>
          Something went wrong...
        </Typography>
        <ETypography color='gray400' font='SM' sx={ { mt:'8px',marginBottom: '48px' }}>
          Sorry, we cannot find the page your are looking for.
        </ETypography>
        <EdifyButton
          primary
          title='Return To Home'
          onClick={() => navigate(ROUTE_DASHBOARD)}
        />
      </Box>
    </PageContainer>
  )
}

export default NotFoundPage
