import { Box, LinearProgress, Typography } from '@mui/material'
import React from 'react'
import { AppColors, AppFonts } from '../../../../src/presentation/Theme'
import { ToastCloseIcon } from '../../../core/components/icons/toast/ToastCloseIcon'
import { ToastSuccessIcon } from '../../../core/components/icons/toast/ToastSuccessIcon'
import { ToastErrorIcon } from '../../../core/components/icons/toast/ToastErrorIcon'
import { ToastLoadIcon } from '../../../core/components/icons/toast/ToastLoadIcon'

export enum ToastStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
}
export interface ToastProps {
  status: ToastStatus
  message: string
  onClose: () => void
}

const defaultStyles = {
  position: 'fixed',
  top: 57,
  right: 23,
  minWidth: 365,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderRadius: '3px',
  padding: '12px 16px',
  alignItems: 'center',
  zIndex: '2000',
}

const statusStyles = {
  success: {
    border: `1.5px solid ${AppColors.success700}`,
    background: AppColors.success50,
  },
  error: {
    border: `1.5px solid ${AppColors.d300}`,
    background: '#FEF6F6',
  },
  loading: {
    border: `1.5px solid ${AppColors.neutral700}`,
    background: AppColors.baseWhite,
  },
}

const Toast: React.FC<ToastProps> = ({
  status = ToastStatus.SUCCESS,
  message,
  onClose,
}) => {
  const styles = {
    ...defaultStyles,
    ...statusStyles[status],
  }
  const getLeftIcon = () => {
    if (status === 'success') return <ToastSuccessIcon />
    if (status === 'error') return <ToastErrorIcon />
    return <ToastLoadIcon />
  }
  return (
    // <Fade in={showToast} timeout={1000}>
    <Box sx={styles}>
      {getLeftIcon()}
      <Box sx={{ marginLeft: '17px', flexGrow: 1 }}>
        <Typography sx={{ ...AppFonts.textButtonSemiboldSmall }}>
          {message}
        </Typography>
        {/* if we do end up doing a dynamic loading UI
        https://codesandbox.io/s/x159xz?file=/ */}
        {status == 'loading' && (
          <Box sx={{ width: '90%' }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
      <ToastCloseIcon onClose={onClose} />
    </Box>
    // </Fade>
  )
}

export default Toast

export interface IndividualToastProps {
  message: string
  onClose: () => void
}

export const SuccessToastComponent: React.FC<IndividualToastProps> = ({
  message,
  onClose,
}) => <Toast data-testid='SuccessToast' status={ToastStatus.SUCCESS} message={message} onClose={onClose} />

export const ErrorToastComponent: React.FC<IndividualToastProps> = ({
  message,
  onClose,
}) => <Toast data-testid='ErrorToast' status={ToastStatus.ERROR} message={message} onClose={onClose} />

export const LoadingToastComponent: React.FC<IndividualToastProps> = ({
  message,
  onClose,
}) => <Toast data-testid='LoadingsToast' status={ToastStatus.LOADING} message={message} onClose={onClose} />
