import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridBadge, GridItem, GridStackItem } from '../gridItems'
import { Avatar } from '@mui/material'
import { IContractor } from '../../../../domain/interfaces/IContractor'
import { IContact } from '../../../../domain/interfaces/IContact'

export const contact_columns: (
  l0Name: string,
  l1Name: string,
) => GridColDef[] = (l0Name, l1Name) => {
  return [
    {
      field: 'fullName',
      renderHeader: () => <GridItem text='Name' />,
      flex: 3,
      renderCell: ({ row }) => (
        <GridStackItem
          topText={row.fullName}
          bottomText={row.email}
          avatar={() => <Avatar alt={`${row.fullName}`} sx={{ mr: 10 }} />}
        />
      ),
    },
    {
      field: 'trade',
      renderHeader: () => <GridItem text='Trade' />,
      width: 215,
      renderCell: ({ row }) => <GridItem text={row.trade} />,
    },
    {
      field: 'projects',
      renderHeader: () => <GridItem text={l0Name} />,
      flex: 2,
      renderCell: ({ row }) => {
        // THIS IS BREAKING TEST

        if (!row.projects) return
        const projects = row.projects
        let displayProjects =
          projects.length > 2
            ? [...projects.slice(0, 2), `+ ${projects.length - 2} more`]
            : projects


        displayProjects = displayProjects.map((proj: string, i: number) => (
          <GridBadge key={i} text={proj ? proj.toString() :''} />
        ))

        return displayProjects
      },
    },
  ]
}
export const normalizeContactsForGrid: any = (contacts?: IContact[]) => {
  if (!contacts) return []

  return contacts?.map((contact: IContact) => {
    return {
      id: contact.id,
      fullName: contact.fullName,
      email: contact.email || '',
      // CHECK WHEN DB IS UPDATED
      projects: contact.projects ? contact.projects.map((p) => p.name) : [],
      trade: contact.trade || '',
    }
  })
}
