import { ButtonProps, Grid, SxProps } from '@mui/material'
import React from 'react'

import { SelectButton } from '../../buttons/SelectButton/SelectButton'

interface CheckboxButtonProps extends ButtonProps {
  buttonGroup: string[] | { name: string; description: string }[]
  // buttonGroup: string[] | {name:string, description:string}[]
  selectedButton?: string
  setSelectedButton: (selectedButton: string) => void
  title?: string
  sx?: SxProps
  readOnly?: boolean
  disabled?: boolean
}

export const EdifyButtonGroup: React.FC<CheckboxButtonProps> = ({
  setSelectedButton,
  buttonGroup,
  selectedButton,
  title,
  sx,
  readOnly,
  disabled,
}) => {
  const group: { name: string; description: string | null }[] = buttonGroup.map(
    (b) => {
      if (typeof b === 'string') {
        return { name: b, description: null }
      }
      return b
    },
  )
  return (
    <Grid container gap='16px' spacing={2} sx={{ marginTop: '8px' }}>
      {group.map((button) => (
        <SelectButton
          disabled={disabled}
          key={button.name}
          title={button.name}
          selectedButton={selectedButton}
          onClick={() => setSelectedButton(button.name)}
          description={button.description}
        />
      ))}
    </Grid>
  )
}
