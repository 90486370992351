import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridItem, GridStackItem } from '../gridItems'
import { Avatar } from '@mui/material'
import { IContractor } from '../../../../domain/interfaces/IContractor'
import { dateToMMMDYYYY } from '../../../../core/core'

export const contractor_columns: (l0Name: string, l1Name:string) => GridColDef[] = (
  l0Name,
  l1Name,
) => {
  return [
    {
      field: 'name',
      renderHeader: () => <GridItem text='Contractor Name' />,
      flex: 1,
      renderCell: ({ row }) => (
        <GridStackItem
          topText={row.name}
          bottomText={row.pointOfContactEmail}
          avatar={() => <Avatar alt={`${row.name}`} sx={{ mr: 10 }} />}
        />
      ),
    },
    {
      field: 'pointOfContactName',
      renderHeader: () => <GridItem text='Primary Point of Contact' />,
      flex: 1,
      renderCell: ({ row }) => <GridItem text={row.pointOfContactName} />,
    },

    {
      field: 'trade',
      renderHeader: () => <GridItem text='Trade' />,
      width: 215,
      renderCell: ({ row }) => <GridItem text={row.trade} />,
    },
  ]
}
export const normalizeContractorsForGrid: any = (
  contractors?: IContractor[],
) => {
  if (!contractors) return []

  return contractors?.map((contractor: IContractor ) => {
    return {
      id: contractor.id,
      name: contractor.name,
      pointOfContactEmail: contractor.pointOfContactEmail || 'NA',
      pointOfContactName: contractor.pointOfContactName || 'NA',

      trade: contractor.trade || 'NA',
      // lastLogin: getTimeAgo(contractor.lastLogin ?? null),
      lastLogin: dateToMMMDYYYY(contractor.lastLogin ?? ''),
    }
  })
}
