// ED-1387 https://edifyai.atlassian.net/browse/ED-1387
// Just an outline will most likely be refactored
import React, { useContext, useEffect, useState } from 'react'
import {
  deleteProject,
  favoriteProject,
  getLocations,
  getProjectById,
  unfavoriteProject,
} from '../../../domain/domain'
import { IProject, IProjectCustomField } from '../../../domain/interfaces/IProjects'
import { showConfirmationDeleteMessage } from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import useQuery from '../../../core/hooks/useQuery'
import { DEFAULT_LIMIT } from '../../components/datagrid/EdifyDataGrid'
import { IUser, IUserList } from '../../../domain/interfaces/IUser'
import { getUsers } from '../../../data/data'
import { Either, Failure } from '../../../core/core'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { normalizeProjectUsers } from '../../components/datagrid/gridData/projectUsers'
import { ILocation } from '../../../domain/interfaces/ILocation'
import { ROUTE_PROJECTS } from '../projects/ProjectsPage'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

export interface ProjectPageViewModelProps {
  getProject: () => void
  project: IProject | undefined
  projectLoading: boolean
  projectError: string | undefined
  showDeleteConfirmation: (ids: string[]) => void
  projectName: string
  setFavorite: () => void
  unSetFavorite: () => void
  pointOfContact: IDemoPointOfContact | undefined
  loadMoreUsers: (paginationModel: GridPaginationModel) => void
  users: IUser[]
  usersLoading: boolean
  usersError: string | undefined
  totalUsers: number
  getProjectUsers: () => void
  sortClickedHandler: (sorter: GridSortModel) => void
  normalizeProjectUsersForDataGrid: () => any
  locations: ILocation[] | undefined
  locationsLoading: boolean
  locationsError: string | undefined
  totalLocations: number
  lSortClickedHandler: (sorter: GridSortModel) => void
  loadMoreLocations: (paginationModel: GridPaginationModel) => void
  setLocations: (locations: ILocation[]) => void
  loadLocations: () => void
  customFields: { label: string; value: string; key: string }[]
  pointOfContactLabel: string
  level0Name: string
}

interface IDemoPointOfContact {
  id?: string
  fullName: string
  email: string
  phone: string
  role?: string | undefined //doesn't exist yet on api call
}

export const transformCustomFields = (
  customFields: IProjectCustomField[], 
  project?: Record<string, any>
): { label: string; value: string; key: string, isRequired:boolean, isHidden:boolean }[] => {
  if(!customFields) return []
  return customFields.filter(cf => cf.key != 'pointOfContact').map(field => ({
    label: field.label,
    value: project ? project[field.key] : '',
    key: field.key,
    isHidden: field.isHidden,
    isRequired: field.isRequired
  }))
}

const ProjectPageContext =
  React.createContext<ProjectPageViewModelProps | null>(null)

export function useProjectPageViewModel(): ProjectPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(ProjectPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
  projectIdProp?: string
}

export const ProjectPageViewModel: React.FC<Props> = ({
  children,
  projectIdProp,
}) => {
  const { id, levelId } = useParams()
  const projectId = projectIdProp ?? id
  const navigate = useNavigate()

  const queryHelper = useQuery({
    ...(levelId ? { levelId: levelId } : {}),
    skip: 0,
    limit: DEFAULT_LIMIT,
    projectId: projectId,
  })
  const { query, getQueryStringFromQuery, setNew } = queryHelper
  const { projectCustomFields, getHierarchyName  } = useOrganizationProvider()
  const level0Name = getHierarchyName(0, false)

  //STATE
  const [project, setProject] = useState<IProject>()
  const [projectLoading, setProjectLoading] = useState<boolean>(false)
  const [projectError, setProjectError] = useState<string | undefined>()

  const [customFields, setCustomFields] = useState<{ label: string; value: string; key: string }[]>([])

  const [users, setUsers] = useState<IUser[]>([])
  const [usersLoading, setUsersLoading] = useState<boolean>(false)
  const [usersError, setUsersError] = useState<string | undefined>()
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const [pointOfContact, setPointOfContact] = useState<
    IDemoPointOfContact | undefined
  >(undefined)
  const [pointOfContactLabel, setPointOfContactLabel] = useState<string>('Point of Contact')

  const [locations, setLocations] = useState<ILocation[]>([])
  const [locationsLoading, setLocationsLoading] = useState<boolean>(false)
  const [locationsError, setLocationsError] = useState<string | undefined>()
  const [totalLocations, setTotalLocations] = useState<number>(0)
  const locationsQueryHelper = useQuery({
    skip: 0,
    limit: DEFAULT_LIMIT,
    projectId,
  })

  const {
    query: lQuery,
    getQueryStringFromQuery: lGetQueryStringFromQuery,
    setNew: lSetNew,
  } = locationsQueryHelper
  const loadLocations = async () => {
    setLocationsError(undefined)
    setLocationsLoading(true)
    setLocations([])
    fetchLocations(0, query)
  }

  const fetchLocations = async (
    fetchSkip: number,
    query: Record<string, any>,
  ) => {
    setLocationsError(undefined)
    setLocationsLoading(true)
    setLocations([])
    
    const newQuery = { ...query, skip: fetchSkip }
    
    const res = await getLocations(lGetQueryStringFromQuery(newQuery))
    setLocationsLoading(false)
    if (res.isLeft()) {
      setLocationsError(res.left?.message ?? 'Error Occurred.')
      return
    }
    setTotalLocations(res.right?.total_count || 0)
    setLocations(res.right?.data as ILocation[])
  }

  const lSortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    const newQuery = {
      ...lQuery,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    }
    fetchLocations(0, newQuery)
  }

  const loadMoreLocations = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchLocations(fetchSkip, lQuery)
  }

  // Matches levels delete logic
  const handleDelete = async () => {
    const res = await deleteProject(project!.id)
    if (res.isLeft()) {
      ErrorToast({ title: `Failed to delete ${project!.name}.` })
      return
    }
    SuccessToast({ title: `${project!.name} successfully deleted.` })
    navigate(ROUTE_PROJECTS)
  }

  const showDeleteConfirmation = async () => {
    showConfirmationDeleteMessage(getProjectName(), handleDelete)
  }

  const getProjectName = () => {
    if (!project) return ''
    return project.name
  }

  const setFavorite = async () => {
    const name = getProjectName()
    const res = await favoriteProject(project!.id)
    if (res.left) {
      ErrorToast({
        title: `Error adding ${name} to favorites.
      `,
      })
      return
    }
    SuccessToast({ title: `${name} has been added to favorites successfully.` })
    setProject({ ...(project as IProject), favorited: true })
  }

  const unSetFavorite = async () => {
    const name = getProjectName()
    const res = await unfavoriteProject(project!.id)
    if (res.left) {
      ErrorToast({
        title: `${name} has been removed from favorites.`,
      })
      return
    }
    SuccessToast({ title: `${name} has been removed from favorites.` })
    setProject({ ...(project as IProject), favorited: false })
  }

  const getProject = async () => {
    setProjectError(undefined)
    setProjectLoading(true)
    const res = await getProjectById(projectId!)
    setProjectLoading(false)
    if (res.isLeft()) {
      setProjectError('Error loading project')
    }
    if (res.right?.pointOfContact) {
      setPointOfContact(res.right?.pointOfContact)
    }
    const cf = transformCustomFields(projectCustomFields!, res.right)
    const pointOfContactField = projectCustomFields?.find(cf => cf.key === 'pointOfContact')
    if(pointOfContactField) setPointOfContactLabel(pointOfContactField.label)
    setCustomFields(cf)
    setProject(res.right as IProject)
  }

  ///USER THAT BELONG TO PROJECT
  const getProjectUsers = async () => {
    setUsersError(undefined)
    setUsersLoading(true)
    setUsers([])
    fetchUsers(0, query)
  }

  const fetchUsers = async (fetchSkip: number, query: Record<string, any>) => {
    setUsersError(undefined)
    setUsersLoading(true)

    const newQuery = { ...query, skip: fetchSkip }

    const res: Either<Failure, IUserList> = await getUsers(
      getQueryStringFromQuery(newQuery),
    )
    setUsersLoading(false)
    if (res.isLeft()) {
      setUsersError(res.left?.message ?? 'Error Occurred.')
      return
    }
    setTotalUsers(res.right?.total_count || 0)
    setUsers(res.right?.data as IUser[])
    setNew(newQuery)
  }

  const loadMoreUsers = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchUsers(fetchSkip, query)
  }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    const newQuery = {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    }
    fetchUsers(0, newQuery)
  }

  const normalizeProjectUsersForDataGrid: any = () => {
    return normalizeProjectUsers(users)
  }


  useEffect(() => {
    //new project case
    if(!projectId && !id) {
      const cf =  transformCustomFields(projectCustomFields!)
      setCustomFields(cf)
      return
    }
    getProject()
    // done need to load users
    if (projectIdProp) return
    getProjectUsers()
  }, [id, projectId])

  useEffect(() => {
    if (!projectId) return
    loadLocations()
  }, [projectId])

  return (
    <ProjectPageContext.Provider
      value={{
        project,
        projectLoading,
        projectError,
        projectName: getProjectName(),
        getProject,
        showDeleteConfirmation,
        setFavorite,
        unSetFavorite,
        pointOfContact,
        loadMoreUsers,
        users,
        usersLoading,
        usersError,
        totalUsers,
        getProjectUsers,
        sortClickedHandler,
        normalizeProjectUsersForDataGrid,
        locations,
        locationsLoading,
        locationsError,
        totalLocations,
        lSortClickedHandler,
        loadMoreLocations,
        setLocations,
        loadLocations,
        customFields,
        pointOfContactLabel,
        level0Name
      }}
    >
      {children}
    </ProjectPageContext.Provider>
  )
}
export default ProjectPageContext
