import { Either, Failure, Left } from '../../../core/core'
import { correctiveActionUpdate } from '../../../data/data'
import {
  createContact,
  deleteContact,
  editContact,
  getContacts,
  searchContacts,
  getContactById,

} from '../../../data/repositories/contacts/ContactsRepository'
import { deleteContacts } from '../../../data/repositories/contacts/ContactsRepository'
import { contactParams } from '../../../presentation/pages/contact/ContactPageViewModel'
import { IContact, IContactCreate, IContactDeleted, IContactList  } from '../../interfaces/IContact'
import { ETrade } from '../../interfaces/IContractor'


export async function getAllContacts(queryString:string): Promise<Either<Failure, IContactList>> {
  return await getContacts(queryString)
}

export async function getSpecificContact(id:string): Promise<Either<Failure, IContact>> {
  return await getContactById(id)
}



export async function adminCreateContact(contact: contactParams, contractorId: string): Promise<Either<Failure, IContact>> {
  return await createContact(contact, contractorId)
}

export async function adminEditContact(contact:contactParams,
  contactId: string,
): Promise<Either<Failure, IContact>> {
  return await editContact(contact, contactId)
}

export async function adminDeleteContact(
  contactId: string,
): Promise<Either<Failure, IContact>> {
  return await deleteContact(contactId)
}

export async function contactsSearch(
  skip: number,
  limit?: number,
  key?: string,
): Promise<Either<Failure, IContactList>> {
  return await searchContacts(skip, limit, key)
}

export async function adminDeleteContacts(
  contactIds: string[],
): Promise<Either<Failure, IContactDeleted[]>> {
  return await deleteContacts(contactIds)
}