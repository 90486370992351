import React from 'react'
import InnerPageContainer, {
  InnerPageContainerProps,
} from '../../inner-page-container/InnerPageContainer'
import { AppColors, AppFonts } from '../../../Theme'
import { Box, Checkbox, FormGroup, Typography } from '@mui/material'
import EdifyToggle from '../../form/EdifyToggle/EdifyToggle'
import FormErrorText from '../../form/FormErrorText'

interface NotificationsSettingsProps extends InnerPageContainerProps {
  emailChecked: boolean
  setEmailChecked: (b: boolean) => void
  smsChecked: boolean
  setSMSChecked: (b: boolean) => void
  notificationError?: string
  SMSTermsChecked: boolean
  setSMSTermsChecked: (b: boolean) => void
  SMSTermsChanged: boolean
  setSMSTermsChanged: (b: boolean) => void
}

const NotificationsSettings: React.FC<NotificationsSettingsProps> = ({
  emailChecked,
  setEmailChecked,
  smsChecked,
  setSMSChecked,
  notificationError,
  SMSTermsChecked,
  setSMSTermsChecked,
  SMSTermsChanged,
  setSMSTermsChanged,
  ...rest
}) => {
  const smsChanged = () => {
    setSMSTermsChanged(true)
    setSMSChecked(!smsChecked)
  }
  return (
    <Box
      sx={{
        paddingTop: '24px',
        border:'none'
      }}
      {...rest}
    >
      <Box
        style={{
          maxWidth: '512px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{ ...AppFonts.textMSemibold, color: AppColors.gray700 }}
        >
          NOTIFICATION SETTINGS
        </Typography>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <EdifyToggle
          data-testid='EmailNotification'
          text='Email Notifications'
          subText='I want to receive Email notifications'
          checked={emailChecked}
          onChange={() => setEmailChecked(!emailChecked)}
        />
        <EdifyToggle
          data-testid='SMSNotification'
          text='SMS Notifications'
          subText='I want to receive SMS notifications'
          checked={smsChecked}
          onChange={smsChanged}
        />
        {/* TWILIO UNCOMMENT AFTER TESTED */}
        {smsChecked && SMSTermsChanged && (
          <FormGroup>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '12px',
                marginLeft: '-12px',
              }}
            >
              <Checkbox
                required
                value={SMSTermsChecked}
                onChange={() => setSMSTermsChecked(!SMSTermsChecked)}
                sx={{ marginLeft: '0px' }}
              />
              <Typography
                sx={{ ...AppFonts.textXSMedium, color: AppColors.gray50 }}
              >
                By checking this box, I agreed to receive SMS notification and
                one time password (OTP) from Safetymojo.ai to the phone number
                provided above. I understand I will receive a new OTP message
                each time when I enter my registered phone number. Message/data
                rates apply.
              </Typography>
            </Box>
            {!SMSTermsChecked && <FormErrorText>required for sms messaging</FormErrorText>}
          </FormGroup>
        )}
      </Box>
      {/* IF WE WANT REQUIRED MESSAGE FOR CHECKBOX */}
      {/* {notificationError && <FormErrorText>{notificationError}</FormErrorText>} */}
    </Box>
  )
}
export default NotificationsSettings
