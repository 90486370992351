import React from 'react'
import { Box, SxProps } from '@mui/material'

interface IEdifyFormFieldProps {
  children: React.ReactNode
  sx?: SxProps
}

export const EdifyFormField: React.FC<IEdifyFormFieldProps> = ({
  children,
  sx,
}) => {
  return <Box sx={{ marginTop: '24px', ...sx }}>{children}</Box>
}
