export function saveToLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value)
}

export function getFromLocalStorage(key: string): string | null {
  return localStorage.getItem(key)
}

export function removeFromLocalStorage(key: string) {
  localStorage.removeItem(key)
}
