import { Either, Failure } from '../../../core/core'
import { IVerifyOTPPayload } from '../../../domain/interfaces/IUserDetails'
import {
  getFromLocalStorage,
  getInitialToken,
  httpPost,
  removeFromLocalStorage,
  saveToLocalStorage,
  setAuthToken,
} from '../../data'
import globalI18n from '../../../i18n'
import { ISSOResponse } from '../../../domain/interfaces/IPerson'
import { clearOrg } from '../orgs/OrgRepository'

export function isLoggedIn(): boolean {
  const loggedInPerson: string = getFromLocalStorage('person') ?? ''

  return loggedInPerson != '' ?? false
}

export async function loginRepo(param: {
  email?: string
  phone?: string
}): Promise<Either<Failure, boolean>> {
  if (param.email === undefined && param.phone === undefined) {
    throw new Error('[email] or [phone] should be set.')
  }

  return httpPost<boolean>('sample', {})
}

export async function requestOtp(param: {
  email?: string
  phone?: string
}): Promise<Either<Failure, ISSOResponse | boolean>> {
  if (param.email === undefined && param.phone === undefined) {
    throw new Error('[email] or [phone] should be set.')
  }

  const data =
    param.email != undefined ? { email: param.email } : { phone: param.phone }
  const res: Either<Failure, ISSOResponse | boolean> = await httpPost<ISSOResponse | boolean>(
    '/requestOTP',
    data,
  )

  return res
}

export async function verifyOtp(
  param: {
    email?: string
    phone?: string
  },
  pin: string,
): Promise<Either<Failure, IVerifyOTPPayload>> {
  if (param.email === undefined && param.phone === undefined) {
    throw new Error('[email] or [phone] should be set.')
  }

  const data =
    param.email != undefined
      ? { email: param.email, pin: pin }
      : { phone: param.phone, pin: pin }

  const res: Either<Failure, IVerifyOTPPayload> =
    await httpPost<IVerifyOTPPayload>('/verifyOTP', data)

  if (res.isRight() && res.right) {
    globalI18n.changeLanguage(res.right.language ?? 'en')
    saveToLocalStorage('person', JSON.stringify(res.right))
  }

  return res
}

export function logout(): boolean {
  setAuthToken('')
  removeFromLocalStorage('person')
  removeFromLocalStorage('authToken')
  clearOrg()
  return true
}

export function currentPerson(): IVerifyOTPPayload | undefined {
  const rawUser = getFromLocalStorage('person')
  return rawUser != null ? JSON.parse(rawUser) : undefined
}

// pass thru function to get the token from RemoteSource
export function getToken(): string {
  return getInitialToken()
}
