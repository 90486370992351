import React, { ReactNode } from 'react'
import { SxProps } from '@mui/system'
import { ETypography } from '../../fonts/ETypography'
import InnerPageContainer from '../../inner-page-container/InnerPageContainer'
import EdifyTooltip from '../../button-dropdown/EdifyTooltip'
import { Box } from '@mui/material'

interface FormSectionProps {
  title: string
  children: ReactNode
  sx?: SxProps
  tooltipText?: string
}

const FormSection: React.FC<FormSectionProps> = ({
  title,
  children,
  sx,
  tooltipText,
}) => {
  return (
    <InnerPageContainer
      sx={{ padding: '24px', marginBottom: '32px', ...sx }}
      header={
        <Box sx={{ display: 'flex', alignItems:'center' }}>
          <ETypography font='HXXSDS' color='gray700'>
            {title}{' '}
          </ETypography>
          {tooltipText && (
            <EdifyTooltip sx={{ marginLeft: '16px' }} text={tooltipText} />
          )}
        </Box>
      }
    >
      {children}
    </InnerPageContainer>
  )
}
export default FormSection
