import { useEffect, useState } from 'react'
import { IForm } from '../../domain/interfaces/IForm'
import {
  createSubmission,
  getForm,
  updateSubmission,
} from '../../domain/domain'
import { getSubmissionById } from '../../data/data'
import { addOrgIdAndTokenToFormComponents } from '../../presentation/pages/submission/submissionHelpers'
import { updateFormComponentsWithOrgFields } from '../../presentation/components/panels/form-renderer/CustomFormioComponents'
import { ISubmission } from '../../domain/interfaces/ISubmission'
import {
  ErrorToast,
  SuccessToast,
} from '../utils/toast-notifications/ToastNotifications'
import { socImageStringReplace } from '../utils/formio-formatters/FormIOFormatter'
import { Formio } from '@formio/react'

export interface IFormSubmitter {
  updateForms: (form: IForm, submission: ISubmission | undefined) => void
  additionalFormSubmissions: any
  setAdditionalFormSubmissions: any
  toggleAdditionalForm: (formId: string, checked: boolean) => void
  linkFormsState: any
  toggleLinkedForms: (
    formId: string,
    title: string,
    submissionIds: string[],
  ) => void
  getSubmissionData: any
  setDataCallback: any
  handleSubmit: any
  handleUpdate: any
  loading: boolean
  isSubmitting: boolean
  getSubmissionIds: () => string
}

//Loads form, gets it ready for View/Create/Edit (no Submission functionality)
const useFormSubmitter = (
  parentForm: IForm,
  formReadOnlyJson: IForm,
  orgId: string,
  level1Name: string,
  level0Name: string,
  submission?: ISubmission,
): IFormSubmitter => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [additionalFormSubmissions, setAdditionalFormSubmissions] = useState<
    any[]
  >([])
  const [reset, setReset] = useState<boolean>(false)
  const [linkFormsState, setLinkFormState] = useState<any>([])
  const getOrgSpecificElements = async (form: IForm) => {
    updateFormComponentsWithOrgFields(form, level1Name, level0Name)
  }

  const setDataCallback = (formId: string, data: any) => {
    setAdditionalFormSubmissions((prevState) => {
      const index = prevState.findIndex((f: any) => f.id == formId)
      if (index < 0) {
        return prevState
      }
      // replace data of form at index
      const newForm = { ...prevState[index], data }
      const newAdditionalFormSubmissions = [
        ...prevState.slice(0, index),
        newForm,
        ...prevState.slice(index + 1),
      ]

      return newAdditionalFormSubmissions
    })
  }

  const fetchForm = async (formId: string) => {
    const res = await getForm(formId)
    if (res.left) {
      // TODO handle error
      return
    }
    await getOrgSpecificElements(res.right!)
    const updateFormJson = addOrgIdAndTokenToFormComponents(
      res.right!,
      level1Name,
      level0Name,
      orgId,
    )
    const formReadOnlyJson = addOrgIdAndTokenToFormComponents(
      res.right!,
      level1Name,
      level0Name,
      orgId,
      true,
    )
    const newForm = {
      id: formId,
      title: res.right?.title,
      isPostSubmission: false,
      form: updateFormJson,
      formReadOnly: formReadOnlyJson,
      data: {},
    }
    return newForm
  }

  const fetchSubmissions = async (submissionIds: string[]) => {
    const cb = submissionIds.map(async (submissionId: string) => {
      return await getSubmissionById(submissionId)
    })
    const res = await Promise.all(cb)

    if (res.some((r) => r.isLeft())) {
      // TODO handle error
      return
    }
    return res.map((r) => r.right)
  }

  const toggleAdditionalForm = async (
    formId: string,
    checked: boolean,
    submissionId?: string,
  ) => {
    if (checked) {
      setAdditionalFormSubmissions((prevState) => {
        const filtered = prevState.filter((f: any) => f.id !== formId)
        return filtered
      })
      return
    }

    const newForm = await fetchForm(formId)
    let newData = {}
    let sub: any = undefined
    if (submissionId) {
      // TODO ERROR HANDLING
      const submission = await fetchSubmissions([submissionId])
      if (submission && submission.length > 0) {
        sub = submission[0]
        if (sub && sub.data) {
          newData = socImageStringReplace(sub.data)
        }
       
      }
    }

    setAdditionalFormSubmissions((prevState) => {
      // Check if the form is already present
      const formExists = prevState.some((f: any) => f.id === formId)
      if (formExists) {
        return prevState
      }

      const newAdditionalFormSubmissions = [
        ...prevState,
        {
          ...newForm,
          data: newData,
          submission: sub,
        },
      ]
      return newAdditionalFormSubmissions
    })

    // setDataCallback(formId, newData) - Make sure this is called if needed, in the appropriate place
  }

  const toggleLinkedForms = async (
    formId: string,
    title: string,
    submissionIds: string[],
    initLoad = false,
  ) => {
    if (submissionIds.length === 0) {
      // Remove form
      setLinkFormState((prevState: any) =>
        prevState.filter((lfs: any) => lfs.id !== formId),
      )
      return
    }

    const [newForm, submissions] = await Promise.all([
      fetchForm(formId),
      fetchSubmissions(submissionIds),
    ])

    if (!newForm) {
      return
    }
    // HERE WE NEED TO REPLACE THE SOC IMAGE STRING
    const replacedSocialImageSubmissions =  socImageStringReplace(submissions)

    const newLinkedForm = {
      id: formId,
      title,
      form: newForm.form,
      formReadOnly: newForm.formReadOnly,
      submissionIds,
      submissions: replacedSocialImageSubmissions,
    }
    setLinkFormState((prevState: any) => {
      // Filter out the formId if it exists
      const filteredState = prevState.filter((lfs: any) => lfs.id !== formId)
      // Add the new form data to the filtered state
      return [...filteredState, newLinkedForm]
    })
  }
  const updateForms = async (
    form: IForm,
    submission: ISubmission | undefined,
  ) => {
    const formIndex = additionalFormSubmissions.findIndex(
      (f: IForm) => f.id == form.id,
    )
    if (formIndex > -1) {
      return
    }

    const newForm = {
      id: form.id,
      title: form.title,
      isPostSubmission: false,
      form: parentForm,
      formReadOnly: formReadOnlyJson,
      submission: submission ? submission : {},
    }
    setAdditionalFormSubmissions([...additionalFormSubmissions, newForm])
  }

  const getSubmissionData = (isUpdate: boolean, state = 'submitted') => {
    const parentForm = additionalFormSubmissions[0]
    // filter parent form from and update forms with no changes
    const additionalForms = additionalFormSubmissions.filter((f, i) => {
      // remove parent
      if (i == 0) return false
      // keep if not updated
      return true

    })

    return {
      formId: parentForm.id,
      data: parentForm.submission ? socImageStringReplace(parentForm.submission.data) : {},
      state,
      linkForms: linkFormsState.map((lf: any) => {
        return {
          formId: lf.id,
          submissionIds: lf.submissionIds,
        }
      }),
      additionalFormSubmissions: additionalForms.map((af: any) => {
        let newSubData = af.data && af.data.data ? af.data.data : af.submission ? af.submission.data : {}
        newSubData = socImageStringReplace(newSubData)
        return {
          formId: af.id,
          data: newSubData,
          state,
        }
      }),
    }
  }

  const getSubmissionIds = () => {
    if (!submission) return ''
    let afIds = ''
    let lfIds = ''
    if (submission.additionalFormSubmissions) {
      afIds = submission.additionalFormSubmissions.map((af: any) => af.id).join(';')
    }
    if (submission.linkFormSubmissions) {
      lfIds = submission.linkFormSubmissions.reduce(
        (acc: string[], lf: any) => {
          return [...acc, ...lf.submissionIds]
        },
        [],
      ).join(';')
    }
    return [submission.id, afIds, lfIds].filter(Boolean).join(';')
  }

  const handleSubmit = async (isDraft: boolean, onSuccess: any) => {
    const submissionData: any = getSubmissionData(
      false,
      isDraft ? 'draft' : 'submitted',
    )
    setIsSubmitting(true)
    const res = await createSubmission(submissionData)
    setIsSubmitting(false)
    if (res.isLeft()) {
      ErrorToast({
        title: res.left?.message ?? 'Error Occurred. Please check submission.',
      })
      const newAdditionalFormSubmissions = addErrorsToDataStructure(
        res.left,
        additionalFormSubmissions,
      )
      setAdditionalFormSubmissions(newAdditionalFormSubmissions)
      return
    }
    Formio.clearCache()
    SuccessToast({
      title: `${isDraft ? 'Draft' : 'Submission'} Created Successfully`,
    })
    onSuccess && onSuccess()
  }

  const handleUpdate = async (isDraft: boolean, onSuccess: any) => {
    const submissionData: any = getSubmissionData(true, isDraft ? 'draft' : 'submitted')
    setIsSubmitting(true)
    const res = await updateSubmission(submission!.id, submissionData)
    setIsSubmitting(false)
    if (res.isLeft()) {
      ErrorToast({
        title: res.left?.message ?? 'Error Occurred. Please check submission.',
      })
      const newAdditionalFormSubmissions = addErrorsToDataStructure(
        res.left,
        additionalFormSubmissions,
      )
      setAdditionalFormSubmissions(newAdditionalFormSubmissions)
      return
    }
    SuccessToast({
      title: 'Successfully Updated',
    })
    onSuccess && onSuccess()
  }
  const getOriginSubmission = async (originSubmissionId: string) => {
    const res = await getSubmissionById(originSubmissionId)
    if (res.isLeft()) {
      // TODO handle error
      return
    }
    const submission = res.right
    if (!submission) return

    await toggleAdditionalForm(submission.formId!, false, originSubmissionId)
  }

  // for view parent form has a submission
  const loadFormsAndSubmissions = async () => {
    if (!submission) return

    setLoading(true)
    // loads parent/viewed form
    await updateForms(parentForm, submission)

    if (submission.originSubmissionId) {
      await getOriginSubmission(submission.originSubmissionId)
      setLoading(false)
      return
    }

    const { additionalFormSubmissions, linkFormSubmissions } = submission
    if (additionalFormSubmissions && additionalFormSubmissions.length > 0) {
      const cb = additionalFormSubmissions.map(async (sub) => {
        return await toggleAdditionalForm(sub.formId, false, sub.id)
      })
      await Promise.all(cb)
    }
    if (linkFormSubmissions && linkFormSubmissions.length > 0) {
      const cb2 = linkFormSubmissions.map(async (lfs: any) => {
        return await toggleLinkedForms(
          lfs.formId,
          lfs.formTitle,
          lfs.submissionIds,
          true,
        )
      })
      await Promise.all(cb2)
    }
    setLoading(false)
  }
  useEffect(() => {
    loadFormsAndSubmissions()
    return () => {
      // TODO POTENIAL PLACE FOR SAVE FIELDS ISSUE
      // window.location.href = `/forms/submissions/${parentForm.id}`
    }
  }, [])

  return {
    updateForms,
    additionalFormSubmissions,
    setAdditionalFormSubmissions,
    toggleAdditionalForm,
    linkFormsState,
    toggleLinkedForms,
    getSubmissionData,
    setDataCallback,
    handleSubmit,
    loading,
    isSubmitting,
    getSubmissionIds,
    handleUpdate,
  }
}

export default useFormSubmitter

const addErrorsToDataStructure = (response: any, dataStructure: any) => {
  if (response && response.errors) {
    return dataStructure.map((item: any) => {
      if (response.errors[item.id]) {
        return {
          ...item,
          errors: response.errors[item.id],
        }
      }
      return item
    })
  }
  return dataStructure
}
