import React, { useContext, useEffect, useState } from 'react'
import { Either, Failure } from '../../../core/core'
import { IForm } from '../../../domain/interfaces/IForm'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  ICreateSubmission,
  ISubmission,
} from '../../../domain/interfaces/ISubmission'
import {
  createSubmission,
  getCurrentUser,
  getLevelById,
  getSubmittedFormById,
  updateSubmission as updateSubmissionAPI,
} from '../../../domain/domain'
import { EState } from '../../../domain/interfaces/ISubmission'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import {
  addOrgIdAndTokenToFormComponents,
  getErrorMessage,
  getSuccessMessage,
} from './submissionHelpers'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import {
  ROUTE_CORRECTIVE_ACTIONS,
  ROUTE_SUBMISSIONS,
} from '../submissions/SubmissionsPage'
import { updateFormComponentsWithOrgFields } from '../../components/panels/form-renderer/CustomFormioComponents'
import { showConfirmationV2Message } from '../../components/dialogs/confirmation-dialog/ConfirmationDialogV2'
import i18n from '../../../i18n'

export interface SubmissionPageViewModelProps {
  form: IForm
  loading: boolean
  error?: string
  submission?: ISubmission | undefined
  sendSubmission: (bol: boolean) => void
  submissionError: string | undefined
  formSubmissionError: string | undefined
  setSubmissionError: (err: string) => void
  onFormUpdate: (clientSubmission: ICreateSubmission) => void
  submissionPayload: ICreateSubmission | undefined
  saveSubmission: (onSuccess?: () => void) => void
  saveDraft: () => void
  onFormChange: (data: any, changed: any) => void
  updateDraft: () => void
  updateSubmission: (onSuccess?: () => void) => void
  isDataChanged: boolean
  setSubmission: (submission: ISubmission) => void
  setIsDataChanged: (bol: boolean) => void
  isValid: boolean
  isSubmitting: boolean
  setFormIOForm: (formioForm: any) => void
  getOrgSpecificElements: () => void
  disableSubmit: boolean
}

const SubmissionPageContext =
  React.createContext<SubmissionPageViewModelProps | null>(null)

export function useSubmissionPageViewModel(): SubmissionPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(SubmissionPageContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
  modalFormId?: string
}
export const SubmissionPageViewModel: React.FC<Props> = ({
  children,
  modalFormId,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { formCategory } = useParams()

  const orgProvider = useOrganizationProvider()
  const { allProjects, allLevels, hierarchies, org } = orgProvider

  ////Params state
  let { formId } = useParams() ?? modalFormId
  const { id: subId, correctiveActionFormId } = useParams()
  const isCorrectiveAction = !formId ? true : false
  if (isCorrectiveAction) {
    formId = correctiveActionFormId
  }
  const initialSubmission = location.state?.submission ?? undefined
  const originalData = initialSubmission?.data ?? {}

  //// Form state
  let formFromOrgProvider = orgProvider.forms.find(
    (f) => f.id == formId,
  ) as IForm
  if (!formFromOrgProvider) {
    formFromOrgProvider = orgProvider.categoryForms.find(
      (f) => f.id == formId,
    ) as IForm
  }

  const [form, setForm] = useState<IForm>(formFromOrgProvider)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [isValid, setIsValid] = useState<boolean>(false)
  //// submission state
  const [submissionError, setSubmissionError] = useState<string | undefined>(
    undefined,
  )
  const [submission, setSubmission] = useState<ISubmission>(initialSubmission)
  const [projectName, setProjectName] = useState<string | undefined>(undefined)
  const [level1Name, setLevel1Name] = useState<string | undefined>(undefined)

  ////Formio state
  const [submissionPayload, setSubmissionPayload] =
    useState<ICreateSubmission>()
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false)
  const [formSubmissionError, setFormSubmissionError] = useState<
    string | undefined
  >(undefined)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [formioForm, setFormIOForm] = useState<any>(undefined)

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)

  // Form and Submission can be passed through router or fetched from API.
  const loadSubmission = async () => {
    setError(undefined)
    setSubmissionError(undefined)
    setLoading(true)
    // new submission page case
    if (!subId) {
      setLoading(false)
      setSubmission({
        orgName: 'mock org name',
        ownerName: '',
        ownerEmail: '',
        ownerPhone: '',
        targetAccountId: '',
        data: {
          userAutofillSelf: getCurrentUser()?.id,
        },
        orgId: '',
        id: 'null-submission-id',
        submittedBy: 'null-submitter name',
        createdAt: 'null date',
        updatedAt: 'null date',
      })

      return
    }

    // edit/show got submission from router
    if (submission && !subId) {
      setLoading(false)
      return
    }
    // edit/show or no submission from router
    const res: Either<Failure, ISubmission> = await getSubmittedFormById(subId!)
    if (res.isLeft()) {
      setSubmissionError(res.left?.message ?? 'Unknown error occurred.')
      setLoading(false)
      return
    }
    const copySub = JSON.parse(JSON.stringify(res.right))
    if (copySub && copySub.data) {
      
      // wrong level being returned
      // res.right.data.level1 = res.right.level1Name
      if (copySub.data.level1) {
        const levelRes = await getLevelById(copySub.data.level1)
        if (levelRes.isRight()) {
          setLevel1Name(levelRes.right?.name ?? undefined)
          copySub.data.level1 = levelRes.right?.name ?? ''
        }
      }

      copySub.data.project = copySub.projectName ?? copySub.data.project ?? ''
      setProjectName(copySub.projectName ?? undefined)

      setSubmission(copySub as ISubmission)
      // setSubmissionPayload({...submissionPayload, data: copySub.data} as ICreateSubmission)

      setLoading(false)
      return
    } else {
      // Will we get here?
    }
  }

  //// EDIT FORM LOGIC
  const onFormChange = (data: any, changed: any) => {
    if (disableSubmit && changed.changed) {
      setDisableSubmit(false)
    }
    if (changed['changes'] === undefined || changed['changes'].length === 0) {
      onFormUpdate(data)
      return
    }

    const changes = changed['changes'] as Array<any>

    let hasActualChange = false
    for (let i = 0; i < changes.length; i++) {
      const detectedChange = changes[i]
      const key = detectedChange['component']['key'] as string
      const value = detectedChange['value']
      const originalDataString = originalData[key]
        ? JSON.stringify(originalData[key])
        : ''
      const valueString = value ? JSON.stringify(value) : ''

      // Need to stringify to properly compare values even though it's an object.
      if (originalDataString != valueString) {
        hasActualChange = true
        break
      }
    }
    if (hasActualChange) {
      setDisableSubmit(false)
      onChange && onChange(data)
    } else {
      onRevertChanges && onRevertChanges()
    }
  }

  const onChange = (formData: any) => {
    const dataToSubmit: ICreateSubmission = {
      formId: form?.id ?? '',
      data: formData?.data ?? {},
      state: EState.Submitted,
    }
    setIsValid(formData.isValid)
    onFormUpdate(dataToSubmit)
  }

  const onFormUpdate = (clientSubmission: ICreateSubmission) => {
    // HERE
    // THIS SHOWS THE NAMES ON UPDATE, BUT
    // BREAKS THE CALL AS IT PASSES THEM NAME
    // INSTEAD OF THE ID
    if (projectName && clientSubmission.data.project == '') {
      clientSubmission.data.project = projectName
      // need id and name here
    }
    // if (level1Name && clientSubmission.data.level1 == '') {
    //   clientSubmission.data.level1 = level1Name
    //   // need id and name here
    // }
    setSubmissionPayload(clientSubmission)
  }

  const onRevertChanges = () => {
    setIsDataChanged(false)
  }

  // SUBMIT FORM LOGIC
  const sendSubmission = async (isDraft: boolean, onSuccess?: () => void) => {
    // if no draft run FE validation
    if (!formioForm) {
      ErrorToast({
        title: 'Form not loaded',
      })
      return
    }
    if (!isDraft) {
      const isValid = formioForm.checkValidity(false, true, true, false)
      if (!isValid) {
        ErrorToast({
          title: 'Please fill out all required fields',
        })
        return
      }
    }

    if (!submissionPayload) {
      ErrorToast({
        title: 'No Change Made',
      })
      return
    }
    if (isDraft) {
      submissionPayload.state = EState.Draft
    }
    //Added submission language
    //TODO: needs to add check for speech to Text once feature is developed
    submissionPayload.submissionLanguage = i18n.language
    setIsSubmitting(true)
    const result = await createSubmission(submissionPayload)
    setIsSubmitting(false)
    if (result.isLeft()) {
      ErrorToast({
        title: getErrorMessage(isDraft, false, form?.title),
      })
      // TODO need better error from BE
      setFormSubmissionError(result.left?.message ?? 'Unknown error occurred.')
      return
    }

    SuccessToast({
      title: getSuccessMessage(isDraft, false, form?.title),
    })

    if (onSuccess) {
      onSuccess()
      return
    }
    // post submission data check
    if (result.right?.data?.postSubmissionData) {
      // stay on submission page, navigate to signature tab.
    }
    if (isCorrectiveAction) {
      navigate(`${ROUTE_CORRECTIVE_ACTIONS}`)
    } else {
      navigate(`/${formCategory}${ROUTE_SUBMISSIONS}/${formId}`, {
        state: { form },
      })
    }
  }

  // Update submission
  const sendUpdatedSubmissionData = async (
    isDraft: boolean,
    onSuccess?: () => void,
  ) => {
    if (!formioForm) {
      ErrorToast({
        title: 'Form not loaded',
      })
      return
    }
    // if no draft run FE validation
    if (!isDraft) {
      const isValid = formioForm.checkValidity(false, true, true, false)
      if (!isValid) {
        ErrorToast({
          title: 'Please fill out all required fields',
        })
        return
      }
    }
    // neither ca form nor submission form changed
    if (!submissionPayload) {
      ErrorToast({
        title: 'No Change Made',
      })
      return
    }

    submissionPayload.state = isDraft ? EState.Draft : EState.Submitted

    const project = allProjects.find(
      (p) => p.name == submissionPayload.data.project,
    )
    const level1 = allLevels.find(
      (l) => l.name == submissionPayload.data.level1,
    )
    if (project) {
      submissionPayload.data.project = project.id
    }
    if (level1) {
      submissionPayload.data.level1 = level1.id
    }
    setIsSubmitting(true)
    const result = await updateSubmissionAPI(submission.id, submissionPayload)
    setIsSubmitting(false)

    if (result.isLeft()) {
      ErrorToast({
        title: getErrorMessage(isDraft, true, form?.title),
      })
      // TODO need better error from BE
      setFormSubmissionError(result.left?.message ?? 'unknown error occurred.')
      return
    }
    SuccessToast({
      title: getSuccessMessage(isDraft, true, form?.title),
    })
    if (onSuccess) {
      onSuccess()
      return
    }
    navigate(`/${formCategory}${ROUTE_SUBMISSIONS}/${formId}`, {
      state: { form },
    })
  }
  const getOrgSpecificElements = async () => {
    if (hierarchies == undefined) {
      //do not change the form if we cannot get the hierarchy names. will use what is already in the form
      return
    }
    //find level 1 in hierarchy names
    // const level1FieldName = hierarchyNamesData.find(
    const level1FieldName = hierarchies.find(
      (element: { level: number; name: string }) => element.level == 1,
    )
    //find level 0 / project in hierarchy names
    const projectFieldName = hierarchies.find(
      (element: { level: number; name: string }) => element.level == 0,
    )

    updateFormComponentsWithOrgFields(
      form,
      (level1FieldName && level1FieldName.name) || 'Level 1',
      (projectFieldName && projectFieldName.name) || 'Project',
    )
    // setIsFinishedLoadingNames(true)
    setIsDataChanged(false)
  }

  const saveDraft = async () => {
    await sendSubmission(true)
  }

  function containsScheduler(components: any) {
    return components.some((component: any) => {
      if (component.key === 'scheduler') {
        return true
      }
      // Recursively search in nested 'components', if they exist
      if (Array.isArray(component.components)) {
        return containsScheduler(component.components)
      }
      // not a scheduler
      return false
    })
  }

  const saveSubmission = async (onSuccess?: () => void) => {
    // confirmation for forms with scheduler component
    const showSubmissionConfirmation = containsScheduler(form.components)

    if (!showSubmissionConfirmation) {
      sendSubmission(false, onSuccess)
      return
    }

    showConfirmationV2Message(
      'Are you sure you want to create a new Safety Team Meeting?  Notifications will be sent to the owner(s) and invitees.',
      () => sendSubmission(false),
      () => sendSubmission(true),
    )
  }
  const updateDraft = async () => {
    await sendUpdatedSubmissionData(true)
  }
  const updateSubmission = async (onSuccess?: () => void) => {
    await sendUpdatedSubmissionData(false, onSuccess)
  }

  useEffect(() => {
    const updateFormJson = addOrgIdAndTokenToFormComponents(
      form,
      orgProvider.getHierarchyName(1),
      orgProvider.getHierarchyName(0),
      org!.id,
    )
    setForm(updateFormJson)
    loadSubmission()
  }, [formId, subId])
  // useEffect(() => {
  //   if (form) {
  //     getOrgSpecificElements()
  //   }
  // }, [form])

  return (
    <SubmissionPageContext.Provider
      value={{
        getOrgSpecificElements,
        error,
        form,
        isDataChanged,
        loading,
        onFormChange: (data: any, changed: any) => onFormChange(data, changed),
        onFormUpdate,
        saveDraft,
        saveSubmission,
        sendSubmission,
        setIsDataChanged,
        setSubmission,
        setSubmissionError,
        submission,
        submissionError,
        submissionPayload,
        updateDraft,
        updateSubmission,
        formSubmissionError,
        setFormIOForm,
        isSubmitting,
        isValid,
        disableSubmit,
      }}
    >
      {children}
    </SubmissionPageContext.Provider>
  )
}

export default SubmissionPageContext