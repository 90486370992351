//form,
// initialSub,

import { useCallback, useEffect, useState } from 'react'
import {
  EState,
  ICreateSubmission,
  ISubmission,
} from '../../domain/interfaces/ISubmission'
import {
  ErrorToast,
  SuccessToast,
} from '../utils/toast-notifications/ToastNotifications'
import {
  createAnonSubmission,
  createSubmission,
  updateSubmission as updateSubmissionAPI,
} from '../../domain/domain'
import {
  getErrorMessage,
  getSuccessMessage,
} from '../../presentation/pages/submission/submissionHelpers'
import { showConfirmationV2Message } from '../../presentation/components/dialogs/confirmation-dialog/ConfirmationDialogV2'
import { IForm } from '../../domain/interfaces/IForm'
import { useOrganizationProvider } from '../../providers/OrganizationProvider'
import { flushSync } from 'react-dom'

export interface IUseFormioReturn {
  onFormChange: (data: any, changed: any) => void
  disableSubmit: boolean
  setFormIOForm: React.Dispatch<React.SetStateAction<any>>
  formioForm: any // You might want to replace 'any' with the actual type of 'formioForm'
  sendSubmission: (isDraft: boolean, onSuccess?: () => void) => Promise<void>
  successFullySubmitted: boolean
  formSubmissionError?: string | undefined
  isSubmitting: boolean
  saveDraft: (onSuccess?: () => void) => Promise<void>
  saveSubmission: (onSuccess?: () => void) => Promise<void>
  updateDraft: (onSuccess?: () => void) => Promise<void>
  updateSubmission: (onSuccess?: () => void) => Promise<void>
  submissionPayload: ICreateSubmission | undefined
  setSuccessFullySubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

interface IUseFormio {
  form?: IForm
  orgId?: string
  isAnon?: boolean
  isDraft?: boolean
  setDataCallback?: any
}

// formioFrom?
const useFormio = (
  formId: string,
  initialSub: ISubmission | undefined,
  options: IUseFormio,
): IUseFormioReturn => {
  const { orgId, isAnon, isDraft = false, form, setDataCallback } = options
  const orgProvider = useOrganizationProvider()
  const allProjects = orgProvider?.allProjects ?? []
  const allLevels = orgProvider?.allLevels ?? []
  const [formioForm, setFormIOForm] = useState<any>(undefined)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)
  const [successFullySubmitted, setSuccessFullySubmitted] =
    useState<boolean>(false)
  const [formSubmissionError, setFormSubmissionError] = useState<
    string | undefined
  >(undefined)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  // const [isValid, setIsValid] = useState<boolean>(false)
  const [submissionPayload, setSubmissionPayload] =
    useState<ICreateSubmission>()
  const originalData: any = initialSub ? initialSub.data : {}

  // HELPERS
  const getPreSubmissionPayload = useCallback(
    (isDraft = false) => {
      // if (!formioForm) {
      //   ErrorToast({
      //     title: 'Form not loaded',
      //   })
      //   return false
      // }
      if (!isDraft) {
        if (formioForm) {
          const isValid = formioForm.checkValidity(false, true, true, false)
          if (!isValid) {
            ErrorToast({
              title: 'Please fill out all required fields',
            })
            return false
          }
        }
      }

      let currentSubmissionPayload = submissionPayload

      if (!currentSubmissionPayload) {
        // Try to get from local storage
        try {
          const storedPayload = localStorage.getItem(
            'submissionPayload',
          )
          if (storedPayload) {
            currentSubmissionPayload = JSON.parse(storedPayload)

          }
        } catch (error) {
          console.error(
            'Error retrieving submission payload from local storage:',
            error,
          )
        }
      }

      if (!currentSubmissionPayload) {
        ErrorToast({
          title: 'No Change Made',
        })
        return false
      }

      // Use currentSubmissionPayload
      return {
        data: currentSubmissionPayload.data,
        formId: currentSubmissionPayload.formId,
        state: currentSubmissionPayload.state,
      }
    },
    [submissionPayload, formioForm],
  )

  const handleSubmissionResponse = (
    result: any,
    isDraft: boolean,
    formTitle: string,
    isUpdate: boolean,
  ) => {
    if (result.isLeft()) {
      ErrorToast({
        title: getErrorMessage(isDraft, isUpdate, formTitle),
      })
      // TODO need better error from BE
      setFormSubmissionError(result.left?.message ?? 'Unknown error occurred.')
      return false
    }

    SuccessToast({
      title: getSuccessMessage(isDraft, isUpdate, formTitle),
    })
    setSuccessFullySubmitted(true)
    return true
  }

  function containsScheduler(components: any) {
    return components.some((component: any) => {
      if (component.key === 'scheduler') {
        return true
      }
      // Recursively search in nested 'components', if they exist
      if (Array.isArray(component.components)) {
        return containsScheduler(component.components)
      }
      // not a scheduler
      return false
    })
  }

  // FORM LISTENERS

  const onFormChange = (data: any, changed: any) => {
    if (disableSubmit && changed.changed) {
      setDisableSubmit(false)
    }
    if (changed['changes'] === undefined || changed['changes'].length === 0) {
      onFormUpdate(data)
      return
    }

    const changes = changed['changes'] as Array<any>

    let hasActualChange = false
    for (let i = 0; i < changes.length; i++) {
      const detectedChange = changes[i]
      const key = detectedChange['component']['key'] as string
      const value = detectedChange['value']
      const originalDataString = originalData[key]
        ? JSON.stringify(originalData[key])
        : ''
      const valueString = value ? JSON.stringify(value) : ''

      // Need to stringify to properly compare values even though it's an object.
      if (originalDataString != valueString) {
        hasActualChange = true
        break
      }
    }
    if (hasActualChange) {
      setDisableSubmit(false)
      onChange && onChange(data)
    } else {
      // setIsDataChanged(false)
    }
  }

  const onFormUpdate = (clientSubmission: ICreateSubmission) => {
    // THIS SHOWS THE NAMES ON UPDATE, BUT
    // BREAKS THE CALL AS IT PASSES THEM NAME
    // INSTEAD OF THE ID
    // if (projectName && clientSubmission.data.project == '') {
    //   clientSubmission.data.project = projectName
    //   // need id and name here
    // }
    // if (level1Name && clientSubmission.data.level1 == '') {
    //   clientSubmission.data.level1 = level1Name
    // need id and name here
    // }
    setDataCallback && setDataCallback(formId, clientSubmission)
    const clone = JSON.parse(JSON.stringify(clientSubmission))
    setSubmissionPayload(clone)
    localStorage.setItem('submissionPayload', JSON.stringify(clone))
  }

  const onChange = (formData: any) => {
    const dataToSubmit: ICreateSubmission = {
      formId: formId,
      data: formData?.data ?? {},
      state: EState.Submitted,
    }
    // setIsValid(formData.isValid)
    onFormUpdate(dataToSubmit)
  }

  // API CALLS
  // handles Anon submission and draft submission.
  const sendSubmission = async (isDraft: boolean, onSuccess?: () => void) => {
    // Pre submission validation/setup
    const payload = getPreSubmissionPayload(isDraft)
    if (!payload) return
    if (isDraft) {
      payload.state = EState.Draft
    }
    let result
    setFormSubmissionError(undefined)
    setIsSubmitting(true)
    // BE submission
    if (isAnon) {
      result = await createAnonSubmission(payload, orgId!)
      setSuccessFullySubmitted(true)
      setIsSubmitting(false)
      return
    } else {
      result = await createSubmission(payload)
    }
    setIsSubmitting(false)
    // Show Toasts/set error if needed
    const successToastShown = handleSubmissionResponse(
      result,
      isDraft,
      form!.title ?? 'form',
      false,
    )
    // if unsuccessful stay on form page and show error
    if (!successToastShown) return

    // successfully submitted, callback handler
    setSuccessFullySubmitted(true)
    onSuccess && onSuccess()
  }

  // Update submission
  const sendUpdatedSubmissionData = async (
    isDraft: boolean,
    onSuccess?: () => void,
  ) => {
    const payload = getPreSubmissionPayload()
    if (!payload) return

    payload.state = isDraft ? EState.Draft : EState.Submitted
    const project = allProjects.find((p) => p.name == payload.data.project)
    const level1 = allLevels.find((l) => l.name == payload.data.level1)
    if (project) {
      payload.data.project = project.id
    }
    if (level1) {
      payload.data.level1 = level1.id
    }

    setFormSubmissionError(undefined)
    setIsSubmitting(true)
    const result = await updateSubmissionAPI(initialSub!.id, payload)
    setIsSubmitting(false)
    const successToastShown = handleSubmissionResponse(
      result,
      isDraft,
      form!.title ?? 'form',
      true,
    )
    if (!successToastShown) return

    onSuccess && onSuccess()
  }

  const saveDraft = async (onSuccess?: () => void) => {
    await sendSubmission(true, onSuccess)
  }
  const saveSubmission = async (onSuccess?: () => void) => {
    // confirmation for forms with scheduler component
    const showSubmissionConfirmation = containsScheduler(form!.components)

    if (!showSubmissionConfirmation) {
      sendSubmission(false, onSuccess)
      return
    }

    showConfirmationV2Message(
      'Are you sure you want to create a new Safety Team Meeting?  Notifications will be sent to the owner(s) and invitees.',
      () => sendSubmission(false, onSuccess),
      () => sendSubmission(true, onSuccess),
    )
  }
  useEffect(() => {
    localStorage.removeItem(
      'submissionPayload',
    )
    return () => {
      setSubmissionPayload(undefined)
      setFormIOForm(undefined)
    }
  }, [])

  const updateDraft = async (onSuccess?: () => void) => {
    await sendUpdatedSubmissionData(true, onSuccess)
  }
  const updateSubmission = async (onSuccess?: () => void) => {
    await sendUpdatedSubmissionData(false, onSuccess)
  }

  return {
    onFormChange,
    disableSubmit,
    setFormIOForm,
    formioForm,
    sendSubmission,
    successFullySubmitted,
    formSubmissionError,
    isSubmitting,
    saveDraft,
    saveSubmission,
    updateSubmission,
    submissionPayload,
    setSuccessFullySubmitted,
    updateDraft,
  }
}

export default useFormio
