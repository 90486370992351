import React from 'react'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowSelectionModel } from '@mui/x-data-grid'
import { ProjectPageViewModelProps } from './ProjectPageViewModel'
import { project_user_columns } from '../../components/datagrid/gridData/projectUsers'
interface IUsersDataGridProps {
  vm: ProjectPageViewModelProps
  title: string
  checkboxSelection?: boolean
  error?: string
  formatTitle?: boolean
}

const ProjectUserDataGrid: React.FC<IUsersDataGridProps> = ({
  vm,
  title,
  checkboxSelection,
  error,
  formatTitle = true
}) => {
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])

  const rows = vm.normalizeProjectUsersForDataGrid()

  return (
    <>
      <EdifyDataGrid
        data-testid='UsersDataGrid'
        formatTitle={formatTitle}
        paginationMode='server'
        title={title}
        noRowsText={'No members found.'}
        columns={project_user_columns}
        rows={rows}
        error={error || vm.usersError}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        loading={vm.usersLoading}
        rowCount={vm.totalUsers || 0}
        loadMoreRows={vm.loadMoreUsers}
        reload={vm.getProjectUsers}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={checkboxSelection}
      />
    </>
  )
}

export default ProjectUserDataGrid
