import React from 'react'
import { CheckCircle, CircleOutlined } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { AppBorders, AppColors, AppHovers } from '../../../Theme'
import { ETypography } from '../../fonts/ETypography'

export interface ISelectButtonProps {
  onClick: () => void
  selectedButton?: string
  title: string
  selected?: boolean
  width?: string
  flex?: number
  description?: string | null
  disabled?: boolean
}

export const SelectButton: React.FC<ISelectButtonProps> = ({
  onClick,
  selectedButton,
  title,
  width,
  flex,
  description,
  disabled
}) => {
  const selected = title == selectedButton
  const textColor = disabled ? 'neutral950' : 'gray700'
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      data-testid={'CloseDialogButton'}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        ...AppBorders.primary,
        ...(width ? { width } : {}),
        ...(flex ? { flex } : {}),
        padding: '12px 16px',
        

        '&:hover': {
          ...AppHovers.primaryButtonOutlined,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignContent:'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
          minWidth: description ? '250px' :'120px',
          maxWidth:'250px'
        }}
      >
        <ETypography font='LSB' color={textColor} sx={{ marginRight: '16px' }}>
          {title}
        </ETypography>
        {description && (
          <ETypography sx={{textAlign:'left',  marginTop:'8px'}} color='gray25' font='SM'>
            {description}
          </ETypography>
        )}
      </Box>
      {selected ? (
        <CheckCircle
          style={{
            width: '22px',
            height: '22px',
            color: disabled ? AppColors.neutral950 :  AppColors.primary600,
          }}
        />
      ) : (
        <CircleOutlined
          style={{
            width: '22px',
            height: '22px',
            color: AppColors.neutral600,
          }}
        />
      )}
    </IconButton>
  )
}
