import React from 'react'
import { Avatar, Box, SxProps, Typography } from '@mui/material'
import { AppColors, AppFonts } from '../../Theme'
import { Circle } from '@mui/icons-material'
import { LaunchIcon } from '../../../core/components/icons/LaunchIcon'
import { INotification } from '../../../domain/interfaces/INotification'
import { dateToMMMDYYYYHHMMA } from '../../../core/core'

interface INotificationProps extends INotification {
  notificationClicked: (id: string) => void
}
const NotificationTile: React.FC<INotificationProps> = ({
  id,
  body,
  status,
  type,
  link,
  notificationClicked,
  createdAt,
  orgId,
}) => {
  const read = status === 'read'
  const circleColor = read ? AppColors.neutral600 : AppColors.primary600
  return (
    // TODO: get propr 'unnotified' status once backend is change
    <StyledBox
      data-testid={'Notification'}
    >
      <Box sx={leftContainerStyles}
        data-testid={'NotificationButton'}
        onClick={() => notificationClicked(id)}
      >
        <Circle sx={{ color: circleColor }} height={18} width={18} />
        <Box sx={{
          overflow: 'hidden', alignItems: 'center', margin: '0 14px', flex:1
        }}>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Typography
              sx={{ ...AppFonts.textMSemibold, color: AppColors.gray700 }}
            >
              {type}
            </Typography>
            <Typography
              sx={{ ...AppFonts.textMRegular, color: AppColors.gray700 }}
            >
              {`${dateToMMMDYYYYHHMMA(createdAt)}`}
            </Typography>
          </Box>
          <Typography
            sx={{
              ...AppFonts.textSRegular, color: AppColors.gray50, marginTop: '5px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`${body}`}
          </Typography>
        </Box>
      </Box>
      {/* not every notification will have a link so don't show */}
      {/* {link !== '' && (
        <Box
          data-testid={'NotificationButton'}
          onClick={() => notificationClicked(id)}
          sx={{ cursor: 'pointer' }}
        >
          <LaunchIcon />
        </Box>
      )} */}
    </StyledBox>
  )
}

const leftContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  alignItems: 'center',
  overflow: 'hidden',
  flex:1
}

export default NotificationTile

//This could refactored as needed if we find other components that need to be styled like this but for now it's just for the notification component, so leave this here for now since this is the only place it's used

interface StyledBoxProps {
  children: React.ReactNode
  sx?: SxProps
}

const StyledBox = ({ children, sx }: StyledBoxProps) => {
  const backgroundColor = AppColors.baseWhite
  const border = `1px solid ${AppColors.neutral600}`

  return (
    <Box
      data-testid='NotificationTile'
      sx={{
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '24px',
        padding: '16px',
        backgroundColor,
        border,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
