import { Avatar, Box, Typography } from '@mui/material'

import React from 'react'
import { AppColors, AppFonts } from '../../../Theme'

interface GridAvatarItemProps {
  text: string
  inactive?: boolean
}

/**
 * Represents a custom grid item component where you can add top text bottom text and a avator.
 * @function GridStackItem
//  * @param {GridAvatarItem} props - The props for the GridStackItem component.
 * @returns {JSX.Element} - The rendered GridStackItem component.
 */

export const GridAvatarItem: React.FC<GridAvatarItemProps> = ({ text, inactive }) => {
  // const fontStyle = inactive ?  {...AppFonts.textSMedium, color:AppColors.gray25} : AppFonts.textMSemibold 
  const fontStyle = {...AppFonts.textSMedium, color: AppColors.gray700}
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {/* TODO REPLACE WITH IMG */}
      <Avatar sx={{ width: 50, height: 50, alignItems: 'center' }} />
      <Box>
        <Typography sx={{ ...fontStyle, marginLeft: '16px' }}>
          {text}
        </Typography>
      </Box>
    </Box>
  )
}
