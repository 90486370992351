import React, { useState } from 'react'

import { Box } from '@mui/material'

import { UsersPageViewModelProps } from './UsersViewModel'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'
import { EdifySelect } from '../../components/form'
import { formatSnakeCase } from '../../../core/utils/text-formaters/TextFormatters'
import { globalSearch } from '../../../domain/usecases/utils/UtilsUsecasses'
import { usersSearch } from '../../../domain/domain'
import { EdifySearch } from '../../components/form/EdifySearch/EdifySearch'

interface IUsersFilterProps {
  vm: UsersPageViewModelProps
}

const UsersFilter: React.FC<IUsersFilterProps> = ({ vm }) => {
  const { query } = vm.queryHelper
  const initValue = query.role ? query.role : 'All'
  const [value, setValue] = useState(initValue)
  const [showRoleClear, setShowRoleClear] = useState(initValue != 'All')

  const handleSelectClicked = (e: any) => {
    const role = e.target.value
    setShowRoleClear(role != 'All')
    const newUserQueryField = { ...query, role }
    vm.fetchUsers(0, newUserQueryField)
  }
  const contactSelected = (contacts: ISearchResult[]) => {
    // search cleared
    if (contacts.length == 0) {
      const newQuery = { ...query }
      delete newQuery.searchKey
      vm.fetchUsers(0, newQuery)
      return
    }
    vm.fetchUsers(0, { ...query, searchKey: contacts[0].name })
  }
  const onUsersTextSearch = (searchKey: string) => {
    const newQuery = { ...query, searchKey }
    vm.fetchUsers(query.skip, newQuery)
  }

  const clear = () => {
    setValue('All')
    setShowRoleClear(false)
    const newUserQueryField = { ...query }
    delete newUserQueryField.role
    vm.fetchUsers(0, newUserQueryField)
  }

  const roleOptions: { value: string; name: string }[] =
    (!vm.rolesLoading &&
      vm.roles?.map((role: any) => {
        return {
          value: role.id,
          name: role.name,
        }
      })) ||
    []

  return (
    <>
      <Box
        data-testid={'UsersPage'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '24px',
        }}
      >
        <Box>
          <EdifySelect
            width={'250px'}
            placeholder='Select Role'
            defaultValue={query.role}
            showClear={showRoleClear}
            onClear={clear}
            value={query.role}
            // sx={{ marginRight: '16px' }}
            dropDownItems={[{ value: '', name: 'All' }, ...roleOptions]}
            onChange={handleSelectClicked}
            renderMenuItem={(item) => {
              return (
                <EdifyMenuItem key={item.value} value={item.value}>
                  {formatSnakeCase(item.name)}
                </EdifyMenuItem>
              )
            }}
          />
        </Box>

        {/* <EdifySearchDropdown
          placeholder='Search Name'
          data-testid='PointOfContact'
          width={218}
          limit={8}
          onSelect={contactSelected}
          searchFunction={globalSearch}
          nameKey='fullName'
          globalSearchKey='users'
          globalSearchPayload={{
            entities: ['users'],
            properties: ['id'],
            returns: ['id', 'fullName'],
          }}
          
        />  */}
        <EdifySearch
          placeholder='Search Name'
          width={'260px'}
          defaultValue={vm.queryHelper.query.searchKey ?? ''}
          onChange={(e: any) => onUsersTextSearch(e.target.value)}
          clear={() => {
            onUsersTextSearch('')
          }}
        />
      </Box>

      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          margin: '24px 0',
        }}
      >
        <EdifyButton
          onClick={() => vm.clearFilters(true)}
          title='Clear Filters'
          primary
          noBackground
        />
      </Box> */}
    </>
  )
}

export default UsersFilter
