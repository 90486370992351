import React, { useEffect } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import useFormio from '../../../core/hooks/useFormio'
import { IForm } from '../../../domain/interfaces/IForm'
import { ISubmission } from '../../../domain/interfaces/ISubmission'
import { useI18nProvider } from '../../../providers/InternationalizationProvider'
import { EdifyButton } from '../../components/buttons'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { REACT_APP_FORMIO_PROJECT_URL } from '../../../config'
import { Form } from '@formio/react'
import MojoFormButtons from '../../components/form/MojoForm/MojoFormButtons'
import ErrorContainer from '../../components/error/ErrorContainer'
import { ModalProvider, useEventModal } from './MojoFormioSubmissionModal'

interface IMojoFormioRenderer {
  formId: string
  orgId: string
  form: IForm
  submission: ISubmission
  isAnon: boolean
  isDraft: boolean
  submissionFormatted: boolean
  submissionId?: string
  noContainer?: boolean
  buttonGroup?: boolean
  readOnly?: boolean
  setDataCallback?: any
  errors?: string[] | undefined
  formReadOnly?: IForm | undefined
}

const _MojoFormioRenderer: React.FC<IMojoFormioRenderer> = ({
  formId,
  orgId,
  form,
  submission,
  submissionFormatted,
  isAnon = false,
  isDraft = false,
  noContainer = false,
  buttonGroup = false,
  readOnly = false,
  errors,
  setDataCallback,
  formReadOnly,
}) => {
  const { openEventModal } = useEventModal()
  let imgUploads: NodeListOf<HTMLAnchorElement>
  const { orgI18nLoaded, options } = useI18nProvider()
  const {
    onFormChange,
    disableSubmit,
    setFormIOForm,
    sendSubmission,
    formioForm,
    successFullySubmitted,
    formSubmissionError,
  } = useFormio(formId, submission, {
    form: form,
    orgId: orgId,
    isAnon: isAnon,
    isDraft: isDraft,
    setDataCallback: setDataCallback,
  })
  // Temp fix for formio form not loading on first tab switch
  const [count, setCount] = React.useState(0)
  const [redraw, setRedraw] = React.useState(false)
  const addClickListener = () => {
    if(count > 0) return
    setCount(count + 1)
    imgUploads = document.querySelectorAll(
      '.formio-component-file .fileSelector',
    )

    imgUploads.forEach((imgUpload) => {
      imgUpload.addEventListener('click', handleImgUpload)
    })
  }
  const handleImgUpload = (event: any) => {
    if (event.target.tagName == 'A') return
    const fileBrowseLink = document.querySelector(
      '.formio-component-file .fileSelector',
    )

    if (fileBrowseLink && fileBrowseLink.contains(event.target)) {
      // Trigger a click event on the found <a> tag
      const link = fileBrowseLink.querySelector('a')
      if (link) {
        link.click()
      }
    }
  }

  useEffect(() => {
    if (!orgI18nLoaded) return
    setTimeout(() => {
      if(count === 0) {
        setCount(count + 1)
      }
    }, 500)
    return () => {
      if (imgUploads) {
        imgUploads.forEach((imgUpload) => {
          imgUpload.removeEventListener('click', handleImgUpload)
        })
      }
    }
  }, [orgI18nLoaded])

  if (noContainer) {
    return (
      <>
        {/* {errors && (
          <ErrorContainer error={'Please Fill Out Required Fields'}>
            {errors.join(',')}
          </ErrorContainer>
        )} */}
        <Form
          url={`${REACT_APP_FORMIO_PROJECT_URL}/${form.path}`}
          form={readOnly ? formReadOnly ?? form : form}
          formReady={setFormIOForm}
          submission={submission}
          onChange={onFormChange}
          options={options}
          onRender={addClickListener}
        />

        {buttonGroup && (
          <MojoFormButtons onCancel={() => 1}>
            <EdifyButton
              // hide={hadInitialSubmission}
              data-testid={'SubmissionButton'}
              primary
              disabled={disableSubmit}
              title={`Submit [DELETE] ${form.title}`}
              onClick={() => sendSubmission(isDraft)}
            />
          </MojoFormButtons>
        )}
      </>
    )
  }
  const onSuccess = () => {
    setRedraw(true)
  }
  function onEvent(event: any) {
    openEventModal(event.type, onSuccess)
  }
  useEffect(() => {
    if (redraw) {
      formioForm.redraw()
      setRedraw(false)
    }
  }, [redraw])
  return (
    <>
      <InnerPageContainer
        innerPageLoading={!orgI18nLoaded}
        headerTitle={`${form.title}`}
        sx={{ padding: '24px' }}
        // renderRightItems={() => <LanguageSearch anonymous />}
      >
        {/* {errors && (
          <ErrorContainer error={'Please Fill Out Required Fields'}>
            {errors.join(',')}
          </ErrorContainer>
        )} */}
        <Form
          key={`${form.id}-${count}`}
          url={`${REACT_APP_FORMIO_PROJECT_URL}/${form.path}`}
          form={readOnly ? formReadOnly ?? form : form}
          formReady={setFormIOForm}
          submission={submission}
          onChange={onFormChange}
          options={{ ...options, readOnly: readOnly }}
          onRender={addClickListener}
          onCustomEvent={onEvent}
        />
      </InnerPageContainer>
      {buttonGroup && (
        <MojoFormButtons onCancel={() => 1}>
          <EdifyButton
            // hide={hadInitialSubmission}
            data-testid={'SubmissionButton'}
            primary
            disabled={disableSubmit}
            title={`Submit [DELETE THIS BUTTON]${form.title}`}
            onClick={() => sendSubmission(isDraft)}
          />
        </MojoFormButtons>
      )}
    </>
  )
}
const MojoFormioRenderer = (props: IMojoFormioRenderer) => (
  <ModalProvider>
    <_MojoFormioRenderer {...props} />
  </ModalProvider>
)


export default MojoFormioRenderer
