import React from 'react'
import InnerPageContainer, {
  InnerPageContainerProps,
} from '../../inner-page-container/InnerPageContainer'
import { AppColors, AppFonts } from '../../../Theme'
import { Box, Checkbox, FormGroup, SxProps, Typography } from '@mui/material'
import EdifyToggle from '../../form/EdifyToggle/EdifyToggle'

interface NotificationsSettingsProps extends InnerPageContainerProps {
  emailChecked: boolean
  smsChecked: boolean
  sx?: SxProps
}

const NotificationsReadOnly: React.FC<NotificationsSettingsProps> = ({
  emailChecked,
  smsChecked,
  sx

}) => {

  return (
    <Box
      sx={{
        border: 'none',
        ...sx
      }}
    >
      <Box
        style={{
          maxWidth: '512px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{ ...AppFonts.textMSemibold, color: AppColors.gray700 }}
        >
          NOTIFICATION SETTINGS
        </Typography>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <EdifyToggle
          data-testid='EmailNotification'
          text='Email Notifications'
          subText='I want to receive Email notifications'
          checked={emailChecked}
          disabled
          onChange={() =>  null}
        />
        <EdifyToggle
          data-testid='SMSNotification'
          text='SMS Notifications'
          subText='I want to receive SMS notifications'
          checked={smsChecked}
          disabled
          onChange={() =>  null}
        />
      </Box>
    </Box>
  )
}
export default NotificationsReadOnly
