/* eslint-disable react/react-in-jsx-scope */
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { IForm } from '../../../domain/interfaces/IForm'
import FormRenderer from '../../components/panels/form-renderer/FormRenderer'

import {
  NonAuthenticatedFormPageViewModel,
  NonAuthenticatedFormPageViewModelProps,
  useNonAuthenticatedFormPageViewModel,
} from './NonAuthenticatedFormPageViewModel'

export const ROUTE_NO_AUTH_FORM = '/clientform'

export interface ForceParamProps {
  loginAccountId?: string
  formId?: string
  formName?: string
  orgId?: string
}

export const NonAuthenticatedFormPage: React.FC<ForceParamProps> = (
  props: ForceParamProps,
) => {
  return (
    <NonAuthenticatedFormPageViewModel
      forceLoginAccountId={props.loginAccountId}
      forceOrgId={props.orgId}
      forceFormId={props.formId}
      forceFormName={props.formName}
    >
      <Page />
    </NonAuthenticatedFormPageViewModel>
  )
}

const Page: React.FC = () => {
  const vm: NonAuthenticatedFormPageViewModelProps =
    useNonAuthenticatedFormPageViewModel()

  const [loading, setLoading] = useState<boolean>(true)
  const [form, setForm] = useState<IForm | undefined>(undefined)
  const [formId, setFormId] = useState<string | undefined>(undefined)
  const [loginAccountId, setLoginAccountId] = useState<string | undefined>(
    undefined,
  )
  const [orgId, setOrgId] = useState<string>()

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setForm(vm.form)
      setFormId(vm.formId)
      setLoginAccountId(vm.loginAccountId)
      setOrgId(vm.orgId)
      setLoading(false)
    }, 2000)
  }, [])

  return (
    <Stack spacing={8}>
      {!loading ? (
        <Box data-testid='NonAuthenticatedForm_formRenderer'>
          <FormRenderer
            forceFormId={formId}
            forceLoginId={loginAccountId}
            orgId={orgId}
            form={form}
            submittedForm={{}}
            sx={{
              margin: '0',
              padding: '16px',
              backgroundColor: 'white',
              borderRadius: '16px',
            }}
          />
        </Box>
      ) : (
        <Box height={90} data-testid='NonAuthenticatedForm_progressSection'>
          <Typography>loading the form</Typography>
          <CircularProgress data-testid='NonAuthenticatedForm_progressSectionCircularProgress' />
        </Box>
      )}
    </Stack>
  )
}
