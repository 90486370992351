import React, { useState } from 'react'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box, Input } from '@mui/material'

import { EdifyFieldLabel, EdifyFormField } from '../../components/form'

import {
  LocationPageViewModel,
  useLocationPageViewModel,
} from './LocationViewModel'
import { Circle } from '@mui/icons-material'
import { ETypography } from '../../components/fonts/ETypography'
import { levelStyles } from './locationStyles'
import { useSubLocationPageViewModel } from './SubLocationViewModel'

const _SubLocationHierarchyCard = () => {
  const { locationHierarchies } = useSubLocationPageViewModel()

  return (
    <>
      <InnerPageContainer>
        {locationHierarchies &&
          locationHierarchies.map((h, i) => {
            if(h.name === '')return<></>
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                  gap: '16px',
                }}
                key={`hierarchies-${h.name}-${i}`}
              >
                <ETypography font='MS' color='gray700'>
                  {`Level ${i + 1} `}
                </ETypography>
                <Box
                  sx={{
                    background: levelStyles[i].background,
                    borderRadius: '5px',
                    display: 'flex',
                    padding: '8px',
                    flex: 1,
                    alignItems: 'center',
                    gap: '8px',
                    minHeight: '44px',
                  }}
                >
                  <Circle
                    sx={{
                      fontSize: '12px',
                      color: levelStyles[i].color,
                      margin: '8px 0',
                    }}
                  />
                  <ETypography
                    font='MS'
                    color='gray700'
                    sx={{ lineHeight: '12px' }}
                  >
                    {h.name}
                  </ETypography>
                </Box>
              </Box>
            )
          })}
      </InnerPageContainer>
    </>
  )
}
const SubLocationHierarchyCard = () => {
  return <_SubLocationHierarchyCard />
}

export default SubLocationHierarchyCard
