import React, { useEffect, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import PageContainer from '../../components/page/PageContainer'
import { INotification } from '../../../domain/interfaces/INotification'
import {
  getNotifications,
  markNotificationRead,
} from '../../../domain/usecases/notifications/NotificationsUsecases'
import { ErrorToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import NotificationTile from '../../components/tiles/NotificationTile'
import DataGridFooter from '../../components/datagrid/DataGridFooter'
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { DEFAULT_LIMIT } from '../../components/datagrid/EdifyDataGrid'



export const ROUTE_NOTIFICATIONS = '/notifications'

const NotificationsPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [notifications, setNotification] = useState<INotification[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: DEFAULT_LIMIT,
  })

  const fetchNotifications = async (fetchSkip: number) => {
    setLoadMore(true)

    const res = await getNotifications(fetchSkip, DEFAULT_LIMIT)
    setLoadMore(false)
    if (res.isLeft()) {
      setError(res.left?.message)
    }
    setNotification(res.right?.data as INotification[])
    setTotalCount(res.right?.total_count as number)
  }

  const loadMoreUsers = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchNotifications(fetchSkip)
  }

  // RENDER
  const renderNotifications = () => {
    if (notifications?.length === 0) {
      return <Typography>You have no notifications</Typography>
    }
    return (
      <Stack>
        {notifications?.map((notification) => (
          <NotificationTile
            key={notification.id}
            notificationClicked={notificationClicked}
            {...notification}
          />
        ))}
      </Stack>
    )
  }

  const notificationClicked = async (id: string) => {
    // find notification so we can get the link and navigate to it
    const notification = notifications?.find((n) => n.id === id)
    const link = notification?.link || '404'

    const res = await markNotificationRead(id)
    window.location.href = link

    if (res.isLeft()) {
      ErrorToast({ title: 'Error marking notification as read' })
    }
  }

  useEffect(() => {
    setLoading(true)
    const init = async () => {
      await fetchNotifications(0)
      setLoading(false)
    }
    init()
  }, [])

  return (
    <Box data-testid='NotificationsPage'>
      <PageContainer
        title='Notifications'
        mainPageLoading={loading}
        mainPageError={error}
        mainPageReload={() => fetchNotifications(0)}
      >
        <InnerPageContainer
          sx={{ backgroundColor: 'transparent', border: 'none', padding: 0 }}
          innerPageLoading={loadMore}
        >
          {renderNotifications()}
        </InnerPageContainer>

        {totalCount > DEFAULT_LIMIT && (
          <DataGrid
            className='hide'
            rows={[]}
            columns={[]}
            loading={false}
            paginationMode='server'
            paginationModel={paginationModel}
            onPaginationModelChange={loadMoreUsers}
            slots={{
              footer: () => (
                <DataGridFooter
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                  totalCount={totalCount}
                />
              ),
            }}
          />
        )}
      </PageContainer>
    </Box>
  )
}

export default NotificationsPage
