import { Avatar } from '@mui/material'
import React from 'react'
import { ComponentProps } from '../../../core/core'

interface IICon extends ComponentProps {
  intial: string
}

export const OrgAvatar: React.FC<IICon> = ({ intial, sx }) => {
  return (
    <Avatar data-testid={'OrgAvatar'} sx={sx}>
      {intial.toUpperCase()}
    </Avatar>
  )
}
