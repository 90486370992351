import React from 'react'
import usePermission from '../../../../core/hooks/usePermission'
import { ERole } from '../../../../domain/interfaces/IRole'
import { EdifyButton ,EdifyButtonProps} from './EdifyButton'


interface PermissionAwareButtonProps extends EdifyButtonProps {
  requiredPermission: ERole
}

export const PermissionButton: React.FC<PermissionAwareButtonProps> = ({
  requiredPermission,
  ...buttonProps
}) => {
  const hasPermission = usePermission(requiredPermission)

  if (!hasPermission) {
    return null
  }

  return <EdifyButton {...buttonProps} />
}