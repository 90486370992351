import React, { useRef, useEffect, useState } from 'react'
import { useMapsLibrary } from '@vis.gl/react-google-maps'

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void
  address?: string
  disabled?: boolean
  showAdd?: boolean
  setShowAdd: (b: boolean) => void
}

// This is an example of the classic "Place Autocomplete" widget.
// https://developers.google.com/maps/documentation/javascript/place-autocomplete
const AutoComplete = ({
  onPlaceSelect,
  address,
  showAdd,
  setShowAdd,
  disabled = false,
}: Props) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const places = useMapsLibrary('places')

  useEffect(() => {
    if (!places || !inputRef.current) return
    const options = {
      fields: ['geometry', 'name', 'formatted_address'],
    }

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
  }, [places])

  useEffect(() => {
    if (!placeAutocomplete) return
    placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace())
    })
  }, [onPlaceSelect, placeAutocomplete])
  if (showAdd && !disabled) {
    return (
      <div className='autocomplete-container' onClick={() => setShowAdd(false)}>
        <input
          onClick={() => setShowAdd(false)}
          // disabled={true}
          // disabled={disabled}
          value={address}
          className='form-control'
          ref={inputRef}
        />
      </div>
    )
  }

  return (
    <div className='autocomplete-container'>
      <input
        disabled={disabled}
        defaultValue={address}
        className='form-control'
        ref={inputRef}
      />
    </div>
  )
}

export default AutoComplete
