import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Input } from '@mui/material'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import SubLocation from './SubLocation'

import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import EdifyImageUploader from '../../components/form/EdifyImage/EdifyImageUploader'
import { EdifyButton } from '../../components/buttons'
import EdifyAttachmentViewer from '../../components/form/EdifyImage/EdifyImageViewer'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createLocation,
  updateLocation,
} from '../../../domain/usecases/locations/LocationsUsecases'

import MapDemo from './MapDemo'
import { ILocation } from '../../../domain/interfaces/ILocation'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import FormErrorText from '../../components/form/FormErrorText'

export type ILocationFormData = {
  name?: string
  projectId?: string
  address?: string
  lat?: number
  long?: number
  attachments?: string[]
  level1Name?: string
  level2Name?: string
  level3Name?: string
  level4Name?: string
  level5Name?: string
}

interface Props {
  projectId: string
  initialLocation?: ILocation
  setLocations?: (locations: ILocation[]) => void
  setHideForm?: (hide: boolean) => void
  locations?: ILocation[]
  onSuccessEditCB?: any
  handleEditClicked?: () => void
  setLocationCreated?: (b: boolean) => void
}

const LocationForm: React.FC<Props> = ({
  projectId,
  initialLocation,
  setLocations,
  locations,
  setHideForm,
  onSuccessEditCB,
  handleEditClicked,
  setLocationCreated,
}) => {
  const { flags, getHierarchyName } = useOrganizationProvider()
  const [locationId, setLocationId] = useState<string | undefined>(
    initialLocation?.id,
  )
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const [imageURL, setImageURL] = useState<string | null>(
    initialLocation ? initialLocation.attachments[0] : '',
  )
  const [address, setAddress] = useState<any>(initialLocation?.address)
  const [name, setName] = useState<any>(initialLocation?.name ?? '')
  const [latLng, setLatLng] = useState<any>(
    initialLocation
      ? { lat: initialLocation.lat, lng: initialLocation.long }
      : null,
  )

  const locationKey = getHierarchyName(-1)
  const deleteImage = () => {
    setImageURL(null)
  }

  const imageUploaded = async (imageURL: string | null) => {
    setSubmissionError(undefined)
    setImageURL(imageURL ?? null)
    setSubmitting(false)
  }

  const onSubmit = () => {
    const error = 
      `Please Select ${locationKey} on map or search for address.`
    if (!latLng) {
      setSubmissionError(error)
      return
    }
    const formData: ILocationFormData = {
      name,
      attachments: imageURL ? [imageURL] : [''],
      lat: latLng.lat,
      long: latLng.lng,
      projectId,
      address,
    }
    setSubmitting(true)
    setSubmissionError(undefined)
    if (locationId) handleUpdate(locationId, formData)
    else handleCreate(formData)
  }

  const handleUpdate = async (id: string, formData: ILocationFormData) => {
    // TODO use projectUpdate when ED-1387 is merged that
    // has the call and tests..
    const res = await updateLocation(formData, id)
    setSubmitting(false)
    if (res.isLeft()) {
      setSubmissionError(
        res.left?.message ?? 'A unknown error occurred, please try again later',
      )
      ErrorToast({ title: 'Error saving new changes.' })
      return
    }

    SuccessToast({ title: `Successfully Updated ${locationKey}.`})
    if (onSuccessEditCB) {
      onSuccessEditCB(res.right)
    }
  }

  const handleCreate = async (formData: ILocationFormData) => {
    const res = await createLocation(formData)
    setSubmitting(false)
    if (res.isLeft()) {
      setSubmissionError(
        res.left?.message ?? 'A unknown error occurred, please try again later',
      )
      ErrorToast({ title: `Error adding ${locationKey}.`})
      return
    }
    SuccessToast({ title: `New ${locationKey} has been added. `})
    if (setLocations && locations) {
      setLocations([...locations, res.right as ILocation])
      setHideForm && setHideForm(true)
      return
    }
    setLocationCreated && setLocationCreated(true)
    setLocationId(res.right!.id)
  }

  const hasChange = () => {
    if (!initialLocation) {
      return address != '' && latLng != null
    }

    return (
      address != initialLocation.address ||
      name != initialLocation.name ||
      initialLocation.attachments[0] != imageURL
    )
  }
  useEffect(() => {
    if (latLng && latLng.lat) {
      setSubmissionError(undefined
      )
    }
  }, [latLng])

  return (
    <>
      <InnerPageContainer
        headerTitle={locationId ? `Edit ${locationKey}` : `Add ${locationKey}`}
        renderRightItems={
          handleEditClicked &&
          (() => (
            <EdifyButton
              noBackground
              title={'Cancel'}
              onClick={handleEditClicked}
            />
          ))
        }
      >
        <Grid container spacing={'24px'}>
          <Grid item sm={12} md={12} lg={6}>
            <EdifyFormField>
              <MapDemo
                address={address}
                setAddress={setAddress}
                setName={setName}
                setLatLng={setLatLng}
                latLng={latLng}
              />
              {submissionError && (
                <FormErrorText>{submissionError}</FormErrorText>
              )}
            </EdifyFormField>
          </Grid>

          <Grid item sm={12} md={12} lg={6}>
            <EdifyFormField>
              <EdifyFieldLabel label='Site specific plan document'/>
              {imageURL ? (
                <EdifyAttachmentViewer
                  name={`${locationKey} Image`}
                  attachment={imageURL}
                  onDelete={deleteImage}
                  sx={{ width: 'auto' }}
                />
              ) : (
                <EdifyImageUploader
                  singleFile
                  uploadUrl='/fileUploads/location'
                  handleLoad={imageUploaded}
                  dataKey='anyFile'
                  acceptedFileTypes={'All'}
                  // Move back to Array
                  // decodeResponse={(object: any) => object.data.originalUrl}
                  handleOnAddStart={() => setSubmitting(true)}
                />
              )}
            </EdifyFormField>
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            <EdifyFormField>
              <EdifyFieldLabel label='NAME' />

              <Input
                disableUnderline
                className={'form-control'}
                // className={`form-control ${errors?.name ? 'error' : ''}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
              // {...register('name', { required: true })}
              />
              {/* {errors.name && (
                <FormErrorText>This field is required</FormErrorText>
              )} */}
            </EdifyFormField>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '24px',
          }}
        >
          <Box>
            <EdifyButton
              data-testid={'SubmissionButton'}
              loading={submitting}
              disabled={submitting}
              primary
              type='submit'
              title={locationId ? `Update ${locationKey}` : `Add ${locationKey}`}
              onClick={onSubmit}
            />
            {/* {!locationId && (
              <span style={{ marginLeft: '12px' }}>
                <EdifyButton
                  data-testid={'SubmissionButton'}
                  loading={submitting}
                  disabled={submitting}
                  primary
                  type='button'
                  onClick={() => {
                    SuccessToast({
                      title: 'Successfully added [Location Name].  ',
                    })
                    navigate('/projects/id/locations/new')
                  }}
                  title={'Save New Location'}
                />
              </span>
            )} */}
          </Box>
        </Box>
      </InnerPageContainer>

      {flags.isSubLocationEnabled && locationId && (
        <SubLocation locationId={locationId} locationName={name} />
      )}
    </>
  )
}

export default LocationForm
