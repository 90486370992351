import styled from '@emotion/styled'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { AppColors, AppFonts } from '../../../Theme'
import { SvgIconProps, Typography } from '@mui/material'
import { IForm } from '../../../../domain/interfaces/IForm'

const NavbarLink = styled(NavLink)(() => ({
  display: 'flex',
  direction: 'ltr',
  alignItems: 'center',
  gap: '12px',
  padding: '12px 28px',
  textDecoration: 'none',
  '&:hover': {
    color: AppColors.g000,
    opacity: 1,
    background: AppColors.gray400,
    textDecoration: 'none',
  },
}))

interface Props {
  iconComponent: React.ComponentType<SvgIconProps>
  iconComponentStyle?: React.CSSProperties
  linkTitle: string
  pathMatch?: string
  route: string
  handleOnClick?: React.MouseEventHandler<HTMLAnchorElement>
  suffixIconComponent?: React.ComponentType<SvgIconProps>
  style?: React.CSSProperties
  state?: { form: IForm }
  hide?: boolean
}

export const NavbarLinkTile: React.FC<Props> = ({
  route,
  iconComponent: IconComponent,
  iconComponentStyle,
  linkTitle,
  handleOnClick,
  suffixIconComponent: SuffixIcon,
  style,
  pathMatch,
  state = {},
  hide = false
}) => {
  if(hide) return null
  // const pathNameMatch =  pathMatch ? route === pathMatch : route
  const isActive = pathMatch
    ? location.pathname.includes(pathMatch)
    : location.pathname === route
  return (
    <NavbarLink
      to={route}
      state={state}
      onClick={handleOnClick}
      style={{ ...style }}
    >
      <IconComponent
        sx={{
          color: isActive ? AppColors.neutral200 : AppColors.gray25,
          height: '22px',
          ...iconComponentStyle,
        }}
      />
      <Typography
        sx={{
          ...AppFonts.interMSemibold,
          color: isActive ? AppColors.neutral200 : AppColors.gray25,
          opacity: isActive ? 1 : 0.6,
          width: '154px',
        }}
      >
        {linkTitle}
      </Typography>

      {SuffixIcon ? (
        <SuffixIcon
          sx={{
            height: '24px',
            color: isActive ? AppColors.neutral200 : AppColors.gray25,
            alignContent: 'flex-end',
          }}
        />
      ) : null}
    </NavbarLink>
  )
}
