import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { Box, IconButton, TextField } from '@mui/material'
import { Node1 as Node } from './treeHelpers'
import { Add } from '@mui/icons-material'
import { PencilIcon } from '../../../core/components/icons/PenciIIcon'
import { ETypography } from '../../components/fonts/ETypography'
import { LevelBadge } from './Node1'
import { TrashIcon } from '../../../core/components/icons/TrashIcon'
import AddHierarchiesModal from './AddHieararchiesModal'
import { deleteSubLocation, updateSubLocation } from '../../../domain/domain'
import { AppColors } from '../../Theme'
import { useLocationPageViewModel } from './LocationViewModel'
import {
  ILocation,
  ISubLocationHierarchyName,
} from '../../../domain/interfaces/ILocation'
import { useSubLocationPageViewModel } from './SubLocationViewModel'

interface Props {
  subLocationsTree?: any
  locationId?: string
  isEdit?: boolean
  defaultExpanded?: string[]
  setSubLocationTree?: any
}
const TreeMUI: React.FC<Props> = ({
  subLocationsTree,
  defaultExpanded,
  setSubLocationTree,
  locationId,
  // locationHierarchies,
  isEdit = false,
}) => {
  const { locationHierarchies } = useSubLocationPageViewModel()
  const subLocationsName = locationHierarchies
    ? locationHierarchies.map((sl) => sl.name ?? '')
    : []
  const onUpdateNode = async (node: Node, newName: string) => {
    // implement node editing logic here
    const res = await updateSubLocation(
      {
        level: node.level,
        locationId: node.locationId,
        name: newName,
        parentId: node.parentId,
      },
      node.id,
    )
    updateNode(node.id, { ...res.right }, node.children)
  }
  const [data, setData] = React.useState(subLocationsTree as any)

  const removeNode = (nodeId: string) => {
    const updateTree = (nodes: any): any[] =>
      nodes.reduce((acc: any[], node: any) => {
        if (node.id === nodeId) return acc // skip the node
        if (node.children.length > 0) {
          return [...acc, { ...node, children: updateTree(node.children) }]
        }
        return [...acc, node]
      }, [])

    setData((prevData: any) => updateTree(prevData))
  }

  const updateNode = (nodeId: string, newNode: any, oldNodeChildren: any) => {
    const updateTree = (nodes: any): any =>
      nodes.reduce((acc: any, node: any) => {
        if (node.id === nodeId)
          return [...acc, { ...newNode, children: updateTree(oldNodeChildren) }] // replace the node
        if (node.children.length > 0) {
          return [...acc, { ...node, children: updateTree(node.children) }]
        }
        return [...acc, node]
      }, [])

    setData(updateTree(data))
  }

  const addNodes = (parentId: string, newNodes: any) => {
    // adding sub level 1 nodes
    // need to change if we add button add node to all levels
    // deep clone?
    if (parentId == locationId) {
      const firstPart = data.slice(0, -1)
      const lastPart = data.slice(-1)

      setData([...firstPart, ...newNodes, ...lastPart])
      return
    }
    const isSL1 = parentId == locationId
    const updateTree = (nodes: any): any =>
      nodes.map((node: any) => {
        if (node.id === parentId) {
          // Spread existing children and add new nodes array
          return { ...node, children: [...node.children, ...newNodes] }
        } else if (node.children.length > 0) {
          return { ...node, children: updateTree(node.children) }
        }
        return node
      })

    setData(updateTree(data))
  }

  const onAddNodes = (parentId: string, nodes: any) => {
    addNodes(parentId, nodes)
  }
  const onDeleteNode = (id: string) => {
    removeNode(id)
  }
  useEffect(() => {
    if (setSubLocationTree) setSubLocationTree(data)
  }, [data])

  if (!data)
    return (
      <InnerPageContainer>
        <p>No sub locations</p>
      </InnerPageContainer>
    )
  return (
    <InnerPageContainer>
      <TreeView
        defaultExpanded={defaultExpanded}
        aria-label='file system navigator'
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ flexGrow: 1, overflowY: 'auto' }}
      >
        {data.map((node: any) => (
          <TreeNode
            key={node.id}
            node={node}
            isEdit={isEdit}
            onDeleteNode={onDeleteNode}
            onUpdateNode={onUpdateNode}
            onAddNodes={onAddNodes}
            subLocationsName={subLocationsName}
          />
        ))}
      </TreeView>
    </InnerPageContainer>
  )
}

export default TreeMUI

interface TreeNodeProps {
  node: Node
  isEdit: boolean
  onAddNodes?: (parentId: string, Nodes: any) => void
  onUpdateNode: (node: any, value: any) => void
  onDeleteNode: (id: string) => void
  subLocationsName: string[] | undefined
}

const TreeNode: React.FC<TreeNodeProps> = ({
  isEdit,
  node,
  onAddNodes,
  onDeleteNode,
  onUpdateNode,
  subLocationsName = [],
}) => {
  const [isEditing, setIsEditing] = React.useState(false)
  const [newName, setNewName] = useState(node.name)
  const [isAdding, setIsAdding] = useState(false)
  const [addType, setAddType] = useState<string>(
    subLocationsName[node.level - 1],
  )

  const [createParentId, setCreateParentId] = useState<string>('')
  const [createLevel, setCreateLevel] = useState<number>(0)
  const [parentName, setParentName] = useState<string>('')

  const handleEditClicked = (e: any) => {
    setIsEditing(true)
    e.stopPropagation()
  }
  const onAddSiblings = (e: any) => {
    setCreateParentId(node.parentId)
    setCreateLevel(node.level)
    setAddType(subLocationsName[node.level - 1])
    setParentName('Location')
    setIsAdding(true)
    e.stopPropagation()
  }

  const onAddChildren = (e: any) => {
    setCreateParentId(node.id)
    setCreateLevel(node.level + 1)
    setParentName(node.name)
    setAddType(subLocationsName[node.level])
    setIsAdding(true)
    e.stopPropagation()
  }

  const handleDeleteClicked = async (e: any) => {
    const res = await deleteSubLocation(node.id)
    onDeleteNode(node.id)
  }
  const handleBlur = (node: Node, newName: string) => {
    onUpdateNode(node, newName)
    setIsEditing(false)
  }
  useEffect(() => {
    setAddType(subLocationsName[node.level - 1])
  }, [subLocationsName])
  if (isAdding) {
    return (
      <AddHierarchiesModal
        open={isAdding}
        handleClose={() => setIsAdding(false)}
        level={createLevel}
        parentId={createParentId}
        parentName={parentName}
        locationId={node.locationId}
        subLevelName={addType}
        onAddNodes={onAddNodes}
      />
    )
  }
  if (isEdit && node.isPlaceholder) {
    return (
      <Box sx={{ marginTop: '12px' }}>
        <ETypography
          font='SM'
          color='gray50'
          sx={{
            padding: '10px 0',
            border: `1px solid ${AppColors.neutral700}`,
            cursor: 'pointer',
            borderRadius: '5px',
          }}
          onClick={onAddSiblings}
        >
          <Add sx={{ color: AppColors.gray50 }} /> {addType}/s
        </ETypography>
      </Box>
    )
  }
  if (node.isPlaceholder) {
    return <></>
  }

  return (
    <TreeItem
      nodeId={node.id}
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            width: '100%',
          }}
        >
          {isEditing ? (
            <TextField
              fullWidth
              size='small'
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onBlur={() => handleBlur(node, newName)}
              onKeyDown={(e) => {
                // if (e.key === 'Escape') node.reset()
                if (e.key === 'Enter') handleBlur(node, newName)
              }}
              autoFocus
            />
          ) : (
            <>
              <ETypography
                font='LSB'
                color='gray600'
                onClick={() => 1}
                sx={{ cursor: 'pointer', flexGrow: 1, padding: '12px 0' }}
              >
                {node.name}
                <LevelBadge
                  level={node.level - 1}
                  label={subLocationsName[node.level - 1]}
                />
              </ETypography>
              {isEdit && (
                <>
                  <IconButton size='small' onClick={handleEditClicked}>
                    <PencilIcon />
                  </IconButton>
                  {node.level < subLocationsName.length && (
                    <IconButton size='small' onClick={onAddChildren}>
                      <Add />
                    </IconButton>
                  )}
                  <IconButton size='small' onClick={handleDeleteClicked}>
                    <TrashIcon />
                  </IconButton>
                </>
              )}
            </>
          )}
        </Box>
      }
    >
      {node.children.map((child) => (
        <TreeNode
          isEdit={isEdit}
          key={child.id}
          node={child}
          onDeleteNode={onDeleteNode}
          onUpdateNode={onUpdateNode}
          onAddNodes={onAddNodes}
          subLocationsName={subLocationsName}
        />
      ))}
    </TreeItem>
  )
}
