import { Formio } from '@formio/react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IForm } from '../../../domain/interfaces/IForm'
import { REACT_APP_FORMIO_PROJECT_URL } from '../../../config'

export interface NonAuthenticatedFormPageViewModelProps {
  loginAccountId?: string
  orgId?: string
  formId: string
  formName: string
  state: string
  form: IForm | undefined
}

const NonAuthenticatedFormPageContext =
  React.createContext<NonAuthenticatedFormPageViewModelProps | null>(null)

export function useNonAuthenticatedFormPageViewModel(): NonAuthenticatedFormPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(NonAuthenticatedFormPageContext)!
}

interface Props {
  forceLoginAccountId?: string
  forceOrgId?: string
  forceFormId?: string
  forceFormName?: string
  children: React.ReactElement | React.ReactElement[]
}

export const NonAuthenticatedFormPageViewModel: React.FC<Props> = ({
  forceLoginAccountId,
  forceOrgId,
  forceFormId,
  forceFormName,
  children,
}) => {
  const [searchParams] = useSearchParams()
  const [form, setForm] = useState<IForm | undefined>(undefined)

  const loginAccountId =
    forceLoginAccountId ?? searchParams.get('loginAccountId') ?? undefined
  const orgId = forceOrgId ?? searchParams.get('orgId') ?? undefined
  const formId = forceFormId ?? searchParams.get('formId') ?? ''
  const formName = forceFormName ?? searchParams.get('formName') ?? ''

  // TODO: use this [onSubmit] when form renderer onSubmit override is working
  //   const onSubmit = async (data: ICreateSubmission) => {
  //     const dataToSubmit: ICreateSubmission = {
  //       formId: formId ,
  //       loginAccountId: loginAccountId,
  //       data: data.data,
  //       state: EState.Submitted,
  //     }

  //     const result = await createSubmission(dataToSubmit)
  //   }

  const getForm = useCallback(async () => {
    const formPath = `${REACT_APP_FORMIO_PROJECT_URL}/${formName}`
    const formio = new Formio(formPath)
    const form: IForm = await formio.loadForm()
    setForm(form)
  }, [])

  useEffect(() => {
    getForm().catch((error) => {
      console.log(error)
    })
  }, [getForm])

  if (form == undefined) {
    return <></>
  } else {
    return (
      <NonAuthenticatedFormPageContext.Provider
        value={{
          loginAccountId: loginAccountId,
          orgId: orgId,
          formId: formId,
          formName: formName,
          // TODO: is draft functionality needed here?
          state: 'submitted',
          form,
        }}
      >
        {children}
      </NonAuthenticatedFormPageContext.Provider>
    )
  }
}
