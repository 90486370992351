import { Either, Failure } from '../../../core/core'
import { getCurrentUser } from '../../../domain/domain'
import { ICSVUserResponse, IUser, IUserList, IUsersDeleted } from '../../../domain/interfaces/IUser'
import { httpDelete, httpDeleteWithPayload, httpGet, httpGetAnon, httpGetBlob, httpPost, httpPut } from '../../data'

export async function getUsers(
  queryString: string,
): Promise<Either<Failure, IUserList>> {
  const res: Either<Failure, IUserList> = await httpGet(
    `/users/v2?${queryString}`,
    false,
  )

  return res
}

export async function getUserById(
  id: string,
): Promise<Either<Failure, IUser>> {
  const res: Either<Failure, IUser> = await httpGet(
    `/users/${id}`,
    true,
  )

  return res
}

export async function usersClientSearch(
  skip: number,
  limit?: number,
  key?: string,
): Promise<Either<Failure, IUserList>> {
  const res: Either<Failure, IUserList> = await httpGet(
    `/users/client/search?key=${key}&skip=${skip}&limit=${limit ?? 5}`,
    false,
  )

  return res
}

export async function createUser(
  email: string,
  name: string,
  roleIds: string[],
  projectIds: string[],
  levelIds: string[],
  phone?: string,
  pointOfContactId?: string | null,
): Promise<Either<Failure, IUser>> {
  const res: Either<Failure, IUser> = await httpPost(
    '/users',
    {
      email,
      fullName: name,
      roleIds,
      projectIds,
      levelIds,
      phone,
      pointOfContactId
    },
    undefined, //no additional headers
    false, // direct data parsing is false
  )

  return res
}

export async function editUser(
  userId: string,
  email: string,
  name: string,

  roleIds: string[],
  projectIds: string[],
  levelIds: string[],
  phone?: string,
  pointOfContactId?: string | null,
): Promise<Either<Failure, IUser>> {
  const res: Either<Failure, IUser> = await httpPut(
    `/users/${userId}`,
    {
      email,
      fullName: name,
      roleIds,
      projectIds,
      levelIds,
      phone,
      pointOfContactId
    },
    false, // direct data parsing is false
  )

  return res
}

export async function deleteUser(
  userId: string,
): Promise<Either<Failure, IUser>> {
  const res: Either<Failure, IUser> = await httpDelete(
    `/users/${userId}`,
    undefined, //no additional headers
  )

  return res
}

export async function deleteUsers(
  userIds: string[],
): Promise<Either<Failure, IUsersDeleted>> {
  const res: Either<Failure, IUsersDeleted> = await httpDeleteWithPayload(
    '/users', { userIds }
  )
  return res
}

export async function searchUsers(
  skip: number,
  limit?: number,
  key?: string,
): Promise<Either<Failure, IUserList>> {
  const urlStr = `/users/search?key=${key}&skip=${skip}&limit=${limit ?? 8}`
  const res: Either<Failure, IUserList> = await httpGet(urlStr
    ,
    false,
  )
  return res
}

export async function anonGetOneUser(
  orgId: string,
  userId: string,
): Promise<Either<Failure, IUser>> {
  const res: Either<Failure, IUser> = await httpGetAnon(
    `/anonymous/users/${userId}`,
    { orgId },
    true,
  )
  return res
}

export async function usersCSValidate(
  csvFile: File,
): Promise<Either<Failure, any>> {
  const formData = new FormData()
  formData.append('csvFile', csvFile)

  const res: Either<Failure, string> = await httpPost(
    '/users/validateCSV',
    formData,
  )
  return res
}

export async function usersCSVCreate(
  csvFile: File,
): Promise<Either<Failure, ICSVUserResponse>> {
  const formData = new FormData()
  formData.append('csvFile', csvFile)

  const res: Either<Failure, ICSVUserResponse> = await httpPost(
    '/users/uploadCSV',
    formData,
  )
  return res
}
export async function usersTemplateCSVGet(): Promise<
  Either<Failure, Blob>
  > {
  const res: Either<Failure, Blob> = await httpGetBlob(
    '/users/downloadTemplateCSV ',
  )
  return res
}

export const getAssignedUserId = (currentUser: IUser | undefined, feature: string, id: string): string | undefined => {

  if (!currentUser) return 'notFound'
  switch (feature) {
    case 'levels': {
      const level = currentUser.levels?.some((level) => level.id == id)
      return level ? currentUser.id : 'notFound'
    }
    case 'projects': {
      const project = currentUser.projects?.some((project) => project.id == id)
      return project ? currentUser.id : 'notFound'
    }
    // no assigned user for locations, it is of you are assigned to a that locations project.
    case 'locations': {
      const project = currentUser.projects?.some((project) => project.id == id)
      return project ? currentUser.id : 'notFound'
    }
    case 'goals': {
      const project = currentUser.projects?.some((project) => project.id == id)
      return project ? currentUser.id : 'notFound'
    }
  }
  return 'notFoundDefaults'
}
//