import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridBadge, GridItem, GridStackItem } from '../gridItems'
import { EGoalDuration, IGoal } from '../../../../domain/interfaces/IGoal'

const getTypeStyle = (type: string) => {
  const t = type.toLowerCase()
  if (t === 'active') {
    return 'success'
  }
  if (t === 'canceled') {
    return 'danger'
  }
  if (t === 'paused') {
    return 'grey'
  }

  return 'primary'
}

export const goal_columns: GridColDef[] = [
  {
    field: 'name',
    renderHeader: () => <GridItem text='Name' />,
    flex: 1.5,
    renderCell: ({ row }) => (
      <GridStackItem topText={row.name} bottomText={row.ownerName} />
    ),
  },

  {
    field: 'type',
    flex: 1,
    renderHeader: () => <GridItem text='Type' />,
    renderCell: ({ row }) => <GridItem text={row.templateName} />,
  },

  {
    field: 'participants',
    flex: 1,
    renderHeader: () => <GridItem text='Participants' />,
    renderCell: ({ row }) => <GridItem text={row.participantsCount} />,
  },
  {
    field: 'incentive',
    renderHeader: () => <GridItem text='Incentive' />,
    flex: 1,
    renderCell: ({ row }) => <GridItem text={row.incentive} />,
  },
  {
    field: 'daysRemaining',
    renderHeader: () => <GridItem text='Days Left' />,
    flex: 1,
    renderCell: ({ row }) => <GridItem text={row.daysLeft} />,
  },
  {
    field: 'status',
    renderHeader: () => <GridItem text='Status' />,
    flex: 1,
    renderCell: ({ row }) => (
      <GridBadge type={getTypeStyle(row.status)} text={row.status} />
    ),
  },
]

export const normalizeGoalsForGrid: any = (goals: IGoal[], userID: string) => {
  if (!goals) return []
  return goals?.map((goal: IGoal) => {
    const isUser = goal.id == userID
    return {
      id: goal.id,
      ownerName: isUser ? 'You' : goal.ownerName,

      // ownerName: goal.ownerName,
      incentive: goal.incentive,
      participantsCount: goal.participantsCount,
      status: goal.state,
      daysLeft:
        goal.daysRemaining
          ? goal.daysRemaining
          : goal.duration == EGoalDuration.NONE
            ? 'Continuous'
            : 'NA',
      templateName: goal.templateName,
      name: goal.name,
    }
  })
}
