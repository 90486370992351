import React from 'react'
import { SxProps, Typography, TypographyProps } from '@mui/material'
import { AppColors, AppFonts } from '../../../Theme'

interface GridItemProps extends TypographyProps {
  text: string
  color?: string
  sx?: SxProps
  inactive?: boolean
  onClick?: any
  alert?: boolean
}

/**
 * Represents a grid item component, it is the default item for     datagrid cells
 * @function GridItem
 * @param {GridItemProps} props - The props for the GridItem component.
 * @returns {JSX.Element}
 * @example  renderCell: ({ row }) => <GridItem text={row.id} />,
 */
export const GridItem: React.FC<GridItemProps> = ({
  text,
  color,
  sx,
  inactive = false,
  alert = false,
  onClick,
  ...rest
}) => {
  return (
    <Typography
      {...rest}
      onClick={onClick}
      className='ellipsis-text'
      noWrap
      sx={{
        
        color: alert ? AppColors.danger600 : inactive ? AppColors.gray25 : color ? color :AppColors.gray50,

        // width:'100%',
        // height:'100%',
        // display: 'flex',
        // alignItems: 'center' ,/* Vertical alignment */
        paddingRight:'12px',
        textOverflow:'ellipsis',
        cursor:'pointer',
        ...AppFonts.textSMedium,
        lineHeight:'20px',
        ...sx,
      }}
    >
      {text}
    </Typography>
  )
}
