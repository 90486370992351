import { MenuItem, MenuItemProps } from '@mui/material'
import React from 'react'
import { AppColors, AppHovers } from '../../Theme'

interface Props extends MenuItemProps {
  darkMode?: boolean
  key?: string
}

// Wrapper component for MenuItem
const EdifyMenuItem: React.FC<Props> = ({
  darkMode = false,
  key,
  title,
  value = title,
  sx,
  children,
  ...rest
}) => {
  return (
    <MenuItem
      sx={{
        '&:hover': darkMode ? { ...AppHovers.dark } : { ...AppHovers.primary },
        '.MuiMenuItem-root.Mui-selected:hover': darkMode ? { ...AppHovers.dark } : { ...AppHovers.primary },
        '&.Mui-selected':  darkMode ? { background: 'black' } : { background: AppColors.neutral600},
        color: darkMode ? AppColors.baseWhite : AppColors.black,
        ...sx,
      }}
      key={key}
      value={value}
      {...rest}
    >
      {children || title}
    </MenuItem>
  )
}

export default EdifyMenuItem
