import React, { useState } from 'react'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'

import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
import pluralize from 'pluralize'
import {
  document_columns,
  normalizeDocumentsForDataGrid,
} from '../../components/datagrid/gridData/documents'
import PDFViewerModal from './PDFViewerModal'

interface IDocumentLibraryDataGridProps {
  vm: any
  title: string
  rows: any
  checkboxSelection?: boolean
  error?: string
  totalCount?: number
}

const DocumentLibraryDataGrid: React.FC<IDocumentLibraryDataGridProps> = ({
  vm,
  title,
  rows,
  checkboxSelection = true,
  error,
  totalCount = -1,
}) => {
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedPdfUrl, setSelectedPdfUrl] = useState<string>('')
  const [selectedPdfId, setSelectedPdfUrlId] = useState<string>('')
  const canDelete = vm.canEditDocuments && checkboxSelection

  const handleRowClicked = (params: GridRowParams) => {
    setSelectedPdfUrl(params.row.path)
    setSelectedPdfUrlId(params.row.id)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  // delete documentLibrary/s
  const removeClicked = async () => {
    vm.showDeleteConfirmation(selectionModel as string[])
    setSelectionModel([])
  }

  const renderHeaderButtons = () => {
    // don't render bulk action items if turned off
    if (!canDelete) return <></>
    const noSelections = selectionModel.length === 0
    return (
      <BulkDeleteButton noSelections={noSelections} onClick={removeClicked} />
    )
  }

  return (
    <>
      <EdifyDataGrid
        data-testid='DocumentLibraryDataGrid'
        paginationMode='server'
        noRowsText={`No ${pluralize(title).toLocaleLowerCase()} found.`}
        title={title}
        renderHeaderButtons={renderHeaderButtons()}
        columns={document_columns}
        rows={normalizeDocumentsForDataGrid(rows)}
        error={error || vm.error}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={handleRowClicked}
        loading={vm.isLoading || vm.isLoadMore}
        rowCount={totalCount >= 0 ? totalCount : vm.totalCount}
        loadMoreRows={vm.loadMore}
        reload={vm.getDocumentLibrary}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={canDelete}
        initSort={[
          {
            field: 'name',
            sort: 'asc',
          },
        ]}
      />
      <PDFViewerModal
        pdfUrl={selectedPdfUrl}
        pdfId={selectedPdfId}
        open={modalOpen}
        onClose={handleCloseModal}
      />
    </>
  )
}

export default DocumentLibraryDataGrid
