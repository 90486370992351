import React from 'react'
import { Box } from '@mui/material'
import PageContainer from '../../components/page/PageContainer'
import { EdifyButton } from '../../components/buttons'

export const ROUTE_DEMO = '/demo'

export const DemoPage: React.FC = () => {
  return (
    <PageContainer
      title='Conversational AI'
      renderRightItems={() => (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ marginRight: '24px' }}>
            <EdifyButton
              data-testid='DemoPage_ObservationButton'
              title='Observation'
              href='https://digitieke.com/SafetyMojoAssistant/'
            ></EdifyButton>
          </Box>
          <Box>
            <EdifyButton
              data-testid='DemoPage_PTPButton'
              title='PTP'
              href='https://digitieke.com/SafetyMojoPre-taskPlan/'
            ></EdifyButton>
          </Box>
        </Box>
      )}
    >
      <Box></Box>
    </PageContainer>
  )
}
