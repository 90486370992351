import React from 'react'

import { GridColDef } from '@mui/x-data-grid'
import { GridItem, GridStackItem } from '../gridItems'
import { dateToMMMDYYYY } from '../../../../core/core'
import { ISubmission } from '../../../../domain/interfaces/ISubmission'
//Hardcoded columns for Submissions with out Dynamic Columns
export const mock_columns: GridColDef[] = [
  {
    field: 'createdAt',
    renderHeader: () => <GridItem text='Submitted' />,
    flex: 1,
    // TODO: no title INFO removing for now
    // renderCell: ({ row }) => (
    //   <GridStackItem
    //     topText={'Title'}
    //     bottomText={dateToMMMDYYYY(row.form?.createdAt ?? '')}
    //   />
    // ),
    renderCell: ({ row }) => (
      <GridItem text={dateToMMMDYYYY(row.form?.createdAt ?? '')} />
    ),
  },
  {
    field: 'ownerName',
    flex: 1,
    renderHeader: () => <GridItem text='Owner Name' />,
    renderCell: ({ row }) => <GridItem text={row.ownerName} />,
  },
  {
    field: 'submittedBy',
    flex: 1,
    renderHeader: () => <GridItem text='Submitted By' />,
    renderCell: ({ row }) => <GridItem text={row.submittedBy} />,
  },
  {
    field: 'state',
    renderHeader: () => <GridItem text='State' />,
    width: 120,
    renderCell: ({ row }) => <GridItem text={row.state} />,
  },
]

// for tables with non dynamic headers
export const normalizeSubmissionsMockDataGrid = (
  submissions?: ISubmission[],
) => {
  if (!submissions) return []
  return submissions.map((sub: ISubmission) => {
    return {
      id: sub.id,
      form: { formID: sub.formId, createdAt: sub.createdAt },
      ownerName: sub.ownerName,
      ownerEmail: sub.id,
      submittedBy: sub.submittedBy,
      state: sub.state,
    }
  })
}
