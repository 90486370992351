import React from 'react'
import { spacingNames, SpaceMappings, borderNames, BorderMappings } from '../../Theme'
import { Box, BoxProps, SxProps } from '@mui/material'

interface EBoxProps extends BoxProps {
  p?: spacingNames
  m?: spacingNames
  border?: borderNames 
  sx?: SxProps
  children: React.ReactNode
  //TODO: flex
  //ml pl mr pr mt pt mb pb etc
}

/**
 * A custom Box component, Forcing the use of our theme. 
 */
export const EBox: React.FC<EBoxProps> = ({
  p = '0',
  m = '0',
  border = 'none',
  children,
  sx,
  ...rest
}) => {
  const padding = SpaceMappings[p]
  const margin = SpaceMappings[m]
  border = BorderMappings[border]
  return (
    <Box sx={{ padding, margin, border, ...sx }} {...rest}>
      {children}
    </Box>
  )
}

// Create sub components for different typography components.
// where the font and color are already set.
