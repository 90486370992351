import React from 'react'
import { Box, Grid } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import InnerPageHeader from '../../components/inner-page-container/InnerPageHeader'
import SubLocationForm from './SubLocationForm'
import SubLocationHierarchyCard from './SubLocationHeirachyCard'
import { EdifyFieldLabel } from '../../components/form'
import TreeMUI from './TreeMUI'
import { ISubLocationHierarchyName } from '../../../domain/interfaces/ILocation'
import { useLocationPageViewModel } from './LocationViewModel'
import { useSubLocationPageViewModel } from './SubLocationViewModel'

interface Props {
  locationId?: string
  subLocationTree: any
  subLocationIds: string[]
}
const SubLocationCard: React.FC<Props> = ({
  locationId,
  subLocationTree,
  subLocationIds,
}) => {
  const { locationHierarchies } = useSubLocationPageViewModel()
  const handleEditClicked = () => {
    // setIsEdit(!isEdit)
  }
  const handleDeleteClicked = () => {
    //TODO
  }
  const header = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <InnerPageHeader title='Sub Location Card' />
        <Box>
          <EdifyButton
            noBackground
            title={'Edit'}
            onClick={handleEditClicked}
            buttonStyle={{ maxHeight: '44px' }}
          />
          <EdifyButton
            noBackground
            title={'Delete'}
            onClick={handleDeleteClicked}
          />
        </Box>
      </Box>
    )
  }
  if (!subLocationTree[0]) return <div>Loading...</div>
  return (
    // <InnerPageContainer headerTitle='Sub Location Card'>
    <>
      {/* {header()} */}
      {subLocationTree[0].id != 'init-id' ? (
        <>
          <Grid container spacing={'24px'} sx={{ marginTop: '12px' }}>
            <Grid item md={12} lg={8}>
              <EdifyFieldLabel label='Structure' />
              <TreeMUI
                locationId={locationId}
                subLocationsTree={subLocationTree}
                isEdit={false}
                defaultExpanded={subLocationIds}
              />
            </Grid>
            <Grid item md={12} lg={4}>
              <EdifyFieldLabel label='Sub Level Names' />
              <SubLocationHierarchyCard  />
            </Grid>
          </Grid>
        </>
      ) : (
        <SubLocationForm
          setSubLocationTree={() => 1}
          subLocationTree={subLocationTree}
          subLocationIds={subLocationIds}
        />
      )}
    </>
  )
}

export default SubLocationCard
