import {
  ExpandMore,
  ExpandLess,
  Circle,
  PieChartOutline,
  MemoryOutlined,
  ReportGmailerrorred,
  AccountTreeOutlined,
  HandymanOutlined,
  EmojiEventsOutlined,
  ErrorOutline,
  Settings,
} from '@mui/icons-material'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'
import { Toolbar, Box, List, styled, Collapse } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiDrawer from '@mui/material/Drawer'
import { AppColors } from '../../../Theme'
import { NavbarLinkTile } from '../../tiles/navbar-tile/NavbarLinkTile'
import FormLinks from './FormLinks'

import { ROUTE_USERS } from '../../../pages/users/UsersPage'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavbarDropdownTile } from '../../tiles/navbar-tile/NavbarDropdown'
import { ROUTE_DASHBOARD } from '../../../pages/dashboard/DashboardPage'
import { ROUTE_DEMO } from '../../../pages/demo/DemoPage'
import { getCurrentOrg, getCurrentUser } from '../../../../domain/domain'
import HierarchyLinks from './HierarchyLinks'
import { ROUTE_CORRECTIVE_ACTIONS } from '../../../pages/submissions/SubmissionsPage'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'
import { ROUTE_CONTRACTORS } from '../../../pages/contractors/ContractorsPage'
import { ROUTE_GOALS } from '../../../pages/goals/GoalsPage'
import { ETypography } from '../../fonts/ETypography'
import East from '@mui/icons-material/East'
import { showContactSalesDialog } from '../../../pages/home/ContactSalesDialogue'
import { getDaysLeft } from '../../../../core/core'
import { IUser } from '../../../../domain/interfaces/IUser'
import { useGlobalProvider } from '../../../../providers/GlobalProvider'
import { ROUTE_ORG_SETTINGS } from '../../../pages/org-settings/OrgSettingsPage'
import { ROUTE_ROLES } from '../../../pages/roles/Roles'
import { ROUTE_DOCUMENT_LIBRARY } from '../../../pages/document-library/DocumentLibraryPage'
import { logout } from '../../../../data/data'
import { EdifyButton } from '../../buttons'
import LogoutIcon from '@mui/icons-material/Logout'
import { ROUTE_FENCES } from '../../../pages/org-settings/ManageFencesPage'
// import { DynamicIconDropdown } from '../../tiles/navbar-tile/DynamicIconDropdown'

export const drawerWidth = '280px'
export const drawerWidthNum = 280

const Drawer = styled(MuiDrawer)(() => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
}))

export const EdifyDrawer: React.FC = () => {
  const navigate = useNavigate()

  const org = getCurrentOrg()
  const user = getCurrentUser() as IUser

  // TODO ROLE use flage
  const isSuperAdmin = user?.roles[0].name === 'SUPER_ADMIN'
  const {
    isContractor,
    isDemoAccount,
    showDrawer,
    documentLinks,
    canViewRoles,
    canViewProjects,
    canViewLevels,
    canViewDashboard,
    canViewDocuments,
    canViewCorrectiveActions,
    canViewContractors,
    canViewGoals,
    canViewSubmissions,
    canViewUsers,
  } = useOrganizationProvider()
  const { isGlobalUser } = useGlobalProvider()
  const [openForm, setOpenForm] = useState<boolean>(true)

  const [openOrg, setOpenOrg] = useState<boolean>(true)

  const [openDemo, setOpenDemo] = useState<boolean>(true)
  const [openDocuments, setOpenDocuments] = useState<boolean>(true)

  const [edifyEmail, setEdifyEmail] = useState<string | undefined>(undefined)

  const canViewSettings =
    (!isContractor && canViewRoles) || isGlobalUser || isSuperAdmin

  useEffect(() => {
    const { email } = getCurrentUser() ?? {}
    const findEdifyEmail = email
      ?.split('@')
      .find((value) => value == 'edify.ai')
    setEdifyEmail(findEdifyEmail)
  }, [])

  const handleLogout = () => {
    logout()
    navigate('/')
  }

  const handleClickForm = () => {
    setOpenForm(!openForm)
  }

  const handleClickOrg = () => {
    setOpenOrg(!openOrg)
  }

  const handleClickDemo = () => {
    setOpenDemo(!openDemo)
  }

  const handleClickDocuments = () => {
    setOpenDocuments(!openDocuments)
  }

  const renderDocumentsLinks = () => {
    if (!org?.isDocumentLibraryEnabled) return null
    if (!org?.documentLibWebViewEnabled) return null

    if (documentLinks && documentLinks.length > 0) {
      return (
        <>
          <NavbarDropdownTile
            // pathMatch={ROUTE_DOCUMENT_LIBRARY}
            // route={ROUTE_DOCUMENT_LIBRARY}
            hide={!canViewDocuments}
            data-testId='EdifyDrawer_Document_Library'
            paths={documentLinks.map((d) => d.id)}
            iconComponent={AutoStoriesOutlinedIcon}
            onClick={handleClickDocuments}
            suffixIconComponent={openDocuments ? ExpandLess : ExpandMore}
            linkTitle='Document Library'
          />
          <Collapse in={openDocuments} timeout='auto' unmountOnExit>
            <List>
              {documentLinks.map((document) => (
                <NavbarLinkTile
                  key={document.id}
                  pathMatch={`${ROUTE_DOCUMENT_LIBRARY}/${document.id}`}
                  route={`${ROUTE_DOCUMENT_LIBRARY}/${document.id}`}
                  iconComponent={Circle}
                  iconComponentStyle={{
                    height: '6px',
                  }}
                  linkTitle={document.name}
                  style={{ paddingLeft: '56px' }}
                />
              ))}
            </List>
          </Collapse>
        </>
      )
    }
    return (
      <NavbarLinkTile
        hide={!canViewDocuments}
        pathMatch={ROUTE_DOCUMENT_LIBRARY}
        data-testId='EdifyDrawer_Document_Library1'
        route={ROUTE_DOCUMENT_LIBRARY}
        iconComponent={AutoStoriesOutlinedIcon}
        linkTitle='Document Library'
      />
    )
  }

  // fixes issues links where active links where not being set
  useLocation()
  return (
    <Drawer
      className='edify-drawer'
      data-testid='EdifyDrawer'
      variant='persistent'
      open={showDrawer}
      anchor={'left'}
      PaperProps={{
        sx: {
          width: drawerWidth,
          backgroundColor: AppColors.gray900,
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{ color: AppColors.g000, mt: '19px', pt: '12px' }}
        role='presentation'
      >
        <List sx={{ gap: '12px' }}>
          {isDemoAccount && (
            <Box
              sx={{
                background: AppColors.gray800,
                fontWeight: 500,
                padding: '16px',
                marginBottom: '16px',
                '&:hover': {
                  cursor: 'pointer',
                  background: AppColors.gray700,
                },
              }}
            >
              <Box
                sx={{ display: 'flex' }}
                onClick={() => showContactSalesDialog({ user })}
              >
                <ErrorOutline
                  sx={{
                    height: '28px',
                    color: AppColors.d600,
                    marginRight: '8px',
                  }}
                />

                <Box>
                  <ETypography font='XLS' color='white'>
                    {/* TODO: check when BE done */}
                    {getDaysLeft(org?.terminationDate ?? new Date().toString())}
                  </ETypography>
                  <ETypography
                    font='SR'
                    color='gray25'
                    sx={{ marginTop: '4px', marginBottom: '16px' }}
                  >
                    Remaining on your free trial
                  </ETypography>
                  <Box>
                    <ETypography font='XLS' color='white'>
                      Talk to Sales <East sx={{ marginLeft: '6px' }} />
                    </ETypography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {/* {!isContractor && (
            <NavbarLinkTile
              data-testId='Home'
              route={ROUTE_HOME}
              pathMatch={ROUTE_HOME}
              iconComponent={Home}
              linkTitle='Home'
            />
          )} */}
          {!isContractor && (
            <NavbarLinkTile
              hide={!canViewDashboard}
              data-testId='EdifyDrawer_Dashboard'
              route={ROUTE_DASHBOARD}
              iconComponent={PieChartOutline}
              linkTitle='Dashboard'
            />
          )}
          <NavbarLinkTile
            hide={!canViewCorrectiveActions && !isContractor}
            data-testId='EdifyDrawer_Home'
            route={ROUTE_CORRECTIVE_ACTIONS}
            pathMatch={ROUTE_CORRECTIVE_ACTIONS}
            iconComponent={ReportGmailerrorred}
            linkTitle='Corrective Actions'
          />
          {!isContractor && (
            <NavbarLinkTile
              hide={!canViewGoals}
              data-testId='Goals_Controls'
              route={ROUTE_GOALS}
              pathMatch={ROUTE_GOALS}
              iconComponent={EmojiEventsOutlined}
              linkTitle='Goals & Controls'
            />
          )}
          {!isContractor && (
            <>
              {canViewSubmissions && <FormLinks />}
              <>
                {renderDocumentsLinks()}
                <NavbarLinkTile
                  hide={!canViewContractors}
                  pathMatch={ROUTE_CONTRACTORS}
                  data-testId='EdifyDrawer_Contractors'
                  route={ROUTE_CONTRACTORS}
                  iconComponent={HandymanOutlined}
                  linkTitle='Contractors'
                />
                <NavbarDropdownTile
                  hide={!canViewUsers && !canViewLevels && !canViewProjects}
                  data-testId='EdifyDrawer_Organization'
                  iconComponent={AccountTreeOutlined}
                  linkTitle='Organization'
                  paths={['users', 'levels', 'projects']}
                  onClick={handleClickOrg}
                  suffixIconComponent={openOrg ? ExpandLess : ExpandMore}
                />
                <Collapse in={openOrg} timeout='auto' unmountOnExit>
                  <List>
                    <NavbarLinkTile
                      hide={!canViewUsers}
                      pathMatch={ROUTE_USERS}
                      data-testId='EdifyDrawer_Organization_Users'
                      route={ROUTE_USERS}
                      iconComponent={Circle}
                      iconComponentStyle={{
                        height: '6px',
                      }}
                      linkTitle='Users'
                      style={{ paddingLeft: '56px' }}
                    />
                    <HierarchyLinks
                      canViewProjects={canViewProjects}
                      canViewLevels={canViewLevels}
                    />
                  </List>
                </Collapse>
              </>
            </>
          )}

          <>
            <NavbarDropdownTile
              data-testId='EdifyDrawer_Settings'
              iconComponent={Settings}
              linkTitle='Settings'
              paths={['roles']}
              onClick={handleClickDemo}
              suffixIconComponent={openDemo ? ExpandLess : ExpandMore}
              hide={!canViewSettings}
            />
            <Collapse in={openDemo} timeout='auto' unmountOnExit>
              <List>
                {' '}
                <NavbarLinkTile
                  data-testId='EdifyDrawer_Org_Settings'
                  route={ROUTE_ORG_SETTINGS}
                  hide={!isGlobalUser}
                  iconComponent={MemoryOutlined}
                  iconComponentStyle={{
                    height: '6px',
                  }}
                  linkTitle='Organization'
                  style={{ paddingLeft: '56px' }}
                />
                <NavbarLinkTile
                  data-testId='EdifyDrawer_Role_Settings'
                  route={ROUTE_ROLES}
                  iconComponent={MemoryOutlined}
                  iconComponentStyle={{
                    height: '6px',
                  }}
                  hide={!canViewRoles}
                  linkTitle='Manage Roles'
                  style={{ paddingLeft: '56px' }}
                />
                <NavbarLinkTile
                  data-testId='EdifyDrawer_Role_Settings'
                  route={ROUTE_FENCES}
                  iconComponent={MemoryOutlined}
                  iconComponentStyle={{
                    height: '6px',
                  }}
                  hide={!isSuperAdmin}
                  linkTitle='Manage Fences'
                  style={{ paddingLeft: '56px' }}
                />
              </List>
            </Collapse>
          </>

          {edifyEmail && (
            <>
              <NavbarDropdownTile
                data-testId='EdifyDrawer_Demo'
                iconComponent={MemoryOutlined}
                linkTitle='Demo'
                paths={['demo']}
                onClick={handleClickDemo}
                suffixIconComponent={openDemo ? ExpandLess : ExpandMore}
              />
              <Collapse in={openDemo} timeout='auto' unmountOnExit>
                <List>
                  <NavbarLinkTile
                    data-testId='EdifyDrawer_Demo_ConversationalAI'
                    route={ROUTE_DEMO}
                    iconComponent={MemoryOutlined}
                    iconComponentStyle={{
                      height: '6px',
                    }}
                    linkTitle='Conversational AI'
                    style={{ paddingLeft: '56px' }}
                  />
                </List>
              </Collapse>
            </>
          )}
        </List>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            marginLeft: '14px',
            '&:hover': {
              cursor: 'pointer',
              opacity: 1,
              background: AppColors.gray400,
              textDecoration: 'none',
            },
          }}
          onClick={handleLogout}
        >
          <EdifyButton
            secondary
            noBackground
            textStyle={{
              color: '#979AA0',
              '&:hover': { color: AppColors.g000 },
            }}
            title='Logout'
            icon={<LogoutIcon sx={{ color: '#979AA0' }} />}
          />
        </Box>
      </Box>
    </Drawer>
  )
}
