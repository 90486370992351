import { Either, Failure } from '../../../core/core'
import { createGoal, deleteGoalById, deleteGoals, goalGetById, goalsGet, goalsSearch, participantGetById, participantsDelete, participantsGet, updateGoal } from '../../../data/repositories/goals/GoalsRepository'
import { IGoal, IGoalFormData, IGoalList, IParticipant, IParticipantsList } from '../../interfaces/IGoal'

export async function adminCreateGoal(goal: IGoalFormData): Promise<Either<Failure, IGoal>> {
  return await createGoal(goal)
}

export async function adminUpdateGoal(goal: IGoalFormData, id:string): Promise<Either<Failure, IGoal>> {
  return await updateGoal(goal, id)
}

export async function getAdminGoals( queryString: string,): Promise<Either<Failure, IGoalList>> {
  return await goalsGet(queryString)
}

export async function getGoalById(id: string): Promise<Either<Failure, IGoal>> {
  return await goalGetById(id)
}

export async function adminDeleteGoals(
  goalIds: string[],
): Promise<Either<Failure, IGoalList>> {
  return await deleteGoals(goalIds)
}
export async function adminDeleteGoalById(
  id: string,
): Promise<Either<Failure, IGoal>> {
  return await deleteGoalById(id)
}

export async function searchGoals(searchTerm: string): Promise<Either<Failure, IGoal[]>> {
  return await goalsSearch(searchTerm)
}


export async function getParticipants(queryString: string): Promise<Either<Failure, IParticipantsList>> {
  return await participantsGet(queryString)
}

export async function getParticipantById(id: string): Promise<Either<Failure, IParticipant>> {
  return await participantGetById(id)
}

export async function adminDeleteParticipants(
  participantsIds: string[],
): Promise<Either<Failure, IParticipantsList>> {
  return await participantsDelete(participantsIds)
}
