import { Either, Failure } from '../../../core/core'
import { locationCreate, locationCSVCreate, locationDelete, locationGet, locationsGet, locationTemplateCSVGet, locationUpdate, subLocationCreate, subLocationCSVCreate, subLocationDelete, subLocationHierarchyNameCreate, subLocationHierarchyNameDelete, subLocationHierarchyNameUpdate, subLocationsGet, subLocationsHierarchyNameGet, subLocationTemplateCSVGet, subLocationUpdate } from '../../../data/repositories/locations/LocationsRepository'
import { ILocationFormData } from '../../../presentation/pages/location/LocationForm'
import { ILocation, ILocationList, ISubLocation, ISubLocationHierarchyName, ISubLocationHierarchyNameList, ISubLocationList } from '../../interfaces/ILocation'

export async function getLocations(queryString: string): Promise<Either<Failure, ILocationList>> {
  return await locationsGet(queryString)
}
export async function getLocationById(id: string): Promise<Either<Failure, ILocation>> {
  return await locationGet(id)
}

export async function createLocation(location: ILocationFormData): Promise<Either<Failure, ILocation>> {
  return await locationCreate(location)
}
export async function updateLocation(location: ILocationFormData, id: string): Promise<Either<Failure, ILocation>> {
  return await locationUpdate(location, id)
}

export async function deleteLocation(id: string): Promise<Either<Failure, ILocation>> {
  return await locationDelete(id)
}

export async function getSubLocationHierarchyNames(): Promise<Either<Failure, ISubLocationHierarchyNameList>> {
  return await subLocationsHierarchyNameGet()
}

export async function createSubLocationHierarchyName(data: ISubLocationHierarchyName): Promise<Either<Failure, ISubLocationHierarchyName>> {
  return await subLocationHierarchyNameCreate(data)
}

export async function updateSubLocationHierarchyName(data: ISubLocationHierarchyName, id: string): Promise<Either<Failure, ISubLocationHierarchyName>> {
  return await subLocationHierarchyNameUpdate(data, id)
}

export async function deleteSubLocationHierarchyName(id: string): Promise<Either<Failure, ISubLocationHierarchyName>> {
  return await subLocationHierarchyNameDelete(id)
}

export async function getSubLocations(locationId: string): Promise<Either<Failure, ISubLocationList>> {
  return await subLocationsGet(locationId)
}

export async function createSubLocation(data: ISubLocation): Promise<Either<Failure, ISubLocation>> {
  return await subLocationCreate(data)
}

export async function updateSubLocation(data: ISubLocation, id: string): Promise<Either<Failure, ISubLocation>> {
  return await subLocationUpdate(data, id)
}

export async function deleteSubLocation(id: string): Promise<Either<Failure, ISubLocation>> {
  return await subLocationDelete(id)
}

export async function getLocationTemplateCSV(): Promise<Either<Failure, string>> {
  return await locationTemplateCSVGet()
}

export async function uploadLocationsCSVFile(csvFile: File,
  id: string,): Promise<Either<Failure, string>> {
  return await locationCSVCreate(csvFile, id)
}

export async function getSubLocationTemplateCSV(): Promise<Either<Failure, string>> {
  return await subLocationTemplateCSVGet()
}

export async function uploadSubLocationsCSVFile(csvFile: File,
  id: string,): Promise<Either<Failure, string>> {
  return await subLocationCSVCreate(csvFile, id)
}