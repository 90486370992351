import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridItem, GridStackItem } from '../gridItems'
import { ILevel } from '../../../../domain/interfaces/IProjects'

export const levels_columns: (l0Name: string) => GridColDef[] = (l0Name) => {
  return [
    {
      field: 'name',
      renderHeader: () => <GridItem text='Name' />,
      flex: 1.5,
      renderCell: ({ row }) => (
        <GridStackItem
          favorite={row.favorite}
          inactive={row.inactive}
          topText={row.name}
          bottomText={row.pointOfContact}
        />
      ),
    },
    {
      field: 'projects',
      renderHeader: () => <GridItem text={l0Name} />,
      flex: 1.5,
      renderCell: ({ row }) => (
        <GridItem text={row.projectCount} inactive={row.inactive} />
      ),
    },
    {
      field: 'status',
      flex: 1,
      renderHeader: () => <GridItem text='Status' />,
      renderCell: ({ row }) => (
        <GridItem text={row.status} inactive={row.inactive} />
      ),
    },
  ]
}

export const normalizeLevelsForDataGrid: any = (levels: ILevel[]) => {
  // should be sorted by favorite on the backend
  return levels.map((level: any) => {
    return {
      id: level.id,
      name: level.name,
      pointOfContact: level.pointOfContact?.fullName ?? 'NA',
      status: level.active ? 'Active' : 'Inactive',
      inactive: !level.active,
      projectCount: level.projects?.length || 'NA',
      favorite: level.favorited,
    }
  })
}

////NOTE DROPDOWN MENU WAS REMOVED FROM DESIGN...  IT MIGHT COME BACK SO HERE IS HOW TO TO DO IT IF IT DOES THIS WAS IN THE VM OF LEVEL YOU CREATE THE MENU THERE

// 1.
// Each drop down has its own state
//  const renderLevelDropdown = (id: string) => {
//   const level = levels.find((l) => l.id === id)
//   if (!level) return
//   const menu = [
//     {
//       text: level.favorite ? 'Unpin as Favorite' : 'Pin as Favorite',
//       icon: level.favorite ? <PinActiveIcon /> : <PinIcon />,
//       onClick: setPinClicked,
//     },
//     {
//       text: 'View Details',
//       icon: <EyeIcon />,
//       onClick: viewClicked,
//     },

//     {
//       text: `Set Status as ${
//         level.status === 'active' ? 'inactive' : 'active'
//       }`,
//       icon: <FlagIcon />,
//       onClick: setStatusClicked,
//     },
//     {
//       text: 'Delete Division',
//       icon: <TrashGreyIcon />,
//       onClick: deleteClicked,
//     }
//   ]

//   return (
//     <ButtonDropdown
//       clickedId={id}
//       menu={menu}
//     />
//   )
// }

//2.
// THEN MAKE THE HEADERS A FUNCTION THAT TAKES THAT AND RETURNS HEADERS

// export const getLevelColumnsHeader = (renderLevelDropdown:(id:string)=>any) => {
//   const level_columns: GridColDef[] = [
//   .....
//   // pass renderLevelDropDown to actions header
//   {
//     field: 'actions',
//     renderHeader: () => <GridItem text='Actions' />,
//     width: actionColumnWidth,
//     renderCell: ({ row }) => (
//       renderLevelDropdown(row.id)
//     ),
//   },
//   ...
//   return level_columns

//3.
// in levels dataGrid you create colums like this
// columns={getLevelColumnsHeader(vm.renderLevelDropdown)}
