import { AvatarProps, Box, SxProps, Typography } from '@mui/material'

import React from 'react'
import { GridItem } from './GridItem'
import { AppColors, AppFonts } from '../../../Theme'

interface GridStackItemProps {
  topText: string
  topTextSx?: SxProps
  bottomText: string
  inactive?: boolean
  favorite?: boolean
  you?: boolean
  avatar?: React.ComponentType<AvatarProps>
}

/**
 * Represents a custom grid item component where you can add top text bottom text and a avator.
 * @function GridStackItem
 * @param {GridStackItemProps} props - The props for the GridStackItem component.
 * @returns {JSX.Element} - The rendered GridStackItem component.


 */

export const GridStackItem: React.FC<GridStackItemProps> = ({
  topText,
  topTextSx,
  bottomText,
  avatar: Avatar,
  you,
  inactive = false,
  favorite = false,
}) => {
  const color = inactive
    ? AppColors.gray25
    : favorite
      ? AppColors.p800
      : AppColors.gray700
  const tFont = topText == 'You' ? AppFonts.textMSemibold : AppFonts.textSMedium
  if (!Avatar) {
    return (
      <Box sx={{ width: '100%', minWidth: 0 }}>
        <Typography
          sx={{
            ...tFont,
            color,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...topTextSx,
          }}
        >
          {topText}
        </Typography>
        <GridItem text={bottomText} inactive={inactive} />
      </Box>
    )
  }
  return (
    
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        sx={{
          width: 32,
          height: 32,
          alignItems: 'center',
        }}
      />
      <Box>
        {/* WANT THIS THIS TO ELLIPSE */}
        <Typography sx={{ ...tFont, color }}>{topText}</Typography>
        <GridItem text={bottomText} inactive={inactive} />
      </Box>
    </Box>
  )
}
