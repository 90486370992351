/* eslint-disable react/prop-types */
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogActions } from '@mui/material'

import { EdifyButton } from '../../buttons'
import { AppColors, AppFonts } from '../../../Theme'

let setController:
  | React.Dispatch<React.SetStateAction<IConfirmationDialogProps | undefined>>
  | undefined

let showDialog: React.Dispatch<React.SetStateAction<boolean>> | undefined
/**
 * Show [Confirmation Dialog].
 *
 * @param submission
 */
export function showOneConfirmationDialog(props: IConfirmationDialogProps) {
  setController?.(props)
  showDialog?.(true)
}

export function hideConfirmationDialog() {
  showDialog?.(false)
  setController?.(() => undefined)
}

export interface IConfirmationDialogProps {
  title: string
  message: string
  confirmText?: string
  onConfirm: () => void
}

export const OneConfirmationDialog = () => {
  const [open, setOpen] = React.useState(false)
  const [props, setProps] = React.useState<IConfirmationDialogProps>()

  setController = setProps
  showDialog = setOpen

  const onConfirmClick = () => {
    props?.onConfirm()
    hideConfirmationDialog()
  }

  return (
    <Dialog
      data-testid='ConfirmationDialog'
      open={open}
      maxWidth={'xs'}
      PaperProps={{
        sx: dialogStyling,
      }}
    >
      <DialogTitle
        sx={{
          ...AppFonts.textXLSemibold,
          color: AppColors.gray700,
          textAlign: 'center',
          p: 0,
        }}
      >
        {props?.title}
      </DialogTitle>
      <DialogContent sx={{ p: 0, pb: 16 }}>
        <DialogContentText
          sx={{ ...AppFonts.textSMedium, color: AppColors.gray50, p: 5 }}
        >
          {props?.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          p: 0,
        }}
      >
        <EdifyButton
          data-testid='ConfirmationDialogButton'
          fullWidth
          title={props?.confirmText ?? 'Confirm'}
          onClick={onConfirmClick}
        />
      </DialogActions>
    </Dialog>
  )
}

export const showOneConfirmationDeleteMessage = async (
  item: string,
  onConfirm: () => void,
) => {
  return showOneConfirmationDialog({
    title: 'Confirm',
    message: 'Confirm...',
    confirmText: 'Delete',
    onConfirm: async () => {
      await onConfirm()
      hideConfirmationDialog()
    },
  })
}

const dialogStyling = {
  width: '353px',
  height: 'auto',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 16,
}
