import React, { useState } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Box } from '@mui/material'
import { ETypography } from '../../components/fonts/ETypography'
import { AppColors } from '../../Theme'
import { formatSnakeCase } from '../../../core/utils/text-formaters/TextFormatters'
import { useSubmissionsPageViewModel } from '../submissions/SubmissionsViewModel'
import {
  createFormRolePermission,
  updateFormRolePermission,
} from '../../../data/data'

import { ErrorToast } from '../../../core/utils/toast-notifications/ToastNotifications'

import { getForm } from '../../../domain/domain'
import { IForm, IRolePermission } from '../../../domain/interfaces/IForm'
import { IRole } from '../../../domain/interfaces/IRole'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

interface IRoleSettingProps {
  role: IRolePermission
  formId: string
}

const RoleFormSetting: React.FC<IRoleSettingProps> = ({ role, formId }) => {
  return (
    <Box
      sx={{
        border: `1px solid ${AppColors.neutral600}`,
        marginBottom: '12px',
        marginTop: '12px',
        padding: '8px 12px',
        borderRadius: '4px',
      }}
    >
      <ETypography sx={{ marginBottom: '12px' }} font='LSB' color='gray700'>
        {formatSnakeCase(role.roleName)}
      </ETypography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <RadioButtonsGroup
          id={role.id}
          initRoles={{ create: role.create, edit: role.edit, read: role.read }}
          roleId={role.roleId}
          formId={formId}
          groupName='View'
          value={role.read}
          roleName={role.roleName}
          groupKey='read'
          values={[
            { label: 'None', value: 0 },
            { label: 'Own', value: 1 },
            { label: 'All', value: 2},
          ]}
        />
        <RadioButtonsGroup
          id={role.id}
          roleId={role.roleId}
          roleName={role.roleName}
          initRoles={{ create: role.create, edit: role.edit, read: role.read }}
          formId={formId}
          groupName='Create'
          value={role.create}
          groupKey='create'
          values={[
            { label: 'None', value: 0 },
            { label: 'All', value: 2},
          ]}
        />
        <RadioButtonsGroup
          id={role.id}
          roleId={role.roleId}
          roleName={role.roleName}
          initRoles={{ create: role.create, edit: role.edit, read: role.read }}
          formId={formId}
          groupName='Edit'
          groupKey='edit'
          value={role.edit}
          values={[
            { label: 'None', value: 0 },
            { label: 'Own', value: 1 },
            { label: 'All', value: 2},
          ]}
        />
      </Box>
    </Box>
  )
}

interface IFormRoleSettingProps {
  form: IForm
  roles: IRole[]
  setForms: (forms: IForm[]) => void
  roleName?: string
}
export const FormRoleSetting: React.FC<IFormRoleSettingProps> = ({
  form,
  roles,
  setForms,
  roleName,
}) => {
  const sortedRoles = [
    ...form.rolePermissions!.sort((a, b) => {
      return a.roleName > b.roleName ? 1 : -1
    }),
  ]
  if (roleName) {
    const role = form.rolePermissions!.find(
      (r) => r.roleName == roleName,
    ) as IRolePermission

    return (
      <InnerPageContainer headerTitle={form.title}>
        <RoleFormSetting formId={form.id} role={role} />
      </InnerPageContainer>
    )
  }
  return (
    <InnerPageContainer headerTitle={'Role Permissions'} collapsed collapsible>
      {sortedRoles.map((r, i) => (
        <RoleFormSetting formId={form.id} key={`${r.id}-${i}`} role={r} />
      ))}
    </InnerPageContainer>
  )
}



interface IRadioButtonsGroupProps {
  values: { value: number; label: string }[]
  groupName: string
  groupKey: string
  id: string
  roleId: string
  value: number
  formId: string
  initRoles: { read: number; edit: number; create: number }
  roleName: string
}

const RadioButtonsGroup: React.FC<IRadioButtonsGroupProps> = ({
  values,
  groupName,
  groupKey,
  id,
  formId,
  roleId,
  roleName,
  initRoles,
  value: initValue,
}) => {
  const { formUpdated } = useOrganizationProvider()
  const { setForm } = useSubmissionsPageViewModel()
  const [loading, setLoading] = useState(false)
  const [value1, setValue1] = useState(initValue)
  const handleClick = async (e: any) => {
    let res
    setLoading(true)
    try {
      if (!id) {
        res = await createFormRolePermission({
          roleId: roleId,
          formId: formId,
          ...initRoles,
          [groupKey]: parseInt(e.target.value),
        })
      } else {
        res = await updateFormRolePermission(id, {
          [groupKey]: parseInt(e.target.value),
        })
      }
      if (res.isRight()) {
        setValue1(e.target.value)
        // need to update form in state.
        const formRes = await getForm(formId)

        if (formRes.right) {
          // update form in form settings page
          setForm(formRes.right)
          // update forms in organization provider
          formUpdated(formRes.right)
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      ErrorToast({ title: 'Error Updating Role' })
    }
  }

  return (
    <FormControl>
      <ETypography font='MM' color='gray700'>
        {loading ? '...Updating' : groupName}
      </ETypography>
      <RadioGroup
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
      >
        {values.map((v) => (
          <FormControlLabel
            disabled={loading}
            key={v.value}
            {...v}
            control={
              <Radio checked={v.value == value1} onClick={handleClick} />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
