import { REACT_APP_SERVER_API } from '../../../../config'
import { IForm } from '../../../../domain/interfaces/IForm'

export const tokenFieldHidden = {
  label: 'token',
  hidden: true,
  persistent: false,
  protected: true,
  clearOnHide: false,
  key: 'token',
  type: 'textfield',
  input: true,
}
export const orgIdFieldHidden = {
  label: 'Org Id',
  hidden: true,
  protected: true,
  clearOnHide: false,
  key: 'orgId',
  type: 'textfield',
  input: true,
}
export const projectsDropdown = {
  label: 'Project',
  widget: 'choicesjs',
  tableView: true,
  dataSrc: 'url',
  data: {
    url: '{data.projectsURL}}?{{data.projectsParams}}',
    headers: [
      {
        key: 'Authorization',
        value: '{{data.token}}',
      },
      {
        key: 'orgId',
        value: '{{data.orgId}}',
      },
    ],
  },
  template: '<span>{{ item.name }}</span>',
  refreshOn: 'level1',
  lazyLoad: true,
  dataType: 'string',
  key: 'project',
  type: 'select',
  selectValues: 'data',
  valueProperty: 'id',
  disableLimit: true,
  noRefreshOnScroll: false,
  ignoreCache: true,
  input: true,
}
export const level1Dropdown = {
  label: 'Level 1',
  widget: 'choicesjs',
  tableView: true,
  dataSrc: 'url',
  data: {
    url: '{{data.level1URL}}?{{data.level1Params}}',
   
    headers: [
      {
        key: 'Authorization',
        value: '{{data.token}}',
      },
      {
        key: 'orgId',
        value: '{{data.orgId}}',
      },
    ],
  },
  dataType: 'string',
  idPath: 'name',
  template: '<span>{{ item.name }}</span>',
  lazyLoad: true,
  key: 'level1',
  type: 'select',
  selectValues: 'data',
  valueProperty: 'id',
  disableLimit: true,
  noRefreshOnScroll: false,
  ignoreCache: true,
  input: true,
}

export const updateFormComponentsWithOrgFields = async (
  form: IForm,
  level1FieldLabel: string,
  projectFieldLabel: string,
) => {

  // remove submit button from form in favor of button in side panel
  if (form.components) {
    // find submit button index
    const submitButton = form.components.find(
      (element) => element.label == 'Submit',
    )
    // remove it from the components list
    if (submitButton) {
      const index = form.components.indexOf(submitButton)
      form.components.splice(index, 1)
    }
    //****************************** */
    // Seems to be not working here on some forms
    // form.components doesn't have a key with level1
    // modify project and level 1 fields to the form.
    const level1Field = form.components.find(
      (element) => element.key == 'level1',
    )

    //****************************** */
    // so  level1Field is undefined so nothing happens
    if (level1Field != undefined) {
      level1Field.label = level1FieldLabel
    }

    // same thing here
    const projectField = form.components.find(
      (element) => element.key == 'project',
    )

    if (projectField != undefined) {
      projectField.label = projectFieldLabel
    }
  }
}
