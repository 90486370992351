import { Box } from '@mui/material'
import React from 'react'
import logo from '../../../../assets/icons/toastClose.svg'

import { ComponentProps } from '../../ComponentProps'

interface Props extends ComponentProps {
  width?: number
  height?: number
  isDarkTheme?: boolean
  onClose?: ()=>void
}

export const ToastCloseIcon: React.FC<Props> = ({ sx, width, height, onClose}) => {
  return (
    <Box
      onClick={onClose}
      data-testid='ToastCloseIcon'
      component='img'
      sx={{
        width: width ?? 18,
        height: height ?? 18,
        '&:hover':{
          cursor:'pointer'
        },
        ...sx,
      }}
      src={logo}
    />
  )
}