import { Box } from '@mui/material'
import React from 'react'
import logo from '../../../../assets/icons/toastSuccess.svg'

import { ComponentProps } from '../../ComponentProps'

interface Props extends ComponentProps {
  width?: number
  height?: number
  isDarkTheme?: boolean
}

export const ToastSuccessIcon: React.FC<Props> = ({ sx, width, height }) => {
  return (
    <Box
      data-testid='ToastSuccessIcon'
      component='img'
      sx={{
        width: width ?? 22,
        height: height ?? 22,
        ...sx,
      }}
      src={logo}
    />
  )
}