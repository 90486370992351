import React, { useState } from 'react'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import FormSection from '../../components/form/Containers/FormSection'
import { Box, Input } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import { httpPost } from '../../../data/data'
import { SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { AdminCreateFormioForm } from '../../../domain/domain'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import ErrorContainer from '../../components/error/ErrorContainer'

export default function AddFormToOrgForm() {
  const [formId, setFormId] = useState('')
  const [formIOId, setFormIOId] = useState('')
  const [formTitle, setFormTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingFormIO, setLoadingFormIO] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [formIOerror, setFormIOerror] = useState<string | undefined>(undefined)
  const { org } = useOrganizationProvider()

  const addForm = async () => {
    setLoadingFormIO(true)
    setFormIOerror(undefined)
    const res = await AdminCreateFormioForm({
      title: formTitle,
      formioId: formIOId,
      // TODO SETUP HEADERS
      mobileHeaders: [],
      desktopHeaders: [],
    })
    setLoadingFormIO(false)
    if (res.isRight()) {
      SuccessToast({
        title:
          'Successfully added form to Mojo.  To add to org continue to next form.',
      })
      setFormId(res.right!.id)
      return
    }
    if (res.isLeft()) {
      setFormIOerror(res.left?.message ?? 'Unsuccessfully added formio form')
    }
    setLoadingFormIO(false)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const res = await httpPost('/formRelations', {
      formId: formId,
      orgId: org?.id,
    })
    setLoading(false)
    if (res.isRight()) {
      SuccessToast({
        title: 'Successfully added form to org please refresh page',
      })
      return
    }
    if (res.isLeft()) {
      setError(res.left?.message ?? 'Unsuccessfully added form')
    }
  }
  return (
    <>
      <InnerPageContainer
        headerTitle='Add Formio Form to Mojo'
        sx={{ marginBottom: '24px' }}
      >
        {formIOerror && (
          <ErrorContainer sx={{ margin: '24px 0' }}>{formIOerror}</ErrorContainer>
        )}
        <Box sx={{ display: 'flex' }}>
          <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
            <EdifyFieldLabel label='Formio Id (Formio Server)' />
            <Input
              disableUnderline
              className={'form-control'}
              value={formIOId}
              onChange={(e) => setFormIOId(e.target.value)}
            />
          </EdifyFormField>
          <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
            <EdifyFieldLabel label='Form Title' />
            <Input
              disableUnderline
              className={'form-control'}
              value={formTitle}
              onChange={(e) => setFormTitle(e.target.value)}
            />
          </EdifyFormField>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', margin: '24px' }}
        >
          <EdifyButton
            onClick={addForm}
            title={'Submit'}
            loading={loadingFormIO}
          />
        </Box>
      </InnerPageContainer>
      <InnerPageContainer headerTitle='Add Form From Mojo to Org'>
        {error && (
          <ErrorContainer sx={{ margin: '24px 0' }}>{error}</ErrorContainer>
        )}
        <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
          <EdifyFieldLabel label='Form ID (Mojo Server)' />
          <Input
            disableUnderline
            className={'form-control'}
            value={formId}
            onChange={(e) => setFormId(e.target.value)}
          />
        </EdifyFormField>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', margin: '24px' }}
        >
          <EdifyButton
            onClick={handleSubmit}
            title={'Submit'}
            loading={loading}
          />
        </Box>
      </InnerPageContainer>
    </>
  )
}
