import React, { useContext, useEffect, useState } from 'react'
import { clearCurrentOrg, getCurrentPerson, setCurrentOrg } from '../domain/domain'
import { IContractor } from '../domain/interfaces/IContractor'
import { IOrgFromVerifyOtp } from '../domain/interfaces/IUserDetails'
import { setCurrentOrgId } from '../data/repositories/orgs/OrgRepository'

export interface GlobalProviderProps {
  isGlobalUserView: boolean
  isGlobalUser: boolean
  setIsGlobalUserView: (bool: boolean) => void
  orgs: IOrgFromVerifyOtp[]
  contractors: IContractor[]
  singleAccountMember: boolean
  singleAccountContractor: boolean
}

const GlobalContext = React.createContext<GlobalProviderProps | null>(null)

export function useGlobalProvider(): GlobalProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(GlobalContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const GlobalProvider: React.FC<Props> = ({ children }) => {
  const { globalRole, orgs, contractors } = getCurrentPerson() || {}

  const isSingleAccountMember = () => {
    if (contractors && contractors.length > 0) return false
    return orgs ? orgs.length === 1 : false
  }

  const isSingleAccountContractor = () => {
    if (orgs && orgs.length > 0) return false
    return contractors ? contractors.length === 1 : false
  }

  const [isGlobalUserView, setIsGlobalUserView] = useState<boolean>(true)
  const [isGlobalUser, setIsGlobalUser] = useState<boolean>(false)

  useEffect(() => {
    const name = globalRole && globalRole.name
    // Handle the case to bypass the org selection page
    if(isSingleAccountMember() && orgs){
      setCurrentOrg(orgs[0].id)
    } else{
      if(isSingleAccountContractor() && contractors){
        //  A contact will not have orgs info that a member has
        // so can't set the org but we can set the orgId
        setCurrentOrgId(contractors[0].orgId)
      }
    }
    if (!name) {
      setIsGlobalUser(false)
      return
    }
    setIsGlobalUser(true)
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        isGlobalUserView,
        setIsGlobalUserView,
        isGlobalUser,
        orgs: orgs ?? [],
        contractors: contractors ?? [],
        singleAccountMember: isSingleAccountMember(),
        singleAccountContractor: isSingleAccountContractor(),
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
