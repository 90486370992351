import { Either, Failure, Left } from '../../../core/core'
import { correctiveActionUpdate } from '../../../data/data'
import {
  createContractor,
  deleteContractor,
  editContractor,
  getContractors,
  searchContractors,
  getContractorById,
  deleteContractors,
} from '../../../data/repositories/contractors/ContractorsRepository'
import { contractorParams } from '../../../presentation/pages/contractor/ContractorPageViewModel'
import {
  IContractor,
  IContractorDeleted,
  IContractorList,
} from '../../interfaces/IContractor'
import {
  ICorrectiveAction,
  ICorrectiveActionSubmitData,
  ICorrectiveActionUpdateData,
} from '../../interfaces/ICorrectiveAction'

export async function getAllContractors(
  queryString: string,
): Promise<Either<Failure, IContractorList>> {
  return await getContractors(queryString)
}

export async function getSpecificContractor(
  id: string,
): Promise<Either<Failure, IContractor>> {
  return await getContractorById(id)
}

export async function adminCreateContractor(
  contractor: contractorParams,
): Promise<Either<Failure, IContractor>> {
  return await createContractor(contractor)
}

export async function adminEditContractor(
  contractor: contractorParams,
  id: string,
): Promise<Either<Failure, IContractor>> {
  return await editContractor(contractor, id)
}

export async function adminDeleteContractor(
  contractorId: string,
): Promise<Either<Failure, IContractor>> {
  return await deleteContractor(contractorId)
}

export async function contractorsSearch(
  skip: number,
  limit?: number,
  key?: string,
): Promise<Either<Failure, IContractorList>> {
  return await searchContractors(skip, limit, key)
}

export async function adminDeleteContractors(
  contractorIds: string[],
): Promise<Either<Failure, IContractorDeleted[]>> {
  return await deleteContractors(contractorIds)
}

export async function updateCorrectiveAction(
  id: string,
  data: ICorrectiveActionUpdateData,
): Promise<Either<Failure, ICorrectiveAction>> {
  return await correctiveActionUpdate(id, data)
}
