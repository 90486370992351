// import { Dialog, DialogTitle, IconButton } from '@mui/material'
// import React from 'react'
// import { EdifyButton } from '../buttons'
// import Close from '@mui/icons-material/Close'

// export interface ImageDialogProps {
//   open: boolean
//   url: string
//   images?: string[]
//   onClose: () => void
// }

// function ImageDialog(props: ImageDialogProps) {
//   const { onClose, url, open, images } = props

//   const handleClose = () => {
//     onClose()
//   }

//   const handleDownload = () => {
//     const a = document.createElement('a')
//     a.href = url
//     a.download = 'image.jpg' //
//     a.style.display = 'none'
//     document.body.appendChild(a)
//     a.click()
//     document.body.removeChild(a)
//   }

//   return (
//     <Dialog onClose={handleClose} open={open}>
//       <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
//         {!images && (
//           <EdifyButton
//             noBackground
//             title={'Download Image'}
//             onClick={handleDownload}
//           />
//         )}
//         <IconButton aria-label='close' onClick={handleClose}>
//           <Close />
//         </IconButton>
//       </DialogTitle>

//       <img
//         style={{ maxWidth: '100%', maxHeight: 'calc(100vh - 64px)' }}
//         src={url}
//       />
//       <div></div>
//     </Dialog>
//   )
// }

// export default ImageDialog

import React, { useState } from 'react'
import { Dialog, DialogTitle, IconButton } from '@mui/material'
import Close from '@mui/icons-material/Close'
import NavigateBefore from '@mui/icons-material/NavigateBefore'
import NavigateNext from '@mui/icons-material/NavigateNext'
import { EdifyButton } from '../buttons'
import ImageWithToken from '../form/EdifyImage/ImageWithToken'

export interface ImageDialogProps {
  open: boolean
  type?: string
  url: string | string[] // Changed to array
  onClose: () => void
}

function ImageDialog(props: ImageDialogProps) {
  const { onClose, url, open, type = 'image' } = props
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [updatedSrc, setUpdatedSrc] = useState<string>('')
  const urls = Array.isArray(url) ? url : [url]

  const handleClose = () => {
    onClose()
  }

  const handleDownload = () => {
    const a = document.createElement('a')
    a.href = updatedSrc+'&download=true'
    a.download = `image-${currentImageIndex}.jpg`
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : url.length - 1,
    )
  }

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < url.length - 1 ? prevIndex + 1 : 0,
    )
  }
  const fileComponent = (
    <img
      src='https://res-academy.cache.wpscdn.com/images/seo_posts/20230705/e2586bdc1cda16d92fd0632491a71092.png'
      style={{
        width: '88px',
        height: '88px',
        marginBottom: '16px',
        marginLeft: '56px',
        cursor: 'pointer',
      }}
    />
  )

  const imgComponent = (
    <ImageWithToken
      style={{ maxWidth: '100%', maxHeight: 'calc(100vh - 64px)' }}
      src={urls[currentImageIndex]}
      setUpdatedSRC={setUpdatedSrc}
    />
  )

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {currentImageIndex > 0 && (
          <IconButton aria-label='previous' onClick={handlePrevious}>
            <NavigateBefore />
          </IconButton>
        )}
        <EdifyButton
          noBackground
          title={`Download ${type}`}
          onClick={handleDownload}
        />

        {currentImageIndex < urls.length - 1 && (
          <IconButton aria-label='next' onClick={handleNext}>
            <NavigateNext />
          </IconButton>
        )}

        <IconButton aria-label='close' onClick={handleClose}>
          <Close />
        </IconButton>
      
      </DialogTitle>
      {type === 'image' ? imgComponent : fileComponent}
    </Dialog>
  )
}

export default ImageDialog
