import { useEffect, useMemo } from 'react'
import { REACT_APP_SERVER_API } from '../../config'
import { getCurrentPerson } from '../../domain/domain'
import io from 'socket.io-client'

/**
 * Hooks to use socket
 * @returns Socket Connection
 */
const useSocketConnection = () => {
  const socket = useMemo(() => {
    return io(REACT_APP_SERVER_API,{
      transports: ['websocket'],
      withCredentials: true
    })
  }, [])
  
  useEffect(() => {
    // Connect to the server
    socket.connect()
    /**
     *  join room use current person id
     *  so that u only recieve your own notifications
     */
    socket.emit('join_room',getCurrentPerson()?.id)
    // Cleanup on component unmount
    return () => {
      // Disconnect from the server
      socket.disconnect()
    }
  }, [socket])

  return socket
}

export default useSocketConnection
