import React from 'react'
import { useLocation } from 'react-router-dom'
import ErrorContainer from '../../components/error/ErrorContainer'

interface ErrorComponentProps {
  message?: string
}

const ErrorComponent: React.FC<ErrorComponentProps> = () => {
  return (
    <ErrorContainer sx={{ margin:'24px' }}>
      Oops! Something went wrong. Please try again later.
    </ErrorContainer>
  )
}

interface ErrorBoundaryWrapperProps {
  children?: React.ReactNode
  key?: React.Key
}

interface ErrorBoundaryWrapperState {
  hasError: boolean
  lastPathname?: React.Key
}

class ErrorBoundaryWrapper extends React.Component<
  ErrorBoundaryWrapperProps,
  ErrorBoundaryWrapperState
> {
  constructor(props: ErrorBoundaryWrapperProps) {
    super(props)
    this.state = { hasError: false }
  }


  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent />
    }
    return this.props.children
  }
}

// Functional Component Wrapper to use useLocation hook
const WithResetErrorBoundary: React.FC<ErrorBoundaryWrapperProps> = ({
  children,
}) => {
  const location = useLocation()
  return (
    <ErrorBoundaryWrapper key={location.pathname}>
      {children}
    </ErrorBoundaryWrapper>
  )
}

export default WithResetErrorBoundary
