import { Box } from '@mui/material'
import React from 'react'
import { AppColors } from '../../Theme'

import { SafetyMojoLoginIcon } from '../../../core/components/icons/SafetyMojoLoginIcon'
import { ETypography } from '../../components/fonts/ETypography'
import SignUpForm from './SignUpForm'
import PageContainer from '../../components/page/PageContainer'

export const ROUTE_DEMO_SIGN_UP = '/'

const InternalSelfSignUp: React.FC = () => {
  return (
    <PageContainer title='' data-testid='LoginPage'>
      <Box
        sx={{
          // height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flex: 1,
        }}
      >
        <SafetyMojoLoginIcon width={180} height={48} />
        <ETypography
          sx={{
            marginTop: '24px',
          }}
          font='HMS'
          color='gray950'
        >
          Enjoy Safety Mojo Free For One Month
        </ETypography>
      </Box>
      <Box
        sx={{
          marginTop: '54px',
          alignItems: 'center',
          padding: '32px 24px',
          borderRadius: '10px',
          border: `1px solid ${AppColors.neutral25}`,
        }}
      >
        <SignUpForm />
      </Box>
    </PageContainer>
  )
}

export default InternalSelfSignUp
