import React, { useContext } from 'react'

export interface OrgSettingsViewModelProps {
  isGlobalUserView: boolean
}

const OrgSettingsContext = React.createContext<OrgSettingsViewModelProps | null>(null)

export function useOrgSettingsViewModel(): OrgSettingsViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(OrgSettingsContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const OrgSettingsViewModel: React.FC<Props> = ({ children }) => {
  const isGlobalUserView = true

  return (
    <OrgSettingsContext.Provider
      value={{
        isGlobalUserView,
      }}
    >
      {children}
    </OrgSettingsContext.Provider>
  )
}
