// THIS needs work just a quick first attempt

import React from 'react'
import { useGridApiContext } from '@mui/x-data-grid'
import { Box, Typography } from '@mui/material'
import { AppColors } from '../../Theme'
import generatePaginationString from '../../../core/utils/pagination/generatePagination'
import { LeftArrowIcon } from '../../../core/components/icons/LeftArrowIcon'
import { RightArrowIcon } from '../../../core/components/icons/RightArrowIcon'
import { EdifyButton } from '../buttons'
import { TABLE_ROW_HEIGHT } from './EdifyDataGrid'

interface CustomFooterProps {
  setPaginationModel: React.Dispatch<
    React.SetStateAction<{
      page: number
      pageSize: number
    }>
  >
  paginationModel: {
    page: number
    pageSize: number
  }
  totalCount?: number
}
//https://codesandbox.io/s/bold-lovelace-fy9hve?file=/demo.tsx
/**
 * Represents a custom footer component for the datagrid. This allows for custom pagination. Set this up in the slots section of datagrid
 * @function CustomGridFooter
 * @param {CustomFooterProps} props - The props for the CustomGridFooter component.
 * @returns {JSX.Element} - The rendered CustomGridFooter component.
 * @example
 *   // in DataGrid component
 *   slots={{
            footer: () => (
              <DataGridFooter
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                totalCount={vm.totalCount}
              />
            ),
          }}
 */
const DataGridFooter: React.FC<CustomFooterProps> = ({
  setPaginationModel,
  paginationModel,
  totalCount,
}) => {
  const apiRef = useGridApiContext()
  const pages = Math.ceil(totalCount! / paginationModel.pageSize)

  const handlePageChange = (page: number) => {
    if (page < 0 || page >= pages) {
      return
    }
    setPaginationModel((oldState) => ({ ...oldState, page: page }))
    apiRef.current.setPage(page)
  }
  const drawNumbers = () => {
    const paginationNumbers = generatePaginationString(
      paginationModel.page,
      pages,
    )
    return paginationNumbers.map((num: string, i:number) => {
      if (num == '...') {
        return (
          <Typography  sx={{ lineHeight: 2, sx: AppColors.gray50, alignSelf: 'center' }} key={`${num}-${i}`}>
            {'...'}
          </Typography>
        )
      }
      // need to subtract 1 because pages are 0 indexed
      const paginationIndex: number = parseInt(num) - 1
      const isActive: boolean = paginationIndex === paginationModel.page
      return (
        <EdifyButton
          data-testid={`PaginationButton-${num}`}
          key={num}
          onClick={() => handlePageChange(paginationIndex)}
          title={num}
          noBackground
          secondary={!isActive}
          primary={isActive}
          textStyle={{
            color: isActive ? AppColors.primary600 : AppColors.gray50,
          }}
        />
      )
    })
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        // we get padding from the buttons 12px 18px
        height:`${TABLE_ROW_HEIGHT}px`,
        padding: '0px 12px',
        borderLeft: '1px solid transparent',
        borderRight: '1px solid transparent',
        borderTop: `1px solid ${AppColors.neutral600}`,
        backgroundColor: AppColors.baseWhite,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
      }}
    >
      <EdifyButton
        disabled={paginationModel.page == 0}
        buttonStyle={
          paginationModel.page == 0 ? { background: 'transparent' } : {}
        }
        textStyle={{ color: AppColors.gray50 }}
        noBackground
        secondary
        onClick={() => handlePageChange(paginationModel.page - 1)}
        icon={<LeftArrowIcon />}
        title='Prev'
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent:'center',
        }}
      >
        {drawNumbers()}
      </Box>

      <EdifyButton
        disabled={paginationModel.page >= pages - 1}
        buttonStyle={
          paginationModel.page >= pages - 1 ? { background: 'transparent' } : {}
        }
        textStyle={{ color: AppColors.gray50 }}
        noBackground
        secondary
        iconRight
        onClick={() => handlePageChange(paginationModel.page + 1)}
        icon={<RightArrowIcon />}
        title='Next'
      />
    </Box>
  )
}

export default DataGridFooter
