import { Box } from '@mui/material'
import React from 'react'
import { EdifyButton } from '../../buttons'

interface Props {
  children: React.ReactElement | React.ReactElement[]
  onCancel?: () => void
  cancelText?: string
  isAnon?: boolean
}
const MojoFormButtons: React.FC<Props> = ({
  children,
  onCancel,
  cancelText = 'cancel',
  isAnon = false
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '24px',
        width: '100%',
        flex: 1,
      }}
    >
      <Box>
        {onCancel && !isAnon ? (
          <EdifyButton noBackground onClick={onCancel} title={cancelText} />
        ) : (
          <p></p>
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default MojoFormButtons
