import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  RoleSettingsViewModel,
  useRoleSettingsViewModel,
} from './RoleSettingsViewModel'

import RolesDataGrid from './RolesDataGrid'
import { Box} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ERole } from '../../../domain/interfaces/IRole'
import { PermissionButton } from '../../components/buttons/edifyButton/PermissionButton'
import withPermission from '../../../core/hooks/withPermission'

export const ROUTE_ROLES = '/roles'


export const _Page: React.FC = () => {
  const navigate = useNavigate()
  const vm = useRoleSettingsViewModel()

  return (
    <PageContainer
      title={'Manage Roles'}
      subtitle='See all your organization’s roles or create new ones.'
      errorObj={vm.errorObj}
      breadCrumbs={[
        { title: 'Settings', to: '' },
        { title: 'Manage Roles', to: ROUTE_ROLES },
      ]}
      renderRightItems={() => (
        <PermissionButton
          requiredPermission={ERole.RoleCreate}
          buttonStyle={{ maxHeight: '48px' }}
          title='Create New Role'
          onClick={() => {
            navigate(`${ROUTE_ROLES}/new`)
          }}
        />
      )}
    >
      <Box sx={{ width: '100%', marginBottom: '48px', background: 'white' }}>

        <RolesDataGrid title='Existing Roles' vm={vm}  />
      </Box>
    </PageContainer>
  )
}

const RolesPageWithPermission = withPermission(_Page, ERole.RoleRead)

export const Roles: React.FC = () => {
  return (
    <RoleSettingsViewModel>
      <RolesPageWithPermission />
    </RoleSettingsViewModel>
  )
}


