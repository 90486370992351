import { Box } from '@mui/material'
import React from 'react'
import { AppColors } from '../../Theme'
import { ETypography } from '../../components/fonts/ETypography'
import { EdifyButton } from '../../components/buttons'
import {
  getCurrentOrg,
  getCurrentPerson,
  getCurrentUser,
} from '../../../domain/domain'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { resetOrg } from '../../../data/repositories/orgs/OrgRepository'

import { useParams } from 'react-router-dom'
import { logout } from '../../../data/data'

const OrgReset = () => {
  const { userId } = useParams()
  const org = getCurrentOrg()
  const person = getCurrentPerson()
  const currentUserId = getCurrentUser()?.id ?? ''

  if (!org) {
    return null
  }
  if (!org.isResetEnabled) {
    return null
  }
  // make sure it is the logged in Users profile page
  if (currentUserId != userId) {
    return null
  }
  if (org.ownerPersonId != person!.id) {
    return null
  }

  const onResetOrg = async () => {
    showConfirmationDialog({
      title: 'Confirm Reset',
      message:
        'Organization content will be reset and you will lose any changes made. Please click Reset to confirm.',
      cancelText: 'Cancel',
      confirmText: 'Reset',
      onCancel: function (): void {
        hideConfirmationDialog()
      },
      onConfirm: async () => {
        // const results = await adminDeleteCertificates(certificateIds)
        const result = await resetOrg(org!.id)

        if (result.isLeft()) {
          ErrorToast({
            title: 'Organization Reset Failed Error resetting organization content. Please try again later.',
          })
          return
        }

        SuccessToast({
          title: 'Organization Reset Successful.  You will now be logged out. Log in again to refresh your organization content.',
        })
        hideConfirmationDialog()
        logout()
      },
    })
  }
  return (
    <Box
      data-testid={'ErrorContainer'}
      sx={{
        // backgroundColor: AppColors.baseWhite,
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        padding: '24px',
        marginTop: '24px',
        borderRadius: '6px',
        border: `1px solid ${AppColors.danger500}`,
        background: AppColors.danger100,
        width: '100%',
      }}
    >
      <Box sx={{ flex: 1 }}>
        <ETypography color='gray700' font='LSB' sx={{ marginBottom: '12px' }}>
          Demo Org Settings
        </ETypography>
        <ETypography color='gray700' font='MM' sx={{ marginBottom: '12px' }}>
          Click the button below to reset the organization content
        </ETypography>
        <EdifyButton alert title={`Reset ${org!.name}`} onClick={onResetOrg} />
      </Box>
    </Box>
  )
}

export default OrgReset
