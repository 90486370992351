import { Form } from '@formio/react'
import React, { useEffect } from 'react'
import {
  SubmissionPageViewModelProps,
  useSubmissionPageViewModel,
} from './SubmissionViewModel'
import { Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { EdifyButton } from '../../components/buttons'
import { ROUTE_SUBMISSIONS } from '../submissions/SubmissionsPage'
import { getCurrentUser } from '../../../domain/domain'
import { REACT_APP_FORMIO_PROJECT_URL } from '../../../config'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { useI18nProvider } from '../../../providers/InternationalizationProvider'

const EditSubmission: React.FC = () => {
  let imgUploads: NodeListOf<HTMLAnchorElement>
  const navigate = useNavigate()
  const { formCategory } = useParams()
  const vm: SubmissionPageViewModelProps = useSubmissionPageViewModel()
  const { orgI18nLoaded, options } = useI18nProvider()

  const addClickListener = () => {
    imgUploads = document.querySelectorAll(
      '.formio-component-file .fileSelector',
    )

    imgUploads.forEach((imgUpload) => {
      imgUpload.addEventListener('click', handleImgUpload)
    })
  }
  const handleImgUpload = (event: any) => {
    if (event.target.tagName == 'A') return
    const fileBrowseLink = document.querySelector(
      '.formio-component-file .fileSelector',
    )
    if (fileBrowseLink && fileBrowseLink.contains(event.target)) {
      // Trigger a click event on the found <a> tag
      const link = fileBrowseLink.querySelector('a')
      if (link) {
        link.click()
      }
    }
  }

  useEffect(() => {
    return () => {
      if (imgUploads) {
        imgUploads.forEach((imgUpload) => {
          imgUpload.removeEventListener('click', handleImgUpload)
        })
      }
    }
  }, [])

  const submitted = vm.submission?.state === 'submitted'
  return (
    <>
      <InnerPageContainer
        sx={{ padding: '24px' }}
        innerPageLoading={!orgI18nLoaded}
      >
        <Form
          url={`${REACT_APP_FORMIO_PROJECT_URL}/${vm.form.path}`}
          form={vm.form}
          onChange={vm.onFormChange}
          submission={vm.submission}
          formReady={vm.setFormIOForm}
          options={options}
          // Still a bug: if user uploads an image then clears it
          // the event listener wont be there on the new image uploader.
          // onRender={addClickListener}
        />
      </InnerPageContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '24px',
        }}
      >
        <EdifyButton
          noBackground
          title='Cancel'
          onClick={() =>
            navigate(
              `/${formCategory}${ROUTE_SUBMISSIONS}/${vm.form.id}/submission/${vm.submission?.id}`,
              { state: { form: vm.form, submission: vm.submission } },
            )
          }
        />
        <Box>
          {!submitted && (
            <EdifyButton
              buttonStyle={{ mr: 24 }}
              title='Save as Draft'
              secondary
              disabled={vm.isSubmitting}
              onClick={vm.updateDraft}
            />
          )}
          <EdifyButton
            disabled={vm.isSubmitting}
            onClick={()=> vm.updateSubmission()}
            title={`${submitted ? 'Update' : 'Submit'} ${vm.form.title}`}
          />
        </Box>
      </Box>
    </>
  )
}
export default EditSubmission
