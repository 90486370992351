import React from 'react'
import { Form } from '@formio/react'
import {
  ICreateSubmission,
  ISubmission,
  EState
} from '../../../../domain/interfaces/ISubmission'
import { IForm } from '../../../../domain/interfaces/IForm'
import {
  createSubmission,
  getAuthToken,
  getCurrentOrgId,
} from '../../../../domain/domain'
'../../../../domain/interfaces/ISubmission'
import { ComponentProps, Either, Failure } from '../../../../core/core'

import {
  ErrorToast,
  SuccessToast,
} from '../../../../core/utils/toast-notifications/ToastNotifications'
import { Box, SxProps } from '@mui/material'
import { FormProps, Options } from '@formio/react/lib/components/Form'

interface IFormProps extends FormProps {
  onSubmitDone?: (data: any) => void
  onSubmit?: (data: ICreateSubmission) => void
  onPrevPage?: (pageData: any) => void
  onNextPage?: (pageData: any) => void
  form?: IForm
  submittedForm: any // todo: ISubmittedForm
  originalData?: any
  onRevertChanges?: () => void
  onChange?: (data: any) => void
  forceLoginId?: string
  forceFormId?: string
  targetLoginId?: string
  orgId?: string
  readOnly?: boolean
  sx?: SxProps
}

const FormRenderer = (props: IFormProps) => {
  const {
    onSubmitDone,
    onSubmit,
    onPrevPage,
    onNextPage,
    form,
    submittedForm,
    originalData,
    onRevertChanges,
    onChange,

    sx,
    forceLoginId,
    forceFormId,
    targetLoginId,
    orgId,
    readOnly,
    ...rest
  } = props

  if (onChange && (originalData === null || originalData === undefined)) {
    throw new Error('Set [originalData] if [onChange] is set.')
  }

  const onFormChange = (data: any, changed: any) => {
    if (changed['changes'] === undefined || changed['changes'].length === 0) {
      return
    }

    const changes = changed['changes'] as Array<any>
    let hasActualChange = false

    for (let i = 0; i < changes.length; i++) {
      const detectedChange = changes[i]
      const key = detectedChange['component']['key'] as string
      const value = detectedChange['value']

      // Need to stringify to properly compare values even though it's an object.
      
      if (JSON.stringify(originalData[key]) != JSON.stringify(value)) {
        hasActualChange = true
        break
      }
    }

    if (hasActualChange) {
      onChange && onChange(data)
    } else {
      onRevertChanges && onRevertChanges()
    }
  }

  const handleSubmit = async (submissionData: any) => {
    const dataToSubmit: ICreateSubmission = {
      formId: forceFormId ?? form?.id ?? '',
      data: submissionData?.data ?? {},
      state: EState.Submitted,
    }
    // if not recieved defined onSubmit function
    // form renderer will do the submission
    if (!onSubmit) {
      if (dataToSubmit.data['submit']) {
        const result: Either<Failure, ISubmission> = await createSubmission(
          dataToSubmit,
        )
        if (result.isRight()) {
          onSubmitDone && onSubmitDone(result)
          SuccessToast({
            title: 'New form submission successful!',
            subtitle: `Submission for ${submittedForm?.orgName} has been added successfully.`,
          })
        } else {
          ErrorToast({
            title: 'New form submission failed!',
            subtitle: 'Unable to submit form, please try again.',
          })
        }
      }
    } else {
      // else execute the recieved onSubmit func & pass the data
      onSubmit(dataToSubmit)
    }
  }

  const formOptions: Options = { readOnly: readOnly }

  // add token and org id to the submission data.
  // it will be used by the form to fetch Level 1 and Projects data

  submittedForm.data = {
    ...submittedForm.data,
    orgId: getCurrentOrgId(),
    token: getAuthToken(),
  }

  return (
    <Box data-testid='FormRender' sx={sx}>
      <Form
        options={formOptions}
        onSubmitDone={onSubmitDone}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        form={form}
        submission={submittedForm}
        onChange={onChange ? onFormChange : undefined} // TODO: implement change detection for discard changes confirmation
        url={process.env.REACT_APP_FORMIO_PROJECT_URL}
        onSubmit={handleSubmit}
        {...rest}
      />
    </Box>
  )
}

export default FormRenderer
