import React, { useState } from 'react'

import {
  I18nProvider,
  useI18nProvider,
} from '../../../providers/InternationalizationProvider'
import { useLocation } from 'react-router-dom'
import PublicOrganizationProvider, {
  usePublicOrganizationProvider,
} from '../../../providers/PublicOrganizationProvider'
import useFormLoader from '../../../core/hooks/useFormLoader'
import PageContainer from '../../components/page/PageContainer'
import { Box } from '@mui/material'
import { SafetyMojoLoginIcon } from '../../../core/components/icons/SafetyMojoLoginIcon'
import { ETypography } from '../../components/fonts/ETypography'
import useSubmissionLoader from '../../../core/hooks/useSubmission'
import useFormio, { IUseFormioReturn } from '../../../core/hooks/useFormio'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { LanguageSearch } from '../../components/banners/drawer/LanguageSearch'
import MojoNewFormRender from './MojoNewFormRender'

interface Props {
  orgId: string
  formId: string
  submissionId: string | undefined
}

const AnonymousSubmissionInner: React.FC<Props> = ({
  orgId,
  formId,
  submissionId,
}) => {
  const { orgI18nLoaded, options } = useI18nProvider()
  const [lastActiveTab, setLastActiveTab] = useState<number>(0)

  const formLoader = useFormLoader(formId, {
    orgId,
    isAnon: true,
  })
  const submissionLoader = useSubmissionLoader(
    submissionId,
    orgId,
    formId,
    true,
  )
  const fio: IUseFormioReturn = useFormio(formId, submissionLoader.submission, {
    form: formLoader.form,
    orgId: orgId,
    isAnon: true,
  })
  const { form } = formLoader
  const { submission } = submissionLoader

  const hasPostSubData =
    submission &&
    submission.postSubmissionData &&
    submission.postSubmissionData.length > 0

  const isReadOnly = () => {
    // no submission create
    if (!submissionId) {
      return false
    }
    // has submission data... Can submit post submission data form
    return !hasPostSubData
  }

  const renderSuccessfullySubmitted = (title: string) => {
    return (
      <InnerPageContainer sx={{ padding: '24px' }}>
        <ETypography font='HMS' color='gray700' sx={{ textAlign: 'center' }}>
          You have successfully submitted a anonymous {title}. You can now close
          this page.
        </ETypography>
      </InnerPageContainer>
    )
  }

  if (formLoader.formLoading || submissionLoader.submissionLoading)
    return (
      <PageContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // flex: 1,
          }}
        >
          <SafetyMojoLoginIcon width={180} height={48} />
        </Box>
      </PageContainer>
    )
  return (
    <PageContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // flex: 1,
        }}
      >
        <SafetyMojoLoginIcon width={180} height={48} />

        <ETypography font='LM' color='gray700' sx={{ marginBottom: '12px' }}>
          {form?.title} Submission
        </ETypography>
        <LanguageSearch anonymous />
        <Box sx={{ marginBottom: '12px' }} />
        {fio.successFullySubmitted && renderSuccessfullySubmitted(form!.title)}
      </Box>
      {!fio.successFullySubmitted && (
        <MojoNewFormRender
          mode='CREATE'
          submissionId={submissionId}
          orgId={orgId}
          formId={formId}
          fio={fio}
          formLoader={formLoader}
          submissionLoader={submissionLoader}
          options={options}
          orgI18nLoaded={orgI18nLoaded}
          isAnon={true}
          readOnly={isReadOnly()}
          lastActiveTab={lastActiveTab}
          setLastActiveTab={setLastActiveTab}
        />
      )}
    </PageContainer>
  )
}

const _AnonymousSubmission: React.FC<Props> = ({
  orgId,
  formId,
  submissionId,
}) => {
  const { publicOrgProviderLoaded } = usePublicOrganizationProvider()

  if (!publicOrgProviderLoaded)
    return (
      <PageContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // flex: 1,
          }}
        >
          <SafetyMojoLoginIcon width={180} height={48} />
        </Box>
      </PageContainer>
    )

  return (
    <AnonymousSubmissionInner
      orgId={orgId}
      formId={formId}
      submissionId={submissionId}
    />
  )
}
const AnonymousSubmission: React.FC = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const orgId = queryParams.get('orgId') ?? ''
  const formId = queryParams.get('formId') ?? ''
  const submissionId = queryParams.get('submissionId') ?? undefined
  return (
    <PublicOrganizationProvider orgId={orgId} formId={formId}>
      <I18nProvider orgId={orgId}>
        <_AnonymousSubmission
          orgId={orgId}
          formId={formId}
          submissionId={submissionId}
        />
      </I18nProvider>
    </PublicOrganizationProvider>
  )
}

export default AnonymousSubmission
