// ED-1386 https://edifyai.atlassian.net/browse/ED-1386
import React from 'react'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { project_columns } from '../../components/datagrid/gridData/projects'
import { useNavigate } from 'react-router-dom'
import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
import { ProjectsPageViewModelProps } from './ProjectsViewModel'
import pluralize from 'pluralize'

interface IProjectsDataGridProps {
  vm: ProjectsPageViewModelProps
  title: string
  rows: IProjectsDataGridProps[]
  levelId?: string | undefined
  checkboxSelection?: boolean
  error?: string
  totalCount?: number
}

const ProjectsDataGrid: React.FC<IProjectsDataGridProps> = ({
  vm,
  title,
  rows,
  levelId,
  checkboxSelection = true,
  error,
  totalCount = -1,
}) => {
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const navigate = useNavigate()

  const handleRowClicked = (params: GridRowParams) => {
    // if use came from level page to projects
    // have named projects and project to not math route for highlight nav
    const baseUrl = levelId ? `/levels/1/${levelId}/project` : '/projects'
    navigate(`${baseUrl}/${params.id}`, {
      state: { name: params.row.projectName },
    })
  }

  // delete projects/s
  const removeClicked = async () => {
    vm.showDeleteConfirmation(selectionModel as string[])
    setSelectionModel([])
  }

  const renderHeaderButtons = () => {
    // don't render bulk action items if turned off
    if (!checkboxSelection) return <></>
    const noSelections = selectionModel.length === 0
    return (
      <BulkDeleteButton noSelections={noSelections} onClick={removeClicked} />
    )
  }

  return (
    <>
      <EdifyDataGrid
        data-testid='ProjectsDataGrid'
        paginationMode='server'
        noRowsText={`No ${pluralize(title).toLocaleLowerCase()} found.`}
        title={title}
        renderHeaderButtons={renderHeaderButtons()}
        columns={project_columns}
        rows={rows}
        error={error || vm.error}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={handleRowClicked}
        loading={vm.isLoading || vm.isLoadMore}
        rowCount={totalCount >=0 ? totalCount : vm.totalCount }
        loadMoreRows={vm.loadMore}
        reload={vm.getProjects}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={checkboxSelection}
      />
    </>
  )
}

export default ProjectsDataGrid
