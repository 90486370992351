import {
  Circle,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material'
import React, { useState } from 'react'
import { IForm } from '../../../../domain/interfaces/IForm'
import { NavbarLinkTile } from '../../tiles/navbar-tile/NavbarLinkTile'
import { ROUTE_SUBMISSIONS } from '../../../pages/submissions/SubmissionsPage'
import { Box, CircularProgress, Collapse, List } from '@mui/material'
import {
  OrganizationProviderProps,
  useOrganizationProvider,
} from '../../../../providers/OrganizationProvider'
import { EdifyButton } from '../../buttons'
import ErrorContainer from '../../error/ErrorContainer'
import { AppColors } from '../../../Theme'
export const ROUTE_FORMS = '/forms'
interface GroupedItems {
  [key: string]: IForm[]
}

const FormLinks: React.FC = () => {
  const vm: OrganizationProviderProps = useOrganizationProvider()
  if (!vm) return null

  if (vm.formsLoading) {
    return (
      <Box
        data-testid='FormLoading'
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (vm.formsError) {
    return (
      <ErrorContainer
        data-testid='FormsLoadingError'
        error={'Error Loading Forms'}
        textStyle={{ color: AppColors.neutral700 }}
      >
        <EdifyButton
          data-testid='FormLinksReload'
          buttonStyle={{ padding: '0px 24px' }}
          noBackground
          title='Reload Forms'
          onClick={vm.reloadForms}
        />
      </ErrorContainer>
    )
  }

  const groupByCategory = () => {
    const grouped: GroupedItems = {}


    vm.categoryForms.forEach((item) => {
      // If the category is null or undefined, you can choose to skip it or assign it to a default category
      if (!item.category) return
      const category = item.category!.name as string

      // Check if the category already exists
      if (!grouped[category]) {
        grouped[category] = []
      }

      // Add the item to the appropriate category
      grouped[category].push(item)
    })

    return Object.values(grouped)
  }

  const getFormLinks = (
    forms: IForm[],
    main: boolean,
    iconComponent: string,
  ) => {
    if (!forms) return []
    // const topForms = vm.forms.filter((f) => f.isFavorited)
    forms.sort((a, b) =>
      a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
    )
    // map over top forms
    const formLinks: any[] = forms.map((form: IForm) => {
      const category = main ? 'forms' : iconComponent
      return (
        <NavbarLinkTile
          // pathMatch={`${category}`}
          pathMatch={`/${category}${ROUTE_SUBMISSIONS}/${form.id}`}
          key={form.id}
          data-testid='EdifyDrawer_Forms_Incidents'
          route={`/${category}${ROUTE_SUBMISSIONS}/${form.id}`}
          iconComponent={Circle}
          iconComponentStyle={{
            height: '6px',
          }}
          state={{ form }}
          linkTitle={form.title}
          style={{ paddingLeft: '56px' }}
        />
      )
    })

    // add 'all Forms link'
    if (main) {
      formLinks.push(
        <NavbarLinkTile
          pathMatch={'forms/all'}
          key={'allForms'}
          data-testid='EdifyDrawer_Forms_Incidents'
          route={'forms/all'}
          iconComponent={Circle}
          iconComponentStyle={{
            height: '6px',
          }}
          linkTitle={'All Forms'}
          style={{ paddingLeft: '56px' }}
        />,
      )
    }

    return formLinks
  }
  const topForms = vm.forms.filter((f) => f.isFavorited)

  const allFormGroups = [topForms, ...groupByCategory()]

  return (
    <>
      {allFormGroups.map((fg: IForm[], i) => {
        const name = i == 0 ? 'Forms' : fg[0].category!.name
        const iconComponent = i == 0 ? 'fa-list-ul' : fg[0].category!.icon
        return (
          <FormSection key={i} name={name} iconComponent={iconComponent}>
            {getFormLinks(fg, i === 0, iconComponent)}
          </FormSection>
        )
      })}
    </>
  )
}

interface IProps {
  children: any
  name: string
  iconComponent: string
}

const FormSection: React.FC<IProps> = ({ children, name, iconComponent='forms' }) => {
  const [openForm, setOpenForm] = useState(true)
  return (
    <>
      <NavbarDropdownTile
        data-testId='EdifyDrawer_Forms'
        iconComponent={iconComponent}
        linkTitle={name}
        paths={[iconComponent]}
        suffixIconComponent={openForm ? ExpandLess : ExpandMore}
        onClick={() => setOpenForm(!openForm)}
      />

      <Collapse in={openForm} timeout='auto'>
        <List>{children}</List>
      </Collapse>
    </>
  )
}

export default FormLinks

import styled from '@emotion/styled'

import { AppFonts } from '../../../Theme'
import { SvgIconProps, Typography } from '@mui/material'

const NavbarDropdown = styled(Box)(() => ({
  display: 'flex',
  direction: 'ltr',
  alignItems: 'center',
  gap: '12px',
  padding: '12px 28px',
  textDecoration: 'none',
  '&:hover': {
    color: AppColors.g000,
    opacity: 1,
    background: AppColors.gray400,
    textDecoration: 'none',
  },
}))

interface Props {
  iconComponent: string
  iconComponentStyle?: React.CSSProperties
  linkTitle: string
  paths: string[]
  onClick?: () => void
  suffixIconComponent?: React.ComponentType<SvgIconProps>
  style?: React.CSSProperties
  state?: { form: IForm }
}

export const NavbarDropdownTile: React.FC<Props> = ({
  iconComponent,
  iconComponentStyle,
  linkTitle,
  onClick,
  suffixIconComponent: SuffixIcon,
  style,
  paths,
}) => {
  const path = location.pathname.split('/')[1]

  return (
    <NavbarDropdown onClick={onClick} style={{ ...style }}>
      <i
        className={`fa ${iconComponent}`}
        style={{
          // color: AppColors.g000,
          color: paths.includes(path) ? AppColors.neutral200 : AppColors.gray25,
          // height: '24px',
          height: '12px',
          paddingLeft: '6px',
          paddingRight: '6px',
          ...iconComponentStyle,
        }}
      />
      <Typography
        sx={{
          ...AppFonts.interMSemibold,
          // color: AppColors.g000,
          color: paths.includes(path) ? AppColors.neutral200 : AppColors.gray25,
          // opacity: 0.6,
          width: '154px',
        }}
      >
        {linkTitle}
      </Typography>

      {SuffixIcon ? (
        <SuffixIcon
          sx={{
            height: '24px',
            color: paths.includes(path)
              ? AppColors.neutral200
              : AppColors.gray25,
            alignContent: 'flex-end',
          }}
        />
      ) : null}
    </NavbarDropdown>
  )
}
