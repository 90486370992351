import React from 'react'

import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { participant_columns } from '../../components/datagrid/gridData/participants'
import { GoalPageViewModelProps } from './GoalPageViewModel'
import { useNavigate } from 'react-router-dom'
import { ROUTE_GOALS } from '../goals/GoalsPage'
import { Box } from '@mui/material'
// import BulkPauseButton from '../../components/buttons/BulkButtons/BulkPauseButton'
// import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'

interface IGoalDataGridProps {
  vm: GoalPageViewModelProps
  title: string
}

const ParticipantsDataGrid: React.FC<IGoalDataGridProps> = ({ vm, title }) => {
  const navigate = useNavigate()
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])

  // Not sure where this should go...
  const handleRowClicked = (params: GridRowParams) => {
    navigate(`${ROUTE_GOALS}/${params.row.id}`)
  }

  // Will be Used in future
  const pauseClicked = async () => {
    // TODO: Implement
  }

  // delete participants
  const removeClicked = async () => {
    vm.deleteSelectedParticipants(selectionModel as string[])
    setSelectionModel([])
  }

  // Pause not implemented yet 
  // const renderHeaderButtons = () => {
  //   const noSelections = selectionModel.length === 0
  //   return (
  //     <Box>
  //       <BulkPauseButton pausing={false} noSelections={noSelections} onClick={removeClicked} />
  //       <BulkDeleteButton noSelections={noSelections} onClick={pauseClicked} />
  //     </Box>
  //   )
  // }

  const rows = vm.normalizeParticipantsForDataGrid()

  return (
    <>
      <EdifyDataGrid
        data-testid='ParticipantsDataGrid'
        paginationMode='server'
        title={title}
        noRowsText={'No participants found.'}
        // renderHeaderButtons={renderHeaderButtons()}
        columns={participant_columns}
        rows={rows}
        error={vm.participantsError}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        // handleRowClicked={handleRowClicked}
        loading={vm.participantsLoading}
        initSort={[
          {
            field: 'fullName',
            sort: 'asc',
          },
        ]}
        rowCount={vm.totalCount}
        loadMoreRows={vm.loadMore}
        reload={vm.loadParticipants}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={false}
      />
    </>
  )
}

export default ParticipantsDataGrid
