import { Either, Failure } from '../../../core/core'
import { EGlobalSearchPayload, EGlobalSearchReturnPayload, EHomeFormLink, EHomeFormLinks, homePageDataGet, searchGlobal, searchGlobalMinimal } from '../../../data/repositories/utils/UtilsRepository'

export async function globalSearch(searchPayload: EGlobalSearchPayload): Promise<Either<Failure, EGlobalSearchReturnPayload>> {
  return await searchGlobal(searchPayload)
}
export async function globalMinimalSearch(searchString: string, searchKey:string): Promise<Either<Failure, EGlobalSearchReturnPayload>> {
  return await searchGlobalMinimal(searchString,searchKey)
}

export async function getHomePageData(): Promise<Either<Failure, EHomeFormLinks>> {
  return await homePageDataGet()
}