import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { ComponentProps } from '../../../core/components/ComponentProps'
import { AppColors } from '../../Theme'

interface ProgressLoadingProp extends ComponentProps {
  color?: AppColors
  text?: string
  size?: number
}

export const CircularProgressLoading: React.FC<ProgressLoadingProp> = ({
  color,
  sx,
  text,
  size,
}: ProgressLoadingProp) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 119,
        height: 52,
        ...sx,
      }}
    >
      <CircularProgress
        size={size}
        sx={{
          color: color ?? AppColors.primary600,
        }}
      />
      <Typography sx={{ padding: '8px' }}>{text}</Typography>
    </Box>
  )
}
