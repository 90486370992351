import React, { useState } from 'react'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box, Input } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import { httpPost, saveToLocalStorage } from '../../../data/data'
import { ErrorToast, SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import ErrorContainer from '../../components/error/ErrorContainer'
import { updateOrg } from '../../../data/repositories/orgs/OrgRepository'

export default function AddCorrectiveActionToOrgForm() {
  const { org, forms } = useOrganizationProvider()
  const initFormId = org?.caFormId ?? ''
  const [formId, setFormId] = useState(initFormId)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const handleSubmit = async () => {
    const orgHasForm = forms.some(form=> form.id == formId)
    if(formId != '' && !orgHasForm){
      ErrorToast({
        title: 'The Form ID you added is not part of the org please add it to your org first.'
      })
      return
    }
    setLoading(true)
    const res = await updateOrg(org!.id, {
      caFormId: formId,
    })
    setLoading(false)
    if (res.isRight()) {
      SuccessToast({
        title:
          'Successfully added corrective action form to org.',
      })
      saveToLocalStorage('org', JSON.stringify(res.right))
      return
    }
    if (res.isLeft()) {
      setError(
        res.left?.message ?? 'Unsuccessfully added corrective action form.',
      )
    }
  }
  return (
    <>
      <InnerPageContainer
        headerTitle='Add Corrective Action Form to Org'
        sx={{ marginTop: '24px' }}
      >
        {error && (
          <ErrorContainer sx={{ margin: '24px 0' }}>{error}</ErrorContainer>
        )}
        <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
          <EdifyFieldLabel label='Corrective Action Form ID' />
          <Input
            disableUnderline
            className={'form-control'}
            value={formId}
            onChange={(e) => setFormId(e.target.value)}
          />
        </EdifyFormField>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', margin: '24px' }}
        >
          <EdifyButton
            onClick={handleSubmit}
            title={'Submit'}
            loading={loading}
          />
        </Box>
      </InnerPageContainer>
    </>
  )
}