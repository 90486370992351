import { Box } from '@mui/material'
import React from 'react'
import logo from '../../../assets/icons/safetyMojoLogo.svg'
import lightLogo from '../../../assets/icons/safetyMojoLight.svg'
import { ComponentProps } from '../ComponentProps'

interface Props extends ComponentProps {
  width?: number
  height?: number
  isDarkTheme?: boolean
  orgName?: string
  logoUrl?: string
  socTokenAndOrgId?: string
}

export const SafetyMojoIcon: React.FC<Props> = ({
  sx,
  width,
  height,
  isDarkTheme,
  logoUrl,
  socTokenAndOrgId,
}) => {
  const getIcon = () => {
    if (!isDarkTheme) return lightLogo
    return logoUrl && socTokenAndOrgId ? logoUrl + socTokenAndOrgId : logo
  }
  return (
    <Box
      data-testid='SafetyMojoIcon'
      component='img'
      sx={{
        width: width ?? 147,
        height: height ?? 40,
        ...sx,
      }}
      src={getIcon()}
    />
  )
}
