import React from 'react'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { levels_columns } from '../../components/datagrid/gridData/levels'
import { useNavigate } from 'react-router-dom'
import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import pluralize from 'pluralize'

interface ILevelsDataGridProps {
  vm: any
  title: string
  rows: any
  checkboxSelection?: boolean
  error?: string
  totalCount?: number
}

const LevelsDataGrid: React.FC<ILevelsDataGridProps> = ({
  vm,
  title,
  rows,
  checkboxSelection = true,
  error,
  totalCount = -1,
}) => {
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const navigate = useNavigate()
  const {getHierarchyName} = useOrganizationProvider()

  const handleRowClicked = (params: GridRowParams) => {
    navigate(`/levels/1/${params.id}`, {
      state: { name: params.row.levelName },
    })
  }

  // delete level/s
  const removeClicked = async () => {
    vm.showDeleteConfirmation(selectionModel as string[])
    setSelectionModel([])
  }

  const renderHeaderButtons = () => {
    // don't render bulk action items if turned off
    if (!checkboxSelection) return <></>
    const noSelections = selectionModel.length === 0
    return (
      <BulkDeleteButton noSelections={noSelections} onClick={removeClicked} />
    )
  }

  return (
    <>
      <EdifyDataGrid
        data-testid='LevelsDataGrid'
        paginationMode='server'
        noRowsText={`No ${pluralize(title).toLocaleLowerCase()} found.`}
        title={title}
        renderHeaderButtons={renderHeaderButtons()}
        columns={levels_columns((getHierarchyName(0, true)))}
        rows={rows}
        error={error || vm.error}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={handleRowClicked}
        loading={vm.isLoading || vm.isLoadMore}
        rowCount={totalCount >=0 ? totalCount : vm.totalCount }
        loadMoreRows={vm.loadMore}
        reload={vm.getLevels}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={checkboxSelection}
      />
    </>
  )
}

export default LevelsDataGrid
