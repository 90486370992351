import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'

import EdifySearchDropdown, {
  ISearchResult,
} from '../../../../components/form/EdifySearch/EdifySearchDropdown'
import InnerPageContainer from '../../../../components/inner-page-container/InnerPageContainer'
import PageContainer from '../../../../components/page/PageContainer'
import { searchForms } from '../../../../../data/data'
import { Either, Failure, Left, Right } from '../../../../../core/core'
import {
  usersSearch,
  formsSearch,
  clientSearch,
  searchLevels
} from '../../../../../domain/domain'
import {
  Box,
  FormControl,
  Select,
  Typography,
} from '@mui/material'
import { projectsSearch } from '../../../../../data/repositories/orgs/OrgRepository'
import { AppBorders } from '../../../../Theme'
import { useNavigate } from 'react-router-dom'
import EdifyMenuItem from '../../../../components/form/EdifyMenuItem'

const md = `
Sure! Here's the Markdown documentation for the *EdifySearchDropdown* component without the code examples:

# EdifySearchDropdown Component Documentation

The *EdifySearchDropdown* component is a customizable search dropdown that allows users to search for items and select them from the search results. It provides various props for customization and can be easily integrated into your React applications.

## Props

The *EdifySearchDropdown* component accepts several props for customization:

- *sx* (Object): Custom styling using Material-UI's SxProps.
- *onSelect* (Function): Callback function triggered when items are selected. It receives an array of selected search items as an argument.
- *width* (Number): Width of the search dropdown. Default is *400*.
- *searchFunction* (Function): Async function that performs the search and returns a Promise. It should accept a search string as an argument and return a Promise that resolves to the search results.
- *multiSelect* (Boolean): If *true*, allows multiple items to be selected. Default is *false*.

## Usage

To use the *EdifySearchDropdown* component, you can follow these steps:

1. Define the *searchFunction*:

The *searchFunction* is a required prop that should be an asynchronous function that performs the search based on the provided search term and returns a Promise with the search results. The search results should be an array of objects, where each object represents a search result item with properties *id* and *name*.

2. Implement the *onSelect* callback:

The *onSelect* prop is a callback function that is triggered when items are selected from the search results. It receives an array of selected search items as an argument. You can handle the selected items as needed in this callback function.

3. Use the *EdifySearchDropdown* component in your React component:

Now, you can use the *EdifySearchDropdown* component in your React component and pass the required props as needed.

That's it! You have successfully integrated the *EdifySearchDropdown* component into your React application, and users can now search and select items using the dropdown.
`

// Example search function that simulates an API call and returns a Promise with search results

const EdifySearchDropdownDoc = () => {
  // Event handler for when items are selected from the dropdown

  const handleSelect = (searchItems: ISearchResult[]) => {
    setSelectedItems(searchItems)
  }
  const [selectedItems, setSelectedItems] = useState<ISearchResult[]>([])




  // Example search function that simulates an API call and returns a Promise with search results
  const performEmptySearch = async (
    searchValue: string,
  ): Promise<Either<Failure, any>> => {
    // Simulate API call with a delay using setTimeout
    const fakeAPICall = (searchValue: string): any => {
      if (searchValue == 'empty') {
        return new Right([])
      }
      return new Left('dfs')
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(fakeAPICall(searchValue))
      }, 1000) // Simulate a 1-second delay
    })
  }
  const initialValues = [
    {
      id: 'bb2df24a-3c05-45c7-bce1-adb7528dc5d2',
      name: 'seedForm3',
    },
    {
      id: '73500ee9-39b0-481b-877f-ffc4ccbbab23',
      name: 'seedForm2',
    },
  ]
  const initialValue = [
    {
      id: 'bb2df24a-3c05-45c7-bce1-adb7528dc5d2',
      name: 'seedForm3',
    },
  ]

  return (
    <PageContainer title='EdifySearchDropdown Doc'>
      <InnerPageContainer header={<h3>markdown</h3>} collapsible>
        <ReactMarkdown>{md}</ReactMarkdown>
      </InnerPageContainer>

      <InnerPageContainer>
        <h1>Selected Items</h1>
        <pre>{JSON.stringify(selectedItems, null, 2)}</pre>
      </InnerPageContainer>

      <InnerPageContainer>
        <h2>Example 1: Basic Usage</h2>
        <EdifySearchDropdown
          onSelect={handleSelect}
          searchFunction={searchForms}
        />
      </InnerPageContainer>

      <InnerPageContainer>
        <h2>Example 2: Multi-Select</h2>
        <EdifySearchDropdown
          onSelect={handleSelect}
          searchFunction={searchForms}
          multiSelect
        />
      </InnerPageContainer>
      <InnerPageContainer>
        <h2>Example 3: Custom Styling</h2>
        <EdifySearchDropdown
          onSelect={handleSelect}
          searchFunction={searchForms}
          sx={{ backgroundColor: '#f0f0f0', border: '2px solid #ccc' }}
          width={300}
        />
      </InnerPageContainer>
      <InnerPageContainer>
        <h2>Example 4: Error</h2>
        <EdifySearchDropdown
          onSelect={handleSelect}
          searchFunction={() => performEmptySearch('error')}
        />
      </InnerPageContainer>

      <InnerPageContainer>
        <h2>Example 5: Empty Search</h2>
        <EdifySearchDropdown
          onSelect={handleSelect}
          searchFunction={clientSearch}
        />
      </InnerPageContainer>

      <InnerPageContainer>
        <h2>Example 6a: renders predefined values</h2>
        <EdifySearchDropdown
          onSelect={handleSelect}
          searchFunction={searchForms}
          initialSelectedItems={initialValue}
        />

        <h2>Example 6b: renders predefined values (multiselect)</h2>
        <EdifySearchDropdown
          multiSelect
          onSelect={handleSelect}
          searchFunction={searchForms}
          initialSelectedItems={initialValues}
        />
      </InnerPageContainer>

      <NavBarSearchDemo />

      <InnerPageContainer>
        <h2>Example 8: Unselected Pill Options (needs work)</h2>
        <EdifySearchDropdown
          onSelect={handleSelect}
          searchFunction={clientSearch}
          multiSelect
          pillListWidth={'100%'}
          width={512}
          searchHistoryKey='projects'
          
        />
      </InnerPageContainer>
    </PageContainer>
  )
}

export default EdifySearchDropdownDoc

const NavBarSearchDemo = () => {
  const navigate = useNavigate()

  const handleSelect = (searchItems: ISearchResult[]) => {
    if(searchItems.length == 0) return 
    setSelectedItems(searchItems)
    const url = selectedOption == 'levels'  ? 'levels/1' : selectedOption === 'forms' ? 'submissions' : selectedOption
    navigate(`/${url}/${searchItems[0].id}`)
  }
  const [selectedItems, setSelectedItems] = useState<ISearchResult[]>([])
  const [selectedOption, setSelectedOption] = useState('users')

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value)
  }

  const getSearchFunction = () =>{
    switch (selectedOption) {
      case 'forms':
        return formsSearch
      case 'projects':
        return projectsSearch
      case 'levels':
        return searchLevels
      default:
        return usersSearch
    }
  }
  return (
    <InnerPageContainer>
      <h2>Example 7: NavBar search</h2>
      <InnerPageContainer>
        <Typography>search: {selectedOption}</Typography>
        <h1>Selected Items</h1>
        <pre>{JSON.stringify(selectedItems, null, 2)}</pre>
      </InnerPageContainer>

      <Box
        sx={{
          ...AppBorders.primary,
          padding: '12px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <FormControl>
          <Select
            style={{ width: '200px', marginRight: '16px' }}
            labelId='dropdown-label'
            id='dropdown'
            defaultValue='forms'
            value={selectedOption}
            onChange={handleChange}
          >
            <EdifyMenuItem value='forms'>Forms</EdifyMenuItem>
            <EdifyMenuItem value='users'>Users</EdifyMenuItem>
            <EdifyMenuItem value='levels'>Levels</EdifyMenuItem>
            <EdifyMenuItem value='projects'>Projects</EdifyMenuItem>
          </Select>
        </FormControl>

        <EdifySearchDropdown
          // multiSelect
          onSelect={handleSelect}
          searchFunction={getSearchFunction()}
          limit={selectedOption === 'users' ? 8 : undefined}
          initialSelectedItems={[]}
          nameKey={selectedOption === 'users' ? 'fullName' : 'name'}
        />
      </Box>
    </InnerPageContainer>
  )
}
