// for testing/demo
export const correctiveActionResponse: IPermission = {
  id: 'correctiveActionFeatureAccess',
  name: 'Corrective Action Response',
  description:
    'The ability to respond to corrective actions, including commenting, sending back, and close on the Corrective Action.',
  featureAccess: 1,
  permissions: {
    comment: 2,
    close: 2,
  },
}

// for testing/demo
export const withOutCreate: IPermission = {
  id: 'withOutCreateFeatureAccess',
  name: 'withOutCreate Action Response',
  description: 'For Testing',
  featureAccess: 1,
  permissions: {
    read: 2,
    edit: 2,
  },
}

import React, { useContext, useEffect, useState } from 'react'
import { adminGetRole, adminGetRolesWithQuery } from '../../../domain/domain'
import { IPermission, IRoleResponse } from '../../../domain/interfaces/IRole'
import { useParams } from 'react-router-dom'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { formatSnakeCase } from '../../../core/utils/text-formaters/TextFormatters'
import { areArraysEqual } from './helpers'

export interface IRolePageViewModel {
  loading: boolean
  error: string | undefined
  role: IRoleResponse | undefined
  id: string | undefined
  roleData: IPermission[]
  setRoleData: React.Dispatch<React.SetStateAction<IPermission[]>>
  formData: IPermission[]
  setFormData: React.Dispatch<React.SetStateAction<IPermission[]>>
  getSelectRole: (selectedRoleId: string) => void
  systemRoleOptions: { name: string; id: string }[]
  activeSystemRole: { name: string; id: string } | undefined
  setActiveSystemRole: React.Dispatch<
    React.SetStateAction<{ name: string; id: string } | undefined>
  >
  roleLoading: boolean
  newRoleOptions: { value: string; name: string }[]
  isSystemRole: boolean
  hasChanged: (newName: string) => boolean
  hideForms: boolean
}

const RolePageContext = React.createContext<IRolePageViewModel | null>(null)

export function useRolePageViewModel(): IRolePageViewModel {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(RolePageContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const RolePageViewModel: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [roleLoading, setRoleLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [role, setRole] = useState<IRoleResponse | undefined>(undefined)
  const { roles } = useOrganizationProvider()

  const [roleData, setRoleData] = useState<IPermission[]>([])
  const [formData, setFormData] = useState<IPermission[]>([])
  const [hideForms, setHideForms] = useState<boolean>(false)
  const [newRoleOptions, setNewRoleOptions] = React.useState<
    { value: string; name: string }[]
  >([])
  const [isSystemRole, setIsSystemRole] = React.useState<boolean>(true)

  const { id } = useParams()
  const systemRoleOptions = roles
    ? roles
      .filter(
        (role) =>
          role.isSystemRole &&
          role.name.toLocaleLowerCase() !== 'super_admin',
      )
      .map((role) => {
        return { name: formatSnakeCase(role.name), id: role.id }
      })
    : []

  const [activeSystemRole, setActiveSystemRole] = useState<
    { name: string; id: string } | undefined
  >(systemRoleOptions?.find((role: any) => role.name === 'User'))

  const getRole = async (selectedRoleId?: string) => {
    let userRoleId
    if (selectedRoleId) {
      userRoleId = selectedRoleId
    } else {
      userRoleId = systemRoleOptions?.find(
        (role: any) => role.name === 'User',
      )?.id
    }
    const roleId = id ? id : userRoleId
    if (!roleId) return
    setLoading(true)
    setError(undefined)
    const res = await adminGetRole(roleId ?? '')

    if (res.right) {
      setRole(res.right)
      setRoleData(res.right.features)
      // add feature access flag to forms for consistency with UI
      const formsWithFeatureAccessFlag = res.right.forms.map(
        addFeatureAccessToForm,
      )
      setFormData(formsWithFeatureAccessFlag)
      if (!res.right.isSystemRole) {
        setIsSystemRole(false)
        await getRoles()
      }
      setLoading(false)
      return
    }
    setLoading(false)
    setError(res.left?.message ?? 'Error loading roles.')
  }
  const getRoles = async () => {
    const resRoles = await adminGetRolesWithQuery('&skip=0&limit=100')
    if (resRoles.right) {
      setNewRoleOptions(
        resRoles.right.data
          .map((role) => {
            return { value: role.id, name: role.name }
          })
          .filter((roleOption) => roleOption.value !== id),
      )
    }
  }
  const getIdAndPermissionAndFeature = (permission: IPermission) => {
    return {
      id: permission.id,
      permissions: permission.permissions,
      featureAccess: permission?.featureAccess ?? 0,
    }
  }

  const getIdAndPermission = (permission: IPermission) => {
    return {
      id: permission.id,
      permissions: permission.permissions,
    }
  }

  const hasChanged = (newName: string) => {
    const originalName = role?.name ?? ''
    if(newName !== originalName) return true
    const originalFeatures =
      role?.features.map(getIdAndPermissionAndFeature) ?? []
    const currentRoles = roleData.map(getIdAndPermissionAndFeature)
    const originalForms = role?.forms.map(getIdAndPermission) ?? []
    const currentForms = formData.map(getIdAndPermission)
    const isSame =
      areArraysEqual(currentRoles, originalFeatures) &&
      areArraysEqual(originalForms, currentForms)
    return !isSame
  }

  const getSelectRole = async (selectedRoleId: string) => {
    setRoleLoading(true)
    setError(undefined)
    const res = await adminGetRole(selectedRoleId)
    setRoleLoading(false)
    if (res.right) {
      setRole(res.right)
      setRoleData(res.right.features)
      const formsWithFeatureAccessFlag = res.right.forms.map(
        addFeatureAccessToForm,
      )
      setFormData(formsWithFeatureAccessFlag)
      return
    }
    setError(res.left?.message ?? 'Error loading roles.')
  }
  const addFeatureAccessToForm = (item: IPermission) => {
    const allZero =
      item.permissions &&
      Object.values(item.permissions).every((value) => value === 0)
    return {
      ...item,
      featureAccess: allZero ? 0 : 1,
    }
  }

  useEffect(() => {
    getRole()
  }, [])
  useEffect(() => {
    const subFeature = roleData.find(
      (data) => data.name === 'Submission Management',
    )
    if(!subFeature) return
    if(hideForms && subFeature.featureAccess === 1 ){
      setHideForms(false)
      return
    }
    if(!hideForms && subFeature.featureAccess === 0 ){
      setHideForms(true)
      return
    }
  }, [roleData])

  return (
    <RolePageContext.Provider
      value={{
        role,
        error,
        loading,
        id,
        roleData,
        setRoleData,
        formData,
        setFormData,
        getSelectRole,
        systemRoleOptions,
        activeSystemRole,
        setActiveSystemRole,
        roleLoading,
        newRoleOptions,
        isSystemRole,
        hasChanged,
        hideForms
      }}
    >
      {children}
    </RolePageContext.Provider>
  )
}
