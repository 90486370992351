import React from 'react'
import { Button as MuiButton, ButtonGroup, ButtonProps } from '@mui/material'
import { AppColors } from '../../Theme'
import { EnhancedPermission } from './helpers'

// Custom Button component
const CustomButton: React.FC<ButtonProps & { isActive: boolean }> = ({
  isActive,
  children,
  disabled,
  sx,
  ...props
}) => {
  return (
    <MuiButton
      disabled={disabled}
      variant={isActive ? 'contained' : 'outlined'}
      sx={{
        bgcolor: isActive ? '#1976d2' : 'transparent',
        color: isActive ? 'white' : '#1976d2',
        fontSize: 12,
        padding: '5px 8px',
        maxHeight: '26px',
        '&:hover': {
          bgcolor: isActive ? '#1565c0' : 'rgba(25, 118, 210, 0.04)',
        },
        '&:disabled': {
          bgcolor: isActive ? AppColors.neutral700 : 'transparent',
          color: 'grey',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiButton>
  )
}

// Props for the PermissionButtonGroup component
interface PermissionButtonGroupProps {
  permissionKey: string
  value: number
  onChange: (value: number) => void
  disabled?: boolean
  permission: EnhancedPermission
}

// PermissionButtonGroup component
const PermissionButtonGroup: React.FC<PermissionButtonGroupProps> = ({
  permissionKey,
  value,
  onChange,
  disabled = false,
  permission,
}) => {
  const buttonValues = permission.buttonValues[permissionKey] || []

  const getButtonValue = (index: number): number => {
    return buttonValues.length === 2 ? index * 2 : index
  }

  return (
    <ButtonGroup
      size='small'
      variant='outlined'
      aria-label={`${permissionKey} permission`}
      sx={{
        '& .MuiButton-root': {
          minWidth: 70,
          border: '1px solid #bdbdbd',
          '&:not(:last-of-type)': {
            borderRight: 'none',
          },
        },
      }}
    >
      {buttonValues.map((buttonLabel, index) => {
        const buttonValue = getButtonValue(index)
        return (
          <CustomButton
            disabled={disabled || permission.readonly && permissionKey !== 'read'}
            key={buttonLabel}
            isActive={value === buttonValue}
            onClick={() => onChange(buttonValue)}
          >
            {buttonLabel}
          </CustomButton>
        )
      })}
    </ButtonGroup>
  )
}

export default PermissionButtonGroup
