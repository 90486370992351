import React from 'react'
import { Checkbox, Grid, SxProps } from '@mui/material'

import { ETypography } from '../../components/fonts/ETypography'
import { camelCaseToNormal } from '../../../core/utils/text-formaters/TextFormatters'
import { SettingsInfoBlock } from '../../pages/formSettings/FormSettingsPage'

interface Props {
  roles: any
}

const PermissionList: React.FC<Props> = ({ roles }) => {
  function checkZero(value: string | number): string {
    return (value !== 0 && value !== '0').toString()
  }

  const renderUserRoles = () => {
    if (!roles) {
      return (
        <ETypography font='SS' color='gray950'>
          No Roles
        </ETypography>
      )
    }

    const jsx = []
    for (const key in roles) {
      if (Object.prototype.hasOwnProperty.call(roles, key)) {
        const value = roles[key]
        if (value === 0 || value === 1) {
          jsx.push(
            // <SettingsInfoBlock
            //   header={camelCaseToNormal(key)}
            //   value={checkZero(value)}
            // />,
            <EdifyToggle1
              disabled
              text={camelCaseToNormal(key)}
              checked={value}
              onChange={() => 1}
            />,
          )
        }
      }
    }
    return jsx
  }
  return <Grid container>{renderUserRoles()}</Grid>
}

export default PermissionList

import { Box, Typography } from '@mui/material'
import { AppColors, AppFonts } from '../../Theme'
interface Props1 {
  text: string
  checked?: boolean
  onChange?: () => void
  sx?: SxProps
  disabled?: boolean
}
export const EdifyToggle1: React.FC<Props1> = ({
  text,
  checked,
  onChange,
  sx,
  disabled
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'top',
        marginTop: '24px',
        ...sx,
      }}
    >
      <Box
        className='form-control'
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginRight: '16px',
          width: '250px',
          alignItems: 'center',
          border: `1px solid ${
            checked ? AppColors.primary700 : AppColors.neutral700
          }`,
        }}
      >
        <Checkbox disabled={disabled} checked={checked} onChange={onChange} />
        <Typography sx={{ ...AppFonts.textSMedium, color: AppColors.gray700 }}>
          {text}
        </Typography>
      </Box>
    </Box>
  )
}
