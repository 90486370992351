import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/page/PageContainer'
import { Box, CircularProgress } from '@mui/material'
import { dateToMMMDYYYY } from '../../../core/core'
import { ProfileHeader } from '../../components/profile/ProfileHeader'
import ProfileContainer from '../../components/profile/ProfileContainer'
import { EdifyReadFormField } from '../../components/form/shared/EdifyReadFormField'
import { phoneInputFormatter } from '../../../core/utils/input-formatters/InputFormatters'
import CertificatesDataGrid from '../certificates/CertificatesDataGrid'
import {
  CertificatesPageViewModel,
  useCertificatesPageViewModel,
} from '../certificates/CertificatesViewModel'
import { IUser } from '../../../domain/interfaces/IUser'
import { getUserById } from '../../../data/data'
import { useParams, useSearchParams } from 'react-router-dom'
import { SafetyMojoLoginIcon } from '../../../core/components/icons/SafetyMojoLoginIcon'
import { ETypography } from '../../components/fonts/ETypography'
import { Error } from '@mui/icons-material'
import ErrorContainer from '../../components/error/ErrorContainer'
import { anonGetUser } from '../../../domain/domain'

interface Props {
  user?: IUser | null
  orgId: string
}
const _AnonUserPage: React.FC<Props> = ({ user, orgId }) => {
  const certificateVM = useCertificatesPageViewModel()

  return (
    <PageContainer breadCrumbSX={{ marginBottom: '0px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // flex: 1,
          marginBottom:'12px'
        }}
      >
        <SafetyMojoLoginIcon width={180} height={48} />

 
      </Box>
      {!user && <ErrorContainer> Error loading user</ErrorContainer>}

      <>
        {user && (
          <ProfileContainer>
            <ProfileHeader
              canEdit={false}
              profile={user}
              imageURL={user?.imageURL}
              // to show name (no edit for anon)
              setIsEdit={() => true}
              fullName={user?.fullName ?? 'NA'}
              // role={user?.roles[0].name}
            />
            <Box sx={{ width: '100%', padding: '0px 24px' }}>
              <Box style={{ display: 'flex', flex: 1 }}>
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'PHONE'}
                  value={user?.phone ? phoneInputFormatter(user?.phone) : 'NA'}
                />
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'EMAIL'}
                  value={user?.email ?? 'NA'}
                />
              </Box>
            </Box>
            <Box sx={{ width: '100%', padding: '0px 24px' }}>
              <Box style={{ display: 'flex', flex: 1 }}>
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'ORGANIZATION'}
                  value={user?.org?.name ?? 'None'}
                />
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'DATE ADDED'}
                  value={user?.createdAt ? dateToMMMDYYYY(user.createdAt) : ''}
                />
              </Box>
            </Box>
            <Box sx={{ width: '100%', padding: '0px 24px' }}>
              <Box style={{ display: 'flex', flex: 1 }}>
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'POINT OF CONTACT'}
                  value={user?.pointOfContactName ?? 'NA'}
                />
              </Box>
            </Box>
          </ProfileContainer>
        )}

        <CertificatesDataGrid
          vm={certificateVM}
          title={'Training and Certificates'}
          checkboxSelection={false}
          isAnon={true}
          anonOrgId={orgId}
        />
      </>
    </PageContainer>
  )
}

export const AnonUserPage: React.FC = () => {
  const [user, setUser] = useState<IUser | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { userId } = useParams()
  const [searchParams] = useSearchParams()
  const orgId = searchParams.get('orgId')

  useEffect(() => {
    const getUser = async () => {
      setLoading(true)
      // TODO replace with ANON call and check when BE done
      const res = await anonGetUser(orgId!, userId!)
      setLoading(false)
      if (res.isLeft() && res.left) {
        setError(res.left.message)
        return
      }
      setUser(res.right!)
    }
    getUser()
  }, [userId])

  if (loading) {
    return (
      <PageContainer breadCrumbSX={{ marginBottom: '0px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // flex: 1,
          }}
        >
          <SafetyMojoLoginIcon width={180} height={48} />

          <ETypography font='LM' color='gray700' sx={{ marginBottom: '12px' }}>
            Certificates and Trainings
          </ETypography>
          {loading && <CircularProgress />}
        </Box>
      </PageContainer>
    )
  }
  return (
    <CertificatesPageViewModel isAnon anonOrgId={orgId!} anonUserId={userId}>
      <_AnonUserPage orgId={orgId!} user={user} />
    </CertificatesPageViewModel>
  )
}
