import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
} from '@mui/material'
import React, { useEffect } from 'react'
import { AppColors, AppFonts } from '../../Theme'
import { EdifyButton } from '../../components/buttons'
import { adminGetCertificateById } from '../../../domain/usecases/certificates/CertificatesUsecases'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { ICertificate } from '../../../domain/interfaces/ICertificate'
import { EdifyReadFormField } from '../../components/form/shared/EdifyReadFormField'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_USERS } from '../users/UsersPage'
import { MojoFormRender } from '../submission/MojoFormRender'
import EdifyImageViewer from '../../components/form/EdifyImage/EdifyImageViewer'
import { ETypography } from '../../components/fonts/ETypography'
import PublicOrganizationProvider, {
  usePublicOrganizationProvider,
} from '../../../providers/PublicOrganizationProvider'
import { I18nProvider } from '../../../providers/InternationalizationProvider'
import { dateToMMDDYY } from '../../../core/core'
import { addOrgIdAndTokenToFormComponents } from '../submission/submissionHelpers'

interface Props {
  open: boolean
  handleClose: (reload?: any) => void
  certificateId: string | undefined
  orgId: string
  isAnon?: boolean
  activeCertificate: ICertificate | undefined
  canEdit: boolean
}

const CertificateModal: React.FC<Props> = ({
  handleClose,
  certificateId,
  open,
  orgId,
  isAnon = false,
  activeCertificate,
  canEdit,
}) => {
  const navigate = useNavigate()
  const { userId } = useParams()
  const [certificateError, setCertificateError] = React.useState<
    string | undefined
  >('')
  const [certificateLoading, setCertificateLoading] =
    React.useState<boolean>(false)
  const [certificate, setCertificate] = React.useState<
    ICertificate | undefined
  >(undefined)
  const loadCertificate = async () => {
    if (activeCertificate) {
      setCertificate(activeCertificate)
      return
    }
    if (!certificateId) return
    setCertificateLoading(true)
    const res = await adminGetCertificateById(certificateId)
    setCertificateLoading(false)
    if (res.isLeft() || !res.right) {
      setCertificateError('Error loading certificate.')
      return
    }
    setCertificate(res.right)
  }

  const renderContent = () => {
    if (certificateLoading) return <CircularProgress />
    if (certificateError) return <div>{certificateError}</div>
    if (!certificate) return <div>No certificate found</div>
    if (certificate.type.toLocaleLowerCase() == 'certificate')
      return <CertificateSection certificate={certificate} />
    if (certificate.type.toLocaleLowerCase() == 'training')
      if (isAnon) {
        return (
          <PublicOrganizationProvider
            orgId={orgId}
            formId={certificate.formId!}
          >
            <I18nProvider orgId={orgId}>
              <AnonFormWrapper certificate={certificate!} orgId={orgId} />
            </I18nProvider>
          </PublicOrganizationProvider>
        )
      }
    return (
      <MojoFormRender
        orgId={orgId}
        formId={certificate.formId!}
        submissionId={certificate.submissionId}
        readOnly={true}
        isAnon={isAnon}
      />
    )
  }

  useEffect(() => {
    return () => {
      loadCertificate()
    }
  }, [certificateId, open])

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby='child-modal-title'
        aria-describedby='child-modal-description'
        sx={{ marginTop: '50px' }}
      >
        <Box
          sx={{
            ...styles,
            width: {
              sm: '100%',
              md: '800px',
            },
          }}
        >
          <DialogTitle
            sx={{
              ...AppFonts.headlineSDesktopSemibold,
              color: AppColors.gray700,
              p: 0,
              fontSize: '24px',
              fontWeight: 600,
              marginBottom: '24px',
            }}
          >
            {certificate?.name ?? ''}
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              pb: 12,
              overflowY: 'scroll',
              height: 'auto',
              overflow: 'scroll',
            }}
          >
            {/* NOT SURE YET HOW TO KNOW THE DIFFERENCE BETWEEN CERTIFICATE AND TRAINING */}
            <InnerPageContainer
              innerPageError={certificateError}
              innerPageLoading={certificateLoading}
            >
              {renderContent()}
            </InnerPageContainer>
          </DialogContent>
          <DialogActions
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              p: 0,
            }}
          >
            <EdifyButton
              data-testid='CloseDialogButton'
              fullWidth
              title={'Close'}
              secondary
              onClick={() => handleClose(false)}
            />

            <EdifyButton
              title={'Edit'}
              hide={
                !canEdit ||
                (certificate &&
                  certificate.type.toLocaleLowerCase() == 'training')
              }
              fullWidth
              onClick={() =>
                navigate(
                  `${ROUTE_USERS}/${userId}/certificates/${certificateId}/edit`,
                )
              }
            />
          </DialogActions>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export default CertificateModal

const styles = {
  position: 'absolute',
  top: '10vh',
  left: '50%',
  transform: 'translate(-50%, -50px)',
  height: 'auto',
  maxHeight: '80vh',
  overflow: 'scroll',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 24,
}

interface AProps {
  orgId: string
  certificate: ICertificate
}

const AnonFormWrapper: React.FC<AProps> = ({ orgId, certificate }) => {
  const { publicOrgProviderLoaded,level1Name,level0Name  } = usePublicOrganizationProvider()

  if (!publicOrgProviderLoaded) return <CircularProgress />

  const readOnlyFormJson = addOrgIdAndTokenToFormComponents(
    certificate.form!,
    level1Name,
    level0Name,
    orgId,
    true,
  )

  return (
    <MojoFormRender
      orgId={orgId}
      formId={certificate.formId!}
      form={readOnlyFormJson}
      submission={certificate.submission}
      readOnly={true}
      isAnon={true}
    />
  )
}

const CertificateSection: React.FC<{ certificate: ICertificate }> = ({
  certificate,
}) => {
  return (
    <>
      <Box sx={{ width: '100%', padding: '0px 24px' }}>
        <Box style={{ display: 'flex', flex: 1 }}>
          <EdifyReadFormField
            sx={{ flex: 1 }}
            label={'issuing organization'}
            value={certificate?.issuer ?? 'NA'}
          />
          <EdifyReadFormField
            sx={{ flex: 1 }}
            label={'ISSUE DATE'}
            value={
              certificate?.issueDate
                ? dateToMMDDYY(certificate.issueDate)
                : 'NA'
            }
          />
        </Box>
      </Box>
      <Box sx={{ width: '100%', padding: '0px 24px' }}>
        <Box style={{ display: 'flex', flex: 1 }}>
          <EdifyReadFormField
            sx={{ flex: 1 }}
            label={'expiration date'}
            value={
              certificate?.expirationDate
                ? dateToMMDDYY(certificate.expirationDate)
                : 'NA'
            }
          />
          <EdifyReadFormField
            sx={{ flex: 1 }}
            label={'Certificate Number'}
            value={certificate?.certificateNumber ?? 'NA'}
          />
        </Box>
      </Box>
      <Box sx={{ width: '100%', padding: '0px 24px' }}>
        <Box style={{ display: 'flex', flex: 1 }}>
          <EdifyReadFormField
            sx={{ flex: 1 }}
            label={'Notes'}
            value={certificate?.notes ?? 'NA'}
          />
        </Box>
      </Box>
      <Box sx={{ width: '100%', padding: '0px 24px', marginTop: '24px' }}>
        <ETypography font='US' color='gray25' sx={{ marginBottom: '8px' }}>
          ATTACHMENTS
        </ETypography>
        {certificate?.attachments && certificate.attachments.length > 0 ? (
          certificate?.attachments.map((attachment) => {
            return (
              <>
                <EdifyImageViewer
                  key={attachment}
                  name={attachment.split('?')[0].split('/').at(-1)}
                  attachment={attachment}
                />
                <Box sx={{ mb: '24px' }} />
              </>
            )
          })
        ) : (
          <ETypography font='XLS' color='gray700'>
            NA
          </ETypography>
        )}
      </Box>
    </>
  )
}
