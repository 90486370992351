import React, { useState } from 'react'
import { IForm } from '../../../domain/interfaces/IForm'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box, Checkbox, Grid } from '@mui/material'
import { AppBorders, AppColors } from '../../Theme'
import { ETypography } from '../../components/fonts/ETypography'
import { EdifyButton } from '../../components/buttons'
import SubmissionsModal from './SubmissionsModal'
import { IFormSubmitter } from '../../../core/hooks'
import LinkIcon from '@mui/icons-material/Link'
interface AdditionalFormsProps {
  form: IForm
  formSubmitter: IFormSubmitter
}

const AdditionalForms: React.FC<AdditionalFormsProps> = ({
  form,
  formSubmitter,
}) => {
  const {
    additionalFormSubmissions,
    linkFormsState,
    toggleAdditionalForm,
    toggleLinkedForms,
  } = formSubmitter
  const { additionalForms, linkForms } = form
  const getSelectedSubmissionIds = (form: any) => {
    const linkedForm = linkFormsState.find((f: any) => f.id == form.id)
    if (!linkedForm) return []
    return linkedForm.submissionIds
  }
  return (
    <>
      {additionalForms && additionalForms.length > 0 && (
        <InnerPageContainer
          headerTitle='Add Additional Forms'
          // collapsed
          collapsible
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginTop: '24px',
          }}
        >
          <ETypography font='MS' color='gray700' sx={{ margin: '12px 0' }}>
            SELECT FORMS
          </ETypography>

          <Grid container spacing={'12px'}>
            {additionalForms.map((f) => {
              const isChecked = additionalFormSubmissions.some(
                (sub: any) => sub.id == f.id,
              )
              return (
                <Grid
                  key={f.id}
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  onClick={() => toggleAdditionalForm(f.id, isChecked)}
                >
                  <ButtonWithCheckbox
                    title={f.title}
                    checked={isChecked}
                  ></ButtonWithCheckbox>
                </Grid>
              )
            })}
          </Grid>
        </InnerPageContainer>
      )}
      {linkForms &&
        linkForms.map((f) => (
          <FormLink
            key={f.id}
            title={f.title}
            formId={f.id}
            toggleLinkedForms={toggleLinkedForms}
            selectedSubmissionIds={getSelectedSubmissionIds(f)}
          />
        ))}
    </>
  )
}

export default AdditionalForms

// FORM LINK
interface FormLinkProps {
  title: string
  formId: string
  toggleLinkedForms: any
  selectedSubmissionIds: string[]
}

const FormLink: React.FC<FormLinkProps> = ({
  title,
  formId,
  toggleLinkedForms,
  selectedSubmissionIds,
}) => {
  const [open, setOpen] = useState(false)
  if (!open) {
    // Modal load VM so only load that when in is open
    return (
      <InnerPageContainer
        headerTitle={`${title}`}
        renderRightItems={() => (
          <EdifyButton
            onClick={() => setOpen(true)}
            buttonStyle={{ padding: 0, margin: 0 }}
            secondary
            // noBackground
            title='+'
          />
        )}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          marginTop: '24px',
        }}
      >
        {selectedSubmissionIds.map((id) => {
          const removeSubmission = selectedSubmissionIds.filter((s) => s !== id)
          return (
            <Box
              sx={{
                display: 'flex',
                width:'80px',
                justifyContent: 'space-between',
                '&: hover': {
                  cursor: 'pointer',
                },
              }}
              key={id}
              onClick={() =>
                toggleLinkedForms(formId, title, removeSubmission)
              }
            >
              <ETypography font='MS' color='primary600'>
                Form
              </ETypography>
              <LinkIcon sx={{ color: AppColors.primary600 }} />
            </Box>
          )
        })}
      </InnerPageContainer>
    )
  }

  return (
    <>
      <SubmissionsModal
        formId={formId}
        open={open}
        handleClose={() => setOpen(false)}
        handleAddSelection={toggleLinkedForms}
        selectedSubmissionIds={selectedSubmissionIds}
      />
      <InnerPageContainer
        headerTitle={`${title}`}
        renderRightItems={() => (
          <EdifyButton
            onClick={() => setOpen(true)}
            buttonStyle={{ padding: 0, margin: 0 }}
            secondary
            noBackground
            title='+'
          />
        )}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          marginTop: '24px',
        }}
      ></InnerPageContainer>
    </>
  )
}

// BUTTON - MOVE TO COMPONENTS
interface ButtonWithCheckboxProps {
  checked: boolean
  title: string
}

const ButtonWithCheckbox: React.FC<ButtonWithCheckboxProps> = ({
  checked: initChecked,
  title,
}) => {
  const [checked, setChecked] = useState<boolean>(initChecked)

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  return (
    <Box
      onClick={() => setChecked(!checked)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 0px 0px 12px',
        ...AppBorders.primary,
        '&: hover': {
          cursor: 'pointer',
        },
      }}
    >
      <ETypography font='MS' color='gray700'>
        {title}
      </ETypography>
      <Checkbox
        checked={checked}
        onChange={handleCheckboxChange}
        color='primary'
      />
    </Box>
  )
}
