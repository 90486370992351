import { Either, Failure } from '../../../core/core'
import {
  IContact,
  IContactCreate,
  IContactDeleted,
  IContactList,
} from '../../../domain/interfaces/IContact'
import { ETrade } from '../../../domain/interfaces/IContractor'
import { contactParams } from '../../../presentation/pages/contact/ContactPageViewModel'
import {
  httpDelete,
  httpDeleteWithPayload,
  httpGet,
  httpPost,
  httpPut,
} from '../../data'

export async function getContacts(
  queryString: string,
): Promise<Either<Failure, IContactList>> {
  const res: Either<Failure, IContactList> = await httpGet(
    `/contacts?${queryString}`,
    false,
  )

  return res
}

export async function getContactById(
  id: string,
): Promise<Either<Failure, IContact>> {
  const res: Either<Failure, IContact> = await httpGet(`/contacts/${id}`, true)

  return res
}

export async function createContact(
  contact: contactParams,
  contractorId: string,
): Promise<Either<Failure, IContact>> {
  const res: Either<Failure, IContact> = await httpPost(
    '/contacts',
    {...contact, contractorId},
    undefined, //no additional headers
    true, 
  )

  return res
}

export async function editContact(
  contact: contactParams,
  contactId: string,
): Promise<Either<Failure, IContact>> {
  const res: Either<Failure, IContact> = await httpPut(
    `/contacts/${contactId}`,
    contact,
    false, // direct data parsing is false
  )

  return res
}

export async function deleteContact(
  contactId: string,
): Promise<Either<Failure, IContact>> {
  const res: Either<Failure, IContact> = await httpDelete(
    `/contacts/${contactId}`,
    undefined, //no additional headers
  )

  return res
}

export async function searchContacts(
  skip: number,
  limit?: number,
  key?: string,
): Promise<Either<Failure, IContactList>> {
  const urlStr = `/contacts?searchKey=${key}&skip=${skip}&limit=${limit ?? 8}`
  const res: Either<Failure, IContactList> = await httpGet(urlStr, false)
  return res
}

// TODO: Add when BE updated
export async function deleteContacts(
  contactIds: string[],
): Promise<Either<Failure, IContactDeleted[]>> {
  const res: Either<Failure, IContactDeleted[]> = await httpDeleteWithPayload(
    '/contacts/bulk',
    { contactIds },
  )
  return res
}
