//ED-1383 (EDIT) https://edifyai.atlassian.net/browse/ED-1383
// ED-1389 (create) https://edifyai.atlassian.net/browse/ED-1389
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PageContainer from '../../page/PageContainer'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'
import InnerPageContainer from '../../inner-page-container/InnerPageContainer'
import { Box, Divider, Grid, Input, TextField } from '@mui/material'
import { EdifyFieldLabel, EdifyFormField } from '../../form'
import { EdifyButton } from '../../buttons'
import {
  ErrorToast,
  SuccessToast,
} from '../../../../core/utils/toast-notifications/ToastNotifications'
import EdifyDatePicker from '../../form/EdifyDatePicker'
import {
  createProject,
  getProjectById,
  updateProject,
} from '../../../../domain/domain'

import EdifySearchDropdown, {
  ISearchResult,
} from '../../form/EdifySearch/EdifySearchDropdown'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import FormErrorText from '../../form/FormErrorText'
import dayjs from 'dayjs'
import EdifyImageUploader from '../../form/EdifyImage/EdifyImageUploader'
import EdifyAttachmentViewer from '../../form/EdifyImage/EdifyImageViewer'
import LocationForm from '../../../pages/location/LocationForm'
import { ETypography } from '../../fonts/ETypography'
import {
  ProjectPageViewModel,
  transformCustomFields,
  useProjectPageViewModel,
} from '../../../pages/project/ProjectPageViewModel'
import LocationCSVUpload from '../../../pages/location/LocationCSVUpload'
import ErrorContainer from '../../error/ErrorContainer'
import usePermission from '../../../../core/hooks/usePermission'
import { ERole } from '../../../../domain/interfaces/IRole'
import { globalSearch } from '../../../../domain/usecases/utils/UtilsUsecasses'

import { useCustomFields } from '../../../../core/hooks/useCustomFields'
import _ from 'lodash'
import { AppColors } from '../../../Theme'
import pluralize from 'pluralize'
import { IProjectCustomField } from '../../../../domain/interfaces/IProjects'

type IProjectInputs = {
  name: string
  [key: string]: string | undefined
}

// Create a helper type for better error handling
type CustomFieldErrors = {
  [key: string]: {
    type: string
    message: string
  }
}

export type IProjectFormData = {
  name?: string
  startDate?: string | null
  endDate?: string | null
  pointOfContact?: string | null
  levelOneIds?: string[] | null
  imageURL?: string | null
  [key: string]: any
}

const _ProjectsForm: React.FC = () => {
  // if id it is edit
  const {
    getHierarchyName,
    allLevels,
    flags,
    projectCustomFields,
    projectCustomFieldsError,
    hasCustomFields,
  } = useOrganizationProvider()

  const {
    locations,
    locationsLoading,
    locationsError,
    loadLocations,
    projectName,
    setLocations,
    project,
    pointOfContactLabel,
  } = useProjectPageViewModel()
  const { id } = useParams()
  const canCreateLocation = usePermission(ERole.LocationCreate)
  const [projectId, setProjectId] = useState<string | undefined>(id)
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [projectLoading, setProjectLoading] = useState<boolean>(false)
  const [pointOfContact, setPointOfContact] = useState<ISearchResult>()
  const [levels, setLevels] = useState<ISearchResult[]>([])
  const [projectError, setProjectError] = useState<string | undefined>(
    undefined,
  )
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const [imageURL, setImageURL] = useState<string | null>(null)
  const [notes, setNotes] = useState<string | null>(null)

  const startDateChanged = (date: dayjs.Dayjs | null) => {
    const dateString = date?.format('MM/DD/YYYY') ?? null
    setStartDate(dateString)
  }
  const endDateChanged = (date: dayjs.Dayjs | null) => {
    const dateString = date?.format('MM/DD/YYYY') ?? null
    setEndDate(dateString)
  }
  const [singleCreate, setSingleCreate] = useState(true)
  const [hideForm, setHideForm] = useState(true)
  const [fileSubmitting, setFileSubmitting] = useState<boolean>(false)
  const [fileUrls, setFileUrls] = useState<string[]>([])
  const data = transformCustomFields(projectCustomFields!, project)
  const { formValues, handleValueChange, setFormValues } = useCustomFields(data)
  const pointOfContactField =
    projectCustomFields &&
    projectCustomFields.find((field) => field.key === 'pointOfContact')
  const pointOfContactFieldLabel =
    pointOfContactField?.label ?? 'Point of Contact'

  const level0Name = getHierarchyName(0)
  const locationKey = getHierarchyName(-1)

  // Validation
  const resolver: Resolver<IProjectInputs> = async (values, context) => {
    const errors: FieldErrors<IProjectInputs> = {}
    const customFieldErrors: CustomFieldErrors = {}
    if (!values.name) {
      errors.name = {
        type: 'required',
        message: 'Name is required',
      }
    }
    // Add more validations here if needed

    // Get custom fields from context
    const customFields = (context?.customFields as IProjectCustomField[]) || []

    // Validate each required custom field
    customFields.forEach((field) => {
      console.log('field', field)
      if (field.isRequired) {
        const fieldValue = values[field.key]
        if (!fieldValue || fieldValue.trim() === '') {
          customFieldErrors[field.key] = {
            type: 'required',
            message: `${field.label} is required`,
          }
        }
      }
    })

    // Combine all errors
    const allErrors = {
      ...errors,
      ...customFieldErrors,
    }
    console.log('All Errors:', allErrors)
    return {
      values,
      errors: allErrors,
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IProjectInputs>({
    resolver,
    context: { customFields: projectCustomFields }, // Pass custom fields to the resolver
    mode: 'onChange',
  })
  console.log({ formValues })

  /**
   * Handles the form submission when the form is valid.
   * will run resolver validations before submitting
   * @param {Object} data - The form data submitted by the user.
   * @returns {void}
   */
  const onSubmit = handleSubmit((data) => {
    // react-hook-form will check for errors here and return
    // only checks name
    const { name } = data
    const pointOfContactId = pointOfContact?.id ?? null
    const levelIds = levels.map((l) => l.id)
    const customizedKeyValues = _.mapValues(
      _.keyBy(formValues, 'key'),
      (item) => item.value,
    )
    const formData: IProjectFormData = {
      // Only name is requires
      name,
      ...customizedKeyValues,
      endDate,
      startDate,
      imageURL,
      levelOneIds: levelIds,
      notes,
      attachments: fileUrls,
      pointOfContact: pointOfContactId != '' ? pointOfContactId : null,
    }
    setSubmitting(true)
    setSubmissionError(undefined)
    if (projectId) handleUpdate(projectId, formData)
    else handleCreate(formData)
  })

  const handleUpdate = async (id: string, formData: IProjectFormData) => {
    // TODO use projectUpdate when ED-1387 is merged that
    // has the call and tests..
    const res = await updateProject(id, formData)
    setSubmitting(false)
    if (res.isLeft()) {
      setSubmissionError(
        res.left?.message ?? 'A unknown error occurred, please try again later',
      )
      ErrorToast({ title: 'Error saving new changes.' })
      return
    }
    SuccessToast({ title: `Successfully Updated ${projectName}.` })
    navigate(-1)
  }

  const handleCreate = async (formData: IProjectFormData) => {
    const res = await createProject(formData)
    setSubmitting(false)
    if (res.isLeft()) {
      setSubmissionError(
        res.left?.message ?? 'A unknown error occurred, please try again later',
      )
      ErrorToast({ title: `Error adding ${level0Name}` })
      return
    }
    setProjectId(res.right!.id)
    navigate(-1)
    // navigate(`${ROUTE_PROJECTS}/${res.right!.id}/edit`)
    SuccessToast({ title: `New ${level0Name} has been added.` })
  }
  const contactSelected = (projects: ISearchResult[]) => {
    setPointOfContact(projects[0])
  }
  const levelsSelected = (projects: ISearchResult[]) => {
    setLevels(projects)
  }

  const getProject = async () => {
    setProjectError(undefined)
    setProjectLoading(true)
    const res = await getProjectById(id!)
    setProjectLoading(false)
    if (res.isLeft() || !res.right) {
      setProjectError('Error loading form.')
      return
    }
    // sets name field
    reset({ name: res.right.name })

    // TODO: Dates not setting on Update
    setStartDate(res.right?.createdAt)
    setEndDate(res.right?.endDate)
    setImageURL(res.right?.imageURL ?? null)
    setNotes(res.right?.notes ?? null)
    setFileUrls(res.right?.attachments ?? [])
    const data = transformCustomFields(projectCustomFields!, res.right)
    setFormValues(data)

    setPointOfContact({
      name: res.right.pointOfContact?.fullName || '',
      id: res.right.pointOfContact?.id || '',
    })

    const levelData = res.right.levels
      ? res.right.levels.map((l) => {
        return {
          id: l.id,
          name: l.name,
        }
      })
      : []
    setLevels(levelData)
  }

  const imageUploaded = async (imageURL: string | null) => {
    setSubmissionError(undefined)
    setImageURL(imageURL ?? null)
    setSubmitting(false)
  }
  const deleteImage = () => {
    setImageURL(null)
  }

  useEffect(() => {
    // Edit
    if (id) {
      getProject()
    }
  }, [id])

  const deleteFileAttachment = (url: string) => {
    // remove from array
    const newAttachments = fileUrls.filter((x) => x !== url)
    setFileUrls(newAttachments)
    setFileSubmitting(false)
  }

  //TODO: handle error, leaving this for now for testing
  // if it errors upload a default image
  const fileUpload = (urls: string[]) => {
    setFileSubmitting(false)
    if (!urls) {
      ErrorToast({ title: 'Error uploading photo.' })

      urls = [
        'https://ih1.redbubble.net/image.2469972999.0130/poster,504x498,f8f8f8-pad,600x600,f8f8f8.jpg',
      ]
      return
    }
    if (Array.isArray(urls)) {
      setFileUrls([...fileUrls, ...urls])
    } else {
      setFileUrls([...fileUrls, urls])
    }
  }

  const getBreadCrumbs = () => {
    const defaultCrumbs = [
      { title: 'Organization', to: '' },
      { title: pluralize(level0Name), to: '/projects' },
    ]
    if (!id) return [...defaultCrumbs, { title: 'New', to: '/projects/new' }]

    return [
      ...defaultCrumbs,
      { title: projectName, to: `/projects/${id}` },
      { title: 'Edit', to: `/projects/${id}/edit` },
    ]
  }

  const renderNewLocationSection = () => {
    if (hideForm && locations && locations.length > 0) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <EdifyButton
            noBackground
            onClick={() => navigate(`/projects/${projectId}`)}
            title={`Finish Adding ${locationKey}`}
          ></EdifyButton>
          <EdifyButton
            noBackground
            onClick={() => setHideForm(false)}
            title={`+ Add Another ${locationKey}`}
          ></EdifyButton>
        </Box>
      )
    }
    return (
      <>
        <ETypography font='HMS' color='gray700'>
          {`Add ${locationKey}(s)`}
        </ETypography>
        <Box sx={{ marginBottom: '24px', marginTop: '24px', gap: 24 }}>
          <EdifyButton
            title='Add One'
            secondary={!singleCreate}
            onClick={() => setSingleCreate(true)}
          />
          <EdifyButton
            // disabled
            buttonStyle={{ marginLeft: '12px' }}
            title='CSV Upload'
            secondary={singleCreate}
            onClick={() => setSingleCreate(false)}
          />
          <EdifyButton
            // disabled
            noBackground
            buttonStyle={{ marginLeft: '12px' }}
            title={`Skip Add ${locationKey}`}
            onClick={() => navigate(`/projects/${projectId}`)}
          />
        </Box>
        {singleCreate && projectId ? (
          <LocationForm
            projectId={projectId}
            setLocations={setLocations}
            locations={locations}
            setHideForm={setHideForm}
          />
        ) : (
          <LocationCSVUpload
            projectId={projectId}
            loadLocations={loadLocations}
          />
        )}
      </>
    )
  }

  const renderLocationsSection = () => {
    if (!canCreateLocation) return null
    if (locationsError) {
      return (
        <ErrorContainer>
          <ETypography font='MM' color='gray700'>
            {`Error Loading ${locationKey}`}
          </ETypography>
        </ErrorContainer>
      )
    }
    if (locationsLoading) {
      return (
        <ETypography font='MM' color='gray700'>
          {`Loading ${locationKey}`}
        </ETypography>
      )
    }

    if (!projectId) {
      return (
        <ETypography font='SM' color='gray400' sx={{ marginTop: '24px' }}>
          {`Save ${level0Name} above to add a  ${locationKey}`}
        </ETypography>
      )
    }
    if (locations && locations.length == 0) {
      return renderNewLocationSection()
    }

    return (
      <>
        {locations && locations.length > 0 && (
          <>
            {locations?.map((l) => {
              return (
                <Box key={`location-${l.id}`} sx={{ marginTop: '24px' }}>
                  <LocationForm projectId={projectId!} initialLocation={l} />
                </Box>
              )
            })}
          </>
        )}
        {/* <Divider sx={{ marginTop: '12px', marginBottom: '12px' }} /> */}
        {renderNewLocationSection()}
      </>
    )
  }

  return (
    <PageContainer
      title={id ? `Edit ${projectName}` : `Create New ${level0Name}`}
      breadCrumbs={getBreadCrumbs()}
    >
      <form onSubmit={onSubmit}>
        <InnerPageContainer
          innerPageLoading={projectLoading}
          innerPageError={projectError}
          innerPageReload={getProject}
          sx={{ padding: '24px', marginBottom: '24px' }}
          headerTitle={`${level0Name} Details`}
        >
          {submissionError && (
            <InnerPageContainer
              sx={{ padding: '12px', marginTop: '12px' }}
              innerPageError={submissionError}
              close={() => setSubmissionError(undefined)}
            />
          )}
          <EdifyFormField>
            <EdifyFieldLabel required label={'Name'} />
            <Input
              sx={{ width: '100%' }}
              data-testid='ProjectName'
              disableUnderline
              placeholder={`Enter ${projectName} Name`}
              className={`form-control ${errors?.name ? 'error' : ''}`}
              {...register('name', { required: true })}
            />
            {errors.name && (
              <FormErrorText>This field is required</FormErrorText>
            )}
          </EdifyFormField>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='START DATE' />
              <EdifyDatePicker date={startDate} setDate={startDateChanged} />
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='END DATE' />
              <EdifyDatePicker date={endDate} setDate={endDateChanged} />
            </EdifyFormField>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label={pointOfContactFieldLabel} />
              <EdifySearchDropdown
                pillListWidth={'100%'}
                sx={{ flex: 1 }}
                initialSelectedItems={pointOfContact ? [pointOfContact] : []}
                width={495}
                onSelect={contactSelected}
                nameKey='fullName'
                limit={8}
                globalSearchKey='users'
                globalSearchPayload={{
                  entities: ['users'],
                  properties: ['id'],
                  returns: ['id', 'fullName'],
                }}
                searchFunction={globalSearch}
              />
            </EdifyFormField>
          </Box>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label={`Select ${getHierarchyName(1, true)}`} />
              <EdifySearchDropdown
                pillListWidth={'100%'}
                multiSelect
                width={495}
                defaultItems={allLevels.map((p) => {
                  return { id: p.id, name: p.name }
                })}
                globalSearchKey='levels'
                globalSearchPayload={{
                  entities: ['levels'],
                  properties: ['id'],
                  returns: ['id', 'name'],
                }}
                searchFunction={globalSearch}
                onSelect={levelsSelected}
                sx={{ flex: 1 }}
                initialSelectedItems={levels}
              />
              <Box sx={{ width: '100%' }}></Box>
            </EdifyFormField>
          </Box>
          <EdifyFormField>
            <EdifyFieldLabel
              label={`${getHierarchyName(
                0,
              )} Logo, (optional, recommended size: 160x160 px)`}
            />
            {imageURL ? (
              <EdifyAttachmentViewer
                attachment={imageURL}
                onDelete={deleteImage}
              />
            ) : (
              <EdifyImageUploader
                edifyUI
                singleFile
                uploadUrl='/fileUploads/project'
                handleLoad={imageUploaded}
                dataKey='anyFile'
                // Move back to Array
                // decodeResponse={(object: any) => object.data.originalUrl}
                handleOnAddStart={() => setSubmitting(true)}
              />
            )}
          </EdifyFormField>
          <Grid item xs={12} marginTop={'24px'}>
            <EdifyFieldLabel label={'Notes'} />
            <Input
              disableUnderline
              multiline
              rows={4}
              data-testid='ProjectName'
              value={notes || ''}
              onChange={(e) => setNotes(e.target.value)}
              sx={{
                border: `1px solid ${AppColors.neutral600}`,
                borderRadius: '4px',
                width: '100%',
                padding: '8px 10px',
                fontSize: '14px',
                fontWeight: '500',
                marginTop: '-12px',
              }}
            />
          </Grid>
          <Grid item xs={12} marginTop={'24px'}>
            <EdifyFieldLabel label={'Attachments'} />
            {fileUrls.map((attachment, i: number) => {
              return (
                <>
                  <EdifyAttachmentViewer
                    key={`${attachment}-${i}`}
                    name={attachment}
                    attachment={attachment}
                    onDelete={() => deleteFileAttachment(attachment)}
                  />
                  <Box sx={{ mb: '24px' }} />
                </>
              )
            })}
            <EdifyImageUploader
              edifyUI
              singleFile={true}
              resetFilesOnSuccess
              handleLoad={fileUpload}
              uploadUrl={'/fileUploads/project'}
              dataKey='anyFile'
              acceptedFileTypes={'All'}
              handleOnAddStart={() => setFileSubmitting(true)}
            />
          </Grid>
        </InnerPageContainer>
        {hasCustomFields && !projectLoading && (
          <InnerPageContainer
            sx={{ padding: '24px', marginBottom: '24px' }}
            headerTitle={`${level0Name} Information`}
            innerPageLoading={projectLoading}
            innerPageError={projectCustomFieldsError}
          >
            <Box>
              <Grid container rowSpacing={'0px'} columnSpacing={'24px'}>
                {formValues
                  .filter((fv) => !fv.isHidden)
                  .map((item, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <CustomFieldInput
                        label={item.label}
                        value={item.value}
                        isRequired={item.isRequired}
                        onChange={handleValueChange}
                        inactive={false}
                        fieldKey={item.key}
                        register={register}
                        error={errors[item.key]?.message}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </InnerPageContainer>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <EdifyButton
            noBackground
            title='Cancel'
            onClick={() => navigate('/projects')}
          />
          <Box>
            <Box>
              <EdifyButton
                data-testid={'SubmissionButton'}
                loading={submitting || fileSubmitting}
                disabled={submitting || fileSubmitting}
                primary
                type='submit'
                title={id ? `Update ${level0Name}` : `Save New ${level0Name}`}
              />
            </Box>
          </Box>
        </Box>
      </form>
      {flags.isLocationEnabled && (
        <>
          <Divider sx={{ marginTop: '12px', marginBottom: '12px' }} />
          {renderLocationsSection()}
        </>
      )}
    </PageContainer>
  )
}

export const ProjectsForm: React.FC = () => {
  return (
    <ProjectPageViewModel>
      <_ProjectsForm />
    </ProjectPageViewModel>
  )
}
interface ICustomFieldInputProps {
  label: string
  value: string
  inactive?: boolean
  onChange: (label: string, value: string) => void
  isRequired: boolean
  error?: string
  fieldKey: string
  register: any
}
const CustomFieldInput: React.FC<ICustomFieldInputProps> = ({
  label,
  value,
  inactive = false,
  onChange,
  isRequired,
  error,
  fieldKey,
  register,
}) => {
  // Destructure register to get both onChange and other props
  const { onChange: registerOnChange, ...rest } = register(fieldKey, {
    required: isRequired ? `${label} is required` : false,
  })

  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <EdifyFormField>
        <EdifyFieldLabel label={label} required={isRequired} />
        <Input
          {...rest}
          placeholder={`Enter ${label}`}
          sx={{ width: '100%' }}
          data-testid='ProjectName'
          disableUnderline
          value={value}
          onChange={(e) => {
            registerOnChange(e) // Notify React Hook Form
            onChange(label, e.target.value) // Your custom onChange handler
          }}
          className={`form-control ${error ? 'error' : ''}`}
        />
        {error && <FormErrorText>{error}</FormErrorText>}
      </EdifyFormField>
    </Box>
  )
}
