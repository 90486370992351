import React, { useContext, useEffect, useState } from 'react'
import { Either, Failure } from '../../../core/core'

import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { adminDeleteContacts, getAllContacts } from '../../../domain/domain'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'

import useQuery from '../../../core/hooks/useQuery'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { normalizeContactsForGrid } from '../../components/datagrid/gridData/contacts'
import { IContact, IContactList } from '../../../domain/interfaces/IContact'
import { IContractorList } from '../../../domain/interfaces/IContractor'

//This will need to be dynamic hardcoding for now
interface ContactRowProps {
  id: string
  name: string
  projects: string[] | undefined
  lastLogin: string | undefined
  role: string | undefined
}

export interface ContactsViewModelProps {
  contacts: IContact[] | undefined
  normalizeContactsForDataGrid: () => ContactRowProps[] | []
  error?: string
  clearError?: () => void
  loadMore?: (paginationModel: GridPaginationModel) => void
  sortSubmissions?: () => void
  sort?: string
  skip: number
  totalCount?: number
  reload: () => void
  isLoading: boolean
  isLoadMore: boolean
  isSearching: boolean
  editContact?: IContact
  setEditContact: (contact: IContact | undefined) => void
  open: boolean
  closeDialog: () => void
  openDialog: () => void
  onSubmit: () => void
  deleteContacts: (ids: string[]) => void
  formError: string | undefined
  setFormError: (error: string | undefined) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  clearFilters: (reload: boolean) => void
  queryHelper: Record<string, any>
  fetchContacts: (fetchSkip: number, query: Record<string, any>) => void
  searchContacts:  (key: string) => void
}

const ContactsContext = React.createContext<ContactsViewModelProps | null>(null)

export function useContactsViewModel(): ContactsViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(ContactsContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
  contractorId: string
}
export const DEFAULT_LIMIT = 20

export const ContactsViewModel: React.FC<Props> = ({
  children,
  contractorId,
}) => {
  const queryHelper = useQuery({ skip: 0, limit: DEFAULT_LIMIT })
  const { defaultQuery, reset, getQueryStringFromQuery, setNew, query } =
    queryHelper

  const [isLoading, setIsLoading] = useState({
    initialLoad: false,
    loadMore: false,
    search: false,
  })
  const [skip, setSkip] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [error, setError] = useState<string | undefined>(undefined)
  const [contacts, setContacts] = useState<IContact[] | undefined>(undefined)
  const [open, setOpen] = useState<boolean>(false)
  const [editContact, setEditContact] = useState<IContact | undefined>(
    undefined,
  )
  const [formError, setFormError] = useState<string | undefined>(undefined)

  const clearFilters = async (reload = false) => {
    reset()
    if (reload) {
      fetchContacts(0, { ...defaultQuery, contractorId: contractorId })
    }
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const openDialog = () => {
    setOpen(true)
  }
  const onSubmit = () => {
    // Not sure if this is being used
  }

  const fetchContacts = async (
    fetchSkip: number,
    query: Record<string, any>,
  ) => {
    setIsLoading((oldState) => {
      return { ...oldState, loadMore: true }
    })
    setError(undefined)

    // load submissions or corrective actions
    const newQuery = { ...query, skip: fetchSkip, contractorId:contractorId }
    const res: Either<Failure, IContactList> = await getAllContacts(
      getQueryStringFromQuery(newQuery),
    )
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: false, loadMore: false }
    })
    if (res.isRight()) {
      setTotalCount(res.right?.total_count || 0)
      setContacts(res.right?.data as IContact[])
      setSkip(fetchSkip)
    } else {
      setError(res.left?.message)
    }
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchContacts(fetchSkip, query)
  }

  const getContacts = async () => {
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: true }
    })
    setSkip(0)
    setContacts([])

    fetchContacts(0, query)
  }

  const searchContacts =  (key: string) => {
    fetchContacts(0, { ...query, searchKey: key })
  }

  const normalizeContactsForDataGrid: any = () => {
    return normalizeContactsForGrid(contacts)
  }

  //DELETE CONTRACTOR
  const deleteContacts = async (ids: string[]) => {
    const confirmMessage = `${ids.length} ${
      ids.length == 1 ? 'contact' : 'contacts'
    }`
    showConfirmationDialog({
      title: 'Confirm Delete',
      message: `Are you sure you want to delete ${confirmMessage} from the database? This action cannot be undone.`,
      cancelText: 'Cancel',
      confirmText: 'Delete',
      onCancel: function (): void {
        hideConfirmationDialog()
      },
      onConfirm: async () => {
        const results = await adminDeleteContacts(ids)
        // something went wrong with api call
        if (results.isLeft()) {
          // backend error
          ErrorToast({
            title: `Failed to delete ${confirmMessage} from the database`,
          })
        }
        if (results.right) {
          SuccessToast({
            title: `Successfully deleted ${confirmMessage} from the database`,
          })
          getContacts()
        }
        setEditContact(undefined)
        hideConfirmationDialog()
        closeDialog()
      },
    })
  }

  //DEMO FOR sorting to if query idea works
  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    fetchContacts(0, {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    })
  }

  useEffect(() => {
    setError(undefined)
    getContacts()
  }, [])

  const onClearError = () => {
    //not sure what do here
    setError(undefined)
  }

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        normalizeContactsForDataGrid,
        isLoading: isLoading.initialLoad,
        isLoadMore: isLoading.loadMore,
        isSearching: isLoading.search,
        loadMore,
        skip,
        error,
        clearError: onClearError,
        reload: getContacts,
        totalCount,
        editContact,
        setEditContact,
        open,
        closeDialog,
        openDialog,
        onSubmit,
        deleteContacts,
        formError,
        setFormError,
        sortClickedHandler,
        queryHelper,
        clearFilters,
        fetchContacts,
        searchContacts
      }}
    >
      {children}
    </ContactsContext.Provider>
  )
}
export default ContactsContext
