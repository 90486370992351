type AnyFunction = (...args: any[]) => any
/**
 * Creates a generic debounced version of a given function.
 *
 * @template T - The type of the original function.
 * @param {T} func - The function to debounce.
 * @param {number} delay - The delay in milliseconds for debouncing.
 * @returns {T} - The debounced version of the original function.
 */
function debounce<T extends AnyFunction>(func: T, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>

  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func.apply(this, args), delay)
  } as T
}

export default debounce