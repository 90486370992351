// ED-1385 https://edifyai.atlassian.net/browse/ED-1385
// ED-1384 https://edifyai.atlassian.net/browse/ED-1384
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PageContainer from '../../page/PageContainer'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'
import InnerPageContainer from '../../inner-page-container/InnerPageContainer'
import { Box, Input } from '@mui/material'
import { EdifyFieldLabel, EdifyFormField } from '../../form'
import { EdifyButton } from '../../buttons'
import {
  ErrorToast,
  SuccessToast,
} from '../../../../core/utils/toast-notifications/ToastNotifications'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../form/EdifySearch/EdifySearchDropdown'
import {
  createLevel,
  getLevelById,
  updateLevel,
} from '../../../../domain/domain'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import FormErrorText from '../../form/FormErrorText'
import usePermission from '../../../../core/hooks/usePermission'
import { ERole } from '../../../../domain/interfaces/IRole'
import NotFoundPage from '../../../pages/NotFoundPage'
import { globalSearch } from '../../../../domain/usecases/utils/UtilsUsecasses'

//just need validation on name since it is the only required field
interface ILevelInput {
  name: string
}

export const LevelsForm = () => {
  const { getHierarchyName, hierarchiesLoading, hierarchiesError, allProjects } =
    useOrganizationProvider()
  const { id } = useParams()

  const [projects, setProjects] = useState<ISearchResult[]>([])
  const [pointOfContact, setPointOfContact] = useState<ISearchResult>()
  const [levelLoading, setLevelLoading] = useState<boolean>(false)
  const [levelError, setLevelError] = useState<string | undefined>(undefined)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const [submissionLoading, setSubmissionLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  // Validation
  const resolver: Resolver<ILevelInput> = async (values) => {
    const errors: FieldErrors<ILevelInput> = {}
    if (!values.name) {
      errors.name = {
        type: 'required',
        message: 'Name is required',
      }
    }
    // Add more validations here if needed
    return {
      values,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ILevelInput>({
    resolver,
    mode: 'onChange',
  })

  /**
   * Handles the form submission when the form is valid.
   * will run resolver validations before submitting
   * @param {Object} data - The form data submitted by the user.
   * @returns {void}
   */
  const onSubmit = handleSubmit((data) => {
    // react-hook-form will check for errors here and return
    // only checks name
    const { name } = data
    if (id) handleUpdate(id, name)
    else handleCreate(name)
  })

  const handleCreate = async (name: string) => {
    const projectIds = projects.length ? projects.map((p) => p.id) : null
    const pointOfContactId = pointOfContact?.id ?? null
    setSubmissionLoading(true)
    setSubmissionError(undefined)
    const res = await createLevel({
      name: name,
      projectIds,
      parentId: null, // no level 2's yet
      pointOfContact: pointOfContactId,
      level: 1,
    })
    setSubmissionLoading(false)
    if (res.isLeft()) {
      setSubmissionError(
        res.left?.message ?? 'A unknown error occurred, please try again later',
      )
      ErrorToast({ title: `Error adding ${levelOneName}` })
      return
    }
    if (res.isRight()) {
      SuccessToast({ title: `Successfully Created New ${levelOneName}` })
      navigate(-1)
    }
  }

  const handleUpdate = async (id: string, name: string) => {
    const projectIds = projects.length ? projects.map((p) => p.id) : null
    const pointOfContactId = pointOfContact?.id ?? null
    setSubmissionLoading(true)
    setSubmissionError(undefined)
    // BACKEND NO-LONGER CAUSE ERROR BUT
    // DOES NOT UPDATE PROJECTS
    const res = await updateLevel(id, {
      name: name,
      projectIds: projectIds || [],
      ...(pointOfContactId ? { pointOfContact: pointOfContactId } : {}),
    })
    setSubmissionLoading(false)
    if (res.isLeft()) {
      setSubmissionError(
        res.left?.message ??
          'A unknown error occurred, please try again later.',
      )
      ErrorToast({ title: 'Error saving new changes.' })
      return
    }
    if (res.isRight()) {
      SuccessToast({ title: `Successfully Updated ${levelOneName}.` })
      navigate(-1)
    }
  }

  const projectSelected = (projects: ISearchResult[]) => {
    setProjects(projects)
  }
  const contactSelected = (contacts: ISearchResult[]) => {
    setPointOfContact(contacts[0])
  }

  // Get Info for Level Edit
  const getLevel = async () => {
    setLevelError(undefined)
    setLevelLoading(true)
    const res = await getLevelById(id!)
    setLevelLoading(false)
    if (res.isLeft() || !res.right) {
      setLevelError('Error loading form.')
      return
    }
    // name should present
    reset({ name: res.right.name })

    // pointOfContact can be null
    if (res.right.pointOfContact) {
      setPointOfContact({
        name: res.right.pointOfContact?.fullName,
        id: res.right.pointOfContact?.id,
      })
    }

    // projects can be null
    if (res.right.projects) {
      const projectData = res.right.projects.map((p) => ({
        id: p.id,
        name: p.name,
      }))
      setProjects(projectData)
    }
  }

  useEffect(() => {
    if (id) {
      getLevel()
    }
  }, [])
  const levelOneName = getHierarchyName(1)
  const canEdit = usePermission(ERole.LevelEdit)
  const canCreate = usePermission(ERole.LevelCreate)
  if (id && !canEdit) return <NotFoundPage />
  if (!id && !canCreate) return <NotFoundPage />
  return (
    <PageContainer
      mainPageLoading={hierarchiesLoading}
      mainPageError={hierarchiesError}
      title={id ? `Edit ${levelOneName}` : `Create New ${levelOneName}`}
    >
      <form onSubmit={onSubmit}>
        <InnerPageContainer
          innerPageError={levelError}
          innerPageLoading={levelLoading}
          innerPageReload={getLevel}
          sx={{ padding: '24px' }}
          headerTitle={`${levelOneName} Details`}
        >
          {submissionError && (
            <InnerPageContainer
              sx={{ padding: '12px', marginTop: '12px' }}
              innerPageError={submissionError}
              close={() => setSubmissionError(undefined)}
            />
          )}

          <EdifyFormField>
            <EdifyFieldLabel required label={`${levelOneName.toUpperCase()} NAME`} />
            <Input
              autoFocus
              data-testid='LevelName'
              sx={{ width: '512px' }}
              disableUnderline
              placeholder={`Enter ${levelOneName} Name`}
              {...register('name')}
              className={`form-control ${errors?.name ? 'error' : ''}`}
            />
            {errors?.name && (
              <FormErrorText>{errors.name.message}</FormErrorText>
            )}
          </EdifyFormField>
          <EdifyFormField>
            <EdifyFieldLabel label='POINT OF CONTACT' />

            <EdifySearchDropdown
              data-testid='PointOfContact'
              initialSelectedItems={pointOfContact ? [pointOfContact] : []}
              limit={8}
              // searchFunction={usersSearch}
              width={512}
              onSelect={contactSelected}
              nameKey='fullName'
              globalSearchKey='users'
              globalSearchPayload={{
                entities: ['users'],
                properties: ['id'],
                returns: ['id','fullName'],
              }}
              searchFunction={globalSearch}
            />
          </EdifyFormField>
          <EdifyFormField>
            <EdifyFieldLabel
              label={`SELECT EXISTING ${getHierarchyName(0, true)}`}
            />
            <EdifySearchDropdown
              data-testid='ProjectSearch'
              defaultItems={allProjects.map((p) => {
                return { id: p.id, name: p.name }
              })}
              multiSelect
              pillListWidth={'100%'}
              width={512}
              onSelect={projectSelected}
              initialSelectedItems={projects}
              globalSearchKey='projects'
              globalSearchPayload={{
                entities: ['projects'],
                properties: ['id'],
                returns: ['id','name'],
              }}
              searchFunction={globalSearch}
            />
          </EdifyFormField>
        </InnerPageContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <EdifyButton
            noBackground
            title='Cancel'
            onClick={() => navigate(-1)}
          />
          <Box>
            <EdifyButton
              type='submit'
              disabled={submissionLoading || Object.keys(errors).length > 0}
              data-testid={'SubmissionButton'}
              primary
              title={id ? `Update ${levelOneName}` : `Save New ${levelOneName}`}
            />
          </Box>
        </Box>
      </form>
    </PageContainer>
  )
}
