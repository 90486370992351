import React from 'react'

import { FormsPageViewModelProps } from './FormsPageViewModel'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import FilterContainer from '../../components/page/FilterContainer'
import { Box } from '@mui/material'
import { globalSearch } from '../../../domain/usecases/utils/UtilsUsecasses'
import { EdifySearch } from '../../components/form/EdifySearch/EdifySearch'
// import { EdifyButton } from '../../components/buttons'
// import FilterButtonGroup from '../../components/buttons/filter-button-group/FilterButtonGroup'

interface IFormsFilterProps {
  vm: FormsPageViewModelProps
  buttonTitle?: string
  canCreate?: boolean
}

// const FILTER_OPTIONS = ['All', 'Fav', 'Complete', 'Canceled']

const FormsFilter: React.FC<IFormsFilterProps> = ({
  vm,
  buttonTitle,
  canCreate,
}) => {
  const { query } = vm.queryHelper
  // TODOS: setup down the road.
  // const navigate = useNavigate()
  // const [activeButton, setActiveButton] = useState<string>('All')

  // const handleFilterButtonClicked = (button: string) => {
  //   const newFormsQueryField = { ...query, state: button.toLocaleLowerCase() }
  //   vm.fetchForms(0, newFormsQueryField)
  // }

  const handleSearchSelected = (searchItems: ISearchResult[]) => {
    if (searchItems.length == 0) {
      vm.fetchForms(query.skip, query)
      return
    }
    vm.onFormSearch(searchItems[0])
  }
  const onFormTextSearch = (searchKey: string) => {
    const newQuery = { ...query, searchKey }
    vm.fetchForms(query.skip, newQuery)
  }

  return (
    <>
      <FilterContainer data-testid={'FormsFilter'} sx={{ marginTop: '0px' }}>
        {/* Place holder for future options */}
        <Box></Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          {/* <EdifySearchDropdown
            placeholder='Search Title'
            searchFunction={globalSearch}
            onSelect={handleSearchSelected}
            width={200}
            nameKey='title'
            globalSearchKey='forms'
            globalSearchPayload={{
              entities: ['forms'],
              properties: ['id'],
              returns: ['id', 'title'],
            }}
            
            searchFunction={(searchTerm: string) => formsSearch(searchTerm)}
            nameKey='title'
            onSelect={handleSearchSelected}
            width={200}
          /> */}

          <EdifySearch
            placeholder='Search Name'
            width={'260px'}
            defaultValue={vm.queryHelper.query.searchKey ?? ''}
            onChange={(e: any) => onFormTextSearch(e.target.value)}
            clear={() => {
              onFormTextSearch('')
            }}
          />
        </Box>
      </FilterContainer>
    </>
  )
}

export default FormsFilter
