import React from 'react'
import {
  LevelsPageViewModel,
  LevelsPageViewModelProps,
  useLevelsPageViewModel,
} from './LevelsViewModel'

import PageContainer from '../../components/page/PageContainer'
import LevelsFilter from './LevelsFilter'
import LevelsDataGrid from './LevelsDataGrid'
import {
  OrganizationProviderProps,
  useOrganizationProvider,
} from '../../../providers/OrganizationProvider'
import { AppColors, AppFonts } from '../../Theme'
import { Box, Grid, Typography } from '@mui/material'
import { normalizeLevelsForDataGrid } from '../../components/datagrid/gridData'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import withPermission from '../../../core/hooks/withPermission'

export const ROUTE_LEVELS = '/levels'


export const _Page: React.FC = () => {
  const vm: LevelsPageViewModelProps = useLevelsPageViewModel()
  const {
    hierarchiesError,
    hierarchiesLoading,
    getHierarchyName,
  }: OrganizationProviderProps = useOrganizationProvider()

  const canCreate = usePermission(ERole.LevelCreate)
  const canEdit = usePermission(ERole.LevelEdit, false, 'NoBulkEdit')
  
  const getRows = () => {
    return vm.levels ? normalizeLevelsForDataGrid(vm.levels) : []
  }

  const hierarchy1Name = getHierarchyName(1, true)
  const hierarchy1NameSingular = getHierarchyName(1, false)
  return (
    <PageContainer
      errorObj={vm.errorObj}
      mainPageLoading={hierarchiesLoading || vm.isLoading}
      mainPageError={hierarchiesError || vm.levelsError}
      mainPageReload={vm.getLevels}
      breadCrumbs={[
        { title: 'Organization', to: '' },
        { title: `${hierarchy1Name}`, to: '/levels/1' },
      ]}
    >
      <InnerPageContainer
        sx={{ padding: '24px', marginBottom: 0 }}
        innerPageLoading={vm.divisionStatsLoading}
        innerPageError={vm.divisionStatsError}
        innerPageReload={vm.getStats}
      >
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <InfoBlock
            text={`Total ${hierarchy1Name}`}
            count={vm.divisionStats?.total_divisions ?? 'NA'}
          />
          <InfoBlock
            text={`Active ${hierarchy1Name}`}
            count={vm.divisionStats?.total_active_divisions ?? 'NA'}
          />
          {/*TODO: CHECK IF THIS SHuOLD BE PROJECT COUNT? */}
          <InfoBlock
            text={`Inactive ${hierarchy1Name}`}
            count={vm.divisionStats?.total_inactive_divisions ?? 'NA'}
          />
          <InfoBlock
            text='Total Account Holders'
            count={vm.divisionStats?.total_account_holders ?? 'NA'}
          />
        </Box>
      </InnerPageContainer>
      {/* </Box> */}
      <LevelsFilter canCreate={canCreate} vm={vm} buttonTitle={hierarchy1NameSingular} />
      <LevelsDataGrid
        checkboxSelection={canEdit}
        vm={vm}
        title={hierarchy1Name}
        rows={getRows()}
      />
    </PageContainer>
  )
}

const LevelsPageWithPermission = withPermission(
  _Page,
  ERole.LevelRead,
)

export const LevelsPage: React.FC = () => {
  return (
    <LevelsPageViewModel>
      <LevelsPageWithPermission />
    </LevelsPageViewModel>
  )
}


// don't see this component being used anywhere else so keep it here for now
interface InfoBlockProps {
  text: string
  count: number | string
}
export const InfoBlock: React.FC<InfoBlockProps> = ({ text, count }) => (
  <Grid
    item
    sm={6}
    md={3}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}
  >
    <Typography
      sx={{
        ...AppFonts.textSMedium,
        color: AppColors.gray50,
        marginBottom: '16px',
      }}
    >
      {text}
    </Typography>
    <Typography
      sx={{
        ...AppFonts.headlineSDesktopSemibold,
        color: AppColors.gray800,
      }}
    >
      {count}
    </Typography>
  </Grid>
)
