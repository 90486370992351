import { Box } from '@mui/material'
import React from 'react'
import logo from '../../../../assets/images/Edify_Logo_White.png'
import darkLogo from '../../../../assets/images/Edify_Logo_Black.png'
import { ComponentProps } from '../../ComponentProps'

import { SafetyMojoIcon } from '../SafteyMojoLogo'

interface Props extends ComponentProps {
  width?: number
  height?: number
  isDarkTheme?: boolean
  // need to make more dynamic,
  orgName?: string
  logoUrl?: string
  socTokenAndOrgId?: string
}

export const EdifyLogo: React.FC<Props> = ({
  sx,
  width,
  height,
  isDarkTheme,
}) => {
  return (
    <Box
      data-testid='EdifyLogo'
      component='img'
      sx={{
        width: width ?? 251,
        height: height ?? 41,
        ...sx,
      }}
      src={isDarkTheme ? darkLogo : logo}
    />
  )
}

export const RomeLogo: React.FC<Props> = ({
  sx,
  width,
  height,
  isDarkTheme = true,
  orgName,
  logoUrl,
  socTokenAndOrgId
}) => {
  return (
    <Box
      data-testid='RomeLogo'
      sx={{
        // display: { xs: 'none', sm: 'block'},
        width: width ?? 147,
        height: height ?? 40,
        marginRight: '110px',
        ...sx,
      }}
    >
      <SafetyMojoIcon logoUrl={logoUrl} orgName={orgName} isDarkTheme={isDarkTheme} sx={{ marginLeft: '9px' }} socTokenAndOrgId={socTokenAndOrgId}/>
    </Box>
  )
}
