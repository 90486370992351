import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Input, TextareaAutosize, TextField } from '@mui/material'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import PageContainer from '../../components/page/PageContainer'
import {
  CertificatesPageViewModel,
  useCertificatesPageViewModel,
} from './CertificatesViewModel'
import { ICertificateCreate } from '../../../domain/interfaces/ICertificate'
import { adminGetCertificateById } from '../../../domain/usecases/certificates/CertificatesUsecases'
import FormErrorText from '../../components/form/FormErrorText'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import EdifyDatePicker from '../../components/form/EdifyDatePicker'
import { EdifyButton } from '../../components/buttons'
import {
  UserPageViewModel,
  useUserPageViewModel,
} from '../user/UserPageViewModel'
import { ROUTE_USERS } from '../users/UsersPage'
import EdifyAttachmentViewer from '../../components/form/EdifyImage/EdifyImageViewer'
import EdifyImageUploader from '../../components/form/EdifyImage/EdifyImageUploader'
import { ErrorToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { AppColors } from '../../Theme'
import moment from 'moment'

// For validations
type ICertificateInputs = {
  name: string
  issuer: string
  notes: string
  certificateNumber: string
}

const _CertificatesForm: React.FC<ICertificateFormProps> = ({
  modalCertificateId,
}) => {
  // if id it is edit

  const {
    updateCertificate,
    createCertificate,
    submissionError: vmSubmissionError,
  } = useCertificatesPageViewModel()
  const { user } = useUserPageViewModel()
  const { id } = useParams()
  const certificateId = modalCertificateId ? modalCertificateId : id
  const readOnly = modalCertificateId !== undefined
  const navigate = useNavigate()
  const [issueDate, setIssueDate] = useState<string | null>(null)
  const [expirationDate, setExpirationDate] = useState<string | null>(null)
  const [issueDateError, setIssueDateError] = useState<string | undefined>(
    undefined,
  )
  const [expirationDateError, setExpirationDateError] = useState<
    string | undefined
  >(undefined)
  const [certificateLoading, setCertificateLoading] = useState<boolean>(false)
  const [certificateError, setCertificateError] = useState<string | undefined>(
    undefined,
  )
  const [certificateName, setCertificateName] = useState<string | undefined>(
    undefined,
  )
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const [attachmentURLS, setAttachmentURLS] = useState<string[]>([])

  const issueDateChanged = (date: dayjs.Dayjs | null) => {
    const dateString = date?.format('MM/DD/YYYY') ?? null
    setIssueDateError(undefined)
    setIssueDate(dateString)
  }
  const expirationDateChanged = (date: dayjs.Dayjs | null | undefined) => {
    setExpirationDateError(undefined)
    const dateString = date?.format('MM/DD/YYYY') ?? null
    setExpirationDate(dateString)
  }

  // Validation
  const resolver: Resolver<ICertificateInputs> = async (values) => {
    const errors: FieldErrors<ICertificateInputs> = {}
    if (!values.name) {
      errors.name = {
        type: 'required',
        message: 'Name is required',
      }
    }
    if (!values.issuer) {
      errors.issuer = {
        type: 'required',
        message: 'Issuing organization is required',
      }
    }
    if (!values.certificateNumber) {
      errors.certificateNumber = {
        type: 'required',
        message: 'Certificate number is required',
      }
    }
    if (!issueDate) {
      setIssueDateError('Issue Date is required')
    }

    // Add more validations here if needed

    return {
      values,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICertificateInputs>({
    resolver,
    mode: 'onSubmit',
  })

  /**
   * Handles the form submission when the form is valid.
   * will run resolver validations before submitting
   * @param {Object} data - The form data submitted by the user.
   * @returns {void}
   */
  const onSubmit = handleSubmit((data) => {
    // react-hook-form will check for errors here and return
    // only checks name
    const { name, notes, issuer, certificateNumber } = data

    if (!issueDate) {
      setIssueDateError('Issue Date is required')
      return
    }
    if (expirationDate){
      const date = moment(expirationDate, 'MM/DD/YYYY')
      const today = moment().startOf('day')
      // Compare the dates
      if (date.isBefore(today)) {
        setExpirationDateError('Expiration Date must be later than today')
        return
      }
    }
    if (
      issueDate &&
      expirationDate &&
      new Date(expirationDate) <= new Date(issueDate)
    ) {
      setExpirationDateError('Expiration Date must be later than Issue Date')
      return
    }
    const formData: ICertificateCreate = {
      name,
      expirationDate: expirationDate ?? '',
      issueDate: issueDate ?? '',
      attachments: attachmentURLS,
      certificateNumber: certificateNumber,
      // throws error
      // createdCertTimeZone: moment.tz.guess(),
      notes: notes,
      issuer: issuer,
    }

    if (certificateId) handleUpdate(certificateId, formData)
    else handleCreate(formData)
  })

  const handleUpdate = async (id: string, formData: ICertificateCreate) => {
    // error/loading/redirect/ handle in view model
    await updateCertificate(formData, id)
  }

  const handleCreate = async (formData: ICertificateCreate) => {
    // error/loading/redirect/ handle in view model
    await createCertificate(formData, user!.id)
  }

  // for edit: grab certificate
  const getCertificate = async () => {
    setCertificateError(undefined)
    setCertificateLoading(true)
    const res = await adminGetCertificateById(id!)
    setCertificateLoading(false)
    if (res.isLeft() || !res.right) {
      setCertificateError('Error loading certificate.')
      return
    }

    const {
      name,
      issuer,
      notes,
      certificateNumber,
      issueDate,
      expirationDate,
      attachments,
    } = res.right
    reset({ name, issuer, notes, certificateNumber })

    setCertificateName(name)
    setAttachmentURLS(attachments ?? [])
    setIssueDate(issueDate)
    setExpirationDate(expirationDate)
  }

  // TODO: handle file uploads images and pdfs
  const imageUploaded = (url: string) => {
    if (!url) {
      ErrorToast({ title: 'Error uploading photo.' })
      // For testing just remove this
      url =
        'https://ih1.redbubble.net/image.2469972999.0130/poster,504x498,f8f8f8-pad,600x600,f8f8f8.jpg'
    }
    setSubmitting(false)
    setAttachmentURLS([...attachmentURLS, url])
  }
  const imageError = (error: any) => {
    setSubmitting(false)
    setSubmissionError(error.message ?? 'Error uploading photo.')
  }

  const deleteAttachment = (url: string) => {
    // remove from array
    const newAttachments = attachmentURLS.filter((x) => x !== url)
    setAttachmentURLS(newAttachments)
    setSubmitting(false)
  }
  useEffect(() => {
    // Edit
    if (id) {
      getCertificate()
    }
  }, [id])

  const getBreadCrumbs = () => {
    const defaultCrumbs = [
      { title: 'Organization', to: '' },
      { title: 'Users', to: `${ROUTE_USERS}` },
      { title: user?.fullName ?? '', to: `${ROUTE_USERS}/${user?.id}` },
    ]
    if (!id)
      return [
        ...defaultCrumbs,
        { title: 'New', to: `${ROUTE_USERS}/${user?.id}/certificates/new` },
      ]

    return [
      ...defaultCrumbs,
      {
        title: 'Edit',
        to: `${ROUTE_USERS}/${user?.id}/certificates/${id}/edit`,
      },
    ]
  }

  return (
    <PageContainer
      title={id ? `Edit ${certificateName ?? ''}` : 'Create New Certificate'}
      breadCrumbs={getBreadCrumbs()}
    >
      <form onSubmit={onSubmit}>
        <InnerPageContainer
          innerPageLoading={certificateLoading}
          innerPageError={certificateError}
          innerPageReload={getCertificate}
          sx={{ padding: '24px', marginBottom: '24px' }}
        >
          {vmSubmissionError && (
            <InnerPageContainer
              sx={{ padding: '12px', marginTop: '12px' }}
              innerPageError={vmSubmissionError}
              // close={() => setSubmissionError(undefined)}
            />
          )}
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel required label={'Certificate Name'} />
              <Input
                sx={{ width: '100%' }}
                disableUnderline
                disabled={id ? true : false} // disable for edit
                placeholder={'Ex - OHSA 30-Hour training'}
                className={`form-control ${errors?.name ? 'error' : ''}`}
                {...register('name', { required: true })}
              />
              {errors.name && (
                <FormErrorText>This field is required</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel required label={'Issuing Organization'} />
              <Input
                sx={{ width: '100%' }}
                disableUnderline
                placeholder={'Ex - OHSA'}
                className={`form-control ${errors?.issuer ? 'error' : ''}`}
                {...register('issuer', { required: true })}
              />
              {errors.issuer && (
                <FormErrorText>This field is required</FormErrorText>
              )}
            </EdifyFormField>
          </Box>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel required label='ISSUE DATE' />
              <EdifyDatePicker
                error={issueDateError ? true : false}
                date={issueDate}
                setDate={issueDateChanged}
              />
              {issueDateError && (
                <FormErrorText>{issueDateError}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='EXPIRATION DATE' />
              <EdifyDatePicker
                error={expirationDateError ? true : false}
                date={expirationDate}
                setDate={expirationDateChanged}
              />
              {expirationDateError && (
                <FormErrorText>{expirationDateError}</FormErrorText>
              )}
            </EdifyFormField>
          </Box>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel required label={'Certificate Number'} />
              <Input
                sx={{ width: '100%' }}
                disableUnderline
                placeholder={'Enter ID'}
                className={`form-control ${errors?.name ? 'error' : ''}`}
                {...register('certificateNumber', { required: true })}
              />
              {errors.certificateNumber && (
                <FormErrorText>This field is required</FormErrorText>
              )}
            </EdifyFormField>
            <Box sx={{ flex: 1 }}></Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label={'Notes'} />
              <TextareaAutosize
                {...register('notes', { required: true })}
                data-testid='Description'
                placeholder='Notes about the certificate'
                className={'form-control'}
                minRows={3}
              />
            </EdifyFormField>
          </Box>
          {/* THIS NEEDS TO BE REVIEWED */}
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <EdifyFormField>
              <EdifyFieldLabel label={'CERTIFICATE ATTACHMENTS'} />
              {attachmentURLS.map((attachment, i:number) => {
                // TODO: Check if Image?
                return (
                  <>
                    <EdifyAttachmentViewer
                      key={attachment}
                      name={`File ${i + 1}`}
                      attachment={attachment}
                      onDelete={() => deleteAttachment(attachment)}
                    />
                    <Box sx={{ mb: '24px' }} />
                  </>
                )
                // TODO: Check if other pdf, doc, etc?
              })}
            </EdifyFormField>
          </Box>

          <EdifyImageUploader
            edifyUI
            singleFile={true}
            resetFilesOnSuccess
            handleLoad={imageUploaded}
            uploadUrl={'/fileUploads/TC'}
            acceptedFileTypes={['image/*','application/pdf']}
            dataKey='anyFile'
            // decodeResponse={(object: any) => object.data.downloadableUrl}
            handleOnAddStart={() => setSubmitting(true)}
          />
        </InnerPageContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <EdifyButton
            noBackground
            title='Cancel'
            // onClick={() => navigate(`${ROUTE_USERS}/${user?.id}`)}
            onClick={() => navigate(-1)}
         
          />
          <Box>
            <Box>
              <EdifyButton
                data-testid={'SubmissionButton'}
                loading={submitting}
                disabled={submitting}
                primary
                type='submit'
                title={id ? 'Update Certificate' : 'Add Certificate'}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </PageContainer>
  )
}

interface ICertificateFormProps {
  modalCertificateId?: string
}

export const CertificatesForm: React.FC<ICertificateFormProps> = (props) => {
  return (
    <UserPageViewModel>
      <CertificatesPageViewModel>
        <_CertificatesForm {...props} />
      </CertificatesPageViewModel>
    </UserPageViewModel>
  )
}
