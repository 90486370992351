import React from 'react'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box } from '@mui/material'
import { ETypography } from '../../components/fonts/ETypography'
import { AppColors } from '../../Theme'
import { RightArrowIcon } from '../../../core/components/icons/RightArrowIcon'
import CloseIcon from '@mui/icons-material/Close'
import { showContactSalesDialog } from '../home/ContactSalesDialogue'
import { getCurrentUser } from '../../../domain/domain'
import { Check } from '../../../core/components/icons/Check'
import { set } from 'lodash'

export default function AiFormFeature() {
  const [hide, setHide] = React.useState(false)
  const showContactSales = async () => {
    const user = getCurrentUser()
    showContactSalesDialog({ user })
  }
  if(hide) return <></>
  return (
    <InnerPageContainer sx={{ p: 24, mt: 24 }}>
      <Box sx={{ display: 'flex' }}>
        <Check sx={{ mr: '18px' }} />
        <Box sx={{ flex: 1 }}>
          <ETypography font='LSB' color='gray700'>
            Did you know we can add AI functionality to any form?
          </ETypography>
          <ETypography
            font='MR'
            color='gray300'
            sx={{ mb: '12px', mt: '12px' }}
          >
            We have the ability to make any of your custom forms AI compatible.
            Click the link below to connect with our sales team to discuss how
            we can work together.
          </ETypography>
          <ETypography
            font='SM'
            color='gray700'
            onClick={showContactSales}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            Contact Sales{' '}
            <RightArrowIcon
              sx={{
                color: AppColors.gray400,
                fontSize: '12px',
              }}
            />
          </ETypography>
        </Box>
        <CloseIcon
          onClick={() => setHide(true)}
          sx={{
            color: AppColors.gray300,
            fontSize: '16px',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        />
      </Box>
    </InnerPageContainer>
  )
}
