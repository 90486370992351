import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import { AppColors } from '../../Theme'
import { ETypography } from '../../components/fonts/ETypography'
import FormErrorText from '../../components/form/FormErrorText'
import { GridCloseIcon } from '@mui/x-data-grid'
import { Either, Failure } from '../../../core/core'
import AddIcon from '@mui/icons-material/Add'

type AllowedFileType = 'csv' | 'xlsx'

interface FileType {
  extension: AllowedFileType
  mimeType: string
}
interface ErrorItem {
  column: number
  errorMessage: string
}

interface GroupedError {
  row: number
  errorMessage: string
}


interface FileUploadProps {
  loading: boolean
  handleUpload: (file: File) => void
  error?: string
  setError?: (error: string | undefined) => void
  allowedFileTypes: AllowedFileType[]
  uploadButtonText?: string
  validateFileFunction?: (file: File) => Promise<Either<Failure, any>>
}

const fileTypeMap: Record<AllowedFileType, FileType> = {
  xlsx: {
    extension: 'xlsx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  csv: { extension: 'csv', mimeType: 'text/csv' },
}

export const EnhancedFileUpload: React.FC<FileUploadProps> = ({
  loading,
  handleUpload,
  error,
  setError,
  allowedFileTypes,
  validateFileFunction,
  uploadButtonText,
}) => {
  const [errors, setErrors] = useState<any[] | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [validating, setValidating] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const allowedFileTypeDetails = useMemo(
    () => allowedFileTypes.map((type) => fileTypeMap[type]),
    [allowedFileTypes],
  )

  function groupErrorsByRow(errors: ErrorItem[]): GroupedError[] {
    const groupedErrors: { [key: number]: string[] } = {}
  
    errors.forEach(error => {
      const row = error.column // assuming 'column' actually represents 'row'
      if (!groupedErrors[row]) {
        groupedErrors[row] = []
      }
      groupedErrors[row].push(error.errorMessage)
    })
  
    return Object.entries(groupedErrors).map(([row, messages]) => ({
      row: parseInt(row),
      errorMessage: messages.join(', ')
    }))
  }
  

  const handleFileSelection = useCallback(
    async (selectedFile: File) => {
      const validFileTypes = allowedFileTypeDetails.map((type) => type.mimeType)
      if (!validFileTypes.includes(selectedFile.type)) {
        setError?.(
          `Please upload a valid file type: ${allowedFileTypes
            .join(', ')
            .toUpperCase()}`,
        )
        return
      }

      setFile(selectedFile)
      if (validateFileFunction) {
        setValidating(true)
        setError?.(undefined)

        try {
          const validationResult = await validateFileFunction(selectedFile)
          if (validationResult.left) {
            setError?.(
              validationResult.left.message || 'File validation failed.',
            )
            if (validationResult.left.data) {
              const errors = groupErrorsByRow(validationResult.left.data)
              setErrors(errors)
            }
            setFile(null)
          }
        } catch (err) {
          setError?.('An error occurred during file validation.')
          setFile(null)
        } finally {
          setValidating(false)
        }
      }
    },

    [setError, allowedFileTypeDetails, allowedFileTypes],
  )

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files[0]) {
      handleFileSelection(files[0])
    }
  }

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      const files = event.dataTransfer.files
      if (files && files[0]) {
        handleFileSelection(files[0])
      }
    },
    [handleFileSelection],
  )

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
    },
    [],
  )

  const clearFile = (e: React.MouseEvent) => {
    e.stopPropagation()
    setFile(null)
    setError?.(undefined)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const uploadClicked = () => {
    if (file) {
      handleUpload(file)
    }
  }

  const acceptedFileTypes = allowedFileTypes.map((type) => `.${type}`).join(',')
  useEffect(() => {
    if (error) {
      setFile(null)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }, [error])

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box
        onClick={() => fileInputRef.current?.click()}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          border: `2px dashed ${AppColors.neutral600}`,
          borderRadius: '4px',
          height: '150px',
          width: '100%',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: AppColors.neutral100,
          },
        }}
      >
        <input
          style={{ display: 'none' }}
          ref={fileInputRef}
          type='file'
          accept={acceptedFileTypes}
          onChange={handleFileChange}
        />
        {validating ? (
          <>
            <CircularProgress size={24} />
            <ETypography
              font='LSB'
              color='gray600'
              sx={{ textAlign: 'center', mt: '8px' }}
            >
              Loading....
            </ETypography>
          </>
        ) : file ? (
          <ETypography font='LSB' color='gray600' sx={{ textAlign: 'center' }}>
            {file.name}
            <GridCloseIcon
              onClick={clearFile}
              sx={{
                fontSize: '16px',
                color: AppColors.gray600,
                marginLeft: '10px',
                cursor: 'pointer',
              }}
            />
          </ETypography>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                border: `2px dashed ${AppColors.neutral600}`,
                borderRadius: '100%',
                marginBottom: '12px',
              }}
            >
              <AddIcon sx={{ fontSize: '30px', color: AppColors.neutral600 }} />
            </Box>
            <ETypography
              font='LSB'
              color='gray600'
              sx={{ textAlign: 'center', marginBottom: '6px' }}
            >
              Drop your{' '}
              {allowedFileTypes
                .map((type) => '.' + type.toUpperCase())
                .join(' or ')}{' '}
              here
            </ETypography>
            <ETypography
              font='MM'
              color='primary600'
              sx={{ textAlign: 'center' }}
            >
              Or browse your files
            </ETypography>
          </>
        )}
      </Box>
      {error && (
        <>
          <FormErrorText sx={{ width: 'auto', marginTop: '8px' }}>
            {error}
          </FormErrorText>
          <Box>
            {errors && errors.map((error, index) => (
              <FormErrorText
                key={index}
                sx={{ width: 'auto', marginTop: '8px' }}
                exclamation={false}
              >
                Row {error.row}: {error.errorMessage}.
              </FormErrorText>
            ))}
          </Box>
        </>
      )}
      {file && !validating && (
        <EdifyButton
          buttonStyle={{ marginTop: '12px' }}
          disabled={loading}
          title={uploadButtonText || 'Upload File'}
          onClick={uploadClicked}
          sx={{ marginTop: '16px' }}
        />
      )}
    </Box>
  )
}
