// ED-1386 https://edifyai.atlassian.net/browse/ED-1386
import React, { useState } from 'react'

import { EdifyButton } from '../../components/buttons'
import { Box } from '@mui/material'
import FilterButtonGroup from '../../components/buttons/filter-button-group/FilterButtonGroup'

import { ProjectsPageViewModelProps } from './ProjectsViewModel'
import { ILevelsQueryField } from '../../../domain/domain'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import { useNavigate } from 'react-router-dom'
import { useTableWidth } from '../../../core/hooks'
import { EdifySearch } from '../../components/form/EdifySearch/EdifySearch'

interface IProjectsFilterProps {
  vm: ProjectsPageViewModelProps
  buttonTitle: string
  onButtonClick?: () => void
  canCreate?: boolean
}

const FILTER_OPTIONS = ['All', 'Favorites', 'Active', 'Inactive']

const ProjectsFilter: React.FC<IProjectsFilterProps> = ({
  vm,
  buttonTitle,
  canCreate,
}) => {
  const { query } = vm.queryHelper
  const initButtonValue = query.tab
    ? query.tab.charAt(0).toUpperCase() + query.tab.slice(1)
    : 'All'
  const [activeButton, setActiveButton] = useState<string>(initButtonValue)
  const navigate = useNavigate()
  const width = useTableWidth(true)

  // TODO check project filters are same as levels
  // the
  const handleFilterButtonClicked = (button: string) => {
    const newLevelsQueryField: ILevelsQueryField = { ...query, tab: button }
    vm.fetchProjects(0, newLevelsQueryField)
  }

  const handleSearchSelected = (searchItems: ISearchResult[]) => {
    // cleared search
    if (searchItems.length == 0) {
      vm.fetchProjects(query.skip, query)
      return
    }
    vm.onProjectSearch(searchItems[0])
  }

  const onProjectsTextSearch = (searchKey: string) => {
    const newQuery = { ...query, searchKey }
    vm.fetchProjects(query.skip, newQuery)
  }

  return (
    <>
      <Box
        data-testid={'ProjectsFilter'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '24px',
        }}
      >
        <FilterButtonGroup
          onButtonClicked={handleFilterButtonClicked}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          buttons={[...FILTER_OPTIONS]}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <EdifySearch
            placeholder='Search Name'
            width={width < 900 ? 200 : 260}
            defaultValue={vm.queryHelper.query.searchKey ?? ''}
            onChange={(e: any) => onProjectsTextSearch(e.target.value)}
            clear={() => {
              onProjectsTextSearch('')
            }}
          />
          {canCreate && (
            <EdifyButton
              onClick={() => navigate('/projects/new')}
              buttonStyle={{ marginLeft: '8px' }}
              title={width < 800 ? 'New' : `Create New ${buttonTitle}`}
              primary
            />
          )}
        </Box>
      </Box>
    </>
  )
}

export default ProjectsFilter
