import { Either, Failure } from '../../../core/core'
import {
  documentsGet,
  documentsPost,
  orgDocumentLinksGet,
  putDocument,
  removeDocument,
  removeDocuments,
} from '../../../data/repositories/doucuments/DocumentsRepository'
import { IDocument, IDocumentLink, IDocumentResponse } from '../../interfaces/IDocument'

export async function getDocumentLinks(): Promise<
  Either<Failure, IDocumentLink[]>> {
  return await orgDocumentLinksGet()
}

export async function getDocuments(query:string): Promise<
  Either<Failure, IDocumentResponse>> {
  return await documentsGet(query)
}

export async function createDocuments(files:any[]): Promise<
  Either<Failure, IDocument[]>> {
  return await documentsPost(files)
}

export async function deleteDocument(
  id: string,
): Promise<Either<Failure, IDocument>> {
  return await removeDocument(id)
}

export async function deleteDocuments(
  ids: string[],
): Promise<Either<Failure, IDocument[]>> {
  return await removeDocuments(ids)
}

export async function updatedDocument(
  id: string,
  filename:string
): Promise<Either<Failure, IDocument>> {
  return await putDocument(id,filename)
}
