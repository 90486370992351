export interface IRoles {
  data: IRole[]
  total_count: number
  message: string
}
export interface IRole {
  id: string
  createdAt: string
  updatedAt: string
  deleted: boolean
  orgId: string
  name: string
  description: string
  isSystemRole: boolean

  // permissions ( 0 means 'FALSE' , 1 means 'LIMITED' and 2 means 'FULL' )
  //  A. Submissions
  submissionCreate: number
  submissionRead: number
  submissionEdit: number
  //  B. Corrective Actions
  correctiveActionCreate: number
  correctiveActionRead: number
  correctiveActionEdit: number
  correctiveActionComment: number
  correctiveActionClose: number
  correctiveActionSendBack: number
  correctiveActionChangeState: number
  // C. Projects
  projectCreate: number
  projectRead: number
  projectEdit: number
  // D. Divisions / Levels
  levelCreate: number
  levelRead: number
  levelEdit: number
  // E. Users
  userCreate: number
  userRead: number
  userEdit: number
  // F. Trainings and Certificates
  trainingCertificateCreate: number
  trainingCertificateRead: number
  trainingCertificateEdit: number
  // G. Goals and Controls
  goalCreate: number
  goalRead: number
  goalEdit: number
  // H. Locations
  locationCreate: number
  locationRead: number
  locationEdit: number
  // I. Contractors
  contractorCreate: number
  contractorRead: number
  contractorEdit: number
  // J. Document Library
  documentCreate: number
  documentRead: number
  documentEdit: number
  // K. Forms
  formCreate: number
  formRead: number
  formEdit: number

  // L. Orgs
  orgCreate: number
  orgEdit: number
  orgRead: number
  orgEdifyEdit: number
  // M. Versions
  versionCreate: number
  versionRead: number
  versionEdit: number
  // N. Todo List
  todoListCreate: number
  todoListRead: number
  todoListEdit: number
  // O. Permissions
  permissionCreate: number
  permissionRead: number
  permissionEdit: number
  // P. Roles
  roleCreate: number
  roleRead: number
  roleEdit: number

  // features access // default to 1 means 'TRUE' while 0 means 'FALSE'
  permissionFeatureAccess: number
  roleFeatureAccess: number
  correctiveActionFeatureAccess: number
  projectFeatureAccess: number
  levelFeatureAccess: number
  userFeatureAccess: number
  trainingCertificateFeatureAccess: number
  goalFeatureAccess: number
  locationFeatureAccess: number
  contractorFeatureAccess: number
  documentFeatureAccess: number
  formFeatureAccess: number
  notificationFeatureAccess: number
  profileFeatureAccess: number
  dashboardFeatureAccess: number
  submissionFeatureAccess: number

  usersCount?: number
}

export enum ERole {
  // A. Submissions
  SubmissionCreate = 'submissionCreate',
  SubmissionRead = 'submissionRead',
  SubmissionEdit = 'submissionEdit',

  // B. Corrective Actions
  CorrectiveActionCreate = 'correctiveActionCreate',
  CorrectiveActionRead = 'correctiveActionRead',
  CorrectiveActionEdit = 'correctiveActionEdit',
  CorrectiveActionComment = 'correctiveActionComment',
  CorrectiveActionClose = 'correctiveActionClose',
  CorrectiveActionSendBack = 'correctiveActionSendBack',
  CorrectiveActionChangeState = 'correctiveActionChangeState',

  // C. Projects
  ProjectCreate = 'projectCreate',
  ProjectRead = 'projectRead',
  ProjectEdit = 'projectEdit',

  // D. Divisions / Levels
  LevelCreate = 'levelCreate',
  LevelRead = 'levelRead',
  LevelEdit = 'levelEdit',

  // E. Users
  UserCreate = 'userCreate',
  UserRead = 'userRead',
  UserEdit = 'userEdit',

  // F. Trainings and Certificates
  TrainingCertificateCreate = 'trainingCertificateCreate',
  TrainingCertificateRead = 'trainingCertificateRead',
  TrainingCertificateEdit = 'trainingCertificateEdit',

  // G. Goals and Controls
  GoalCreate = 'goalCreate',
  GoalRead = 'goalRead',
  GoalEdit = 'goalEdit',

  // H. Locations
  LocationCreate = 'locationCreate',
  LocationRead = 'locationRead',
  LocationEdit = 'locationEdit',

  // I. Contractors
  ContractorCreate = 'contractorCreate',
  ContractorRead = 'contractorRead',
  ContractorEdit = 'contractorEdit',

  // J. Document Library
  DocumentCreate = 'documentCreate',
  DocumentRead = 'documentRead',
  DocumentEdit = 'documentEdit',

  // K. Forms
  FormCreate = 'formCreate',
  FormRead = 'formRead',
  FormEdit = 'formEdit',

  // L. Orgs
  OrgCreate = 'orgCreate',
  OrgEdit = 'orgEdit',
  OrgRead = 'orgRead',
  OrgEdifyEdit = 'orgEdifyEdit',

  // M. Versions
  VersionCreate = 'versionCreate',
  VersionRead = 'versionRead',
  VersionEdit = 'versionEdit',

  // N. Todo List
  TodoListCreate = 'todoListCreate',
  TodoListRead = 'todoListRead',
  TodoListEdit = 'todoListEdit',

  // O. Permissions
  PermissionCreate = 'permissionCreate',
  PermissionRead = 'permissionRead',
  PermissionEdit = 'permissionEdit',

  // P. Roles
  RoleCreate = 'roleCreate',
  RoleRead = 'roleRead',
  RoleEdit = 'roleEdit',

  // Feature access
  PermissionFeatureAccess = 'permissionFeatureAccess',
  RoleFeatureAccess = 'roleFeatureAccess',
  CorrectiveActionFeatureAccess = 'correctiveActionFeatureAccess',
  ProjectFeatureAccess = 'projectFeatureAccess',
  LevelFeatureAccess = 'levelFeatureAccess',
  UserFeatureAccess = 'userFeatureAccess',
  TrainingCertificateFeatureAccess = 'trainingCertificateFeatureAccess',
  GoalFeatureAccess = 'goalFeatureAccess',
  LocationFeatureAccess = 'locationFeatureAccess',
  ContractorFeatureAccess = 'contractorFeatureAccess',
  DocumentFeatureAccess = 'documentFeatureAccess',
  FormFeatureAccess = 'formFeatureAccess',
  NotificationFeatureAccess = 'notificationFeatureAccess',
  ProfileFeatureAccess = 'profileFeatureAccess',
  DashboardFeatureAccess = 'dashboardFeatureAccess',
  SubmissionFeatureAccess = 'submissionFeatureAccess',
}

export interface IRoleOld {
  id: string
  name: string
  description: string
  userCreate?: number
  userRead?: number
  userEdit?: number
  formCreate?: number
  formEdit?: number
  formRead?: number
  createdAt: string
  updatedAt: string
  contractorCreate?: number
  goalCreate?: number
  goalEdit?: number
  contractorEdit?: number
  correctiveActionEditOwn?: number
  correctiveActionRead?: number
  levelCreate?: number
  levelEdit?: number
  levelDelete?: number
  levelRead?: number
  submissionCreateAll?: number
  submissionEditAll?: number
  submissionEditHierarchy?: number
  submissionEditOwn?: number
  submissionReadAll?: number
  submissionReadOwn?: number
  projectCreate?: number
  projectEdit?: number
  projectRead?: number
  certificateCreate?: number
  trainingCertificateEditOwn?: number
  trainingCertificateRead?: number
  trainingCertificateEditAll?: number
  trainingCertificateCreate?: number
  trainingCertificateCreateOwn?: number
}

export enum ERoleOld {
  UserCreate = 'userCreate',
  UserRead = 'userRead',
  UserEdit = 'userEdit',
  FormCreate = 'formCreate',
  FormEdit = 'formEdit',
  FormRead = 'formRead',
  GoalCreate = 'goalCreate',
  GoalEdit = 'goalEdit',
  GoalDelete = 'goalDelete',
  ContractorCreate = 'contractorCreate',
  ContractorEdit = 'contractorEdit',
  CorrectiveActionEditOwn = 'correctiveActionEditOwn',
  CorrectiveActionRead = 'correctiveActionRead',
  LevelCreate = 'levelCreate',
  LevelEdit = 'levelEdit',
  LevelDelete = 'levelDelete',
  LevelRead = 'levelRead',
  SubmissionCreateAll = 'submissionCreateAll',
  SubmissionEditAll = 'submissionEditAll',
  SubmissionEditHierarchy = 'submissionEditHierarchy',
  SubmissionEditOwn = 'submissionEditOwn',
  SubmissionReadAll = 'submissionReadAll',
  SubmissionReadOwn = 'submissionReadOwn',
  ProjectCreate = 'projectCreate',
  ProjectEdit = 'projectEdit',
  ProjectRead = 'projectRead',

  TrainingCertificateCreate = 'trainingCertificateCreate',
  TrainingCertificateEdit = 'trainingCertificateEditAll',
  TrainingCertificateEditOwn = 'trainingCertificateEditOwn',
  TrainingCertificateRead = 'trainingCertificateRead',
  TrainingCertificateCreateOwn = 'trainingCertificateCreateOwn',
}

export interface IPermission {
  id: string
  name?: string
  title?: string
  description: string
  featureAccess: number // Changed to number for more flexibility
  permissions: {
    [key: string]: number // Allow any string key with a number value
  }
}

export interface IRoleResponse {
  orgId: string
  name: string
  description: string
  isSystemRole: boolean

  // Permissions
  submissionCreate: number
  submissionRead: number
  submissionEdit: number
  correctiveActionCreate: number
  correctiveActionRead: number
  correctiveActionEdit: number
  correctiveActionComment: number
  correctiveActionClose: number
  correctiveActionSendBack: number
  projectCreate: number
  projectRead: number
  projectEdit: number
  levelCreate: number
  levelRead: number
  levelEdit: number
  userCreate: number
  userRead: number
  userEdit: number
  trainingCertificateCreate: number
  trainingCertificateRead: number
  trainingCertificateEdit: number
  goalCreate: number
  goalRead: number
  goalEdit: number
  locationCreate: number
  locationRead: number
  locationEdit: number
  contractorCreate: number
  contractorRead: number
  contractorEdit: number
  documentCreate: number
  documentRead: number
  documentEdit: number
  formCreate: number
  formRead: number
  formEdit: number
  orgCreate: number
  orgRead: number
  orgEdit: number
  orgEdiyEdit: number // Note: This might be a typo in the original data, consider changing to 'orgEdifyEdit'
  versionCreate: number
  versionRead: number
  versionEdit: number
  todoListCreate: number
  todoListRead: number
  todoListEdit: number
  permissionCreate: number
  permissionRead: number
  permissionEdit: number
  roleCreate: number
  roleRead: number
  roleEdit: number

  // Feature Access
  permissionFeatureAccess: number
  roleFeatureAccess: number
  correctiveActionFeatureAccess: number
  projectFeatureAccess: number
  levelFeatureAccess: number
  userFeatureAccess: number
  trainingCertificateFeatureAccess: number
  goalFeatureAccess: number
  locationFeatureAccess: number
  contractorFeatureAccess: number
  documentFeatureAccess: number
  formFeatureAccess: number
  notificationFeatureAccess: number
  profileFeatureAccess: number
  dashboardFeatureAccess: number
  submissionFeatureAccess: number

  // Metadata
  id: string
  createdAt: string | Date
  updatedAt: string | Date
  deleted: boolean

  // Related data
  features: IPermission[]
  forms: IPermission[]
}
