import React from 'react'
import { Modal, Box } from '@mui/material'
import PdfViewer from './PDFViewer'
import { EdifyButton } from '../../components/buttons'

interface PDFViewerModalProps {
  pdfUrl: string
  pdfId: string
  open: boolean
  onClose: () => void
  skipLoad?: boolean
}

const PDFViewerModal: React.FC<PDFViewerModalProps> = ({
  pdfUrl,
  open,
  onClose,
  pdfId,
  skipLoad = false,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '1500px',
    height: '90%',
    // p: 4,
    // padding: '24px',
    // backgroundColor: 'white',
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='pdf-modal-title'
      aria-describedby='pdf-modal-description'
    >
      <Box sx={style}>
        <PdfViewer skipLoad={skipLoad} pdfUrl={pdfUrl} pdfId={pdfId} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            marginTop: '-60px',
            marginRight: '24px',
          }}
        >
          <EdifyButton onClick={onClose} title='Close' />
        </Box>
      </Box>
    </Modal>
  )
}

export default PDFViewerModal
