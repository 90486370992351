import React from 'react'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import {
  form_columns,
  normalizeFormsForDataGrid,
} from '../../components/datagrid/gridData/forms'
import { useNavigate } from 'react-router-dom'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { ROUTE_SUBMISSIONS } from '../submissions/SubmissionsPage'
import { IForm } from '../../../domain/interfaces/IForm'
import { useFormsPageViewModel } from './FormsPageViewModel'
import { useGlobalProvider } from '../../../providers/GlobalProvider'
import pluralize from 'pluralize'
import { getCurrentUser } from '../../../domain/domain'
// import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
interface IFormsDataGridProps {
  vm: any
  title: string
  checkboxSelection?: boolean
  error?: string
}

const FormsDataGrid: React.FC<IFormsDataGridProps> = ({
  vm,
  title,
  checkboxSelection = true,
  error,
}) => {
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const navigate = useNavigate()
  const { isGlobalUserView, isGlobalUser } = useGlobalProvider()
  const { setForms, isUserInOrg } = useOrganizationProvider()
  const { forms, setPageForms } = useFormsPageViewModel()
  const user = getCurrentUser()

  const handleRowClicked = (params: GridRowParams) => {
    navigate(`/forms${ROUTE_SUBMISSIONS}/${params.id}`, {
      state: { name: params.row.formName },
    })
  }

  // delete form/s super-admin role
  // const removeClicked = async () => {
  //   vm.showDeleteConfirmation(selectionModel as string[])
  //   setSelectionModel([])
  // }

  // will want this toggle for roles
  // const renderHeaderButtons = () => {
  //   // don't render bulk action items if turned off
  //   if (!checkboxSelection) return <></>
  //   const noSelections = selectionModel.length === 0
  //   return (
  //     <BulkDeleteButton noSelections={noSelections} onClick={removeClicked} />
  //   )
  // }
  const updateFormUI = (id: string) => {
    const newForms = forms.map((f: IForm) => {
      if (f.id == id) {
        return { ...f, isFavorited: !f.isFavorited }
      }
      return f
    })
    setForms(newForms)
    setPageForms(newForms)
  }
  const canAccessSettings = isGlobalUserView && isGlobalUser
  return (
    <>
      <EdifyDataGrid
        data-testid='FormsDataGrid'
        noRowsText={`No ${pluralize(title)} found.`}
        title={title}
        columns={form_columns}
        rows={normalizeFormsForDataGrid(
          forms,
          updateFormUI,
          navigate,
          vm.getFavoriteCount,
          isUserInOrg,
          canAccessSettings,
          user
        )}
        error={error || vm.error}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={handleRowClicked}
        loading={vm.isLoading || vm.isLoadMore}
        rowCount={vm.totalCount || 0}
        loadMoreRows={vm.loadMore}
        reload={vm.getForms}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={checkboxSelection}
        // for super Admin role
        // renderHeaderButtons={renderHeaderButtons()}
        // client side attempt
        // paginationMode='client'
        paginationMode='server'
        initSort={[
          {
            field: 'favorite',
            sort: 'desc',
          },
        ]}
      />
    </>
  )
}

export default FormsDataGrid
