import { Typography } from '@mui/material'
import React from 'react'
import { AppColors, AppFonts } from '../../Theme'

interface ILevelHeaderProps {
  text: string
  favorite?: boolean
  inactive?: boolean
}
const LevelHeader: React.FC<ILevelHeaderProps> = ({
  text,
  inactive,
  favorite,
}) => {
  return (
    <Typography
      sx={{
        ...AppFonts.headlineSDesktopSemibold,
        color: inactive
          ? AppColors.gray25
          : favorite
            ? AppColors.p800
            : AppColors.gray950,
      }}
    >
      {text}
    </Typography>
  )
}

export default LevelHeader
