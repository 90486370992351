import React, { useState } from 'react'
import { EdifyButton } from '../../components/buttons'
import { Box, Input } from '@mui/material'
import FilterButtonGroup from '../../components/buttons/filter-button-group/FilterButtonGroup'
import { GoalsPageViewModelProps } from './GoalsPageViewModel'
import { useNavigate } from 'react-router-dom'
import { ISearchResult } from '../../components/form/EdifySearch/EdifySearchDropdown'
import { useTableWidth } from '../../../core/hooks'
import FilterContainer from '../../components/page/FilterContainer'
import { IGoalsQueryField } from '../../../domain/interfaces/IGoal'
import { ROUTE_GOALS } from './GoalsPage'
import { EdifySearch } from '../../components/form/EdifySearch/EdifySearch'

interface IGoalsFilterProps {
  vm: GoalsPageViewModelProps
  buttonTitle: string
  canCreate: boolean
}

const FILTER_OPTIONS = ['All', 'Active', 'Complete', 'Canceled']

const GoalsFilter: React.FC<IGoalsFilterProps> = ({
  vm,
  buttonTitle,
  canCreate,
}) => {
  const navigate = useNavigate()
  const { query } = vm.queryHelper
  const initButtonValue = query.state
    ? query.state.charAt(0).toUpperCase() + query.state.slice(1)
    : 'All'
  const [activeButton, setActiveButton] = useState<string>(initButtonValue)
  const width = useTableWidth(true)

  const handleFilterButtonClicked = (button: string) => {
    const newGoalsQueryField: IGoalsQueryField = {
      ...query,
      state: button.toLocaleLowerCase(),
    }
    vm.fetchGoals(0, newGoalsQueryField)
  }

  const handleSearchSelected = (searchItems: ISearchResult[]) => {
    if (searchItems.length == 0) {
      vm.fetchGoals(query.skip, query)
      return
    }
    vm.onGoalSearch(searchItems[0])
  }

  return (
    <>
      <FilterContainer data-testid={'GoalsFilter'}>
        <FilterButtonGroup
          onButtonClicked={handleFilterButtonClicked}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          buttons={[...FILTER_OPTIONS]}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <EdifySearch
            placeholder='Search Name'
            width={width < 856 ? 170 : 260}
            defaultValue ={vm.queryHelper.query.searchKey ?? ''}
            onChange={(e: any) => vm.onGoalTextSearch(e.target.value)}
            clear={vm.clearSearch}
          />

          {canCreate && (
            <EdifyButton
              onClick={() => navigate(`${ROUTE_GOALS}/new`)}
              buttonStyle={{ marginLeft: '8px' }}
              title={width < 856 ? 'New' : `Create New ${buttonTitle}`}
              primary
            />
          )}
        </Box>
      </FilterContainer>
    </>
  )
}

export default GoalsFilter
