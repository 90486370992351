import React, { useState } from 'react'
import { IUseFormioReturn } from '../../../core/hooks/useFormio'
import { IFormLoader } from '../../../core/hooks/useFormLoader'
import { ISubmissionLoader } from '../../../core/hooks/useSubmission'
import MojoTabForm from '../../components/form/MojoForm/MojoTabForm'
import { Box, CircularProgress } from '@mui/material'
import MojoFormioRenderer1 from './MojoFormRenderer1'
import MojoFormButtons from '../../components/form/MojoForm/MojoFormButtons'
import { EdifyButton } from '../../components/buttons'
import useFormSubmitter, {
  IFormSubmitter,
} from '../../../core/hooks/useFormSubmitter'
import { TrashIcon } from '../../../core/components/icons/TrashIcon'
import { PencilIcon } from '../../../core/components/icons/PenciIIcon'
import { useNavigate } from 'react-router-dom'
import { Reply } from '@mui/icons-material'
import { AppColors } from '../../Theme'
import { DownloadIcon } from '../../../core/components/icons/DownloadIcon'
import { showQRCodeMessage } from '../../components/dialogs/qr-code/QRCodeDialog'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import {
  deleteSubmittedFormById,
  generatePDFZipFile,
  getCurrentUser,
} from '../../../domain/domain'
import { extractAndMergeFilesToPDF } from '../../../core/utils/pdf-converter/PdfConverter'
import { canEditSubmission } from './submissionPermissionHelpers'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { usePublicOrganizationProvider } from '../../../providers/PublicOrganizationProvider'
import { SHEA_HOMES_ID } from '../corrective-action/CorrectiveActionPageViewModel'

interface IMojoFormRender2Props {
  formId: string
  orgId: string
  submissionId?: string
  submissionLoader: ISubmissionLoader
  formLoader: IFormLoader
  fio: IUseFormioReturn
  options: any
  orgI18nLoaded: boolean
  isAnon?: boolean
  readOnly?: boolean
  lastActiveTab?: number
  setLastActiveTab?: React.Dispatch<React.SetStateAction<number>>
  mode: 'VIEW' | 'EDIT' | 'CREATE'
  setActiveFormId?: any
  activeFormId?: string
  originalLanguage?: boolean
}

const MojoNewFormRender: React.FC<IMojoFormRender2Props> = ({
  formId,
  orgId,
  submissionId,
  submissionLoader,
  formLoader,
  fio,
  options,
  orgI18nLoaded,
  lastActiveTab,
  setLastActiveTab,
  isAnon = false,
  readOnly = false,
  mode: initMode,
  setActiveFormId,
  activeFormId,
  originalLanguage,
}) => {
  const { level1Name, level0Name } = usePublicOrganizationProvider()
  const user = getCurrentUser()
  const { form, formReadOnly } = formLoader
  const navigate = useNavigate()
  const isLoading = !orgI18nLoaded || formLoader.formLoading
  const [readOnlyState, setReadOnlyState] = useState(readOnly)
  const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false)
  const [mode, setMode] = useState(initMode)
  const { submission, setSubmission, resetSubmission } = submissionLoader
  const formSubmitter = useFormSubmitter(
    form!,
    formReadOnly!,
    orgId,
    level1Name,
    level0Name,
    submissionLoader.submission,
  )

  const hasLinkForms = () => {
    if (!form) return false
    if (!form.linkForms) return false
    return form.linkForms.length > 0
  }
  const hasAdditionalForms = () => {
    if (submission?.originSubmissionId) return true
    if (hasLinkForms()) return true
    if (!form) return false
    if (!form.additionalForms) return false
    return form.additionalForms.length > 0
  }

  const hasPostSubData =
    submission &&
    submission.postSubmissionData &&
    submission.postSubmissionData.length > 0

  const isDraft = submission?.state == 'draft' ? true : false

  const setModeAndOptions = (mode: any, readState: boolean) => {
    setMode(mode)
    setReadOnlyState(readState)
  }
  const showQRCode = () => {
    const url = `${window.location.origin}
      /anonymous/submissions?orgId=${orgId}&formId=${form!.id}&submissionId=${
      submission!.id}`
    showQRCodeMessage(url)
  }

  const downloadPDF = async () => {
    if (!form || !submission) {
      ErrorToast({ title: 'Error no form/submission' })
      setDownloadingPDF(false)
      return
    }
    setDownloadingPDF(true)
    const ids = formSubmitter.getSubmissionIds()
    const res = await generatePDFZipFile(ids, 'details')
    if (res.isLeft() || !res.right) {
      ErrorToast({ title: 'Error downloading PDF.' })
      setDownloadingPDF(false)
      return
    }
    extractAndMergeFilesToPDF(res.right, form.name)
    setDownloadingPDF(false)
  }

  const canEdit = () => {
    if (!form || !submission || !user) return false
    return canEditSubmission(form, submission?.userId ?? '1', user)
  }

  const handleDelete = async (id: string) => {
    showConfirmationDialog({
      title: 'Confirm Delete',
      message:
        'Are you sure you want to delete the submission? This action cannot be undone.',
      cancelText: 'Cancel',
      confirmText: 'Delete',
      onCancel: function (): void {
        hideConfirmationDialog()
      },
      onConfirm: async () => {
        const res = await deleteSubmittedFormById(id)
        if (res.isLeft()) {
          ErrorToast({ title: 'Error removing submission.' })
          return
        }
        SuccessToast({ title: 'Removing submission success.' })
        navigate(-1)
        hideConfirmationDialog()
      },
    })
  }
  const handleEditClicked = () => {
    //FIX: Issue where some payloads are not get saved to submissionPayload state.
    const initSub = localStorage.getItem('initialSubmission')
    if (initSub) {
      const parsedInitSub = JSON.parse(initSub)
      setSubmission(parsedInitSub)
    }
    setModeAndOptions('EDIT', false)
  }

  const isPostSubForm = (activeFormId = '') => {
    if (!hasPostSubData) return false
    return submission!.postSubmissionData!.some((ps) => {
      return ps.childFormId == activeFormId
    })
  }

  const isParentFormActive = () => {
    // if no additional forms it is a single form
    if (formSubmitter.additionalFormSubmissions.length == 0) return true
    // formId is the Id of the parent form
    return activeFormId == formId
  }

  const renderViewButtons = () => {
    return (
      <MojoFormButtons
        onCancel={() => {
          navigate(-1)
        }}
        cancelText='Back'
      >
        <Box>
          <>
            {form?.isSharingEnabled && (
              <EdifyButton
                hide={orgId == SHEA_HOMES_ID}
                data-testid='ShareSubButton'
                buttonStyle={{ mr: 24 }}
                secondary
                title='Share'
                icon={<Reply sx={{ color: AppColors.gray700 }} />}
                onClick={showQRCode}
              />
            )}

            <EdifyButton
              data-testid='DownloadPDFButton'
              buttonStyle={{ mr: 24 }}
              secondary
              disabled={downloadingPDF}
              icon={
                !downloadingPDF ? (
                  <DownloadIcon />
                ) : (
                  <CircularProgress
                    size={18}
                    sx={{ color: AppColors.gray700 }}
                  />
                )
              }
              title='Download PDF'
              onClick={downloadPDF}
            />

            <EdifyButton
              data-testid='EditSubButton'
              secondary
              hide={!canEdit() || isPostSubForm(activeFormId)}
              // hide={!canEdit() || hasAdditionalForms() || hasPostSubData}
              onClick={() => handleEditClicked()}
              icon={<PencilIcon />}
              title={'Edit'}
            />

            <EdifyButton
              hide={!canEdit() || isAnon}
              data-testid='DeleteSubButton'
              noBackground
              alertInverse
              onClick={() =>
                handleDelete(submissionLoader?.submission?.id ?? 'no id')
              }
              icon={<TrashIcon />}
              title='Remove'
            />
          </>
        </Box>
      </MojoFormButtons>
    )
  }

  // hard reset for connected forms.
  const cancelEditAdditionalForms = () => {
    resetSubmission()
  }
  const renderEditButtons = () => {
    if (isDraft) {
      return renderCreateButtons()
    }
    return (
      <MojoFormButtons
        onCancel={() => {
          if (hasAdditionalForms() || hasPostSubData) {
            cancelEditAdditionalForms()
          }
          clearSubmissionStorage()
          setModeAndOptions('VIEW', true)
        }}
      >
        <Box>
          {!hasAdditionalForms() && (
            <EdifyButton
              data-testid={'SubmissionButton'}
              primary
              disabled={fio.isSubmitting}
              loading={fio.isSubmitting}
              title={`Save ${formLoader?.form?.title}`}
              onClick={() => fio.updateSubmission(() => navigate(-1))}
            />
          )}
          {hasAdditionalForms() && (
            <EdifyButton
              data-testid={'SubmissionButton'}
              primary
              loading={formSubmitter.isSubmitting}
              disabled={formSubmitter.isSubmitting}
              title={`Save ${formLoader?.form?.title}`}
              onClick={() =>
                formSubmitter.handleUpdate(
                  false,
                  tempFixRedirectToSubmissionList,
                )
              }
            />
          )}
        </Box>
      </MojoFormButtons>
    )
  }
  const clearSubmissionStorage = () => {
    localStorage.removeItem('submission')
    const initSub = localStorage.getItem('initialSubmission')
    if (initSub) {
      const parsedInitSub = JSON.parse(initSub)
      setSubmission(parsedInitSub)
    }
  }

  // TEMP FIX.. Could also be a place to reset state
  const tempFixRedirectToSubmissionList = () => {
    // submissionLoader.setSubmission(undefined)
    // navigate(-1)
    // clearSubmissionStorage()
    resetSubmission()
    // still is not clearing the submission with connected forms
    // setTimeout(() => {
    //   navigate(-1)
    // }, 1500)
    window.location.href = `/forms/submissions/${formId}`
  }
  const renderCreateButtons = () => {
    const hasBeenSubmitted =
      submission?.id != 'null-submission-id' || submission?.orgId != ''
    return (
      <MojoFormButtons
        isAnon={isAnon}
        onCancel={
          initMode == 'CREATE'
            ? () => tempFixRedirectToSubmissionList()
            : () => {
              clearSubmissionStorage()
              setModeAndOptions('VIEW', true)
            }
        }
      >
        <Box>
          {!hasAdditionalForms() && (
            <>
              <EdifyButton
                hide={isAnon}
                data-testid={'SubmissionButton'}
                buttonStyle={{ mr: 24 }}
                title={'Save as Draft'}
                secondary
                loading={fio.isSubmitting}
                disabled={fio.isSubmitting}
                onClick={
                  hasBeenSubmitted
                    ? () => fio.updateDraft(() => navigate(-1))
                    : () => fio.saveDraft(() => navigate(-1))
                }
              />
              <EdifyButton
                // hide={hadInitialSubmission}
                hide={isAnon && readOnly}
                data-testid={'SubmissionButton'}
                primary
                disabled={fio.isSubmitting}
                loading={fio.isSubmitting}
                title={'Submit Form'}
                onClick={
                  hasBeenSubmitted
                    ? () => fio.updateSubmission(() => navigate(-1))
                    : () => fio.saveSubmission(() => navigate(-1))
                }
              />
            </>
          )}
          {hasAdditionalForms() && (
            <>
              <EdifyButton
                // hide={hadInitialSubmission}
                data-testid={'SubmissionButton'}
                buttonStyle={{ mr: 24 }}
                disabled={formSubmitter.isSubmitting}
                loading={formSubmitter.isSubmitting}
                title={'Save as Draft'}
                secondary
                onClick={
                  hasBeenSubmitted
                    ? () =>
                      formSubmitter.handleUpdate(
                        true,
                        tempFixRedirectToSubmissionList,
                      )
                    : () =>
                      formSubmitter.handleSubmit(
                        true,
                        tempFixRedirectToSubmissionList,
                      )
                }
              />
              <EdifyButton
                // hide={hadInitialSubmission}
                data-testid={'SubmissionButton'}
                primary
                disabled={formSubmitter.isSubmitting || !isParentFormActive()}
                loading={formSubmitter.isSubmitting}
                title={'Submit All Forms'}
                // onClick={() =>
                //   formSubmitter.handleSubmit(false, () => navigate(-1))
                // }
                onClick={
                  hasBeenSubmitted
                    ? () =>
                      formSubmitter.handleUpdate(
                        false,
                        tempFixRedirectToSubmissionList,
                      )
                    : () =>
                      formSubmitter.handleSubmit(
                        false,
                        tempFixRedirectToSubmissionList,
                      )
                }
              />
            </>
          )}
          <EdifyButton
            hide={!hasBeenSubmitted || isAnon}
            data-testid='DeleteSubButton'
            noBackground
            alertInverse
            onClick={() =>
              handleDelete(submissionLoader?.submission?.id ?? 'no id')
            }
            icon={<TrashIcon />}
            title='Remove'
          />
        </Box>
      </MojoFormButtons>
    )
  }
  const renderForm = () => {
    // loading...
    if (
      (formLoader.formLoading || submissionLoader.submissionLoading,
      formSubmitter.loading)
    )
      return <CircularProgress />
    if (hasAdditionalForms() || hasPostSubData) {
      return (
        <MojoTabForm
          mode={mode}
          key={`MojoTabForm-${mode}`}
          orgId={orgId}
          formId={formId}
          submissionId={submissionId}
          options={{ ...options, readOnly: readOnlyState }}
          noContainer
          readOnly={readOnlyState}
          setLastActiveTab={setLastActiveTab}
          lastActiveTab={lastActiveTab}
          formSubmitter={formSubmitter}
          setActiveFormId={setActiveFormId}
          // TODO REMOVE THIS
          // {...fio}
          {...submissionLoader}
          {...formLoader}
          {...fio}
        />
      )
    }
    return (
      <MojoFormioRenderer1
        mode={mode}
        key={`MojoTabForm-${mode}`}
        orgId={orgId}
        formId={formId}
        submissionId={submissionId}
        options={{ ...options, readOnly: readOnlyState }}
        readOnly={readOnlyState}
        formSubmitter={formSubmitter}
        originalLanguage={originalLanguage}
        {...submissionLoader}
        {...formLoader}
        {...fio}
        // orgI18nLoaded={orgI18nLoaded}
        // onSubmitSuccess={onSubmitSuccess}
      />
    )
  }

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <Box>
      {renderForm()}
      {mode == 'VIEW' &&
        submission &&
        !readOnlyState &&
        isDraft &&
        renderCreateButtons()}
      {mode == 'VIEW' && submission && readOnlyState && renderViewButtons()}
      {mode == 'EDIT' && submission && !readOnlyState && renderEditButtons()}
      {/* {mode == 'CREATE' && !hasPostSubData && isAnon && renderCreateButtons()} */}
      {mode == 'CREATE' && !hasPostSubData && renderCreateButtons()}
    </Box>
  )
}

export default MojoNewFormRender
