import React, { createContext, useContext, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ContractorsForm from '../contractor/ContractorsForm'
import { ContractorPageViewModel } from '../contractor/ContractorPageViewModel'

enum formioEvents {
  CREATE_CONTRACTOR = 'createContractor',
}

interface ModalContextType {
  openEventModal: (eventType: string, onSuccess?: () => void) => void
  closeEventModal: () => void
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const useEventModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}

interface ModalProviderProps {
  children: React.ReactNode
  onSubmit?: (type: string, data: any) => Promise<void>
}

export const ModalProvider: React.FC<ModalProviderProps> = ({
  children,
  onSubmit,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [currentEventType, setCurrentEventType] = useState('')
  const [onSuccessCallback, setOnSuccessCallback] = useState<(() => void) | undefined>() 

  const openEventModal = (eventType: string,onSuccess?: () => void) => {
    setCurrentEventType(eventType)
    setModalOpen(true)
    setOnSuccessCallback(() => onSuccess)
  }

  const closeEventModal = () => {
    setModalOpen(false)
    setCurrentEventType('')
    onSuccessCallback?.()
  }

  // NOT USED YET but could be used down the line
  const handleSubmit = async (data: any) => {
    onSubmit && await onSubmit(currentEventType, data)
    onSuccessCallback?.()
    closeEventModal()
  }
  const onSuccess = async () => {
    onSuccessCallback?.()
    closeEventModal()
  }

  return (
    <ModalContext.Provider value={{ openEventModal, closeEventModal,  }}>
      {children}
      <Dialog
        open={modalOpen}
        onClose={closeEventModal}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>
          {/* Could add support for more event types here */}
          {currentEventType === formioEvents.CREATE_CONTRACTOR && 'Add New Contractor'}
          <IconButton
            onClick={closeEventModal}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {currentEventType === formioEvents.CREATE_CONTRACTOR && (
            <ContractorPageViewModel  onSuccess={closeEventModal}>
              <ContractorsForm/>
            </ContractorPageViewModel>
          )}
        </DialogContent>
      </Dialog>
    </ModalContext.Provider>
  )
}