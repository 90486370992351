import React from 'react'
import { AppColors, AppFonts } from '../../Theme'
import { AccountCircle } from '@mui/icons-material'
import { Avatar, Button, Stack, Typography } from '@mui/material'

interface IProfileTileProps {
  name: string
  role: string
  imageUrl?: string
  isDarkMode?: boolean
  onClick?: () => void
  isGlobalUserView?: boolean
}

export const ProfileTile: React.FC<IProfileTileProps> = (
  props: IProfileTileProps,
) => {
  const isDarkMode = props.isDarkMode ?? true

  const size = 40
  const standardStyling = {
    borderRadius: '8px',
    color: isDarkMode ? AppColors.g000 : AppColors.g500,
  }
  // TODO: THIS CAUSED ERROR ON WHEN I CREATED I CONTRACTOR 
  const getUserIcon = () => {
    if (props.imageUrl) {
      return (
        <Avatar src={props.imageUrl }sx={{ width: size, height: size }}>
        </Avatar>
      )
    }
    if (props.name && props.name[0]) {
      return (
        <Avatar sx={{ width: size, height: size }}>
          {`${props.name[0]}`}
        </Avatar>
      )
    }
    return <AccountCircle sx={{ width: size, height: size }} />
  }
  return (
    <Button
      data-testid='ProfileTile'
      onClick={props.onClick}
 
    >
      <Stack direction={'row'} spacing={16} sx={standardStyling}>
        {getUserIcon()}
        <Stack sx={{ display: { xs:'none', lg: 'block' } }}>
          <Typography
            noWrap
            sx={{
              ...AppFonts.interTextSSemibold,
              color: isDarkMode ? AppColors.neutral200 : AppColors.gray950,
            }}
          >
            {props.name}
          </Typography>
          <Typography
            noWrap
            sx={{
              ...AppFonts.interTextSMedium,
              color: isDarkMode ? AppColors.gray25 : AppColors.gray400,
            
              textAlign: 'left',
            }}
          >
            {props.role}
          </Typography>
        </Stack>
      </Stack>
    </Button>
  )
}
