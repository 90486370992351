import { IUser } from './IUser'


export interface IDivisionStats {
  total_divisions: number,
  total_active_divisions: number,
  total_inactive_divisions: number,
  total_account_holders: number
}

export interface IProject {
  id: string
  name: string
  orgId: string
  createdAt: string
  updatedAt: string
  startDate: string
  endDate: string
  active: boolean
  deleted?: boolean 
  daysRemaining?:string
  pointOfContact?:IUser
  imageURL?: string
  favorited?: boolean 
  levels?:ILevel[]
  notes?: string; // newly added
  attachments?: string[]; // newly added
  [key: string]: string | boolean | IUser | ILevel[] | string[] | undefined;

  // customField1?: string; // newly added
  // customField2?: string; // newly added
  // customField3?: string; // newly added
  // customField4?: string; // newly added
  // customField5?: string; // newly added
  // customField6?: string; // newly added
  // customField7?: string; // newly added
  // customField8?: string; // newly added
  // customField9?: string; // newly added
  // customField10?: string; // newly added
  // customField11?: string; // newly added
  // customField12?: string; // newly added
  // customField13?: string; // newly added
  // customField14?: string; // newly added
  // customField15?: string; // newly added
  // customField16?: string; // newly added
  // customField17?: string; // newly added
  // customField18?: string; // newly added
  // customField19?: string; // newly added
  // customField20?: string; // newly added
}
// DOuble check this when you do EDIT PROJECT TICKET
export interface IProjectFormData {
  id?: string
  name?: string
  endDate?: string | null
  startDate?: string | null
  active?: boolean
  pointOfContact?:string | null
  imageURL?: string | null
  levelOneIds?:string[] | null
  notes?: string; // newly added
  attachments?: string[]; // newly added
  [key: string]: string | boolean | string [] |undefined | null;

  customField1?: string; // newly added
  customField2?: string; // newly added
  customField3?: string; // newly added
  customField4?: string; // newly added
  customField5?: string; // newly added
  customField6?: string; // newly added
  customField7?: string; // newly added
  customField8?: string; // newly added
  customField9?: string; // newly added
  customField10?: string; // newly added
  customField11?: string; // newly added
  customField12?: string; // newly added
  customField13?: string; // newly added
  customField14?: string; // newly added
  customField15?: string; // newly added
  customField16?: string; // newly added
  customField17?: string; // newly added
  customField18?: string; // newly added
  customField19?: string; // newly added
  customField20?: string; // newly added
}

export interface ILevel {
  id: string
  name: string
  level: number
  orgId: string
  parentId?: string
  deleted?: boolean
  createdAt: string
  updatedAt: string
  active?: boolean 
  favorited?: boolean
  projects: IProject[]
  pointOfContact?: IUser
}

export interface ILevelList {
  data: ILevel[]
  message: string
  total_count?: number
}

export interface IProjectList {
  data: IProject[]
  message: string
  total_count: number 
}

export interface IProjectCustomFieldList {
  data: IProjectCustomField[]
  message: string
  total_count: number 
}

export interface IProjectCustomField {
  id: string;
  createdAt: string; // ISO 8601 date string
  updatedAt: string; // ISO 8601 date string
  deleted: boolean;
  orgId: string;
  key: string;
  label: string;
  isRequired: boolean;
  isHidden: boolean;
}

export interface IProjectCustomFieldFormData {
  id?: string
  label: string
  key: string 
  isRequired?: boolean
  isHidden?: boolean
}

export interface IDeletedObject {
  id: string
  deleted: boolean 
}

// just 1 (division) for now, will go up to 5
export enum LevelOptions {
  One = '1',
}