// could move this to own section but it will only be used here

import { Either, Failure } from '../../../core/core'
import { IContactSalesInputs } from '../../../presentation/pages/home/ContactSalesDialogue'
import { httpPost } from '../../data'

export async function contactSales(formData: IContactSalesInputs): Promise<Either<Failure, IContactSalesInputs>> {
  const res: Either<Failure, IContactSalesInputs> = await httpPost(
    '/contactSales', //TODO: check when BE finished
    formData
  )
  return res
}