import React from 'react'
import { Box } from '@mui/material'
import PageContainer from '../../components/page/PageContainer'
import AddFormToOrgForm from './AddFormToOrgForm'
import { ROUTE_FORMS } from '../forms/FormsPage'

export const ROUTE_ORG_FORM_SETTINGS = '/orgFormSettings'

const OrgFormSettingsPage: React.FC = () => {
  return (
    <Box data-testid='OrgFormSettingsPage'>
      <PageContainer
        title='Organization Form Settings'
        breadCrumbs={[
          { title: 'All Forms', to: `${ROUTE_FORMS}/all` },
          { title: 'Settings', to: `${ROUTE_FORMS}/settings` },
        ]}
      >
        <AddFormToOrgForm />
      </PageContainer>
    </Box>
  )
}

export default OrgFormSettingsPage
