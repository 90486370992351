/* eslint-disable quotes */
import React from 'react'
import { Box, Grid, Input, InputAdornment } from '@mui/material'
import { useState } from 'react'
import SubmissionModal from './SubmissionModal'
import { ETypography } from '../../components/fonts/ETypography'
import { EdifyButton } from '../../components/buttons'
import useFormLoader from '../../../core/hooks/useFormLoader'
import { EdifyFieldLabel } from '../../components/form'
import { MagnifyGlassIcon } from '../../../core/components/icons/MagnifyGlassIcon'
import { GridClearIcon } from '@mui/x-data-grid'
import { AppColors, AppFonts } from '../../Theme'
import { AddOutlined, Check } from '@mui/icons-material'
import { ISubmission } from '../../../domain/interfaces/ISubmission'
import { CircularProgressLoading } from '../../components/loading-progress/CircularProgressLoading'
import ErrorContainer from '../../components/error/ErrorContainer'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { getSignStatus } from './ViewSubmission'
import { PrimaryBanner } from '../goal/GoalForm'
import { getCurrentUser } from '../../../domain/domain'
import { canViewSubmission } from './submissionPermissionHelpers'

// can add more template types here as needed
type TemplateType = 'generic' | 'signature'

type TemplateVerbs = {
  submit: string
  submitted: string
  unSubmitted: string
  addSubmission: string
}
function getTemplateType(template: string): TemplateType {
  switch (template) {
    case 'signature':
      return 'signature'
    // add more cases here as needed
    default:
      return 'generic'
  }
}

const templateVerbs: Record<TemplateType, TemplateVerbs> = {
  generic: {
    submit: 'Submit',
    submitted: 'Submitted',
    unSubmitted: 'Unsubmitted',
    addSubmission: 'Add Submission',
  },
  signature: {
    submit: 'Sign',
    submitted: 'Signed',
    unSubmitted: 'Unsigned',
    addSubmission: 'Add Signature',
  },
}

interface Props {
  childFormID?: string
  orgId: string
  isAnon?: boolean
  postSubmissionData: any
  userId?: string | undefined
  parentSubmission: ISubmission
  parentSubmissionReloader: any
}

export interface ISignee {
  name: string
  submissionId: string | undefined
  id: string
  status: string
  userId?: string | undefined
}

const SigneeList: React.FC<Props> = ({
  orgId,
  postSubmissionData,
  isAnon = false,
  userId,
  parentSubmission,
  parentSubmissionReloader,
}) => {
  const childFormId = postSubmissionData.childFormId
  const submitters = postSubmissionData?.submitters ?? []
  const template: TemplateType = getTemplateType(
    postSubmissionData?.template ?? '',
  )
  const { form, loadFormError, formReadOnly } = useFormLoader(childFormId, {
    orgId,
    isAnon: isAnon,
  })

  const sortSigners = (submitters: ISignee[]) => {
    const x = [...submitters]
    x.sort((a, b) => {
      if (a.userId === userId) return -1
      if (b.userId === userId) return 1
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    })
    return x
  }

  const [filteredSubmittersState, setFilteredSubmittersState] = useState<
    ISignee[]
  >(sortSigners(submitters))
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [activeSubId, setActiveSubId] = useState<string | undefined>(undefined)
  const [activeSignee, setActiveSignee] = useState<ISignee | undefined>(
    undefined,
  )
  const [open, setOpen] = useState(false)

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value
    setSearchTerm(term)
    if (term.trim() === '') {
      setFilteredSubmittersState(submitters)
      return
    }
    const filteredSubmitters = submitters.filter((submitter: any) => {
      return submitter.name.toLowerCase().includes(term.toLowerCase())
    })
    setFilteredSubmittersState(filteredSubmitters ?? [])
  }

  const clear = () => {
    setSearchTerm('')
    setFilteredSubmittersState(submitters)
  }

  const handleSigneeClicked = (
    userId: string | undefined,
    submissionId: string | undefined,
  ) => {
    const submitter = postSubmissionData.submitters.find(
      (submitter: ISignee) => submitter.userId === userId,
    )
    setActiveSignee(submitter)
    setOpen(true)

    setActiveSubId(submissionId)
  }
  if (loadFormError) {
    return <ErrorContainer>{loadFormError}</ErrorContainer>
  }
  if (!form) {
    return (
      <Box sx={{ height: '200px', display: 'flex', justifyContent: 'center' }}>
        <CircularProgressLoading />
      </Box>
    )
  }
  const handleClose = (response?: any) => {
    setActiveSubId(undefined)
    setActiveSignee(undefined)
    if (response) {
      parentSubmissionReloader(parentSubmission.id)
    }

    setOpen(false)
  }

  const { canSign, timeRemaining, startDateTime, bannerType } = getSignStatus(
    parentSubmission.data?.scheduler,
  )
  const user = getCurrentUser()

  const role = user?.roles[0]?.name ?? ''

  const getInitMode = (): 'EDIT' | 'VIEW' | 'CREATE' => {
    return 'VIEW'
  }

  return (
    <>
      <InnerPageContainer>
        {!canSign && timeRemaining && (
          <PrimaryBanner
            type={bannerType == 'error' ? 'error' : 'primary'}
            message={timeRemaining}
          />
        )}
        {/* {canSign && timeRemaining && (
          <PrimaryBanner
            type='warn'
            message={`${timeRemaining} left to sign`}
          />
        )} */}
        <Grid
          container
          gap={'12px'}
          sx={{
            marginBottom: '24px',
            marginTop: '24px',
            gap: '12',
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'space-between',
          }}
        >
          <Grid item sm={12} md={9}>
            <Box
              sx={{
                display: 'flex',
                width: '360px',
                alignContent: 'space-between',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <EdifyFieldLabel label='Search for names' />{' '}
              <EdifyButton
                noBackground
                title='refresh list'
                onClick={() => parentSubmissionReloader(parentSubmission.id)}
              />
            </Box>

            <Input
              sx={{
                ...AppFonts.textMRegular,
                color: AppColors.gray700,
                width: '360px',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              autoComplete='off'
              disableUnderline
              className='form-control'
              id='search'
              value={searchTerm}
              onChange={handleSearchChange}
              startAdornment={
                <InputAdornment position='start'>
                  <MagnifyGlassIcon />
                </InputAdornment>
              }
              endAdornment={
                !searchTerm.length ? null : (
                  <InputAdornment
                    position='start'
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <GridClearIcon
                      onClick={clear}
                      sx={{
                        width: '20px',
                        height: '20px',
                        color: AppColors.neutral950,
                      }}
                    />
                  </InputAdornment>
                )
              }
            />
          </Grid>
          <Grid item sm={12} md={3} justifyContent={'flex-end'}>
            <EdifyButton
              disabled={!canSign}
              icon={<AddOutlined sx={{ color: AppColors.baseWhite }} />}
              buttonStyle={{ float: 'left', height: '50px', marginTop: '12px' }}
              title={templateVerbs[template].addSubmission}
              onClick={() => handleSigneeClicked(activeSubId, undefined)}
            />
          </Grid>
        </Grid>
        <Box>
          {filteredSubmittersState.map((submitter) => (
            <ISignee
              canView={canViewSubmission(
                form,
                submitter?.userId ?? '',
                user?.id ?? '',
              )}
              role={role}
              canSign={canSign}
              isAnon={isAnon}
              currentUserId={userId}
              userId={submitter!.userId}
              key={submitter.id}
              handleSigneeClicked={() =>
                handleSigneeClicked(submitter!.userId, submitter.submissionId)
              }
              {...submitter}
              template={template}
            />
          ))}
        </Box>
      </InnerPageContainer>
      {open && (
        <SubmissionModal
          initMode={getInitMode()}
          canSign={canSign}
          open={open}
          handleClose={handleClose}
          orgId={orgId}
          childForm={form}
          subId={activeSubId}
          submitter={activeSignee}
          parentSubId={parentSubmission.id}
          currentUserId={userId}
          isAnon={isAnon}
          formReadOnly={formReadOnly}
        />
      )}
    </>
  )
}

// should be IPostSubmissionData
interface ISigneeProps {
  name: string
  submissionId: string | undefined
  id: string
  currentUserId?: string | undefined
  userId: string | undefined
  handleSigneeClicked: (id: string, subId: string | undefined) => void
  status: string
  isAnon?: boolean
  canSign: boolean
  role: string
  canView: boolean
  template: TemplateType
}

const ISignee: React.FC<ISigneeProps> = ({
  name,
  handleSigneeClicked,
  submissionId,
  id,
  status,
  userId,
  currentUserId = undefined,
  isAnon = false,
  canSign,
  role,
  canView,
  template
}) => {
  const isUser = isAnon
    ? false
    : currentUserId
      ? currentUserId === userId
      : false
  const isSigned = status === 'submitted'

  const handleClick = () => {
    // if (!canView) {
    //   showOneConfirmationDialog({
    //     title: '',
    //     message:
    //       'You are only allowed to view your own submissions for the selected form',
    //     confirmText: 'Continue',
    //     onConfirm: async () => {
    //       hideConfirmationDialog()
    //     },
    //   })
    //   return
    // }
    handleSigneeClicked(id, submissionId)
  }

  const getButtonBackGround = () => {
    if (isSigned) {
      return {
        backgroundColor: AppColors.success600,
        borderColor: AppColors.success600,
        '&:hover': {
          backgroundColor: AppColors.success500,
        },
      }
    }
    if (!isAnon && role == 'USER' && !isUser) {
      return { backgroundColor: AppColors.neutral950 }
    }
    if (!canSign && isUser) {
      return { backgroundColor: AppColors.neutral950 }
    }

    if (isUser) {
      return { backgroundColor: AppColors.primary600 }
    }
    return {}
  }
  const getDisabled = () => {
    if (isSigned) {
      return false
    }
    return (
      isAnon ||
      (!isUser && !isSigned) ||
      (isUser && !canSign) ||
      (!isUser && role == 'USER')
    )
  }
  const getIsSignedText = (): string => {
    if (isSigned) {
      return templateVerbs[template].submitted
    }
    return templateVerbs[template].submit
  }
  const getStatusText = ()=>{
    if(status === 'submitted'){
      return templateVerbs[template].submitted
    }
    return templateVerbs[template].unSubmitted
  }
  const getText = () => {
    if (isUser) {
      return getIsSignedText()
    }
    return getStatusText()
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '12px',
        alignItems: 'center',
      }}
    >
      <ETypography font='MS' color='gray700' sx={{ width: '220px' }}>
        {name} {isUser ? '(You)' : ''}
      </ETypography>
      <EdifyButton
        icon={isSigned ? <Check sx={{ color: AppColors.baseWhite }} /> : <></>}
        disabled={getDisabled()}
        title={getText()}
        onClick={handleClick}
        buttonStyle={getButtonBackGround()}
      />
    </Box>
  )
}

export default SigneeList
