import React from 'react'
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  SxProps,
  Typography,
} from '@mui/material'
import { AppColors, AppFonts, AppHovers } from '../../../Theme'

export interface EdifyButtonProps extends ButtonProps {
  title: string
  primary?: boolean
  alert?: boolean
  primaryInverse?: boolean
  alertInverse?: boolean
  secondary?: boolean
  disabled?: boolean
  icon?: JSX.Element | null
  iconRight?: boolean
  loading?:boolean
  noBackground?: boolean
  buttonStyle?: SxProps
  textStyle?: SxProps
  hide?: boolean
}

/**
 * Enum for button themes.
 * @readonly
 * @enum {string}
 */
enum ButtonTheme {
  PRIMARY = 'primary',
  PRIMARY_INVERSE = 'primaryInverse',
  SECONDARY = 'secondary',
  DISABLED = 'disabled',
  ALERT='alert',
  ALERT_INVERSE = 'alertInverse',
}
const baseStyles = {
  padding: '12px 18px', // getting over written in mercury.css
  borderRadius: '5px',

}

const buttonStyles = {
  primary: {
    backgroundColor: AppColors.primary600,
    border: `1px solid ${AppColors.primary600}`,
    '&:hover': {
      background: AppColors.primary500,
    },
  },
  primaryInverse: {
    backgroundColor: AppColors.baseWhite,
    border: `1px solid ${AppColors.primary600}`,
    '&:hover': {
      background: AppColors.primary25,
    },
  },
  secondary: {
    backgroundColor: AppColors.baseWhite,
    border: `1px solid ${AppColors.neutral700}`,
    '&:hover': {
      background: AppColors.neutral200,
    },
  },
  alert: {
    backgroundColor: AppColors.danger600,
    border: `1px solid ${AppColors.danger600}`,
    '&:hover': {
      background: AppColors.danger500, 
    },
  },
  alertInverse: {
    backgroundColor: AppColors.baseWhite,
    border: `1px solid ${AppColors.danger500}`,
    '&:hover': {
      background: AppColors.danger50, 
    },
  },
  disabled: {
    backgroundColor: AppColors.neutral700,
    border: `1px solid ${AppColors.neutral700}`,
    '&:hover': {
      pointer: 'none',
      background: AppColors.primary25,
    },
  },
  // You can add more styles here
}

/**
 * EdifyButton component.
 * @component
 *
 * @param {object} props - Properties passed to component
 * @param {string} props.title - The title text of the button
 * @param {boolean} [props.primary=false] - If true, button will have primary theme
 * @param {boolean} [props.primaryInverse=false] - If true, button will have primaryInverse theme
 * @param {boolean} [props.secondary=false] - If true, button will have secondary theme
 * @param {boolean} [props.disabled=false] - If true, button will be disabled
 * @param {JSX.Element} props.icon - The icon to be displayed within the button
 * @param {boolean} [props.iconRight=false] - If true, icon will be displayed on the right side
 * @param {boolean} [props.loading=false] - If true, a loading spinner will be displayed
 * @returns {React.ElementType} The EdifyButton component
 */
export const EdifyButton: React.FC<EdifyButtonProps> = ({
  title,
  primary = true,
  alert,
  primaryInverse,
  secondary,
  disabled,
  iconRight,
  icon: Icon,
  loading,
  noBackground,
  alertInverse,
  buttonStyle = {},
  textStyle = {},
  hide = false,
  ...props
}) => {
  const border = noBackground ? {border:'none', backgroundColor:'transparent',  '&:hover': {
    cursor: 'pointer',
    background: 'transparent',
  },} : {}

  const getButtonTextHover = ()=>{
    if(noBackground && primary ) {
      return AppHovers.primaryButtonText
    }
    return {}
  }

  // const theme: ButtonTheme = disabled
  // ? ButtonTheme.DISABLED
  // : secondary
  //   // eslint-disable-next-line indent
  //   ? ButtonTheme.SECONDARY
  //   : primaryInverse
  //     // eslint-disable-next-line indent
  //     ? ButtonTheme.PRIMARY_INVERSE
  //     :alertInverse
  //       ? ButtonTheme.ALERT_INVERSE
  // : ButtonTheme.PRIMARY


  const isSecondaryAndDisabledBackGround = secondary && disabled ? {color:AppColors.gray700, background: noBackground ? AppColors.baseWhite : '' } :{}

  const isSecondaryAndDisabledTextColor = secondary && disabled ? {color:AppColors.gray700} :{}

  // eslint-disable-next-line indent
  const theme: ButtonTheme = disabled
    ? ButtonTheme.DISABLED
    : secondary
      // eslint-disable-next-line indent
      ? ButtonTheme.SECONDARY
      : primaryInverse
        // eslint-disable-next-line indent
        ? ButtonTheme.PRIMARY_INVERSE
        : alert
        // eslint-disable-next-line indent
        ? ButtonTheme.ALERT
          :alertInverse
            ? ButtonTheme.ALERT_INVERSE
            : ButtonTheme.PRIMARY

  const fontColor = disabled
    ? AppColors.baseWhite
    : primaryInverse
      ? AppColors.primary600
      : secondary
        ? AppColors.gray700
        : alertInverse
          ? AppColors.danger500
          : noBackground
            ? AppColors.primary600
            : AppColors.baseWhite
  if(hide) return <></>          
  return (
    <Button
      disabled={disabled}
      {...props}
      sx={{
        ...baseStyles,
        ...buttonStyles[theme],
        ...border,
        ...isSecondaryAndDisabledBackGround,
        // ...flex,
        ...buttonStyle,
        
      }}
    >
      {loading && <CircularProgress
        size={20}
        sx={{
          color: fontColor,
          mr:6
        }}
      />}
      {!iconRight && Icon && <IconBox sx={{mr:6}}>{Icon}</IconBox>}
      <Typography
        sx={{flex:1, textTransform:'capitalize', ...AppFonts.textButtonSemiboldSmall, color: fontColor,...isSecondaryAndDisabledTextColor, '&:hover': {...getButtonTextHover()}, ...textStyle }}
      >
        {title}
      </Typography>
      {iconRight && Icon && <IconBox sx={{ml:6}}>{Icon}</IconBox>}
    </Button>
  )
}

interface IconBoxProps {
  iconRight?: boolean
  children?: JSX.Element
  sx: SxProps
}
const IconBox: React.FC<IconBoxProps> = ({ sx, children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx
      }}
    >
      {children}
    </Box>
  )
}
