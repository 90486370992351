import { Box } from '@mui/material'
import React from 'react'
import logo from '../../../assets/icons/downArrow.svg'

import { ComponentProps } from '../ComponentProps'

interface Props extends ComponentProps {
  isDarkTheme?: boolean
}

export const DownArrow: React.FC<Props> = ({ sx }) => {
  return (
    <Box
      data-testid='TrashIcon'
      component='img'
      sx={{
        width: 24, 
        height: 24, 
        ...sx,
      }}
      src={logo}
    />
  )
}