import React from 'react'
import usePermission from './usePermission'
import { ERole } from '../../domain/interfaces/IRole'
import NotFoundPage from '../../presentation/pages/NotFoundPage'


const withPermission = (WrappedComponent: React.ComponentType<any>, requiredPermission: ERole) => {
  const WithPermissionComponent = (props: any) => {
    const hasPermission = usePermission(requiredPermission)
 
    if (!hasPermission) {
      return <NotFoundPage />
    }

    return <WrappedComponent {...props} />
  }

  // Set the display name for better debugging
  WithPermissionComponent.displayName = `WithPermission(${getDisplayName(WrappedComponent)})`

  return WithPermissionComponent
}

// Helper function to get the display name of a component
function getDisplayName(WrappedComponent: React.ComponentType<any>): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withPermission