import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  UsersPageViewModel,
  UsersPageViewModelProps,
  useUsersPageViewModel,
} from './UsersViewModel'
import { EdifyButton } from '../../components/buttons'
import UsersDataGrid from './UsersDataGrid'
import { useNavigate } from 'react-router-dom'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import UsersFilter from './UsersFilter'
import withPermission from '../../../core/hooks/withPermission'
export const ROUTE_USERS = '/users'


export const _Page: React.FC = () => {
  const vm: UsersPageViewModelProps = useUsersPageViewModel()
  const navigate = useNavigate()
   
  const canCreate = usePermission(ERole.UserCreate, false)
  const canEdit = usePermission(ERole.UserEdit, false, 'NoBulkEdit')

  const handleNewClicked = () => {
    navigate(`${ROUTE_USERS}/new`)
  }
  const renderRightItems = ()=>{
    if(canCreate){
      return (
        <EdifyButton
          title={'Add New User'}
          onClick={handleNewClicked}
        />
      )
    }
  }

  return (
    <PageContainer
      errorObj={vm.errorObj}
      mainPageLoading={vm.isLoading}
      title={'Users'}
      breadCrumbs={[
        { title: 'Organization', to: '' },
        { title: 'Users', to: ROUTE_USERS },
      ]}
      renderRightItems={renderRightItems}
    >
      {/* TODO: Uncomment when BE Implemented */}
      <UsersFilter vm={vm} />
      <UsersDataGrid vm={vm} title={'User'} checkboxSelection={canEdit} />
    </PageContainer>
  )
}

const UsersPageWithPermission = withPermission(
  _Page,
  ERole.UserFeatureAccess,
)

export const UsersPage: React.FC = () => {
  return (
    <UsersPageViewModel>
      <UsersPageWithPermission />
    </UsersPageViewModel>
  )
}
