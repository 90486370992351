import React from 'react'
import { EdifyButton } from '../edifyButton/EdifyButton'
import { ButtonProps } from '@mui/material'
import { AppColors } from '../../../Theme'
import { TrashDisabledIcon } from '../../../../core/components/icons/TrashDisabledIcon'
import { TrashIcon } from '../../../../core/components/icons/TrashIcon'

interface Props extends ButtonProps {
  noSelections: boolean
  onClick: () => void
}

const BulkDeleteButton: React.FC<Props> = ({ noSelections, onClick }) => {
  return (
    <>
      <EdifyButton
        disabled={noSelections}
        textStyle={{
          color: noSelections ? AppColors.neutral700 : AppColors.danger500,
        }}
        alertInverse
        noBackground
        title='Remove'
        onClick={onClick}
        icon={noSelections ? <TrashDisabledIcon /> : <TrashIcon />}
      />
    </>
  )
}
export default BulkDeleteButton
