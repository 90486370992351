import React, { useState } from 'react'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'

import { saveToLocalStorage } from '../../../data/data'
import { SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { EdifyFormField } from '../../components/form'
import ErrorContainer from '../../components/error/ErrorContainer'
import { updateOrg } from '../../../data/repositories/orgs/OrgRepository'
import EdifyToggle from '../../components/form/EdifyToggle/EdifyToggle'
import PageContainer from '../../components/page/PageContainer'
import pluralize from 'pluralize'
import NotFoundPage from '../NotFoundPage'
export const ROUTE_FENCES = '/fences'

export default function ManageFencesPage() {
  const { org, flags, setFlags, getHierarchyName, currentUser } = useOrganizationProvider()


  const [loading, setLoading] = useState(false)
  const levelName = getHierarchyName(1)
  const projectName = getHierarchyName(0)
  const [error, setError] = useState<string | undefined>(undefined)

  const levelFenceFlagToggled = async () => {
    setLoading(true)
    const res = await updateOrg(org!.id, {
      isLevelFenceEnabled: !flags.isLevelFenceEnabled,
    })
    setLoading(false)
    if (res.isRight()) {
      SuccessToast({
        title: `${levelName} fence is now ${
          res.right?.isLevelFenceEnabled ? 'enabled' : 'disabled'
        }.`,
      })
      saveToLocalStorage('org', JSON.stringify(res.right))
      setFlags({
        ...flags,
        isLevelFenceEnabled: res.right?.isLevelFenceEnabled ? true : false,
      })
      return
    }
    if (res.isLeft()) {
      setError(res.left?.message ?? `Unsuccessfully toggled ${levelName} fence`)
    }
  }

  const projectFenceFlagToggled = async () => {
    setLoading(true)
    const res = await updateOrg(org!.id, {
      isProjectFenceEnabled: !flags.isProjectFenceEnabled,
    })
    setLoading(false)
    if (res.isRight()) {
      SuccessToast({
        title: `${projectName} fence is now ${
          res.right?.isProjectFenceEnabled ? 'enabled' : 'disabled'
        }.`,
      })
      saveToLocalStorage('org', JSON.stringify(res.right))
      setFlags({
        ...flags,
        isProjectFenceEnabled: res.right?.isProjectFenceEnabled ? true : false,
      })
      return
    }
    if (res.isLeft()) {
      setError(res.left?.message ?? `Unsuccessfully toggled ${projectName} fence`)
    }
  }
  const role = currentUser?.roles[0]
  console.log(role)
  if(role?.name !== 'SUPER_ADMIN') return <NotFoundPage />

  return (
    <PageContainer
      title={'Manage Fences'}
      subtitle='Control user access to data in your organization.'

      breadCrumbs={[
        { title: 'Settings', to: '' },
        { title: 'Manage Fences', to: ROUTE_FENCES },
      ]}

    >
      <InnerPageContainer
        headerTitle='Available Fences'
      >
        {error && (
          <ErrorContainer sx={{ margin: '24px 0' }}>{error}</ErrorContainer>
        )}

        <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
          <EdifyToggle
            disabled={loading}
            checked={flags.isLevelFenceEnabled}
            text={`${levelName} Fence`}
            subText={`If enabled, restricts users to only see data from their assigned ${pluralize(levelName)}`}
            onChange={levelFenceFlagToggled}
          />
        </EdifyFormField>
        <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
          <EdifyToggle
            disabled={loading}
            checked={flags.isProjectFenceEnabled}
            text={`${projectName} Fence`}
            subText={`If enabled, restricts users to only see data from their assigned ${pluralize(projectName)}`}
            onChange={projectFenceFlagToggled}
          />
        </EdifyFormField>
      </InnerPageContainer>
    </PageContainer>
  )
}
