import React from 'react'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import {
  goal_columns,
  normalizeGoalsForGrid,
} from '../../components/datagrid/gridData/goals'
import { useNavigate } from 'react-router-dom'
import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
import pluralize from 'pluralize'

interface IGoalsDataGridProps {
  vm: any
  title: string
  rows: any
  checkboxSelection?: boolean
  error?: string
}

const GoalsDataGrid: React.FC<IGoalsDataGridProps> = ({
  vm,
  title,
  rows,
  checkboxSelection = true,
  error,
}) => {
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const navigate = useNavigate()

  const handleRowClicked = (params: GridRowParams) => {
    navigate(`/goals/${params.id}`, {
      state: { name: params.row.goalName },
    })
  }

  // delete goal/s
  const removeClicked = async () => {
    vm.showDeleteConfirmation(selectionModel as string[])
    setSelectionModel([])
  }

  // will want this toggle for roles
  const renderHeaderButtons = () => {
    // don't render bulk action items if turned off
    if (!checkboxSelection) return <></>
    const noSelections = selectionModel.length === 0
    return (
      <BulkDeleteButton noSelections={noSelections} onClick={removeClicked} />
    )
  }
  return (
    <>
      <EdifyDataGrid
        data-testid='GoalsDataGrid'
        paginationMode='server'
        noRowsText={`No ${pluralize(title)} found.`}
        title={title}
        renderHeaderButtons={renderHeaderButtons()}
        columns={goal_columns}
        rows={normalizeGoalsForGrid(rows)}
        error={error || vm.error}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={handleRowClicked}
        loading={vm.isLoading || vm.isLoadMore}
        rowCount={vm.totalCount || 0}
        loadMoreRows={vm.loadMore}
        reload={vm.getGoals}
        sortClickedHandler={vm.sortClickedHandler}
        checkboxSelection={checkboxSelection}
      />
    </>
  )
}

export default GoalsDataGrid
