import React, { useContext, useEffect, useState } from 'react'
import globalI18n from '../i18n'
import {
  getLanguages,
  getTranslations,
} from '../domain/usecases/translations/TranslationsUsecases'
import ISO6391 from 'iso-639-1'

// this is really a org specific provider for an org language resources
export interface I18nProviderProps {
  getOrgI18n: () => typeof globalI18n | null
  orgI18nLoaded: boolean
  orgI18nError: string | undefined
  orgI18n: typeof globalI18n
  options: any | undefined
  languages: any | undefined
  fetchAndSetTranslations: () => void
  languagesError: string | undefined
  languagesLoading: boolean
}

const I18nContext = React.createContext<I18nProviderProps | null>(null)

export function useI18nProvider(): I18nProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(I18nContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
  orgId?: string
}

export const I18nProvider: React.FC<Props> = ({ children, orgId }) => {
  const orgI18n = globalI18n.createInstance()
  const [orgI18nLoaded, setOrgI18nLoaded] = useState(false)
  const [orgI18nError, setOrgI18nError] = useState<string | undefined>(
    undefined,
  )
  const [options, setOptions] = useState<any | undefined>(undefined)
  const [languages, setLanguages] = useState<any | undefined>(undefined)
  const [languagesLoading, setLanguagesLoading] = useState<boolean>(true)

  const [languagesError, setLanguagesError] = useState<string | undefined>(
    undefined,
  )

  const loadLanguages = async () => {
    setLanguages(undefined)
    setLanguagesError(undefined)
    setLanguagesLoading(true)
    const langRes = await getLanguages(orgId)
    // show error in UI if failed
    if (langRes.isLeft()) {
      setLanguagesError('Error')
      setLanguagesLoading(false)
      return false
    }
    const languageKeys = Object.keys(langRes.right ? langRes.right : {})
    // do not show languages if there is only one option?
    if (languageKeys.length <= 1) {
      setLanguagesLoading(false)
      return false
    }
    const languageOptions = languageKeys.map((key: string) => {
      return { key: key, value: ISO6391.getNativeName(key) }
    })
    setLanguages(languageOptions)
    setLanguagesLoading(false)
    return true
  }

  async function fetchAndSetTranslations() {
    if (!orgId) {
      setOrgI18nLoaded(true)
      setOrgI18nError('No orgId Provided')
      return
    }
    setOrgI18nError(undefined)
    setOrgI18nLoaded(false)
    const res = await getTranslations(orgId, globalI18n.language)
    if (!res.right) {
      setOrgI18nLoaded(true)
      setOrgI18nError('Error')
      return
    }
    const translationData = {
      [globalI18n.language]: res.right.data,
    }
    orgI18n.init({
      nsSeparator: false,
      lng: globalI18n.language,
      fallbackLng: 'en',
      resources: translationData,
    })
    // FORMIO REACT COMPONENT NEED i18n to be this way
    setOptions({
      language: globalI18n.language,
      i18n: orgI18n,
    })
    setOrgI18nLoaded(true)
  }

  // Not using probably can delete
  const getOrgI18n = () => {
    if (!orgI18nLoaded) return null
    return orgI18n
  }
  const loadLanguagesAndTranslations = async () => {
    if (!orgId || orgId === '') {
      setOrgI18nLoaded(true)
      setOrgI18nError('No orgId Provided')
      return
    }
    // don't need to load translations if there aren't any
    const loadedLanguages = await loadLanguages()
    if (!loadedLanguages) {
      setOrgI18nLoaded(true)
      return
    }
    fetchAndSetTranslations()
  }

  useEffect(() => {
    loadLanguagesAndTranslations()
  }, [])

  // All that is used for know is orgI18nLoaded, and options
  // Ask about UI for error when loading translations
  return (
    <I18nContext.Provider
      value={{
        getOrgI18n,
        orgI18nLoaded,
        orgI18nError,
        orgI18n,
        options,
        languages,
        fetchAndSetTranslations,
        languagesError,
        languagesLoading,
      }}
    >
      {children}
    </I18nContext.Provider>
  )
}

//FORMIO RESOURCE GET
// const url = `https://wcf-form.edifyai.com/romedev/${org}/submission?data.language=${lang}`
// const response = await fetch(url)
// const data = await response.json()

// // Assuming data[0].data.translation contains your translation object
// const translationData = {
//   [lang]: {
//     translation: data[0].data.translation, // Adapt this path based on your actual data structure
//   },
// }

// with backend option
// i18n
//   .use(HttpBackend)
//   .use(initReactI18next)
//   .init({
//     lng: 'en',
//     fallbackLng: 'en',
//     ns: ['common', 'homepage'], // Define namespaces
//     defaultNS: 'common', // Set default namespace
//     backend: {
//       loadPath: (lng: string, ns: string) => {
//         `${AWS_CONFIG.aws_app_translations_path}/${currentOrg}/${lng.json`,

//       }
//       // crossDomain: true
//     }
//   })

// first time logging in ->
// no orgId or language in localStorage
