import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
} from '@mui/material'
import React from 'react'

import pluralize from 'pluralize'
import { AppColors, AppFonts } from '../../Theme'
import { EdifyButton } from '../../components/buttons'
import { EdifyFormField } from '../../components/form'
import { subLocationCreate } from '../../../data/repositories/locations/LocationsRepository'
import { ErrorToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { set } from 'date-fns'
import FormErrorText from '../../components/form/FormErrorText'
// eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars

interface Props {
  open: boolean
  handleClose: () => void
  parentId: string
  level: number
  locationId: string
  subLevelName: string
  parentName: string
  onAddNodes?: (parentId: string, Nodes: any) => void
}

const AddHierarchiesModal: React.FC<Props> = ({
  open,
  parentId,
  level,
  locationId,
  handleClose,
  subLevelName,
  parentName,
  onAddNodes,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [values, setValues] = React.useState<string>('')

  const onSubmit = async () => {
    const names = values.split(',')
    try {
      // Map each item to an axios.get request
      const promises = names.map((name) =>
        subLocationCreate({
          name,
          parentId,
          level,
          locationId,
        }),
      )

      // Await all promises to resolve
      const results = await Promise.all(promises)

      // Process results here (Axios wraps the response in an object, response.data contains the actual data)
      const data = results
        .filter((r) => r.isRight())
        .map((response) => {
          if (response.isLeft()) {
            setError(response.left?.message)
            return
          }
          return {
            ...response.right,
            children: [],
          }
        })
      const errors = results
        .filter((r) => r.isLeft())
        .map((r, i) => i + 1 + ': ' + r.left?.message ?? 'Error')

      setError(errors.join(', '))

      onAddNodes && onAddNodes(parentId, data)
      errors.length == 0 && handleClose()
    } catch (error) {
      setError('Error occurred, please try again')
    }
  }
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='child-modal-title'
        aria-describedby='child-modal-description'
      >
        <Box sx={{ ...styles, width: '600px' }}>
          <form>
            <DialogTitle
              sx={{
                ...AppFonts.headlineSDesktopSemibold,
                color: AppColors.gray700,
                p: 0,
              }}
            >
              Add {pluralize(subLevelName)} to {parentName}
            </DialogTitle>
            <DialogContent sx={{ p: 0, pb: 24 }}>
              <Box sx={{ width: '100%' }}>
                <EdifyFormField>
                  <Input
                    sx={{
                      border: `1px solid ${AppColors.neutral700}`,
                      width: '100%',
                      padding: '8px',
                      borderRadius: '5px',
                    }}
                    multiline
                    rows={8}
                    disableUnderline
                    placeholder={'Add one or more values separated by commas'}
                    // className='form-control'
                    onChange={(e) => setValues(e.target.value)}
                    value={values}
                    // className={`form-control ${errors?.fullName ? 'error' : ''}`}
                  />
                  {error && <FormErrorText>{error}</FormErrorText>}
                </EdifyFormField>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                p: 0,
              }}
            >
              <EdifyButton
                data-testid='CloseDialogButton'
                fullWidth
                title={'Cancel'}
                secondary
                onClick={handleClose}
              />

              <EdifyButton
                onClick={onSubmit}
                disabled={loading || values.trim() == ''}
                loading={loading}
                fullWidth
                title={'Add'}
              />
            </DialogActions>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  )
}
export default AddHierarchiesModal

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '425px',
  height: 'auto',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 24,
}
