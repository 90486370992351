import React from 'react'
import { Box, SxProps } from '@mui/material'

interface FilterContainerProps {
  children: React.ReactNode
  sx?: SxProps
}

const FilterContainer: React.FC<FilterContainerProps> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '24px 0',
        ...sx
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default FilterContainer
