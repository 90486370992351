import React from 'react'
import { Box, SxProps } from '@mui/material'
import { ETypography } from '../../fonts/ETypography'

interface IEdifyReadFormFieldProps {
  label: string
  value: string
  sx?: SxProps
}

export const EdifyReadFormField: React.FC<IEdifyReadFormFieldProps> = ({
  sx,
  label,
  value,
}) => {
  return (
    <Box sx={{ marginTop: '24px', ...sx }}>
      <ETypography font='US' color='gray25' sx={{ marginBottom: '8px' }}>
        {label.toUpperCase()}
      </ETypography>
      <ETypography font='XLS' color='gray700'>
        {value}
      </ETypography>
    </Box>
  )
}
