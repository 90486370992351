// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigProd = {
  apiKey: 'AIzaSyD8dZ_s1JdgISM1ywtofyh90Vyvo_sjTqY',
  authDomain: 'rome-be59e.firebaseapp.com',
  projectId: 'rome-be59e',
  storageBucket: 'rome-be59e.appspot.com',
  messagingSenderId: '662910830329',
  appId: '1:662910830329:web:7b0375544e4f6725ea0428',
  measurementId: 'G-DKRJPBH1HL',
}
export default firebaseConfigProd
