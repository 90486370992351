import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridBadge, GridItem, GridStackItem } from '../gridItems'

import { dateToMMMDYYYY, getDaysLeftNumber } from '../../../../core/core'
import { EdifyButton } from '../../buttons'
import { useNavigate } from 'react-router-dom'
import { ROUTE_USERS } from '../../../pages/users/UsersPage'
import { ICertificate } from '../../../../domain/interfaces/ICertificate'

// TODO: check if this is correct
const getTypeStyle = (date: string) => {
  const diffDays = getDaysLeftNumber(date)
  if (diffDays <= 30 && diffDays >= 0) {
    return 'warn'
  }
  if (diffDays <= 0) {
    return 'danger'
  }
  return 'primary'
}

// TODO: check if this is correct
const getBadge = (date: string) => {
  const diffDays = getDaysLeftNumber(date)
  let message = 'Active'
  if (diffDays <= 30 && diffDays >=1) {
    message = `exp. in ${diffDays} days`
  }

  if (diffDays == 0) {
    message = 'Expires Today'
  }
  if (diffDays < 0) {
    message = 'Expired'
  }
  return message
}

// TODO check fields values with BE values
export const certificate_columns: () => GridColDef[] = () => {
  return [
    {
      field: 'name',
      renderHeader: () => <GridItem text='Name' />,
      flex: 1.5,
      renderCell: ({ row }) => (
        <GridStackItem topText={row.name} bottomText={row.type} />
      ),
    },
    {
      field: 'issueDate',
      renderHeader: () => <GridItem text='Training Date' />,
      flex: 1,
      renderCell: ({ row }) => (
        <GridItem text={row.issueDate ? dateToMMMDYYYY(row.issueDate.split('T')[0]) :''} />
      ),
    },
    {
      field: 'expirationDate',
      flex: 1,
      renderHeader: () => <GridItem text='Expiration Date' />,
      renderCell: ({ row }) => (
        <GridItem
          text={row.expirationDate ? dateToMMMDYYYY(row.expirationDate.split('T')[0]) : ''}
        />
      ),
    },

    {
      field: 'status',
      flex: 1,
      sortable:false,
      renderHeader: () => <GridItem text='Status' />,
      renderCell: ({ row }) =>
        row.expirationDate ? (
          <GridBadge
            type={getTypeStyle(row.expirationDate)}
            text={getBadge(row.expirationDate)}
          />
        ) : (
          <GridItem text='' />
        ),
    },
    {
      field: 'actions',
      flex: 1,
      renderHeader: () => <GridItem text='Actions' />,
      renderCell: ({ row }) =>
        row.type == 'certificate' && row.canEdit ? (
          <LinkButton id={row.id} userId={row.userId} />
        ) : (
          <GridItem text='No Action Required' />
        ),
    },
  ]
}

const LinkButton = (props: any) => {
  const navigate = useNavigate()
  return (
    <EdifyButton
      title={'Update'}
      noBackground
      onClick={(e: any) => {
        e.stopPropagation()
        navigate(`${ROUTE_USERS}/${props.userId}/certificates/${props.id}/edit`)
      }}
    />
  )
}

export const normalizeCertificatesForDataGrid: any = (
  certificates: ICertificate[],
  userId: string,
  canEdit: boolean
) => {
  return certificates.map((certificate: ICertificate) => {
    return {
      id: certificate.id,
      name: certificate.name,
      type: certificate.type.toLocaleLowerCase(),
      issueDate: certificate.issueDate || certificate.trainingDate,
      expirationDate: certificate.expirationDate,
      userId: userId,
      canEdit: canEdit,
    }
  })
}
