import { Either, Failure } from '../../../core/core'
import {
  IDocument,
  IDocumentLink,
  IDocumentResponse,
} from '../../../domain/interfaces/IDocument'

import {
  httpDelete,
  httpDeleteWithPayload,
  httpGet,
  httpPost,
  httpPut,
} from '../../data'
const documentsUrl = '/documents'

export async function orgDocumentLinksGet(): Promise<
  Either<Failure, IDocumentLink[]> > {
  const res: Either<Failure, IDocumentLink[]> = await httpGet(
    'documentFolders',
    true,
  )
  // res.right = [
  //   { id: '1', name: 'doc folder1' },
  //   { id: '2', name: 'doc f2' },
  // ]
  // res.right = []
  return res
}

export async function orgDocumentLinksPost(data:IDocumentLink): Promise<
  Either<Failure, IDocumentLink[]> > {
  const res: Either<Failure, IDocumentLink[]> = await httpPost(
    'documentFolders',
    data,
    {},
    true,
  )
  return res
}


export async function documentsGet(query?:string): Promise<
  Either<Failure, IDocumentResponse> > {
  const url = query ? `${documentsUrl}?${query}` : documentsUrl
  const res: Either<Failure, IDocumentResponse> = await httpGet(
    url,
    false,
  )
  return res
}
export async function documentsGetOne(id?:string): Promise<
  Either<Failure, IDocument> > {
  const url = `${documentsUrl}/${id}` 
  const res: Either<Failure, IDocument> = await httpGet(
    url,
    true,
  )
  return res
}

export async function documentsPost(
  files: any,
): Promise<Either<Failure, IDocument[]>> {
  const formData = new FormData()
  formData.append('documents', files[0])
  const res: Either<Failure, IDocument[]> = await httpPost(documentsUrl, formData)
  return res
}

export async function putDocument(
  id: string,
  filename: string,
): Promise<Either<Failure, IDocument>> {
  const res: Either<Failure, IDocument> = await httpPut(
    `${documentsUrl}/${id}`,
    { filename },
    true,
  )
  return res
}

export async function removeDocument(
  id: string,
): Promise<Either<Failure, IDocument>> {
  const res: Either<Failure, IDocument> = await httpDelete(
    `${documentsUrl}/${id}`,
    true,
  )
  return res
}

export async function removeDocuments(
  ids: string[],
): Promise<Either<Failure, IDocument[]>> {
  const res: Either<Failure, IDocument[]> = await httpDeleteWithPayload(
    `${documentsUrl}/bulk`,
    {
      documentIds: ids,
    },
    true,
  )
  return res
}
