import { Avatar, Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { AppColors, AppFonts } from '../../Theme'
import { EdifyButton } from '../buttons'
import { PersonDefaultIcon } from '../../../core/components/icons/PersonDefaultIcon'
import { IVerifyOTPPayload } from '../../../domain/interfaces/IUserDetails'
import { IUser } from '../../../domain/interfaces/IUser'
import EdifyImageUploader from '../form/EdifyImage/EdifyImageUploader'
import { ETypography } from '../fonts/ETypography'
import { getCurrentUser } from '../../../domain/domain'
import { IPerson } from '../../../domain/interfaces/IPerson'
import { IContractor } from '../../../domain/interfaces/IContractor'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { formatSnakeCase } from '../../../core/utils/text-formaters/TextFormatters'
import { IContact } from '../../../domain/interfaces/IContact'
import { ca } from 'date-fns/locale'
import { getToken } from '../../../data/data'

interface ProfileHeaderProps {
  imageURL?: string
  deleteProfile?: () => void
  setIsEdit?: (b: boolean) => void
  handleClick?: any
  avatarInitial?: string
  profile?:
    | IVerifyOTPPayload
    | IUser
    | IPerson
    | IContractor
    | IContact
    | undefined
  loadedProfile?: boolean
  imageUploaded?: (url: string | null) => void
  fullName?: string
  role?: string
  canEdit: boolean
  isEdit?: boolean
  canDelete?:boolean
}
export const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  imageURL,
  deleteProfile,
  setIsEdit,
  imageUploaded,
  loadedProfile,
  fullName,
  role,
  canEdit,
  handleClick,
  isEdit,
  canDelete
}) => {
  const [currentImageUrl, setCurrentImageUrl] = useState<
    string | undefined | null
  >(imageURL)
  const token = getToken()
  function getAvatar() {
    if (currentImageUrl) {
      return { src: `${currentImageUrl}&token=${token}` }
    }
    if (fullName) {
      return {
        children: (
          <Typography
            sx={{
              ...AppFonts.interHeadlineMSemibold,
              fontSize: '50px',
              color: AppColors.primary600,
            }}
          >
            {fullName[0].toUpperCase()}
          </Typography>
        ),
      }
    }

    return { children: <PersonDefaultIcon /> }
  }


  const editClick = () => {
    setIsEdit && setIsEdit(true)
    handleClick && handleClick()
  }

  const handleImageUpload = (url: string) => {
    setCurrentImageUrl(url)
    imageUploaded && imageUploaded(url)
  }

  const deleteImageUpload = () => {
    setCurrentImageUrl(null)
    imageUploaded && imageUploaded(null)
  }

  return (
    <>
      <Box sx={{ height: '224px', width: '100%' }}>
        <Box
          // I THINK THIS COLOR MIGHT BE RANDOM OR AN IMAGE SO JUST COPIED FROM FIGMA FOR NOW
          sx={{
            height: '140px',
            // backgroundColor: '#A5ACBA',
            background: 'linear-gradient(to right, #2B32B2, #1488CC)',
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
            position: 'relative',
          }}
        >
          <Avatar
            sx={{
              // #B1DDFF =>1F89DB
              border: '2px solid #B1DDFF',
              width: '148px',
              height: '148px',
              position: 'absolute',
              top: 68,
              left: 32,
              backgroundColor: AppColors.neutral500,
            }}
            {...getAvatar()}
          />
        </Box>
        {/* <p>{currentImageUrl}</p> */}

        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '0 24px',
            background: 'white',
          }}
        >
          {!isEdit &&  canEdit &&(
            <>
              <EdifyButton
                primary
                noBackground
                title='Edit'
                onClick={editClick}
              />
              <EdifyButton
                hide={!canDelete}
                primary
                noBackground
                title='Delete'
                onClick={deleteProfile}
              />
            </>
          )}

          {/* USER/PERSON UPLOAD UI WAITING FOR FULL BE SUPPORT */}
          {loadedProfile && imageUploaded && currentImageUrl && (
            
            <EdifyButton
              primary
              noBackground
              onClick={deleteImageUpload}
              title='Delete Image'
              sx={{ padding: '18px !important' }}
            />
          )}
          {loadedProfile && imageUploaded && !currentImageUrl && (
            <EdifyImageUploader
              noUI
              label={currentImageUrl ? 'Update Photo' : 'Add Photo'}
              handleLoad={handleImageUpload}
              uploadUrl='/fileUploads/logo'
              dataKey='anyFile'
            />
          )}
        </Box>
      </Box>

      {!isEdit && (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'flexStart',
            width: '100%',
            padding: '0px 24px',
            flexDirection: 'column',
          }}
        >
          <ETypography font='HMS' color='gray700'>
            {fullName ?? 'NA'}
          </ETypography>
          {role && (
            <ETypography font='XLR' color='gray700'>
              {formatSnakeCase(role)}
            </ETypography>
          )}
        </Box>
      )}
    </>
  )
}
