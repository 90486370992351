import React, { useMemo } from 'react'
import useFormio from '../../../core/hooks/useFormio'
import useFormLoader from '../../../core/hooks/useFormLoader'
import useSubmissionLoader from '../../../core/hooks/useSubmission'
import { IForm } from '../../../domain/interfaces/IForm'
import { ISubmission } from '../../../domain/interfaces/ISubmission'
import MojoFormioRenderer from './MojoFormioRenderer'
import { CircularProgress } from '@mui/material'
// import { usePublicOrganizationProvider } from '../../../providers/PublicOrganizationProvider'

interface IMojoFormRenderProps {
  formId: string
  orgId: string
  form?: IForm
  submissionId?: string
  submission?: ISubmission
  isAnon?: boolean
  isDraft?: boolean
  noContainer?: boolean
  buttonGroup?: boolean
  readOnly?: boolean
  setDataCallback?: any
  errors?: string[] | undefined
  formReadOnly?: IForm | undefined
}

export const MojoFormRender: React.FC<IMojoFormRenderProps> = ({
  formId,
  orgId,
  form,
  submission,
  submissionId,
  isAnon = false,
  isDraft = false,
  noContainer,
  buttonGroup,
  readOnly = false,
  setDataCallback,
  errors,
  formReadOnly
}) => {
  const memoizedComponent = useMemo(() => {
    if (!form) {
      return (
        <MojoFormLoader
          formId={formId}
          orgId={orgId}
          submissionId={submissionId}
          isAnon={isAnon}
          isDraft={isDraft}
          readOnly={readOnly}
        />
      )
    }
    if (!submission) {
      return (
        <MojoSubmissionLoader
          form={form}
          formId={formId}
          orgId={orgId}
          submissionId={submissionId}
          isAnon={isAnon}
          isDraft={isDraft}
        />
      )
    }
    return (
      <MojoFormioRenderer
        setDataCallback={setDataCallback}
        form={form!}
        formReadOnly={formReadOnly}
        submission={submission!}
        submissionId={submissionId}
        formId={formId}
        orgId={orgId}
        isAnon={isAnon}
        isDraft={isDraft}
        submissionFormatted={true}
        noContainer={noContainer}
        buttonGroup={buttonGroup}
        readOnly={readOnly}
        errors={errors}
      />
    )
  }, [form, submission, submissionId])
  return memoizedComponent
}

interface IMojoFormLoaderProps {
  formId: string
  orgId: string
  submissionId?: string
  isAnon?: boolean
  isDraft?: boolean
  readOnly?: boolean
}

const MojoFormLoader: React.FC<IMojoFormLoaderProps> = ({
  formId,
  orgId,
  submissionId,
  isAnon = false,
  isDraft = false,
  readOnly = false,
}) => {

  const { form, loadFormError, formLoading } = useFormLoader(formId, {
    orgId,
    isAnon: true,
  })

  if (formLoading) {
    return <CircularProgress />
  }
  if (loadFormError) {
    return <p>Error Loading Form</p>
  }
  if (!form) {
    return <p>No form</p>
  }
  return (
    <MojoSubmissionLoader
      form={form}
      formId={formId}
      orgId={orgId}
      isAnon={isAnon}
      submissionId={submissionId}
      isDraft={isDraft}
      readOnly={readOnly}
    />
  )
}

interface IMojoSubmissionLoaderProps {
  formId: string
  orgId: string
  form: IForm
  submissionId?: string
  submission?: ISubmission
  isAnon?: boolean
  isDraft?: boolean
  readOnly?: boolean
  errors?: string[] | undefined
}

const MojoSubmissionLoader: React.FC<IMojoSubmissionLoaderProps> = ({
  formId,
  orgId,
  form,
  submissionId,
  isAnon = false,
  isDraft = false,
  readOnly = false,
  errors
}) => {
  const {
    submission,
    submissionFormatted,
    submissionLoading,
    loadSubmissionError,
  } = useSubmissionLoader(submissionId)

  const memoizedComponent = useMemo(() => {
    if (loadSubmissionError) {
      return <p>Error Loading Submission</p>
    }
    if (submissionLoading || !submissionFormatted) {
      return <CircularProgress />
    }
    if (!submission) {
      return <p>No submission</p>
    }
    return (
      <MojoFormioRenderer
        submissionFormatted={submissionFormatted}
        form={form}
        submission={submission}
        formId={formId}
        orgId={orgId}
        isAnon={isAnon}
        isDraft={isDraft}
        errors={errors}
        readOnly={readOnly}
      />
    )
  }, [
    form,
    submission,
    submissionFormatted,
    submissionLoading,
    loadSubmissionError,
    submissionId,
  ])

  return memoizedComponent
}
