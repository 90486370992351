import { Form } from '@formio/react'
import React, { useEffect } from 'react'

import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { EdifyButton } from '../../components/buttons'
import { REACT_APP_FORMIO_PROJECT_URL } from '../../../config'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import ErrorContainer from '../../components/error/ErrorContainer'
import {
  SubmissionPageViewModel,
  SubmissionPageViewModelProps,
  useSubmissionPageViewModel,
} from '../submission/SubmissionViewModel'
import { updateFormComponentsWithOrgFields } from '../../components/panels/form-renderer/CustomFormioComponents'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import PageContainer from '../../components/page/PageContainer'
import { ROUTE_CORRECTIVE_ACTIONS } from './CorrectiveActionsPage'

const NewCorrectiveActionPage: React.FC = () => {
  let imgUploads: NodeListOf<HTMLAnchorElement>
  const vm: SubmissionPageViewModelProps = useSubmissionPageViewModel()
  const { hierarchies } = useOrganizationProvider()
  const navigate = useNavigate()

  const addClickListener = () => {
    imgUploads = document.querySelectorAll(
      '.formio-component-file .fileSelector',
    )

    imgUploads.forEach((imgUpload) => {
      imgUpload.addEventListener('click', handleImgUpload)
    })
  }
  const handleImgUpload = (event: any) => {
    if (event.target.tagName == 'A') return
    const fileBrowseLink = document.querySelector(
      '.formio-component-file .fileSelector',
    )

    if (fileBrowseLink && fileBrowseLink.contains(event.target)) {
      // Trigger a click event on the found <a> tag
      const link = fileBrowseLink.querySelector('a')
      if (link) {
        link.click()
      }
    }
  }

  const getOrgSpecificElements = async () => {
    if (hierarchies == undefined) {
      //do not change the form if we cannot get the hierarchy names. will use what is already in the form
      return
    }
    //find level 1 in hierarchy names
    // const level1FieldName = hierarchyNamesData.find(
    const level1FieldName = hierarchies.find(
      (element: { level: number; name: string }) => element.level == 1,
    )
    //find level 0 / project in hierarchy names
    const projectFieldName = hierarchies.find(
      (element: { level: number; name: string }) => element.level == 0,
    )

    updateFormComponentsWithOrgFields(
      vm.form,
      (level1FieldName && level1FieldName.name) || 'Level 1',
      (projectFieldName && projectFieldName.name) || 'Project',
    )
    // setIsFinishedLoadingNames(true)
    vm.setIsDataChanged(false)
  }

  // useEffect(() => {
  //   if (vm.form) {
  //     getOrgSpecificElements()
  //   }
  // }, [vm.form])

  useEffect(() => {
    return () => {
      if (imgUploads) {
        imgUploads.forEach((imgUpload) => {
          imgUpload.removeEventListener('click', handleImgUpload)
        })
      }
    }
  }, [])
  if (!vm) return <p>no vm</p>
  if (!vm.form) return <p>no form</p>

  return (
    <PageContainer
      title={'New Corrective Action'}
      // renderRightItems={renderRightItems}
      breadCrumbs={[
        { title: 'All Corrective Actions', to: ROUTE_CORRECTIVE_ACTIONS },
        { title: 'New Corrective Action', to: `${ROUTE_CORRECTIVE_ACTIONS}/new` },
      ]}
      mainPageError={vm.error || vm.submissionError}
      mainPageLoading={vm.loading}
    >
      <InnerPageContainer sx={{ padding: '24px' }}>
        {vm.formSubmissionError && (
          <ErrorContainer>{vm.formSubmissionError}</ErrorContainer>
        )}
        <Form
          url={`${REACT_APP_FORMIO_PROJECT_URL}/${vm.form.path}`}
          form={vm.form}
          onChange={vm.onFormChange}
          submission={vm.submission}
          formReady={vm.setFormIOForm}
          // Still a bug: if user uploads an image then clears it
          // the event listener wont be there on the new image uploader.
          // onRender={addClickListener}
        />
      </InnerPageContainer>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '24px',
        }}
      >
        <EdifyButton noBackground title='Cancel' onClick={() => navigate(-1)} />
        <Box>
          <EdifyButton
            data-testid={'SubmissionButton'}
            primary
            disabled={vm.isSubmitting}
            title={`Submit ${vm.form.title}`}
            onClick={()=>vm.saveSubmission()}
          />
        </Box>
      </Box>
    </PageContainer>
  )
}
export default NewCorrectiveActionPage
