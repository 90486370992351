
function generatePaginationString(currentPage: number, totalPages: number) {
  const pages = []
  
  // if no pages show 1
  if(totalPages == 0){
    return ['1']
  }
  
  // If total pages are less than 7, just fill the pages sequentially
  if(totalPages <= 7) {
    for(let i = 1; i <= totalPages; i++) {
      pages.push(i.toString())
    }
  } 
  else {
    // Always include the first page
    pages.push('1')

    // Determine middle pages start and end
    let middleStart = Math.max(2, currentPage - 2)
    let middleEnd = Math.min(totalPages - 1, currentPage + 2)
    
    // Adjust middle range if near start
    if(currentPage <= 3) {
      middleEnd = 6
    }

    // Adjust middle range if near end
    if(currentPage >= totalPages - 3) {
      middleStart = totalPages - 5
    }

    // Include ellipsis after first page if needed
    if(middleStart !== 2) {
      pages.push('...')
    }

    // Include the middle pages
    for(let i = middleStart; i <= middleEnd; i++) {
      pages.push(i.toString())
    }

    // Include ellipsis before last page if needed
    if(middleEnd !== totalPages - 1) {
      pages.push('...')
    }

    // Always include the last page
    pages.push(totalPages.toString())
  }

  return pages
}

export default generatePaginationString