// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigDev = {
  apiKey: 'AIzaSyDnjLz6vE86Wma5i6xuDxMdHknYpgHzk98',
  authDomain: 'rome-dev-6e3fe.firebaseapp.com',
  projectId: 'rome-dev-6e3fe',
  storageBucket: 'rome-dev-6e3fe.appspot.com',
  messagingSenderId: '943619674641',
  appId: '1:943619674641:web:1d2dca1bc36497ad26d7b0',
  measurementId: 'G-1QN6G7WHZL',
}

export default firebaseConfigDev
