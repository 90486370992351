import { Box, SxProps, Typography } from '@mui/material'
import React from 'react'
import EdifySwitch from '../EdifySwitch/EdifySwitch'
import { AppColors, AppFonts } from '../../../Theme'
interface Props {
  text: string
  subText: string
  checked?: boolean
  onChange: (e?: any) => void
  sx?: SxProps
  disabled?: boolean
}
const EdifyToggle: React.FC<Props> = ({
  text,
  subText,
  checked,
  onChange,
  sx,
  disabled = false,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '24px',
        ...sx,
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '16px',
          width: '250px',
        }}
      >
        <Typography sx={{ ...AppFonts.textSMedium, color: AppColors.gray700 }}>
          {text}
        </Typography>
        <Typography sx={{ ...AppFonts.textXSMedium, color: AppColors.gray50 }}>
          {subText}
        </Typography>
      </Box>
      <EdifySwitch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </Box>
  )
}

export default EdifyToggle
