import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'

import { ROUTE_USERS } from '../users/UsersPage'
import useQuery from '../../../core/hooks/useQuery'
import { DEFAULT_LIMIT } from '../users/UsersViewModel'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import {
  ICertificate,
  ICertificateCreate,
} from '../../../domain/interfaces/ICertificate'
import {
  anonGetAllCertificates,
  deleteCertificate,
  getCertificates,
} from '../../../data/repositories/certificates/CertificatesRepository'
import {
  adminCreateCertificate,
  adminDeleteCertificates,
  adminEditCertificate,
} from '../../../domain/usecases/certificates/CertificatesUsecases'

export interface CertificatesPageViewModelProps {
  loadCertificates: () => void
  deleteCertificates: (certificateIds: string[]) => void
  certificates: ICertificate[] | undefined
  certificatesLoading: boolean
  certificatesError: string | undefined
  submissionError: string | undefined
  createCertificate: (
    certificate: ICertificateCreate,
    contractorId: string,
  ) => void
  updateCertificate: (certificate: ICertificateCreate, id: string) => void
  totalCount: number
  loadMore: (paginationModel: GridPaginationModel) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  reload: () => void
  handleFilterButtonClicked: (button: string) => void
  queryHelper: Record<string, any>
}

const CertificatesPageContext =
  React.createContext<CertificatesPageViewModelProps | null>(null)

export function useCertificatesPageViewModel(): CertificatesPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(CertificatesPageContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
  isAnon?: boolean
  anonOrgId?: string
  anonUserId?: string
}

export const CertificatesPageViewModel: React.FC<Props> = ({
  children,
  isAnon = false,
  anonUserId,
  anonOrgId,
}) => {
  const queryHelper = useQuery({
    skip: 0,
    limit: DEFAULT_LIMIT,
    sortBy: 'expirationDate',
    order: 'asc',
  })
  const { defaultQuery, reset, getQueryStringFromQuery, setNew, query } =
    queryHelper
  const { userId } = useParams()
  const navigate = useNavigate()
  const [certificates, setCertificates] = useState<ICertificate[] | undefined>(
    undefined,
  )
  const [certificatesLoading, setCertificatesLoading] = useState<boolean>(false)
  const [certificatesError, setCertificatesError] = useState<
    string | undefined
  >(undefined)
  const [submissionError, setSubmissionError] = useState<string | undefined>(
    undefined,
  )
  const [totalCount, setTotalCount] = useState<number>(0)
  const [skip, setSkip] = useState<number>(0)

  const handleFilterButtonClicked = (button: string) => {
    const newSubmissionQueryField = { ...query }
    delete newSubmissionQueryField.type
    switch (button) {
      case 'Certificates':
        newSubmissionQueryField.type = 'certificate'
        break
      case 'Trainings':
        newSubmissionQueryField.type = 'training'
        break
    }
    fetchCertificates(0, newSubmissionQueryField)
  }

  const fetchCertificates = async (
    fetchSkip: number,
    query: Record<string, any>,
  ) => {
    setCertificatesLoading(true)
    setCertificatesError(undefined)

    // load submissions or corrective actions
    // Not sure how BE will handle userId
    const newQuery = { ...query, skip: fetchSkip, userId: userId }
    let res
    if (isAnon) {
      // TODO: replace and test with ANON call when BE done
      res = await anonGetAllCertificates(
        anonOrgId!,
        anonUserId!,
        getQueryStringFromQuery(newQuery),
      )
      // res = await getCertificates(getQueryStringFromQuery(newQuery))
    } else {
      res = await getCertificates(getQueryStringFromQuery(newQuery))
    }
    setCertificatesLoading(false)
    if (res.isRight()) {
      setTotalCount(res.right?.total_count || 0)
      const certs = res.right?.data ?? []
      // for testing until training is available
      setCertificates(certs as ICertificate[])
      setSkip(fetchSkip)
    } else {
      setCertificatesError(res.left?.message)
    }
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchCertificates(fetchSkip, query)
  }

  const loadCertificates = async () => {
    setCertificatesLoading(true)
    setSkip(0)
    setCertificates([])

    fetchCertificates(0, query)
  }

  const updateCertificate = async (
    certificate: ICertificateCreate,
    id: string,
  ) => {
    setSubmissionError(undefined)
    const result = await adminEditCertificate(certificate, id)

    if (result.isLeft()) {
      setSubmissionError(result.left?.message ?? 'Error updating certificate.')
      ErrorToast({ title: 'Error updating certificate.' })
    } else {
      SuccessToast({ title: `Certificate: ${certificate.name} Updated` })
      navigate(`${ROUTE_USERS}/${userId}`)
    }
  }

  const createCertificate = async (
    certificate: ICertificateCreate,
    userId: string,
  ) => {
    setSubmissionError(undefined)

    const result = await adminCreateCertificate(certificate, userId)

    if (result.isLeft()) {
      setSubmissionError(result.left?.message ?? 'Error creating certificate.')
      ErrorToast({ title: 'Error creating certificate.' })
    } else {
      SuccessToast({
        title: `Certificate: ${certificate.name} Created`,
      })
      navigate(`${ROUTE_USERS}/${userId}`)
    }
  }

  const deleteCertificates = async (certificateIds: string[]) => {
    showConfirmationDialog({
      title: 'Confirm Delete',
      message:
        'Are you sure you want to delete this certificates from the database? This action cannot be undone.',
      cancelText: 'Cancel',
      confirmText: 'Delete',
      onCancel: function (): void {
        hideConfirmationDialog()
      },
      onConfirm: async () => {
        // const results = await adminDeleteCertificates(certificateIds)
        const cb = certificateIds.map(async (id) => {
          return await deleteCertificate(id)
        })
        const result = await Promise.all(cb)

        // if (results.isLeft()) {
        //   ErrorToast({
        //     title: 'Error removing certificates.',
        //   })
        //   return
        // }

        SuccessToast({
          title: 'Certificates has been deleted successfully.',
        })
        hideConfirmationDialog()
        loadCertificates()
      },
    })
  }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    fetchCertificates(0, {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    })
  }

  useEffect(() => {
    if (userId) {
      loadCertificates()
    }
  }, [userId])

  return (
    <CertificatesPageContext.Provider
      value={{
        handleFilterButtonClicked,
        loadCertificates,
        sortClickedHandler,
        certificates,
        certificatesLoading,
        certificatesError,
        deleteCertificates,
        submissionError,
        createCertificate,
        updateCertificate,
        totalCount,
        loadMore,
        reload: loadCertificates,
        queryHelper,
      }}
    >
      {children}
    </CertificatesPageContext.Provider>
  )
}
