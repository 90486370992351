import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
} from '@mui/material'
import React, { useEffect } from 'react'
import { AppColors, AppFonts } from '../../Theme'
import { EdifyButton } from '../../components/buttons'
import { useI18nProvider } from '../../../providers/InternationalizationProvider'
import { MojoFormRender } from './MojoFormRender'
import { IForm } from '../../../domain/interfaces/IForm'
import useSubmissionLoader from '../../../core/hooks/useSubmission'
import { ISignee } from './SingeeList'
import { ETypography } from '../../components/fonts/ETypography'
import { EdifyFormField } from '../../components/form'
import {
  createAnonSubmission,
  createSubmission,
  getCurrentUser,
  updateSubmission,
} from '../../../domain/domain'
import useFormio, { IUseFormioReturn } from '../../../core/hooks/useFormio'
import MojoFormioRenderer1 from './MojoFormRenderer1'
import { dateToMMMDYYYY } from '../../../core/core'
import ErrorContainer from '../../components/error/ErrorContainer'
import { canEditSubmission } from './submissionPermissionHelpers'

interface Props {
  open: boolean
  handleClose: (reload?: any) => void
  orgId: string
  childForm: IForm
  subId?: string | undefined
  submitter: ISignee | undefined
  parentSubId: string
  currentUserId: string | undefined
  isAnon: boolean
  formReadOnly?: IForm | undefined
  canSign: boolean
  initMode: 'VIEW' | 'EDIT' | 'CREATE'
}

const SubmissionModal: React.FC<Props> = ({
  open,
  handleClose,
  childForm,
  orgId,
  subId = undefined,
  submitter,
  parentSubId,
  currentUserId,
  isAnon,
  formReadOnly,
  canSign,
  initMode,
}) => {
  const [name, setName] = React.useState<string | undefined>(
    submitter ? submitter.name : undefined,
  )
  const [mode, setMode] = React.useState<'VIEW' | 'EDIT' | 'CREATE'>(initMode)
  const [nameInput, setNameInput] = React.useState<string>(name ?? '')
  const [error, setError] = React.useState<string | undefined>('')
  const [modalFormSubmitting, setModalFormSubmitting] =
    React.useState<boolean>(false)
  const submissionLoader = useSubmissionLoader(subId)
  const { submission, submissionLoading, setSubmission, reloadSubmission } =
    submissionLoader
  const fio: IUseFormioReturn = useFormio(childForm.id, submission, {
    form: childForm,
    orgId: orgId,
    isAnon: false,
  })

  const onSubmit = async () => {
    // view sub user hits update
    if (mode === 'VIEW') {
      setMode('EDIT')
    }
    const trimName = nameInput.trim()
    if (nameInput != '' && trimName == '') {
      setNameInput('')
      setError('Name can not be blank')
      return
    }
    if (nameInput == '') {
      setError('Name is required')
      return
    }
    if (!fio.submissionPayload) return
    const submitterName = submitter ? false : trimName
    const submitterId = submitter ? submitter.id : false
    const modalSubmissionData = {
      data: fio.submissionPayload.data,
      formId: childForm.id,
      state: fio.submissionPayload.state,
      // ...fio.submissionPayload,
      parentSubmissionId: parentSubId,
      ...(submitterName && { submitterName }),
      ...(submitterId && { submitterId }),
    }

    let res
    if (subId) {
      //TODO: this call is breaking BE, think it is a
      // BE fix.
      res = await updateSubmission(subId, modalSubmissionData)

      // handleClose({ ...res.right, name: nameInput })
      return
    }
    setModalFormSubmitting(true)
    // if (!submitter) {
    if (isAnon) {
      res = await createAnonSubmission(modalSubmissionData, orgId)
    } else {
      res = await createSubmission(modalSubmissionData)
    }
    setModalFormSubmitting(false)
    // TODO: RELOAD
    if (res.isRight()) {
      handleClose({ ...res.right, name: trimName })
      return
    } else {
      //handle error in model
      setError(res.left?.message ?? 'Error Occurred. Please try again.')
    }
  }

  useEffect(() => {
    if (!subId && canSubmit) {
      setMode('CREATE')
      return
    }
    if (subId && canEdit) {
      setMode('VIEW')
      return
    }

    return () => {
      setSubmission(undefined)
    }
  }, [open])
  useEffect(() => {
    if (error && nameInput !== '') {
      setError(undefined)
    }
  }, [nameInput])
  const renderError = () => {
    return <ErrorContainer>{error}</ErrorContainer>
  }

  const renderName = () => {
    if (!name || name === '')
      return (
        <EdifyFormField sx={{ marginBottom: '24px' }}>
          <EdifyFormField>Name</EdifyFormField>
          <Input
            disableUnderline
            className='form-control'
            value={nameInput}
            onChange={(e: any) => setNameInput(e.target.value)}
          />
        </EdifyFormField>
      )

    return (
      <Box sx={{ marginBottom: '12px' }}>
        <ETypography font='SM' color='gray700'>
          {name}
        </ETypography>
        {submitter?.status == 'submitted' && (
          <ETypography font='SM' color='gray700'>
            Submitted {dateToMMMDYYYY(submission?.updatedAt ?? '')}
          </ETypography>
        )}
      </Box>
    )
  }
  const currentUser = getCurrentUser()
  const canEdit = canEditSubmission(
    childForm,
    submitter?.id ?? 'none',
    currentUser,
  )

  const canSubmit =
    (canSign && !submitter) || (submitter && submitter.userId == currentUserId)

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby='child-modal-title'
        aria-describedby='child-modal-description'
        sx={{ marginTop: '50px' }}
      >
        <Box
          sx={{
            ...styles,
            width: {
              sm: '100%',
              md: '600px',
            },
          }}
        >
          <DialogTitle
            sx={{
              ...AppFonts.headlineSDesktopSemibold,
              color: AppColors.gray700,
              p: 0,
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            {childForm.title}
            {error && renderError()}
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              pb: 12,
              overflowY: 'scroll',
              height: 'auto',
              overflow: 'scroll',
            }}
          >
            {!submissionLoading && (
              <>
                {renderName()}
                <MojoFormioRenderer1
                  key={mode}
                  mode={mode}
                  orgId={orgId}
                  formId={childForm.id}
                  submissionId={submission?.id ?? 'ERROR NEED ID'}
                  // options={{ ...options}}
                  options={{ readOnly: mode === 'VIEW' }}
                  form={childForm}
                  formLoading={false}
                  formSubmissionError={undefined}
                  formReadOnly={formReadOnly}
                  isAnon={false}
                  {...submissionLoader}
                  {...fio}
                />
              </>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              p: 0,
            }}
          >
            <EdifyButton
              data-testid='CloseDialogButton'
              fullWidth
              title={'Cancel'}
              secondary
              onClick={() => handleClose(false)}
            />

            <EdifyButton
              //NOTE: have subId != undefined to match mobile and not allow update
              // TODO: update when requested
              hide={!canSubmit || subId != undefined}
              onClick={onSubmit}
              disabled={modalFormSubmitting}
              title={`${subId && mode == 'VIEW' ? 'Update' : 'Submit'}`}
              fullWidth
            />
          </DialogActions>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export default SubmissionModal

const styles = {
  position: 'absolute',
  top: '10vh',
  left: '50%',
  transform: 'translate(-50%, -50px)',
  height: 'auto',
  maxHeight: '80vh',
  overflow: 'scroll',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 24,
}
