export class Failure {
  readonly message: string
  readonly statusCode?: number
  readonly errors?: any
  readonly data?: any


  constructor(message?: string, statusCode?: number, errors?: any, data?: any) {
    this.message = message ?? 'Something went wrong.'
    this.statusCode = statusCode
    this.errors = errors ?? {}
    this.data = data ?? {}
  }
}
