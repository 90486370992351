import React, { useContext, useState } from 'react'

export interface SettingsPopoverViewModelProps {
  isProfileClick: boolean
}

const SettingsPopoverContext =
  React.createContext<SettingsPopoverViewModelProps | null>(null)

export function useSettingsPopoverViewModel(): SettingsPopoverViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(SettingsPopoverContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const SettingsPopoverViewModel: React.FC<Props> = ({ children }) => {
  const [isProfileClick, setIsProfileClick] = useState<boolean>(false)

  return (
    <SettingsPopoverContext.Provider value={{ isProfileClick }}>
      {children}
    </SettingsPopoverContext.Provider>
  )
}
