/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { CloseOutlined } from '@mui/icons-material'
import { PencilIcon } from '../../../core/components/icons/PenciIIcon'
import './styles.css'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { AppColors, AppFonts, AppHovers } from '../../Theme'
import { Input } from '@mui/material'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { createSubLocation, updateSubLocation } from '../../../domain/domain'
import { levelStyles } from './locationStyles'

const subLocationsNameDefault = [
  'Site-x',
  'Level-x',
  'Floor-x',
  'Room-x',
  'sl5-x',
]

const CreateSiblingNode = ({ node }) => {
  const [showInput, setShowInput] = useState(false)
  const pl = node.data.level * 24 ?? 0
  return (
    <div className={'node-container'} onClick={() => setShowInput(!showInput)}>
      <div className='node-content' style={{ paddingLeft: pl + 'px' }}>
        {!showInput && <span className='node-text'>placeholder</span>}
        {showInput && <input className='node-text' />}
      </div>
    </div>
  )
}

const Node1 = ({ node, style, dragHandle, tree }) => {
  const { subLocationHierarchies } = useOrganizationProvider()
  const subLocationsName = subLocationHierarchies
    ? subLocationHierarchies.map((sl) => sl.name)
    : subLocationsNameDefault

  const handleDelete = () => {
    // Delete from DB then from tree
    console.log('deleting: ', node.id)
    tree.delete(node.id)
  }

  const handleCreateSub = () => {
    console.log('--------handleCreateSub-------')
    console.log({ node })
    console.log({ tree })
    node.focus()
    if (node.level <= 3) {
      node.tree.createInternal()
    } else {
      node.tree.createLeaf()
    }
  }
  const handleCreateSibling = async (thisNode) => {
    console.log('-------handleCreateSibling--------')
    console.log({ node })
    console.log(tree.root.children[0].data.locationId)
    console.log(tree.root.children[0].data.locationId)
    node.focus()

    let created = await thisNode.tree.createInternal()
    console.log({ created })
  }
  const handleCancel = () => {
    console.log('-------handleCancel!--------')
    console.log({ node: node })
    //if editing just reset
    node.reset()
    // if creating need to remove node
  }
  const nodeChanged = async (inputString) => {
    console.log('-------nodeChanged--------')
    console.log({ node: node })
    if (inputString == '') {
      //   ErrorToast({ title: 'Error' })
      node.tree.delete(node.id)
      return
    }
    //creating a dummy id
    let res
    const data = {
      level: level + 1,
      name: inputString,
      parentId:
        node.parent.id == '__REACT_ARBORIST_INTERNAL_ROOT__'
          ? tree.root.children[0].data.locationId
          : node.parent.id,
      locationId: tree.root.children[0].data.locationId,
    }
    if (!node.data.id || node.data.name == '') {
      console.log('addSubLevel data to DB:')
      console.log(data)
      res = await createSubLocation(data)
      if (res.isLeft()) {
        ErrorToast({ title: 'Error Creating' })
        node.tree.delete(node.id)
        return
      }
      console.log({ res })
      SuccessToast({ title: 'Created' })
    } else {
      console.log('editSubLevel data to DB:')

      res = await updateSubLocation(data, node.data.id)
      if (res.isLeft()) {
        ErrorToast({ title: 'Error Creating' })
        node.tree.delete(node.id)
        return
      }
      SuccessToast({ title: 'Update' })
    }

    // if DB fails remove from tree
    const error = ''
    if (error == 'error') {
      node.tree.delete(node.id)
      return
    }
    // success
    // todo figure out how to store id from db

    node.submit(inputString)
    node.id = res.right.id
    tree.reset()
    console.log({ tree })
    console.log({ node })
  }
  const handleOnBlur = (node) => {
    node.reset()
  }

  const level = parseInt(node.level)
  // TODO: handle first Node in create form
  // if (node.id == 'init'  && !node.data.name =='' && !node.isEditing) {
  //   console.log('========')
  //   console.log(node.name)
  //   console.log(node)
  //   node.edit()
  // }

  if (node.data.isPlaceholder) {
    return <CreateSiblingNode node={node} />
  }
  return (
    <div
      className={`node-container ${node.state.isSelected ? 'isSelected' : ''}`}
      style={style}
      ref={dragHandle}
    >
      <div
        className='node-content'
        onClick={() => node.isInternal && node.toggle()}
      >
        {node.isLeaf ? (
          <></>
        ) : (
          <span className='arrow'>
            {node.isOpen ? (
              <KeyboardArrowDown sx={{ color: AppColors.neutral950 }} />
            ) : (
              <KeyboardArrowRight sx={{ color: AppColors.neutral950 }} />
            )}
          </span>
        )}
        <span className='node-text'>
          {node.isEditing ? (
            <Input
              disableUnderline
              type='text'
              defaultValue={node.data.name}
              onFocus={(e) => e.currentTarget.select()}
              onBlur={() => handleOnBlur(node)}
              onKeyDown={(e) => {
                // if (e.key === 'Escape') node.reset()
                if (e.key === 'Enter') nodeChanged(e.currentTarget.value)
              }}
              autoFocus
            />
          ) : (
            <>
              {/* Text L/Semibold */}
              <span
                style={{
                  ...AppFonts.textLSemibold,
                  marginLeft: '4px',
                }}
              >
                {node.data.name}
              </span>
              {/* Text XS/Medium */}

              <LevelBadge level={level} label={subLocationsName[level]} />
            </>
          )}
        </span>
      </div>

      <div className='file-actions'>
        <div className='folderFileActions'>
          {!node.isEditing && (
            <>
              {level <= 4 && (
                <div onClick={() => handleCreateSibling(node)}>
                  <LevelBadge
                    level={level}
                    label={`Add ${subLocationsName[level]}`}
                  />
                </div>
              )}
              {level <= 3 && (
                <div onClick={() => handleCreateSub(node)}>
                  <LevelBadge
                    level={level + 1}
                    label={`Add ${subLocationsName[level + 1]}`}
                  />
                </div>
              )}
              <button
                style={{ marginLeft: '20px' }}
                onClick={() => node.edit()}
                title='Rename...'
              >
                <PencilIcon />
              </button>
              <button onClick={() => handleDelete()} title='Delete'>
                <CloseOutlined />
              </button>
            </>
          )}
          {node.isEditing && (
            <button onClick={() => handleCancel(node)} title='Cancel'>
              <CloseOutlined /> <p>cancel</p>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Node1

export const LevelBadge = ({ level, label }) => {
  return (
    <span
      style={{
        ...AppFonts.textXSMedium,
        marginLeft: '12px',
        color: levelStyles[level] ? levelStyles[level].color : 'black',
        background: levelStyles[level] ? levelStyles[level].background :'grey',
        // color:'black',
        // background: 'grey',
        padding: '2px 10px',
        borderRadius: '16px',
        cursor: 'pointer',
        width: 'auto',
      }}
    >
      {label}
    </span>
  )
}
