import { Either, Failure } from '../../../core/core'
import { INotification, INotificationList } from '../../../domain/interfaces/INotification'
import { httpGet, httpPut } from '../../data'

const notificationsURL = '/notifications'

export async function notificationsGet(skip:number | string, limit: number | string): Promise<
  Either<Failure, INotificationList>
  // eslint-disable-next-line 
> {
  const res: Either<Failure, INotificationList> = await httpGet(
    `${notificationsURL}?skip=${skip}&limit=${limit}`,false
  )

  return res
}

export async function notificationMarkAsRead(id: string): Promise<
  Either<Failure, INotification>
// eslint-disable-next-line indent
> {
  const res: Either<Failure, INotification> = await httpPut(
    `${notificationsURL}/markAsRead/${id}`,
    {}
  )
  return res
}