import React from 'react'
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'
import PageHeader from './PageHeader'
import { AppColors, AppFonts, AppHovers } from '../../Theme'
import { useNavigate } from 'react-router-dom'
import { LoadingTile } from '../tiles/LoadingTile'
import { EdifyButton } from '../buttons'
import ErrorContainer from '../error/ErrorContainer'
import { ArrowForward } from '../../../core/components/icons/ArrowForward'
import { ETypography } from '../fonts/ETypography'
import NotFoundPage from '../../pages/NotFoundPage'
import { Settings, SettingsApplications } from '@mui/icons-material'
import EdifyIconButton from '../buttons/EdifyIconButton/EdifyIconButton'

// 1065 plus the padding on the left and right
export const MAX_BODY_CONTENT_WIDTH = 1065 + 64
interface IBreadCrumb {
  title: string
  to: string
  cb?: () => void
}
interface PageContainerProps extends _InnerPageContainerProps {
  mainPageLoading?: boolean
  mainPageError?: string | undefined
  mainPageReload?: () => void
  background?: string
  canView?: boolean
  noMaxWidth?: boolean
  pageSettingsLink?: string
  title?: string | React.ReactNode
  errorObj?: any | undefined
}

const PageContainer: React.FC<PageContainerProps> = ({
  mainPageLoading,
  mainPageError,
  title,
  children,
  mainPageReload,
  background,
  breadCrumbs,
  canView = true,
  noMaxWidth = false,
  pageSettingsLink,
  errorObj,
  ...rest
}) => {
  const navigate = useNavigate()
  if(errorObj && errorObj.status === 403) {
    return <NotFoundPage />
  }
  if (!canView) {
    return <NotFoundPage />
  }
  // First check if loading, may or may not have title
  // could potentially add, sections loading with in here
  // later or pass children
  if (mainPageLoading) {
    return (
      <_InnerPageContainer
        title={title ?? ''}
        breadCrumbs={breadCrumbs}
        noMaxWidth={noMaxWidth}
      >
        <LoadingTile />
      </_InnerPageContainer>
    )
  }

  // once loading is done check for errors provides and option to go back and can accept a reload prop
  if (mainPageError) {
    return (
      <_InnerPageContainer
        title={title || 'Page Error'}
        breadCrumbs={breadCrumbs}
        noMaxWidth={noMaxWidth}
      >
        <ErrorContainer error={mainPageError}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '24px',
            }}
          >
            <EdifyButton
              data-testid='PageContainerGoBack'
              buttonStyle={{ padding: '0px 24px' }}
              title='Go back'
              onClick={() => navigate(-1)}
            />
            {mainPageReload && (
              <EdifyButton
                data-testid='PageContainerReload'
                buttonStyle={{ padding: '0px 24px' }}
                noBackground
                title='Reload Page'
                onClick={mainPageReload}
              />
            )}
          </Box>
        </ErrorContainer>
      </_InnerPageContainer>
    )
  }

  // Once we get here loading and error are false
  return (
    <_InnerPageContainer
      title={title}
      background={background}
      breadCrumbs={breadCrumbs}
      noMaxWidth={noMaxWidth}
      pageSettingsLink={pageSettingsLink}
      {...rest}
    >
      {children}
    </_InnerPageContainer>
  )
}

interface PageWrapperProps {
  children: React.ReactNode
  background?: string
  noMaxWidth?: boolean
}

const _PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  noMaxWidth = false,
  ...props
}) => {
  return (
    <Stack
      sx={{
        position: 'relative',
        p: {
          xs: 0, // Zero padding on the smallest breakpoint (mobile)
          sm: 32, // Padding 32 starting from small devices and up
        },
        maxWidth: noMaxWidth ? 'none' : MAX_BODY_CONTENT_WIDTH,
        margin: 'auto',
        height: '100%',
        background: props.background ?? AppColors.neutral25,
      }}
      {...props}
    >
      {children}
    </Stack>
  )
}

interface _InnerPageContainerProps {
  children: React.ReactNode
  title?: string | React.ReactNode
  subtitle?: string
  subTitleComponent?: React.ReactNode
  renderRightItems?: () => React.ReactNode
  reload?: () => void
  breadCrumbs?: IBreadCrumb[]
  topActions?: { title: string; callback: () => void }[]
  padding?: boolean
  stickyHeader?: boolean
  background?: string
  breadCrumbSX?: SxProps
  noMaxWidth?: boolean
  pageSettingsLink?: string
}
// Wraps all pages
const _InnerPageContainer: React.FC<_InnerPageContainerProps> = ({
  children,
  renderRightItems,
  title,
  breadCrumbs,
  topActions,
  subtitle,
  subTitleComponent,
  background,
  breadCrumbSX,
  noMaxWidth,
  pageSettingsLink,
}) => {
  const navigate = useNavigate()

  const renderTopActions = () => {
    if (!topActions) return []
    const actions = topActions.map((ta) => (
      // Should this be a button
      <Typography
        data-testid={ta.title}
        sx={{
          ...AppFonts.textMSemibold,
          color: AppColors.primary600,
          marginLeft: '24px',
          '&:hover': {
            cursor: 'pointer',
            ...AppHovers.primaryButtonText,
          },
        }}
        key={ta.title}
        onClick={ta.callback}
      >
        {ta.title}
      </Typography>
    ))
    return <Box style={{ display: 'flex' }}>{actions}</Box>
  }

  return (
    <_PageWrapper background={background} noMaxWidth={noMaxWidth}>
      <Box
        style={{
          display: 'flex',
          marginBottom: '20px',
          alignContent: 'center',
          justifyContent:
            topActions || pageSettingsLink ? 'space-between' : 'flex-start',
        }}
      >
        {breadCrumbs && (
          <Breadcrumbs
            aria-label='breadcrumb'
            separator={<ArrowForward />}
            sx={{ ...breadCrumbSX }}
          >
            {breadCrumbs.map((item, index) => {
              if (item.cb) {
                return (
                  <ETypography
                    onClick={item.cb}
                    font='MM'
                    color='gray50'
                    key={index}
                  >
                    {item.title}
                  </ETypography>
                )
              }
              if (item.to != '') {
                return (
                  <Link
                    key={index}
                    sx={{
                      ...AppFonts.textMMedium,
                      '&: hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => navigate(item.to)}
                    underline='hover'
                    color={
                      index + 1 == breadCrumbs.length
                        ? AppColors.primary600
                        : AppColors.gray50
                    }
                  >
                    {item.title ?? ''}
                  </Link>
                )
              }
              return (
                <ETypography font='MM' color='gray50' key={index}>
                  {item.title}
                </ETypography>
              )
            })}
          </Breadcrumbs>
        )}
        {topActions && renderTopActions()}
        {pageSettingsLink && (
          // <IconButton
          //   color='primary'
          //   onClick={() => navigate(pageSettingsLink)}
          // >
          //   <SettingsApplications sx={{ color: 'gray' }} />
          // </IconButton>
          <EdifyIconButton
            background={AppColors.primary700}
            onClick={() => navigate(pageSettingsLink)}
            sx={{
              border: 'none',
              padding: '12px 8px',
              marginLeft: '12px',
              '&:hover': {
                background: AppColors.primary600,
              },
            }}
          >
            <Settings sx={{ color: 'white' }} />
          </EdifyIconButton>
        )}
      </Box>
      <Box
        data-testid={'PageWrapperContainer'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {typeof title === 'string' ? <PageHeader title={title} /> : title}

          {subtitle && (
            <ETypography font='XLR' color='gray50' sx={{ marginTop: '8px' }}>
              {subtitle}
            </ETypography>
          )}
          {subTitleComponent && subTitleComponent}
        </Box>
        {renderRightItems && renderRightItems()}
      </Box>
      <>{children}</>
    </_PageWrapper>
  )
}

export default PageContainer

// Name
