import React, { useEffect } from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  AdminOrgsPageViewModel,
  AdminOrgsPageViewModelProps,
  useAdminOrgsPageViewModel,
} from './AdminOrgsViewModel'
import { EdifyButton } from '../../components/buttons'
import AdminOrgsDataGrid from './AdminOrgsDataGrid'

// import AdminOrgsFilter from './AdminOrgsFilter'
import { useNavigate } from 'react-router-dom'
import AdminOrgsFilter from './AdminOrgsFilter'
import {  ROUTE_ORG_SELECTION } from '../org_selection/OrgSelectionPage'
import { useGlobalProvider } from '../../../providers/GlobalProvider'

export const ROUTE_ADMIN_ORGS = '/adminOrgs'

export const AdminOrgsPage: React.FC = () => {
  return (
    <AdminOrgsPageViewModel>
      <_Page />
    </AdminOrgsPageViewModel>
  )
}

export const _Page: React.FC = () => {
  const vm: AdminOrgsPageViewModelProps = useAdminOrgsPageViewModel()
  const { setIsGlobalUserView} = useGlobalProvider()

  useEffect(()=>{
    setIsGlobalUserView(true)
  },[])

  return (
    <PageContainer
      title={'All Organizations'}
      subtitle='Access all other Organizations as Global Admin'
      breadCrumbs={[{ title: 'My Organizations', to: ROUTE_ORG_SELECTION }]}
    >
      {/* <AdminOrgsFilter vm={vm} /> */}
      <AdminOrgsDataGrid
        vm={vm}
        title={'AdminOrg'}
        checkboxSelection={false}
      />
    </PageContainer>
  )
}
